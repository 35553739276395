import { render, staticRenderFns } from "./GeneralCepariumSelectable.vue?vue&type=template&id=0a20ce8a&scoped=true"
import script from "./GeneralCepariumSelectable.vue?vue&type=script&lang=js"
export * from "./GeneralCepariumSelectable.vue?vue&type=script&lang=js"
import style0 from "./GeneralCepariumSelectable.vue?vue&type=style&index=0&id=0a20ce8a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a20ce8a",
  null
  
)

export default component.exports