<template>
  <v-main fluid>
    <v-container fluid>
      <!-- Logos Row -->
      <v-row align="center" justify="center" class="pt-5">
        <v-col cols="6" align="center">
          <v-img width="400px" src="/img/logo-solena-mono-white.png"> </v-img>
        </v-col>
      </v-row>

      <!-- Card row -->
      <v-row align="baseline" justify="center" class="pt-5">
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card :loading="loading" elevation="20">
            <v-card-title class="justify-center">
              <p class="text-center mb-0">{{ translations.login }}</p>
            </v-card-title>
            <v-form ref="form" class="pb-5">
              <v-card-text class="pb-5">
                <v-row align="center" justify="center">
                  <v-col cols="10" md="10">
                    <v-text-field
                      v-model="email"
                      class="centered-input"
                      :label="translations.email"
                      :rules="emailRules"
                      required
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center" class="mt-0">
                  <v-col cols="10" md="10">
                    <v-text-field
                      v-model="password"
                      class="centered-input"
                      :rules="passwordRules"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      :label="translations.password"
                      @click:append="showPassword = !showPassword"
                      required
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="3" class="py-0">
                    <v-btn-toggle
                      divided
                      color="primary"
                      v-model="langSelected"
                      density="compact"
                      class="justify-center"
                    >
                      <div v-for="(lang, index) in langs" :key="index">
                        <v-btn @click="changeLanguageReset(lang.id)">
                          <v-img
                            width="30px"
                            class="mx-auto"
                            :src="lang.icon"
                          ></v-img>
                        </v-btn>
                      </div>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
                <v-row
                  justify="center"
                  align="center"
                  align-content="center"
                  class="mt-0"
                >
                  <v-col
                    cols="12"
                    md="12"
                    sm="12"
                    align="center"
                    class="pb-0 pl-5 pt-0"
                  >
                    <v-checkbox
                      :true-value="true"
                      :false-value="false"
                      v-model="keepSessionOpen"
                      :label="translations.keepSessionOpen"
                    >
                    </v-checkbox>
                  </v-col>
                  <!-- <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    align="center"
                    class="pb-0 pl-5 pt-0"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="forgotPassword()"
                          v-bind="attrs"
                          v-on="on"
                          color="primary"
                          text
                        >
                          ¿Olvidaste tu contraseña?
                        </v-btn>
                      </template>
                      <span>⚠ En desarrollo...</span>
                    </v-tooltip>
                  </v-col> -->
                </v-row>
                <!-- Advice Row -->
                <!-- <v-row
                  align="center"
                  align-content="center"
                  justify="center"
                  class="mt-0"
                >
                  <v-col cols="12" lg="6" md="6" sm="4" align="center" class="pt-0"
                    ><v-checkbox
                      v-model="privacityAdvice"
                      :rules="checkRules"
                      :true-value="1"
                      :false-value="0"
                    >
                      <div slot="label">
                        Acepto el
                        <a @click.stop href="https://solena.ag/privacyNotice" class="text-decoration-underline" target="_blank">
                          aviso de privacidad
                        </a>
                      </div></v-checkbox
                    ></v-col
                  >
                </v-row> -->
              </v-card-text>
              <v-card-actions class="pb-5 pt-5">
                <v-row justify="center" align="center">
                  <v-col cols="4">
                    <v-btn
                      @click="loginUser()"
                      :loading="loading"
                      color="primary"
                      depressed
                      block
                    >
                      {{ translations.accept }}
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="!keepSessionOpen"
                    class="text-center py-0"
                  >
                    <small class="py-0">
                      {{ translations.sessionDuration }}</small
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar color="error" timeout="2000" v-model="showSnack">
      {{ textSnack }}
    </v-snackbar>
  </v-main>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
// Validations
import { requiredValidation, emailValidation } from "@/helpers/validations.js";

export default {
  name: "Login",
  layout: "login",
  auth: false,
  data() {
    return {
      email: "",
      password: "",
      textSnack: "",
      loading: false,
      showSnack: false,
      keepSessionOpen: true,
      privacityAdvice: false,
      showPassword: false,
      // validation rules
      emailRules: null,
      passwordRules: null,
      checkRules: null,
      langs: [
        {
          id: "en",
          name: "English",
          icon: require("@/assets/img/usa-flag.jpeg"),
        },
        {
          id: "es",
          name: "Español",
          icon: require("@/assets/img/mexico-flag.png"),
        },
        // {
        //   id: "pt",
        //   name: "Português",
        //   icon: require("@/assets/img/brazil-flag.png"),
        // },
      ],
      langSelected: 0,
    };
  },
  created() {
    //load all string resources by language selected
    this.loadTextSources();
  },
  computed: {
    ...mapGetters("session", ["currentTranslations"]),
    translations() {
      return this.currentTranslations;
    },
    ...mapState("session", ["error", "token", "authenticatedUser"]),
  },
  methods: {
    ...mapMutations(["SET_LOADER"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("session", ["login", "restartPassword", "changeLanguage"]),
    loadTextSources() {
      //set strings in variables according to language user selected
      this.emailRules = emailValidation(
        true,
        this.translations.requiredField,
        this.translations.validEmail
      );

      this.passwordRules = requiredValidation(this.translations.requiredField);
      this.checkRules = [(v) => v == 1 || this.translations.acceptTerms];
    },
    changeLanguageReset(id) {
      //change language in app store
      this.changeLanguage(id);
      //reload all string sources according to language selected
      this.loadTextSources();
      //reset form validation to show advices in correct language selected
      this.$refs.form.resetValidation();
    },
    async loginUser() {
      try {
        if (this.$refs.form.validate()) {
          this.SET_LOADER(true);

          const response = await this.login({
            email: this.email,
            password: this.password,
            keepSessionOpen: this.keepSessionOpen,
          });
          if (response.error) throw response;

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: `${this.translations.welcome} ${this.authenticatedUser.name} ${this.authenticatedUser.lastName}`,
            timeout: 4000,
          });

          this.$router.push("/reception");
        }
      } catch (error) {
        if (error.code && error.code == "01") {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: this.translations.incorrectCredentials,
            timeout: 4000,
          });
        } else if (error.code && error.code == "02") {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: this.translations.incorrectPassword,
            timeout: 4000,
          });
        } else {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: error.message,
            timeout: 4000,
          });
        }
      }
      this.SET_LOADER(false);
    },

    async forgotPassword() {
      try {
        console.log("forgotPassword");
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    } else {
      console.log("YES", localStorage.getItem("language"));
      if (localStorage.getItem("language") == "en") {
        this.langSelected = 0;
      } else if (localStorage.getItem("language") == "es") {
        this.langSelected = 1;
      } else if (localStorage.getItem("language") == "pt") {
        this.langSelected = 2;
      }
    }
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
