<template>
<v-container class="align-self-start" fluid>
    <v-row class="mb-4 align-center">
        <v-col class="d-flex align-center">
            <v-icon color="primary" size="36" class="mr-3">
                mdi-clipboard-text-clock-outline
            </v-icon>
            <h2 class="text-h5 font-weight-medium">
                Historial de Oligonucleótidos y sonda
                <span v-if="markerSelected" class="text-subtitle-1 green--text">
                    ({{ getMarkerName() }})
                </span>
            </h2>
        </v-col>
    </v-row>
    <v-row class="ma-0">
        <v-col class="pa-0 d-flex">
            <v-btn fab color="primary" small class="mr-4" @click="closeModal">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-text-field dense outlined label="Buscar..." prepend-inner-icon="mdi-magnify" v-model="search" clearable background-color="white"></v-text-field>
        </v-col>
        <v-col class="pa-0">
            <v-btn color="primary" class="ml-4" @click="showNewOligoModal = true" :disabled="disableOligoAdd()">
                <v-icon>
                    mdi-plus
                </v-icon>
                Agregar nueva
            </v-btn>
        </v-col>
    </v-row>
    <v-card outlined elevation="0" class="mt-4">

        <v-card-text>
            <v-container>

                <v-data-table :headers="headers" :items="oligoItems" :items-per-page="10" hide-default-footer fixed-header no-data-text="No hay registros oligo" :loading="isLoading" class="elevation-1">
                    <template #[`item.statusDesign`]="{ item }">
                        <v-chip v-if="item.statusDesign == 'PENDING'" color="orange" dark>
                            <span>{{ getOligoStatus(item.statusDesign) }} </span>
                        </v-chip>
                        <v-chip v-if="item.statusDesign == 'DESIGNING'" color="blue" dark>
                            <span>{{ getOligoStatus(item.statusDesign) }} </span>
                        </v-chip>
                        <v-chip v-if="item.statusDesign == 'FINISHED'" color="secondary">
                            <span>{{ getOligoStatus(item.statusDesign) }} </span>
                        </v-chip>
                        <v-chip v-if="item.statusDesign == 'DISCARDED'" color="red" dark>
                            <span>{{ getOligoStatus(item.statusDesign) }} </span>
                        </v-chip>

                    </template>
                    <template #[`item.updatedAt`]="{ item }">
                        <span>{{ dateFormat(item.updatedAt) }} </span>
                    </template>
                    <template #[`item.createdAt`]="{ item }">
                        <span>{{ dateFormat(item.createdAt) }} </span>
                    </template>
                    <template #[`item._id`]="{ item }">
                        <v-menu bottom right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item @click="updateFormOligo(item)" v-if="permissions.find((permission) => permission == 'lab_marker_write')">
                                    <v-list-item-title> {{ item.statusDesign === 'FINISHED' || item.statusDesign ===
                                            'DISCARDED' ? 'Ver' : 'Editar' }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="openValidations(item)" v-if="(item.statusDesign === 'FINISHED' || item.statusDesign === 'DISCARDED') && markerSelected.synthesisStatus === 'FINISHED'">
                                    <v-list-item-title> Validación </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="openSynthesizeConfirmation(item)" v-if="item.statusDesign === 'FINISHED' && markerSelected.synthesisStatus === 'NOT_STARTED'" :disabled="!permissions.find((permission) => permission == 'lab_validation_read')">
                                    <v-list-item-title> Sintetizar </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="openSynthesizeEndConfirmation(item)" v-if="item.statusDesign === 'FINISHED' && markerSelected.synthesisStatus === 'IN_PROGRESS'" :disabled="!permissions.find((permission) => permission == 'lab_validation_read')">
                                    <v-list-item-title> Finalizar Síntesis </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="openDiscardConfirmation(item)" v-if="item.statusDesign !== 'DISCARDED' && item.statusDesign !== 'FINISHED'" :disabled="!permissions.find((permission) => permission == 'lab_oligo_write')">
                                    <v-list-item-title> Descartar </v-list-item-title>
                                </v-list-item>

                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="numPages" total-visible="10" color="primary"></v-pagination>
            </v-container>
        </v-card-text>
    </v-card>
    <update-oligo-form-modal v-model="showUpdateOligoFormModal" @close="showUpdateOligoFormModal = false" @update="fetchData()" @close_finish="fetchData(), showUpdateOligoFormModal = false" />
    <DiscardCofirmationModal v-model="showDiscardCofirmationModal" v-if="showDiscardCofirmationModal" @confirmation="discardCurrentOligo()" :title="`¿Estás segura de que quieres descartar el Oligonucleótidos y Sonda (${oligoAndProbeDataSelected.oligoIdentifier})?`" />
    <new-oligo-modal v-model="showNewOligoModal" @close="showNewOligoModal = false" @close_and_update="fetchData(), showNewOligoModal = false" />
    <SynthesizeCofirmationModal v-model="showSynthesizeCofirmationModal" v-if="showSynthesizeCofirmationModal" @confirmation="startSynthesize()" :title="`¿Estás segura de que quieres iniciar Síntesis (${oligoAndProbeDataSelected.oligoIdentifier})?`" />
    <SynthesizeEndCofirmationModal v-model="showSynthesizeEndCofirmationModal" v-if="showSynthesizeEndCofirmationModal" @confirmation="endSynthesize" :title="`¿Estás segura de que quieres finalizar Síntesis (${oligoAndProbeDataSelected.oligoIdentifier})?`" />
</v-container>
</template>

<script>
//vuex
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
//Libraries
import dayjs from "dayjs";
//componentes
import UpdateOligoFormModal from './modals/UpdateOligoFormModal.vue';
import DiscardCofirmationModal from "@/components/utils/GenericConfirmationModal.vue";
import SynthesizeCofirmationModal from "@/components/utils/GenericConfirmationModal.vue";
import SynthesizeEndCofirmationModal from "@/components/validations/modals/SynthesizeEndCofirmationModal.vue";
import NewOligoModal from './modals/NewOligoModal.vue';

export default {
    components: {
        UpdateOligoFormModal,
        DiscardCofirmationModal,
        NewOligoModal,
        SynthesizeCofirmationModal,
        SynthesizeEndCofirmationModal,
    },
    data: () => ({
        isLoading: false,
        headers: [{
                text: "ID",
                sortable: false,
                value: "oligoIdentifier",
            },
            {
                text: "Estatus",
                sortable: false,
                value: "statusDesign",
            },
            {
                text: "Fecha creación",
                sortable: false,
                value: "createdAt",
            },
            {
                text: "Ultima actualización",
                sortable: false,
                value: "updatedAt",
            },
            {
                text: "Acciones",
                sortable: false,
                value: "_id",
            },
        ],
        oligoItems: [],
        page: 1,
        numPages: 1,
        allOligoItems: [],
        search: null,
        showUpdateOligoFormModal: false,
        showDiscardCofirmationModal: false,
        showNewOligoModal: false,
        showSynthesizeCofirmationModal: false,
        showSynthesizeEndCofirmationModal: false,
    }),
    props: {
        value: Boolean,
    },
    computed: {
        ...mapState("molecularMarker", ["markerSelected", "oligoAndProbeDataSelected"]),
        ...mapState("session", ["permissions"]),
    },
    async mounted() {
        await this.fetchData()
    },
    methods: {
        ...mapMutations("molecularMarker", ["SET_MARKER_SELECTED", "SET_OLIGO_AND_PROBE_SELECTED"]),
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        ...mapActions("molecularMarker", ["getOligoHistoryByMarker", "discardOligo", "startSynthesis", "endSynthesis"]),
        closeModal() {
            this.SET_MARKER_SELECTED(null)
            this.$emit("hide_table")
        },
        async fetchData() {
            try {
                this.isLoading = true
                let response = await this.getOligoHistoryByMarker({
                    markerId: this.markerSelected._id
                })
                this.allOligoItems = response.marker.oligoHistory || []
                //revert array to get last added
                if (this.allOligoItems.length != 0) {
                    this.allOligoItems.reverse()
                }
                //make a pagination in array
                const result = this.paginateArrayWithMetadata(this.allOligoItems, this.page, 10);
                this.oligoItems = result.data
                this.numPages = result.totalPages
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.isLoading = false;
        },
        paginateArrayWithMetadata(array, page, pageSize) {
            const totalItems = array.length;
            const totalPages = Math.ceil(totalItems / pageSize);

            if (page < 1 || page > totalPages) {
                return {
                    data: [],
                    totalPages,
                    currentPage: page,
                    hasPrev: false,
                    hasNext: false
                };
            }

            const startIndex = (page - 1) * pageSize;
            const endIndex = startIndex + pageSize;

            return {
                data: array.slice(startIndex, endIndex),
                totalPages,
                currentPage: page,
                hasPrev: page > 1,
                hasNext: page < totalPages
            };
        },
        paginateFilteredArray(array, filterFn, page, pageSize) {
            const filteredData = array.filter(filterFn); // Aplicar filtro
            return this.paginateArrayWithMetadata(filteredData, page, pageSize);
        },
        getMarkerName() {
            if (this.markerSelected) {
                if (this.markerSelected.markerType == 'MO') {
                    return this.markerSelected.microorganism_meta.name
                } else {
                    return this.markerSelected.soilFunction.es
                }
            }
        },
        dateFormat(value) {
            const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
            return date || "--/--/----";
        },
        getOligoStatus(value) {
            let status = {
                PENDING: "Pendiente",
                DESIGNING: "Diseño",
                FINISHED: "Terminado",
                DISCARDED: "Descartado"
            }
            return status[value] || value
        },
        updateFormOligo(item) {
            this.SET_OLIGO_AND_PROBE_SELECTED(item)
            this.showUpdateOligoFormModal = true
        },
        openValidations(item) {
            this.SET_OLIGO_AND_PROBE_SELECTED(item)
            this.$emit("open_validations")
        },
        openDiscardConfirmation(item) {
            this.SET_OLIGO_AND_PROBE_SELECTED(item)
            this.showDiscardCofirmationModal = true
        },
        async discardCurrentOligo() {
            try {
                let params = {
                    markerId: this.markerSelected._id,
                    oligoId: this.oligoAndProbeDataSelected._id,
                    oligoIdentifier: this.oligoAndProbeDataSelected.oligoIdentifier
                }
                await this.discardOligo({
                    params: params,
                })
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Oligo descartado`,
                    timeout: 5000,
                });
                await this.fetchData()
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
        },
        disableOligoAdd() {
            let disable = true
            if (this.permissions.find((permission) => permission == 'lab_oligo_write')) {
                // if (this.allOligoItems.length != 0) {
                //     if (this.allOligoItems[0].validationFinished) {
                //         disable = false
                //     }
                // } else {
                    disable = false
                // }
            }
            return disable

        },
        openSynthesizeConfirmation(item) {
            this.SET_OLIGO_AND_PROBE_SELECTED(item)
            this.showSynthesizeCofirmationModal = true
        },
        openSynthesizeEndConfirmation(item) {
            this.SET_OLIGO_AND_PROBE_SELECTED(item)
            this.showSynthesizeEndCofirmationModal = true
        },
        async startSynthesize() {
            try {
                let payloa = {
                    markerId: this.markerSelected._id,
                    oligoIdentifier: this.oligoAndProbeDataSelected.oligoIdentifier
                }
                await this.startSynthesis({
                    params: payloa
                })
                this.markerSelected.synthesisStatus = "IN_PROGRESS"
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Síntesis iniciada`,
                    timeout: 5000,
                });
                await this.fetchData()
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });

            }
        },
        async endSynthesize(fluorophore) {
            try {
                let payloa = {
                    markerId: this.markerSelected._id,
                    oligoIdentifier: this.oligoAndProbeDataSelected.oligoIdentifier,
                    fluorophore: fluorophore
                }
                await this.endSynthesis({
                    params: payloa
                })
                this.markerSelected.synthesisStatus = "FINISHED"
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Síntesis finalizada`,
                    timeout: 5000,
                });
                await this.fetchData()
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });

            }
        },
    },
    watch: {
        page() {
            const result = this.paginateFilteredArray(this.allOligoItems, this.page, 10);
            this.oligoItems = result.data
            this.numPages = result.totalPages
        },
        search(value) {
            this.page = 1
            this.numPages = 1
            if (value && value.length != 0) {
                const result = this.paginateFilteredArray(this.allOligoItems, item => item.oligoIdentifier.toLowerCase().includes(value.toLowerCase()), this.page, 10);
                this.oligoItems = result.data
                this.numPages = result.totalPages
            } else {
                const result = this.paginateArrayWithMetadata(this.allOligoItems, this.page, 10);
                this.oligoItems = result.data
                this.numPages = result.totalPages
            }

        }
    },
};
</script>

<style scoped>
.v-divider {
    margin: 20px 0;
}
</style>
