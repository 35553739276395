<template>
  <v-container class="align-self-start">
    <v-row>
      <v-col cols="12" md="4" class="mb-4">
        <FilterVectorTable />
      </v-col>
      <v-col cols="12" md="8" class="mb-4">
        <VectorTable />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VectorTable from "@/components/vectors/VectorTable.vue";
import FilterVectorTable from "@/components/vectors/FilterVectorTable.vue";

export default {
  components: {
    VectorTable,
    FilterVectorTable,
  },
};
</script>
