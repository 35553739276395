// Guard
import { privateGuard } from "@/middlewares/guards";

// Layouts
import NavLayout from "../layouts/NavLayout.vue";

// Views
import Vectors from "../views/Vectors.vue";

export default [
  {
    path: "/vectors",
    beforeEnter: privateGuard,
    component: NavLayout,
    children: [
      {
        path: "",
        name: "Plásmidos / Vector",
        component: Vectors,
        meta: {
          title: "Plásmidos / Vector",
        },
      },
    ],
  },
];
