<template>
<v-dialog v-model="show" :max-width="dialogWidth" scrollable persistent>
    <v-card elevation="12" class="mx-auto rounded-lg overflow-hidden">
        <v-app-bar color="primary accent-4" dark flat height="60">
            <v-avatar color="white" size="36" class="mr-4">
                <v-icon color="primary accent-4">mdi-dna</v-icon>
            </v-avatar>
            <v-toolbar-title class="headline font-weight-bold">
                Nuevo Marcador
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-app-bar>
        <v-card-text>
            <v-form ref="form" lazy-validation>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="formData.molecularMarkerID" label="ID comercial" :rules="validation('ID comercial')" outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="formData.longMarkerID" label="ID técnico" :rules="validation('ID técnico')" outlined dense></v-text-field>
                        </v-col>
                        <!-- Version marker -->
                        <v-col cols="12" md="6">
                            <v-text-field v-model="formData.version" label="Versión" :rules="[   
                                v => (v !== null && v !== undefined && v !== '') || `La versión es requerida`,
                                v => v >= 0 || 'La versión debe ser mayor o igual a 0']" outlined dense></v-text-field>
                        </v-col>
                        <!-- Marker Type - Only enabled when status is 'DESIGNING' -->
                        <!-- :rules="formData.status === 'DESIGNING' ? [v => !!v || 'El tipo de marcador es requerido'] : []" -->
                        <v-col cols="12" md="6">
                            <v-select v-model="formData.markerType" :items="markerType" label="Tipo de Marcador" :rules="validation('tipo de marcador')" outlined dense></v-select>
                        </v-col>

                        <v-col cols="12" md="6" v-if="formData.markerType && (formData.markerType == 'MO' || formData.markerType == 'MO_FNC')">
                            <v-combobox dense outlined label="Selecciona microorganismo" clearable :search-input.sync="searchIdentification" :items="identifications" item-text="name" :loading="identificationsLoading" v-model="formData.identification" return-object :rules="validationMarker" ref="microorganismField">
                            </v-combobox>

                        </v-col>
                        <v-col cols="12" md="6" v-if="formData.markerType && (formData.markerType == 'MO' || formData.markerType == 'MO_FNC')">
                            <v-autocomplete dense outlined label="Asociación con cultivos" v-model="formData.symbiosis" :items="symbiosis" item-text="text" :rules="validationMarker" ref="symbiosisField"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" v-if="formData.markerType && (formData.markerType == 'FNC' || formData.markerType == 'MO_FNC')">
                            <v-text-field dense outlined label="Ingresa función en suelo (EN)" clearable v-model="formData.soilFunctionEN" :rules="validation('función')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" v-if="formData.markerType && (formData.markerType == 'FNC' || formData.markerType == 'MO_FNC')">
                            <v-text-field dense outlined label="Ingresa función en suelo (ES)" clearable v-model="formData.soilFunctionES" :rules="validation('función')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" v-if="formData.markerType && (formData.markerType == 'FNC' || formData.markerType == 'MO_FNC')">
                            <v-text-field dense outlined label="Ingresa proceso (EN)" clearable v-model="formData.processEn" :rules="validation('proceso')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" v-if="formData.markerType && (formData.markerType == 'FNC' || formData.markerType == 'MO_FNC')">
                            <v-text-field dense outlined label="Ingresa proceso (ES)" clearable v-model="formData.process" :rules="validation('proceso')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" v-if="formData.markerType && (formData.markerType == 'FNC' || formData.markerType == 'MO_FNC')">
                            <v-text-field v-model="formData.impact" label="Impacto" outlined dense></v-text-field>
                        </v-col>
                        <!-- Locus - Only enabled when status is 'DESIGNING' -->
                        <v-col cols="12" md="6">
                            <v-text-field v-model="formData.locus" label="Locus" :rules="[v => !!v || 'El locus es requerido']" outlined dense></v-text-field>
                        </v-col>

                        <!-- Taxonomic Group - Only enabled when status is 'DESIGNING' -->
                        <v-col cols="12" md="6">
                            <v-select v-model="formData.taxonomicGroup" :items="taxonomicTypes" label="Grupo Taxonómico" :rules="validation()" outlined dense></v-select>
                        </v-col>

                        <!-- Taxonomic Category - Only enabled when status is 'DESIGNING' -->
                        <v-col cols="12" md="6">
                            <v-select v-model="formData.taxonomicCategory" :items="texonomicCategories" label="Categoría Taxonómica" :rules="validation()" outlined dense></v-select>
                        </v-col>

                        <!-- Taxon - Only enabled when status is 'DESIGNING' -->
                        <v-col cols="12" md="6">
                            <v-text-field v-model="formData.taxon" label="Taxon" :rules="[v => !!v || 'El taxon es requerido']" outlined dense></v-text-field>
                        </v-col>

                        <!-- Project - Only enabled when status is 'DESIGNING' -->
                        <v-col cols="12" md="6">
                            <v-text-field v-model="formData.project" label="Proyecto" :rules="[v => !!v || 'El proyecto es requerido']" outlined dense></v-text-field>
                        </v-col>
                        <!-- Temporal field for link package with marker -->
                        <v-col cols="12" md="6">
                            <v-text-field v-model="formData.packageAndVersion" label="Paquete y versión" outlined dense></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-container fluid>
                <v-row>
                    <v-col cols="">
                        <v-btn color="red" block @click="closeModal" dark :disabled="isLoading">Cancelar</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn color="primary" block @click="confirmation" :loading="isLoading">
                            <v-icon>mdi-content-save</v-icon>
                            Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
//vuex
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
//validations
import {
    requiredValidation
} from "@/helpers/validations.js";
export default {
    data: () => ({

        isLoading: false,
        formData: {
            //initial information
            molecularMarkerID: null,
            longMarkerID: null,
            markerType: null,
            identification: null,
            symbiosis: null,
            soilFunctionEN: null,
            soilFunctionES: null,
            process: null,
            processEn: null,
            locus: '',
            taxonomicGroup: null,
            taxonomicCategory: null,
            taxon: '',
            project: '',
            version: 0,
            impact: null,
            packageAndVersion: null
        },

        markerType: [{
                text: "Taxonómico",
                value: "MO"
            },
            {
                text: "Funcional",
                value: "FNC"
            },
            {
                text: "Taxonómico/Funcional",
                value: "MO_FNC"
            },
        ],
        taxonomicTypes: [{
                text: "Planta",
                value: "plant"
            },
            {
                text: "Bacteria",
                value: "bacteria"
            },
            {
                text: "Hongo",
                value: "fungi"
            },
            {
                text: "Nematodo",
                value: "nematode"
            },
            {
                text: "Insecto",
                value: "insect"
            },
            {
                text: "Virus",
                value: "virus"
            },
            {
                text: "Arqueas",
                value: "archaea"
            },
        ],
        texonomicCategories: [{
                text: "Dominio",
                value: "domain"
            },
            {
                text: "Phylum",
                value: "phylum"
            },
            {
                text: "Familia",
                value: "family"
            },
            {
                text: "Género",
                value: "gender"
            },
            {
                text: "Especie",
                value: "species"
            },
        ],

        searchIdentification: null,
        identifications: [],
        identificationsLoading: false,
        symbiosis: [{
                text: "Patógeno",
                value: "pathogen"
            },
            {
                text: "Benéfico",
                value: "benefic"
            },
        ],
        validationMarker: [(v) => !!v || 'El campo es requerido']
    }),
    props: {
        value: Boolean,
    },
    computed: {
        dialogWidth() {
            if (this.$vuetify.breakpoint.xs) return "90vw"; // Extra pequeño
            if (this.$vuetify.breakpoint.sm) return "70vw"; // Pequeño
            if (this.$vuetify.breakpoint.md) return "60vw"; // Mediano
            return "50vw"; // Grande en adelante
        },
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        ...mapActions("molecularMarker", ["getMolecularMarkerByName", "createMolecularMarker"]),
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        ...mapActions("identification", ["searchIdentifications"]),
        closeModal() {
            this.cleanForm()
            this.show = false;
        },
        validation(name) {
            return requiredValidation(name);
        },
        async confirmation() {
            try {
                if (this.$refs.form.validate()) {
                    this.isLoading = true
                    let payload = {
                        process: this.formData.process,
                        processEn: this.formData.processEn,
                        molecularMarkerID: this.formData.molecularMarkerID,
                        longMarkerID: this.formData.longMarkerID,
                        identification: this.formData.identification,
                        markerType: this.formData.markerType,
                        symbiosis: this.formData.symbiosis,
                        soilFunctionEN: this.formData.soilFunctionEN,
                        soilFunctionES: this.formData.soilFunctionES,
                        locus: this.formData.locus,
                        taxonomicGroup: this.formData.taxonomicGroup,
                        taxonomicCategory: this.formData.taxonomicCategory,
                        taxa: this.formData.taxon,
                        project: this.formData.project,
                        version: this.formData.version,
                        impact: this.formData.impact,
                        packageAndVersion: this.formData.packageAndVersion,
                    }
                    await this.createMolecularMarker(payload)
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: `Nuevo marcador agregado`,
                        timeout: 5000,
                    });
                    this.cleanForm()
                    this.$emit("close_update")
                }
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.isLoading = false;
        },

        async getIdentifications(value) {
            if (value) {
                try {
                    this.identificationsLoading = true;
                    const response = await this.searchIdentifications({
                        name: value,
                    });
                    if (response.error) throw response;
                    this.identifications = response.IDENTIFICATIONS;
                } catch (error) {
                    console.log(error);
                }
                this.identificationsLoading = false;
            } else {
                this.identifications = [];
            }
        },
        async verifyMarker() {
            try {
                if (this.formData.identification && this.formData.symbiosis) {
                    let nameMo = null
                    if (typeof this.formData.identification == "object") {
                        //identification already in system
                        nameMo = this.formData.identification.name
                    } else {
                        //new identification name
                        nameMo = this.formData.identification
                    }
                    let payload = {
                        name: nameMo,
                        symbiosis: this.formData.symbiosis
                    }

                    let response = await this.getMolecularMarkerByName(payload)
                    if (response.molecularMarker) {
                        //add validation 
                        this.validationMarker.push((v) => false || 'El marcador ya existe')
                        this.$nextTick(() => {
                            this.$refs.microorganismField.validate();
                            this.$refs.symbiosisField.validate();
                        });
                    } else {
                        this.validationMarker = [(v) => !!v || 'El campo es requerido']
                        this.$nextTick(() => {
                            this.$refs.microorganismField.validate();
                            this.$refs.symbiosisField.validate();
                        });
                    }
                }
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
        },
        cleanForm() {
            this.formData = {
                //initial information
                status: null,
                markerType: null,
                identification: null,
                symbiosis: null,
                soilFunctionEN: null,
                locus: '',
                taxonomicGroup: null,
                taxonomicCategory: null,
                taxon: '',
                project: '',
                version: 0,
                impact: null,
                packageAndVersion: null
            }
            this.searchIdentification = null
            this.identifications = []
            this.searchSample = null
            this.validationMarker = [(v) => !!v || 'El campo es requerido']
            this.$refs.form.reset()
        },
    },
    watch: {
        async searchIdentification(value) {
            await this.getIdentifications(value);
        },
    },
};
</script>

<style scoped>
.v-divider {
    margin: 20px 0;
}
</style>
