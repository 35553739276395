<template>
  <v-card outlined elevation="0">
    <v-card-title>
      <span class="headline">Tabla de Suelos</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col align="end">
          <v-btn color="primary" @click="showFormCreateSoilStockModal = true" :disabled="!permissions.find((permission) => permission == 'lab_soil_store_write')">
            <v-icon> mdi-plus </v-icon>
            Nuevo suelo
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="soils"
        :items-per-page="itemsPerPageTableSoil"
        class="elevation-0"
        no-data-text="No hay registros"
        hide-default-footer
        fixed-header
        :style="{ maxHeight: '800px', overflowY: 'auto' }"
        :loading="isLoadingTable"
      >
        <template #[`item.registrationDate`]="{ item }">
          <span>{{ dateFormat(item.registrationDate) }} </span>
          <v-menu
            offset-y
            :close-on-click="true"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on"
                >mdi-dots-horizontal-circle</v-icon
              >
            </template>
            <v-card width="auto">
              <v-card-text>
                <v-container>
                  {{ item.storeNote }}
                </v-container>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
        <template #[`item.bucketId`]="{ item }">
          <span>
            
            <div >
              <span>
                {{ item.folioAnalysis }}
              </span>
            </div>
          </span>
        </template>
        <template #[`item.assembly`]="{ item }">
          <span>
            <div v-if="item.assembly">
              <v-icon color="primary"> mdi-check-circle </v-icon>
            </div>
            <div v-else>
              <v-icon color="red"> mdi-close-circle </v-icon>
            </div>
          </span>
        </template>
        <template #[`item.hasSoilSample`]="{ item }">
          <span>
            <div v-if="item.hasSoilSample">
              <v-icon color="primary"> mdi-check-circle </v-icon>
            </div>
            <div v-else>
              <v-icon color="red"> mdi-close-circle </v-icon>
            </div>
          </span>
        </template>
        <template #[`item.hasDnaSample`]="{ item }">
          <span>
            <div v-if="item.hasDnaSample">
              <v-icon color="primary"> mdi-check-circle </v-icon>
            </div>
            <div v-else>
              <v-icon color="red"> mdi-close-circle </v-icon>
            </div>
          </span>
        </template>
        <template #[`item.client`]="{ item }">
          <div v-if="item.client">
            <span class="text-caption">
              {{ item.client.companyName }}
            </span>
            <v-menu
              offset-y
              :close-on-click="true"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" v-bind="attrs" v-on="on"
                  >mdi-dots-horizontal-circle</v-icon
                >
              </template>
              <v-card width="auto">
                <v-card-text>
                  <v-container>
                    <span class="font-weight-bold">
                      Nombre cliente:
                      <span class="font-weight-regular">{{
                        item.client.companyName
                      }}</span>
                    </span>
                    <v-spacer></v-spacer>
                    <span class="font-weight-bold">
                      Razón social:
                      <span class="font-weight-regular">{{
                        item.client.billingInformation.companyName
                      }}</span>
                    </span>
                    <v-spacer></v-spacer>
                    <span class="font-weight-bold">
                      Distribuidor:
                      <span class="font-weight-regular">{{
                        item.client.distributor.companyName
                      }}</span>
                    </span>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
          <div v-else>N/A</div>
        </template>
        <template #[`item.sampleType`]="{ item }">
          <span class="text-caption">
            {{ getSampleType(item) }}
          </span>
          <v-menu
            offset-y
            :close-on-click="true"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on"
                >mdi-dots-horizontal-circle</v-icon
              >
            </template>
            <v-card width="auto">
              <v-card-text>
                <v-container>
                  <span class="font-weight-bold">
                    Cultivo:
                    <span class="font-weight-regular" v-if="item.cropCycle">{{
                      getCrop(item.cropCycle.crop.cropName)
                    }}</span>
                    <span v-else> no definido</span>
                  </span>
                  <v-spacer></v-spacer>
                  <span class="font-weight-bold">
                    Etapa fenologica:
                    <span
                      class="font-weight-regular"
                      v-if="item.phenologicalStage"
                      >{{ getPhenoStage(item.phenologicalStage) }}</span
                    >
                    <span v-else> no definido</span>
                  </span>
                </v-container>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
        <template #[`item._id`]="{ item }">
          <v-btn color="blue" x-small dark @click="updateRow(item)" :disabled="!permissions.find((permission) => permission == 'lab_soil_store_write')">
            <v-icon x-small> mdi-pencil </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-pagination
        v-model="page"
        :length="numPages"
        :total-visible="itemsPerPageTableSoil"
        color="primary"
      ></v-pagination>
    </v-card-text>
    <FormCreateSoilStockModal
      v-model="showFormCreateSoilStockModal"
      @close="showFormCreateSoilStockModal = false"
      @close_update="(showFormCreateSoilStockModal = false), fetchData()"
    />
    <FormUpdateSoilStockModal
      v-model="showFormUpdateSoilStockModal"
      @close="showFormUpdateSoilStockModal = false"
      @close_update="(showFormUpdateSoilStockModal = false), fetchData()"
    />
  </v-card>
</template>

<script>
// vuex
import { mapActions, mapMutations, mapState } from "vuex";
// Libraries
import dayjs from "dayjs";
//components
import FormCreateSoilStockModal from "@/components/soil_storage/modals/FormCreateSoilStockModal.vue";
import FormUpdateSoilStockModal from "@/components/soil_storage/modals/FormUpdateSoilStockModal.vue";

export default {
  components: {
    FormCreateSoilStockModal,
    FormUpdateSoilStockModal,
  },
  data: () => ({
    soils: [],
    page: 1,
    numPages: 1,
    isLoadingTable: false,
    showFormCreateSoilStockModal: false,
    showFormUpdateSoilStockModal: false,
  }),
  async created() {
    await this.fetchData();
  },
  mounted() {},
  computed: {
    ...mapState("session", ["permissions"]),
    ...mapState("soilStore", [
      "filterTableSoil",
      "searchTableSoil",
      "itemsPerPageTableSoil",
      "headers",
    ]),
  },
  methods: {
    ...mapActions("soilStore", ["getSamplesStored"]),
    ...mapMutations("soilStore", ["SET_DATA_IDICATORS_TABLE", "SET_ROW_TABLE"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async fetchData() {
      try {
        this.isLoadingTable = true;
        let payload = {
          filter: this.filterTableSoil,
          page: this.page,
          search: this.searchTableSoil,
          perPageItems: this.itemsPerPageTableSoil,
        };
        let response = await this.getSamplesStored(payload);
        this.numPages = response.pages;
        this.soils = response.samples;
        this.SET_DATA_IDICATORS_TABLE({
          quantityAll: response.quantityAll,
          dnaStoredQuantity: response.dnaStoredQuantity,
        });
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.isLoadingTable = false;
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("DD/MM/YYYY")}`;
      return date || "--/--/----";
    },
    async reloadTable() {
      this.page = 1;
      await this.fetchData();
    },
    getSampleType(value) {
      const types = {
        CLASSIC: "Suelo compuesto",
        MAS: "Muestra para genómica",
        MAS_VEGETABLE: "Vegetal",
        VEGETABLE: "Vegetal sin georeferencia",
        COMPOST: "Composta",
        PRODUCT: "Producto",
        SUBSTRATUM: "Sustrato",
        NO_ADVISOR: "Suelo sin asesor",
      };
      if (value.sampleType) {
        let temp = types[value.sampleType];
        if (value.sampleType == "COMPOST" || value.sampleType == "PRODUCT") {
          if (value.composition) {
            let compositions = {
              LIQUID: "Líquido",
              SOLID: "Sólido",
            };
            temp += ` ${compositions[value.composition]}`;
          }
        }
        return temp;
      } else {
        return "N/A";
      }
    },
    getCrop(value) {
      let template = "";
      const crop = {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        other: "Otro",
      };
      let cropName = crop[value];
      if (cropName) {
        template = cropName;
      } else {
        template = "Sin cultivo";
      }
      return template;
    },
    getPhenoStage(value) {
      let peheno = {
        Transplanting: "Trasplante",
        "Vegetative growth": "Desarrollo Vegetativo",
        Bloom: "Floración",
        "Fruit developmento": "Desarrollo del fruto",
        Harvest: "Cosecha",
        Sowing: "Siembra",
        Sprout: "Emergencia",
        Tuberization: "Tuberización",
        "Tuber filling": "Llenado de tuberculos",
        "Foliage development": "Desarrollo foliar",
        "Foliar expansion": "Expansión foliar",
        "First leaf": "Primera hoja",
        "Third leaf": "Tercer hoja ",
        "Fifth leaf": "Quinta hoja",
        "Start of bulbing": "Inicio de bulbificación",
        "Bulb growth": "Desarrollo del bulbo",
        "Fruit maturity": "Maduración de fruto",
        Sprouting: "Brotación",
      };
      return peheno[value] ? peheno[value] : value;
    },
    updateRow(item) {
      this.SET_ROW_TABLE(item);
      this.showFormUpdateSoilStockModal = true;
    },
  },
  watch: {
    async searchTableSoil() {
      await this.reloadTable();
    },
    async itemsPerPageTableSoil() {
      await this.reloadTable();
    },
    async page() {
      await this.fetchData();
    },
    async filterTableSoil() {
      await this.reloadTable();
    },
  },
};
</script>

<style scoped>
.headline {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>
