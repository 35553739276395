export function SET_MARKER_SELECTED(state, item) {
  state.markerSelected = item;
}

export function SET_OLIGO_AND_PROBE_SELECTED(state, item) {
  state.oligoAndProbeDataSelected = item;
}

export function SET_RESULT_ITEM_SELECTED(state, item) {
  state.resultItemSelected = item;
}

