import axios from "axios";

export async function getSamplesStored(context, payload) {
  try {
    const { data } = await axios.get(`/sample/samples-stored`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function getSamplesByFolio(context, payload) {
  try {
    const { data } = await axios.get(`/sample/samples-by-folio`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function createSoilStock(context, { payload, id }) {
  try {
    const { data } = await axios.put(`/sample/create-soilstock`, payload, {
      params: { sampleId: id },
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function updateSoilStock(context, { payload, id }) {
  try {
    const { data } = await axios.put(`/sample/update-soilstock`, payload, {
      params: { sampleId: id },
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}
