<template>
  <v-dialog v-model="show" width="40vw" scrollable>
    <v-card height="auto" elevation="5" class="rounded-xl" min-height="200">
      <v-card-title class="text-h5 lighten-2">
        Registros en tiempo
      </v-card-title>
      <v-card-text>
        <div v-if="isLoading">
          <v-row class="text-start mt-2">
            <v-col>
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <span class="font-weight-bold font-italic ml-5"
                >Cargando eventos...</span
              >
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item
              fill-dot
              v-for="(log, i) in logs"
              :key="i"
              :color="log.color"
              :icon="getIcon(log.type)"
            >
              <v-card color="primary" dark>
                <v-card-title class="text-subtitle-1">
                  {{ getType(log.type) }}</v-card-title
                >
                <v-card-text class="white text--primary">
                  <v-row no-gutters>
                    <v-col cols="12"
                      ><span class="text-caption"
                        ><span class="font-weight-bold font-italic">Hora:</span>
                        {{ dateFormat(log.date) }}</span
                      ></v-col
                    >
                    <v-col cols="12"
                      ><span class="text-caption">
                        <span class="font-weight-bold font-italic"
                          >Usuario:</span
                        >
                        {{ log.user.name }} {{ log.user.lastName }}</span
                      ></v-col
                    >
                    <template v-if="log.type == 'RANGE_UPDATE'">
                      <v-col cols="12"
                        ><span class="text-caption">
                          <span class="font-weight-bold font-italic"
                            >Cambios:
                          </span>
                          <span>
                            <span class="font-weight-bold font-italic">
                              nivel confiabilidad anterior:
                            </span>

                            {{ getReliability(log.meta_data.reliability_old) }}
                          </span>
                          <v-spacer></v-spacer>
                          <span>
                            <span class="font-weight-bold font-italic">
                              nivel confiabilidad nuevo:
                            </span>

                            {{ getReliability(log.meta_data.reliability_new) }}
                          </span>
                          <v-spacer></v-spacer>
                          <span>
                            <span class="font-weight-bold font-italic">
                              nivel medio anterior:
                            </span>

                            {{ log.meta_data.medium_old }}
                          </span>

                          <span>
                            <span class="font-weight-bold font-italic"
                              >nivel alto anterior:
                            </span>
                            {{ log.meta_data.hight_old }}
                          </span>
                          <v-spacer></v-spacer>
                          <span>
                            <span class="font-weight-bold font-italic">
                              nivel medio nuevo:
                            </span>
                            {{ log.meta_data.medium_new }}
                          </span>

                          <span>
                            <span class="font-weight-bold font-italic">
                              nivel alto nuevo:
                            </span>
                            {{ log.meta_data.hight_new }}
                          </span>
                          <v-spacer></v-spacer> </span
                      ></v-col>
                    </template>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
          <div v-if="!isLoading && logs.length == 0">
            <v-row class="text-center mt-2">
              <v-col>
                <span class="font-weight-bold font-italic"
                  >No hay registros de eventos</span
                >
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
//Vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
//Libraries
import dayjs from "dayjs";
export default {
  data: () => ({
    isLoading: false,
    logs: [],
  }),
  props: {
    value: Boolean,
  },
  computed: {
    ...mapState("riskLevel", ["levelSelected"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    getType(value) {
      let values = {
        RANGE_UPDATE: "Cambio de rangos",
      };
      return values[value];
    },
    dateFormat(value) {
      if (value) {
        const date = `${dayjs(value).format("DD-MM-YYYY - HH:mm")} hrs`;
        return date ? date : "N/A";
      } else {
        return "N/A";
      }
    },
    getIcon(type) {
      let icon = "";
      switch (type) {
        case "RANGE_UPDATE":
          icon = "mdi-swap-horizontal";
          break;

        default:
          icon = "mdi-help-circle-outline";
          break;
      }

      return icon;
    },
    getReliability(value) {
      let reliabilityEnum = {
        low: "Baja",
        medium: "Media",
        hight: "Alta",
      };
      return reliabilityEnum[value] || value;
    },
  },
  mounted() {
    try {
      this.isLoading = true;
      this.logs = this.levelSelected.logs.reverse() || [];
    } catch (error) {
      console.log(error);
      this.SET_ALERT_TYPE({
        visible: true,
        type: "error",
        text: `Algo salió mal. Error: ${error.message}`,
        timeout: 5000,
      });
    }
    this.isLoading = false;
  },
};
</script>
