<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="3" md="3" class="py-1">
        <v-text-field prepend-inner-icon="mdi-magnify" v-model="search" clearable>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" class="py-1">
        <v-dialog v-model="filterDialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" rounded small><v-icon>mdi-filter-multiple</v-icon></v-btn>
          </template>

          <v-card>
            <v-card-title> {{ translations.filters }} </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <span> {{ translations.crop }} </span>
                    <v-autocomplete v-model="form.cropCycle" :items="crop_items" :label="translations.selectCrop"
                      :no-data-text="translations.noCrop" solo dense clearable></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span> {{ translations.sampleType }} </span>
                    <v-autocomplete v-model="form.sampleType" :items="sampleType" :label="translations.selectSampleType"
                      solo dense clearable></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span> {{ translations.analysisType }} </span>
                    <v-autocomplete v-model="form.analysisType" :items="analysisType"
                      :label="translations.selectAnalysisType" solo dense clearable></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="gray" dark @click="filterDialog = false">
                {{ translations.cancel }}
              </v-btn>
              <v-btn color="info" @click="cleanFilters">
                {{ translations.clearFilters }}
              </v-btn>
              <v-btn color="primary" @click="applyFilters"> {{ translations.apply }} </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn color="primary" rounded small class="ml-1" @click="fetchData">
          <v-icon> mdi-refresh </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table width="100%" height="600" :headers="headers" :items="samples" :items-per-page="10" fixed-header
          :no-data-text="translations.noSamplesInTransit" :loading-text="`${translations.loadingSamplesInTransit}...`"
          :page.sync="page" hide-default-footer :loading="fetchingData">
          <template #[`item.sampling_status`]="{ item }">
            <v-chip v-if="item.sampling_status == 'VALIDATED'" color="#dadada">
              {{ getStatus(item.sampling_status) }}
            </v-chip>
            <v-chip v-else color="info" text-color="black">
              {{ getStatus(item.sampling_status) }}
            </v-chip>
          </template>
          <template #[`item.crop`]="{ item }">
            <v-icon v-if="item.crop" color="primary">mdi-sprout-outline</v-icon>
            <v-icon v-else color="gray">mdi-sprout-outline</v-icon>
            {{ getCrop(item.crop) }}
          </template>

          <template #[`item.field_registration`]="{ item }">
            {{ getDateFormatted(item.field_registration) }}
          </template>
          <template #[`item.sample_type`]="{ item }">
            {{ getSampleType(item.sample_type) }}
          </template>
          <template #[`item.analysis_type`]="{ item }">
            {{ getAnalysisType(item.analysis_type) }}
          </template>
          <template #[`item.qr_code`]="{ item }">
            <v-icon>mdi-qrcode</v-icon>
            {{ item.qr_code }}
          </template>
        </v-data-table>
        <v-pagination v-model="page" :length="numPages" total-visible="10" color="primary"></v-pagination>
      </v-col>
    </v-row>
    <v-row class="text-center" v-if="showFetchingDataButton">
      <v-col>
        <v-btn color="primary" rounded @click="fetchData"><v-icon>mdi-refresh</v-icon></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//Vuex
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
//Libraries
import dayjs from "dayjs";
//axios
import axios from "axios";
export default {
  data: () => ({
    headers: [],
    samples: [],
    fetchingData: false,
    page: 1,
    numPages: 0,
    search: null,
    showFetchingDataButton: false,
    filterDialog: false,
    crop_items: [],
    sampleType: [],
    analysisType: [],
    form: {
      cropCycle: null,
      sampleType: null,
      analysisType: null,
    },
    user: JSON.parse(localStorage.getItem("user")),
  }),
  created() {
    this.headers = [
      { text: this.translations.qrCode, value: "qr_code" },
      { text: this.translations.analysisType, value: "analysis_type" },
      { text: this.translations.sampleType, value: "sample_type" },
      { text: `#${this.translations.subsamples}`, value: "qty_sub_sample" },
      { text: this.translations.samplingZone, value: "irrigation_unit_name" },
      { text: this.translations.crop, value: "crop" },
      { text: this.translations.producer, value: "client_name" },
      { text: this.translations.responsible, value: "sampler_name" },
      { text: this.translations.fieldRegistration, value: "field_registration" },
      { text: this.translations.status, value: "sampling_status" },
    ]
    this.sampleType = [
      {
        text: this.translations.soil,
        value: "CLASSIC",
      },
      {
        text: this.translations.plant,
        value: "MAS_VEGETABLE",
      },
      {
        text: this.translations.vegetalWithoutGeoreference,
        value: "VEGETABLE",
      },
      {
        text: this.translations.compost,
        value: "COMPOST",
      },
      {
        text: this.translations.product,
        value: "PRODUCT",
      },
      {
        text: this.translations.substrate,
        value: "SUBSTRATUM",
      },
      {
        text: this.translations.liquid,
        value: "LIQUID",
      },
    ];
    this.analysisType = [
      {
        text: this.translations.initial,
        value: "INITIAL",
      },
      {
        text: this.translations.tracking,
        value: "MONITORING",
      },
    ]
  },
  async mounted() {
    this.setCropItems();
    await this.fetchData();
  },
  computed: {
    ...mapGetters("session", ["currentTranslations"]),
    translations() {
      return this.currentTranslations;
    },
    ...mapState("reception", ["crops"]),
    ...mapState(["cancelToken"]),
  },
  methods: {
    ...mapMutations(["SET_CANCEL_TOKEN"]),
    ...mapActions("reception", ["getSampleTransit"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async fetchData() {
      this.fetchingData = true;
      try {
        if (typeof this.cancelToken != "undefined") {
          this.cancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        this.SET_CANCEL_TOKEN(axios.CancelToken.source());
        let payload = {
          page: this.page,
          search: this.search,
          laboratory: this.user.laboratory._id,
          filters: this.form,
        };
        let response = await this.getSampleTransit(payload);
        if (response) {
          if (!response.error) {
            //hide loaders
            this.fetchingData = false;
            this.showFetchingDataButton = false;
            //set sample data
            this.samples = response.samples;
            this.numPages = response.pages;
            console.log(response);
          } else {
            throw response;
          }
        }
      } catch (e) {
        //print log
        console.log(e);
        //hide loaders
        this.fetchingData = false;
        //show attempt button to try fetch data manually
        this.showFetchingDataButton = true;
        //show error to user
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `${this.translations.somethingWentWrong} ${error.message}`,
          timeout: 5000,
        });
      }
    },
    getStatus(value) {
      let status = {
        IN_PROGRESS: this.translations.inProcess,
        VALIDATED: this.translations.finished,
      };
      return status[value];
    },
    setCropItems() {
      let cropsArray = Object.entries(this.translations.crop_name_list);
      for (let crop of cropsArray) {
        this.crop_items.push({
          text: crop[1],
          value: crop[0],
        });
      }
    },
    getCrop(value) {
      let result = this.translations.crop_name_list[value];
      return result || this.translations.notAvailable;
    },
    getDateFormatted(value) {
      const date = `${dayjs(value).format("D/M/YYYY - HH:mm")} hrs`;
      return date ? date : "----";
    },
    getSampleType(value) {
      const types = {
        CLASSIC: this.translations.soil,
        MAS_VEGETABLE: this.translations.plant,
        VEGETABLE: this.translations.vegetalWithoutGeoreference,
        COMPOST: this.translations.compost,
        PRODUCT: this.translations.product,
        SUBSTRATUM: this.translations.substrate,
        NO_ADVISOR: this.translations.soilWithoutAdvisor,
      };
      if (value.type) {
        let temp = types[value.type];
        if (value.type == "COMPOST" || value.type == "PRODUCT") {
          if (value.composition) {
            let compositions = {
              LIQUID: this.translations.liquid,
              SOLID: this.translations.solid,
            };
            temp += ` ${compositions[value.composition]}`;
          }
        }
        return temp;
      } else {
        return "N/A";
      }
    },
    getAnalysisType(value) {
      if (value == "INITIAL" || value == "Initial") {
        return this.translations.initial;
      } else {
        return this.translations.tracking;
      }
    },
    async applyFilters() {
      this.filterDialog = false;
      this.page = 1;
      await this.fetchData();
    },
    async cleanFilters() {
      this.search = null;
      this.filterDialog = false;
      this.form = {
        cropCycle: null,
        sampleType: null,
        analysisType: null,
      };
      await this.fetchData();
    },
  },
  watch: {
    async search() {
      this.page = 1;
      await this.fetchData();
    },
    async page() {
      await this.fetchData();
    },
  },
};
</script>

<style scoped></style>
