<template>
  <v-dialog v-model="show" width="auto" scrollable persistent>
    <v-card height="auto" elevation="5" class="rounded-lg" min-height="200">
      <v-card-title class="text-h5 lighten-2"
        >Añadir archivo de oligonucleótidos y Sonda
      </v-card-title>
      <v-card-text>
        <v-form lazy-validation ref="form">
          <v-container>
            <v-row>
              <v-col>
                <v-file-input
                  v-model="fileSelected"
                  label="Selecciona archivo"
                  hide-no-data
                  outlined
                  dense
                  clearable
                  :rules="validation()"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-container fluid>
          <v-row>
            <v-col cols="">
              <v-btn
                color="red"
                block
                @click="closeModal"
                dark
                :disabled="isLoading"
                >Cancelar</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                block
                @click="confirmation"
                :loading="isLoading"
                >Cargar archivo</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//vuex
import { mapActions, mapMutations, mapState } from "vuex";
//validations
import { requiredValidation } from "@/helpers/validations.js";
export default {
  data: () => ({
    fileSelected: null,
    isLoading: false,
  }),
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    closeModal() {
      this.show = false;
    },
    validation(name) {
      return requiredValidation(name);
    },
    async confirmation() {
      try {
        if (this.$refs.form.validate()) {
          this.isLoading = true;
          let payload = {};
          // await this.assingPackage(payload);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: `Documento procesado exitosamente`,
            timeout: 5000,
          });
          this.$emit("update");
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.isLoading = false;
    },
  },
  watch: {},
};
</script>
