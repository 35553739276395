// Guard
import { privateGuard } from "@/middlewares/guards";

// Layouts
import NavLayout from "../layouts/NavLayout.vue";

// Views
import Markers from "../views/Markers.vue";

export default [
  {
    path: "/markers",
    beforeEnter: privateGuard,
    component: NavLayout,
    children: [
      {
        path: "",
        name: "Marcadores",
        component: Markers,
        meta: {
          title: "Marcadores",
        },
      },
    ],
  },
];
