<template>
    <v-container class="align-self-start" fluid>
        <div v-if="!hideMainTable">
            <v-row>
                <v-col cols="12">
                    <MolecularMarkerTable :showAddNewFormattValidations="true" :hideValidations="false"
                        :hideSelectionTable="false" @nextValidations="hideMainTable = true"
                        @openOligo="showOligoHistory = true, hideMainTable = true"></MolecularMarkerTable>
                </v-col>
            </v-row>
        </div>
        <div v-if="showValidations">
            <Validation @hide_validations="showOligoHistory = true, showValidations = false"></Validation>
        </div>
        <div v-if="showOligoHistory"
            @close_and_open_form="showUpdateOligoFormModal = true, showOligoHistoryModal = false">
            <oligo-history-table @hide_table="hideMainTable = false, showOligoHistory = false"
                @open_validations="showValidations = true, showOligoHistory = false" />
        </div>
    </v-container>
</template>

<script>
//components
import MolecularMarkerTable from "@/components/cmda_admin/MolecularMarkerTable.vue";
import Validation from "@/components/validations/Validation.vue";
import OligoHistoryTable from '@/components/validations/OligoHistoryTable.vue';

export default {
    components: {
        MolecularMarkerTable,
        Validation,
        OligoHistoryTable,
    },
    data: () => ({
        hideMainTable: false,
        showValidations: false,
        showOligoHistory: false,
    }),
};
</script>

<style scoped></style>
