/**
 * General validation for an input
 * @param    {Boolean}   required    Specifies if the field is required.
 * @param    {Number}    length      Specifies the field limit.
 * @param    {String}    name        Specifies the field name.
 * @return   {Array<Fn>}             Array with the rules
 */

export const requiredValidation = (text_error = "The field is required") => {
  return [(v) => !!v || `${text_error}`];
};

export const requiredFValidation = (name = "campo") => {
  return [(v) => !!v || `La ${name} es obligatoria.`];
};

export const requiredContainerValidation = (
  text_error = "The field is required",
  codeError = "The code must have at least 14 digits",
  code = 0
) => {
  return [
    (v) => !!v || text_error,
    (v) =>
      typeof v.length != null
        ? v.length == 14 || codeError
        : true,
  ];
};
export const emailValidation = (
  required = false,
  text_error = "The field is required",
  text_error_pattern = "You must enter a valid email"
) => {
  let validations = [];

  if (required) validations.push((v) => !!v || `${text_error}`);

  validations.push(
    (v) =>
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
      `${text_error_pattern}`
  );

  return validations;
};

export const numberValidation = (rules = { name: "campo", required: true }) => {
  if (!rules.name) rules.name = "campo";
  let validations = [];

  if (rules.required)
    validations.push((v) => !!v || `El ${rules.name} es obligatorio.`);

  if (rules.min !== undefined && rules.required)
    validations.push(
      (v) => Number(v) >= rules.min || `El valor mínimo es ${rules.min}.`
    );
  else if (rules.min !== undefined && !rules.required)
    validations.push((v) =>
      v !== null && v !== undefined && v !== ""
        ? v.length === 0 ||
          (Number(v) >= rules.min && Number(v) >= 0) ||
          `El valor mínimo es ${rules.min}.`
        : `El ${rules.name} es obligatorio.`
    );

  if (rules.max !== undefined)
    validations.push((v) =>
      v
        ? v.length === 0 ||
          Number(v) <= rules.max ||
          `El valor máximo es ${rules.max}.`
        : ""
    );

  return validations;
};
