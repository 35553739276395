<template>
<v-container fluid>
    <v-row>
        <v-col>
            <v-btn text color="primary" @click="closeComponent()">
                <v-icon> mdi-keyboard-backspace </v-icon>
            </v-btn>
        </v-col>
    </v-row>
    <v-stepper v-model="steps" elevation="0">
        <v-stepper-header>
            <v-stepper-step :complete="steps > 1" step="1">
                Armado de prueba
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="steps > 2" step="2">
                Subir resultados
            </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <v-row v-if="!showStrainPanel">
                    <v-col align="end">
                        <v-btn color="primary" @click="setupDone()" :disabled="!permissions.find((permission) => permission == 'lab_validation_write')"> Continue </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="!showStrainPanel">
                    <v-col>
                        <v-btn color="primary" @click="showStrainPanel = true" :disabled="oligoAndProbeDataSelected.validationFinished || !permissions.find((permission) => permission == 'lab_validation_write')">
                            <v-icon> mdi-plus </v-icon>
                            Agregar cepa
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="!showStrainPanel">
                    <v-col>
                        <v-responsive class="overflow-x-auto">
                            <v-simple-table dense class="overflow-y-auto">
                                <thead>
                                    <tr>
                                        <th class="text-left">No.</th>
                                        <th class="text-left">ID</th>
                                        <th class="text-left">Microorganismo</th>
                                        <th class="text-left">Número de réplicas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in oligoAndProbeDataSelected.exclusivityOtherGenus.results" :key="index">
                                        <td class="text-center" v-if="item.type == 'WATER'">
                                            Control negativo
                                        </td>
                                        <td class="text-center" v-else-if="item.type == 'CONTROL_POSITIVE'">
                                            Control positivo
                                        </td>
                                        <td class="text-center" v-else>
                                            <v-icon class="mr-5" @click="removeItem(item)" color="black" v-if="item.cq.length == 0" :disabled="!permissions.find((permission) => permission == 'lab_validation_write')">mdi-close-circle</v-icon>
                                            {{ index - 1 }}
                                        </td>
                                        <td> {{ item.name }}</td>
                                        <td>{{ item.type !== 'SOIL' ? (item.type !== 'WATER' ?
                                                item.microorganism.name : '-') : 'Suelo' }}</td>
                                        <!-- <td>-</td>
                                        <td>-</td>
                                        <td>-</td> -->
                                        <td v-if="oligoAndProbeDataSelected.validationFinished || !permissions.find((permission) => permission == 'lab_validation_write')">
                                            {{ item.replicasNumber }}
                                        </td>
                                        <td v-else>{{ item.replicasNumber }}
                                            <v-btn color="blue" x-small fab @click="setItemResultSelected(item)" dark v-if="!oligoAndProbeDataSelected.exclusivityOtherGenus.isTestDone && item.type !== 'CONTROL_POSITIVE'">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-responsive>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-btn text color="primary" @click="showStrainPanel = false">
                                    <v-icon> mdi-keyboard-backspace </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-tabs>
                                <v-tab> Cepario </v-tab>
                                <v-tab> Plásmidos / Vector </v-tab>
                                <v-tab> Suelos </v-tab>
                                <v-tab-item>
                                    <general-ceparium-selectable @addItems="openAddItemsConfirmation" />
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row>
                                        <v-col cols="12" class="pb-0">
                                            <SimpleVectorFilter />
                                        </v-col>
                                        <v-col class="pt-0">
                                            <SelectableVectorTable @addItems="openAddItemsConfirmation">
                                            </SelectableVectorTable>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-col cols="12" class="pb-0">
                                        <simple-filter-soil-table></simple-filter-soil-table>
                                    </v-col>
                                    <v-col>
                                        <selectable-soil-table @addItems="openAddItemsConfirmation"></selectable-soil-table>
                                    </v-col>
                                </v-tab-item>
                            </v-tabs>
                        </v-row>
                    </v-col>
                </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
                <v-card class="overflow-y-auto" max-height="740">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="2">
                                <v-btn text color="primary" @click="steps = 1" :disabled="!permissions.find((permission) => permission == 'lab_validation_write')">
                                    <v-icon> mdi-keyboard-backspace </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-row justify="end">
                                    <v-col cols="auto">
                                        <v-btn color="blue" dark block @click="showDeleteResultsConfirmationModal = true" :loading="isCleaningResults" :disabled="oligoAndProbeDataSelected.validationFinished || !permissions.find((permission) => permission == 'lab_validation_write')">
                                            <v-icon>mdi-delete-variant</v-icon>
                                            Limpiar resultados
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3">
                            <v-col cols="12" sm="6">
                                <v-file-input dense outlined label="Ingresa resultados" clearable v-model="fileResult" accept=".xlsx" :loading="uploadingResults" ref="result_field" append-icon="mdi-file-excel" :disabled="oligoAndProbeDataSelected.validationFinished || !permissions.find((permission) => permission == 'lab_validation_write')">
                                </v-file-input>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-textarea filled name="input-7-4" label="Obervaciones" v-model="observations" :disabled="oligoAndProbeDataSelected.validationFinished || !permissions.find((permission) => permission == 'lab_validation_write')"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-row>
                                    <v-col class="text-start">
                                        <span class="text-subtitle-1 font-weight-bold">
                                            Resultados
                                        </span>
                                    </v-col>
                                    <v-col v-if="oligoAndProbeDataSelected.exclusivityOtherGenus.urls && oligoAndProbeDataSelected.exclusivityOtherGenus.urls.length != 0">
                                        <v-autocomplete label="Descarga resultado" clearable dense v-model="fileTodownload" :items="oligoAndProbeDataSelected.exclusivityOtherGenus.urls" item-text="url" outlined ref="download_result_input">
                                            <template #item="{ item }">
                                                <div>
                                                    <strong> Resultado (XLSX) - {{ dateFormat(item.date) }}</strong>
                                                </div>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-responsive class="overflow-x-auto">
                                    <v-simple-table dense class="overflow-y-auto">
                                        <thead>
                                            <tr>

                                                <th class="text-left">No.</th>
                                                <th class="text-left">ID</th>
                                                <th class="text-left">Microorganismo</th>
                                                <th class="text-left">Cq</th>
                                                <th class="text-left">Eficiencia</th>
                                                <th class="text-left">Eficiencia R2</th>
                                                <th class="text-left">Fecha</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(item, index) in oligoAndProbeDataSelected.exclusivityOtherGenus.results">
                                                <tr v-for="(strain, innerIndex) in item.replicasNumber" :key="`${index}-${innerIndex}`">
                                                    <td class="text-center" v-if="item.type == 'WATER'">
                                                        Control negativo
                                                    </td>
                                                    <td class="text-center" v-else-if="item.type == 'CONTROL_POSITIVE'">
                                                        <v-row class="d-flex align-center justify-center" style="gap: 0px;">
                                                            <v-col cols="auto">
                                                                <div :style="`background-color: ${item.colors[innerIndex] || 'white'}; width: 15px; height: 15px;`">
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                Control positivo
                                                            </v-col>
                                                        </v-row>                                                 
                                                    </td>
                                                    <td class="text-center" v-else>
                                                        <v-row class="d-flex align-center justify-center" style="gap: 0px;">
                                                            <v-col cols="auto">
                                                                <div :style="`background-color: ${item.colors[innerIndex] || 'white'}; width: 15px; height: 15px;`">
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                {{ index - 1 }}{{ numberToLetter(innerIndex + 1) }}
                                                            </v-col>
                                                        </v-row>
                                                    </td>
                                                    <td>{{ item.name }}</td>
                                                    <td>{{ item.type !== 'SOIL' ? (item.type !== 'WATER' ?
                                                            item.microorganism.name : '-') : 'Suelo' }}</td>
                                                    <td>{{ item.cq[innerIndex] || '-' }}</td>
                                                    <td>{{ item.efficience[innerIndex] || '-' }}</td>
                                                    <td>{{ item.efficienceR2[innerIndex] || '-' }}</td>
                                                    <td>{{ item.excluded[innerIndex]
                                                            ? getExcludedKind(item.excluded[innerIndex]) : '-' }}</td>
                                                    <td>{{ item.uploadDate ? dateFormat(item.uploadDate) :
                                                            '--/--/----' }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </v-simple-table>
                                </v-responsive>
                            </v-col>
                        </v-row>
                        <v-row v-if="charts.length != 0">
                            <v-col class="text-center justify-center align-center d-flex">
                                <v-carousel height="400" hide-delimiters show-arrows-on-hover>
                                    <v-carousel-item v-for="(image, i) in charts" :key="i">
                                        <v-row>
                                            <v-col cols="12">
                                                <span>{{ dateFormat(image.date) }}</span>
                                                <v-icon color="primary" class="ml-2" @click="showResultsExclusivityOtherGenusModal = true">mdi-eye</v-icon>
                                            </v-col>
                                            <v-col>
                                                <v-img :src="image.url" height="400" contain></v-img>
                                            </v-col>
                                        </v-row>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
    <AddReplicatesModal v-model="showAddReplicatesModal" @addQuantity="addItems" />
    <DeleteResultsConfirmationModal v-model="showDeleteResultsConfirmationModal" @confirmation="cleanResults()" :title="`¿Estás seguro de que deseas eliminar todos los resultados?`" />
    <results-exclusivity-other-genus-modal v-model="showResultsExclusivityOtherGenusModal" />
    <update-replicates-modal v-model="showUpdateReplicatesModal" :type="'exclusivityOtherGenus'"/>
</v-container>
</template>

<script>
//Libraries
import dayjs from "dayjs";
// vuex
import {
    mapState,
    mapActions,
    mapMutations
} from 'vuex';
import GeneralCepariumSelectable from '../ceparium/GeneralCepariumSelectable.vue';
import SimpleVectorFilter from "@/components/vectors/SimpleVectorFilter.vue";
import SelectableVectorTable from "@/components/vectors/SelectableVectorTable.vue";
import SimpleFilterSoilTable from '../soil_storage/SimpleFilterSoilTable.vue';
import SelectableSoilTable from '../soil_storage/SelectableSoilTable.vue';
import AddReplicatesModal from "@/components/validations/modals/AddReplicatesModal.vue";
import DeleteResultsConfirmationModal from "@/components/utils/GenericConfirmationModal.vue";
import ResultsExclusivityOtherGenusModal from './modals/ResultsExclusivityOtherGenusModal.vue';
import UpdateReplicatesModal from './modals/UpdateReplicatesModal.vue';

// components
export default {
    components: {
        GeneralCepariumSelectable,
        SimpleVectorFilter,
        SelectableVectorTable,
        SimpleFilterSoilTable,
        SelectableSoilTable,
        AddReplicatesModal,
        DeleteResultsConfirmationModal,
        ResultsExclusivityOtherGenusModal,
        UpdateReplicatesModal,
    },
    data: () => ({
        steps: 1,
        showStrainPanel: false,
        tempItems: [],
        lastType: null,
        charts: [],
        observations: null,
        fileResult: null,
        charts: [],
        uploadingResults: false,
        isCleaningResults: false,
        fileTodownload: null,
        // modal variables
        showAddReplicatesModal: false,
        showDeleteResultsConfirmationModal: false,
        showResultsExclusivityOtherGenusModal: false,
        showUpdateReplicatesModal: false,

    }),
    computed: {
        ...mapState("session", ["permissions"]),
        ...mapState("molecularMarker", ["oligoAndProbeDataSelected", "markerSelected"]),
    },
    mounted() {
        if (this.oligoAndProbeDataSelected.exclusivityOtherGenus.isSetUp) {
            this.steps = 2
        }
        this.observations = this.oligoAndProbeDataSelected.exclusivityOtherGenus.observations
        if (this.oligoAndProbeDataSelected.exclusivityOtherGenus.charts && this.oligoAndProbeDataSelected.exclusivityOtherGenus.charts.length != 0) {
            this.charts = this.oligoAndProbeDataSelected.exclusivityOtherGenus.charts
        }
    },
    methods: {
        ...mapActions("molecularMarker", ["addItemToResult", "removeItemToResult", "exclusivityOtherGenusSetupDone", "cleanAllResultsExclusivityOtherGenus", "addExclusivityOtherGenusObservations", "uploadResultsExclusivityOtherGenus"]),
        ...mapMutations("molecularMarker", ["SET_RESULT_ITEM_SELECTED"]),
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        closeComponent() {
            this.$emit("close")
        },
        async setupDone() {
            if (this.oligoAndProbeDataSelected.exclusivityOtherGenus.isSetUp) {
                this.steps = 2
            } else {
                try {
                    let payload = {}
                    let params = {
                        markerId: this.markerSelected._id,
                        oligoId: this.oligoAndProbeDataSelected._id,
                    }
                    await this.exclusivityOtherGenusSetupDone({
                        params: params,
                        body: payload
                    })
                    this.oligoAndProbeDataSelected.exclusivityOtherGenus.isSetUp = true
                    this.steps = 2
                } catch (error) {
                    console.log(error);
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "error",
                        text: `Algo salió mal. Error: ${error.message}`,
                        timeout: 5000,
                    });
                }
            }
        },
        openAddItemsConfirmation({
            items,
            type
        }) {
            this.tempItems = items
            this.lastType = type
            this.showAddReplicatesModal = true
        },
        async addItems(quantity) {
            try {
                for (let item of this.tempItems) {
                    if (this.lastType == "VECTOR") {
                        let register = {
                            microorganism: item.microorganism,
                            name: item.ampliconId,
                            type: "VECTOR",
                            vectorId: item._id,
                            cq: [],
                            efficience: [],
                            efficienceR2: [],
                            excluded: [],
                            colors: [],
                            replicasNumber: quantity,
                        }
                        let params = {
                            markerId: this.markerSelected._id,
                            oligoId: this.oligoAndProbeDataSelected._id,
                            type: "exclusivityOtherGenus",
                            oligoIdentifier: this.oligoAndProbeDataSelected.oligoIdentifier,
                        }
                        let response = await this.addItemToResult({
                            params: params,
                            body: register,
                        })

                        this.oligoAndProbeDataSelected.exclusivityOtherGenus.results.push(response.item)
                    }
                    if (this.lastType == "STRAIN") {
                        let register = {
                            microorganism: item.genomicSequence.putativeAssignation || item.genomicSequence.genomicAssignation,
                            name: item.genomicSequence.moSequenceId,
                            type: "STRAIN",
                            strainId: item._id,
                            cq: [],
                            efficience: [],
                            efficienceR2: [],
                            excluded: [],
                            colors: [],
                            replicasNumber: quantity
                        }
                        let params = {
                            markerId: this.markerSelected._id,
                            oligoId: this.oligoAndProbeDataSelected._id,
                            type: "exclusivityOtherGenus",
                            oligoIdentifier: this.oligoAndProbeDataSelected.oligoIdentifier,
                        }
                        let response = await this.addItemToResult({
                            params: params,
                            body: register,
                        })
                        this.oligoAndProbeDataSelected.exclusivityOtherGenus.results.push(response.item)
                    }
                    if (this.lastType == "SOIL") {
                        let register = {
                            microorganism: null,
                            name: item.folioAnalysis,
                            type: "SOIL",
                            // controlNumber: controlNumber,
                            sampleId: item._id,
                            cq: [],
                            efficience: [],
                            efficienceR2: [],
                            excluded: [],
                            colors: [],
                            replicasNumber: quantity
                        }
                        let params = {
                            markerId: this.markerSelected._id,
                            oligoId: this.oligoAndProbeDataSelected._id,
                            type: "exclusivityOtherGenus",
                            oligoIdentifier: this.oligoAndProbeDataSelected.oligoIdentifier,
                        }
                        let response = await this.addItemToResult({
                            params: params,
                            body: register,
                        })
                        this.oligoAndProbeDataSelected.exclusivityOtherGenus.results.push(response.item)
                    }
                }
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Se agregaron correctamente`,
                    timeout: 3000,
                });
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.showAddReplicatesModal = false
            this.showStrainPanel = false
        },
        async removeItem(item) {
            try {
                let params = {
                    markerId: this.markerSelected._id,
                    oligoId: this.oligoAndProbeDataSelected._id,
                    resultId: item._id,
                    type: "exclusivityOtherGenus",
                }
                await this.removeItemToResult({
                    params: params,
                    body: {}
                })
                const indexToRemove = this.oligoAndProbeDataSelected.exclusivityOtherGenus.results.findIndex(element => element._id == item._id);
                if (indexToRemove !== -1) {
                    this.oligoAndProbeDataSelected.exclusivityOtherGenus.results.splice(indexToRemove, 1);
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: `Se removio correctamente`,
                        timeout: 3000,
                    });
                }
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
        },
        numberToLetter(n) {
            if (n < 1 || n > 26) {
                return "Valor fuera de rango"; // Solo manejamos del 1 al 26
            }
            const letra = String.fromCharCode(64 + n);
            return letra;
        },
        getExcludedKind(value) {
            return value ? "Excluded" : "Incluido"
        },
        dateFormat(value) {
            const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
            return date || "--/--/----";
        },
        async cleanResults() {
            try {
                this.isCleaningResults = true
                let payload = {}
                let params = {
                    markerId: this.markerSelected._id,
                    oligoId: this.oligoAndProbeDataSelected._id,
                }
                let response = await this.cleanAllResultsExclusivityOtherGenus({
                    params: params,
                    body: payload
                })
                this.oligoAndProbeDataSelected.exclusivityOtherGenus.results = response.results
                this.oligoAndProbeDataSelected.exclusivityOtherGenus.isTestDone = false
                this.charts = []
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Resultados eliminados`,
                    timeout: 5000,
                });
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }

            this.isCleaningResults = false
        },
        setItemResultSelected(item) {
            this.SET_RESULT_ITEM_SELECTED(item)
            this.showUpdateReplicatesModal = true
        }
    },
    watch: {
        async observations(value) {
            try {
                let payload = {
                    observations: value
                }
                let params = {
                    markerId: this.markerSelected._id,
                    oligoId: this.oligoAndProbeDataSelected._id,
                }
                await this.addExclusivityOtherGenusObservations({
                    params: params,
                    body: payload
                })
                this.oligoAndProbeDataSelected.exclusivityOtherGenus.observations = value
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
        },
        async fileResult(value) {
            if (value) {
                try {
                    this.uploadingResults = true
                    var formData = new FormData();
                    formData.append("file", value);
                    formData.append("markerId", this.markerSelected._id);
                    formData.append("oligoId", this.oligoAndProbeDataSelected._id);
                    let response = await this.uploadResultsExclusivityOtherGenus(formData)
                    this.charts = response.charts
                    this.oligoAndProbeDataSelected.exclusivityOtherGenus.charts = response.charts
                    this.oligoAndProbeDataSelected.exclusivityOtherGenus.results = response.results
                    this.oligoAndProbeDataSelected.exclusivityOtherGenus.isTestDone = true
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: `Resultados guardados`,
                        timeout: 5000,
                    });
                    this.fileResult = null
                    this.$refs.result_field.$el.querySelector('input').blur();
                } catch (error) {
                    console.log(error);
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "error",
                        text: `Algo salió mal. Error: ${error.message}`,
                        timeout: 5000,
                    });
                }
                this.uploadingResults = false
            }

        },
        fileTodownload(value) {
            if (value) {
                var a = document.createElement("a");
                a.href = value;
                let fileName = value.split("/").pop();
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(value);
                a.remove();
                this.fileTodownload = null
                this.$refs["download_result_input"].reset();
                this.$refs.download_result_input.$el.querySelector('input').blur();
            }
        }
    }
};
</script>
