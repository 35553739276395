export function loggedIn(state) {
  return state.token !== null;
}
export function token(state) {
  return state.token;
}

/**
 * Retrieves the current language set in the application.
 * 
 * This function returns the language code currently stored in the application state.
 * 
 * @param {Object} state - The Vuex or Pinia state object that holds the current application state.
 * @returns {String} - The current language code (e.g., 'en', 'es', 'pt').
 */
export function currentLanguage(state) {
  return state.language;
}

/**
 * Retrieves the translation strings for the current language.
 * 
 * This function returns the specific language translations based on the current language
 * stored in the state.
 * 
 * @param {Object} state - The Vuex or Pinia state object that holds the current application state, including translations.
 * @returns {Object} - An object containing translation strings for the current language.
 */
export function currentTranslations(state) {
  return state.translations[state.language];
}
