<template>
  <div>
    <v-row>
      <v-col>
        <v-card elevation="0" outlined>
          <v-card-title> </v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col cols="12" sm="8">
                <v-text-field
                  outlined
                  label="Buscar..."
                  prepend-inner-icon="mdi-magnify"
                  background-color="white"
                  dense
                  clearable
                  v-model="searchQuery"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-menu
                  offset-y
                  :close-on-click="true"
                  :close-on-content-click="false"
                  v-model="filterMenu"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on" block>
                      <v-icon left>mdi-filter</v-icon>
                      Filtro
                    </v-btn>
                  </template>
                  <v-card width="auto">
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete
                              label="Ordenamiento"
                              hide-details
                              outlined
                              clearable
                              dense
                              :items="sortItems"
                              hide-no-data
                              item-text="text"
                              v-model="filter.sort"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="warning" @click="cleanFilter()"
                        >Restaurar</v-btn
                      >
                      <v-btn color="primary" @click="applyFilter()"
                        >Aplicar</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-combobox
                  filled
                  label="Número de items"
                  :items="[5, 10, 20, 30]"
                  clearable
                  v-model="numItems"
                ></v-combobox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6">
        <v-card class="p-3" elevation="0" outlined>
          <v-row>
            <v-col cols="6">
              <v-subheader class="text-center">Total Registros</v-subheader>
              <v-card-title class="text-center">{{ quantityAll }}</v-card-title>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="p-3" elevation="0" outlined>
          <v-row>
            <v-col cols="6">
              <v-subheader class="text-center">Disponibles</v-subheader>
              <v-card-title class="text-center">{{
                quantityAvailables
              }}</v-card-title>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// VUEX
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      filterMenu: null,
      searchQuery: "",
      numItems: 10,
      filter: {
        sort: null,
      },
      sortItems: [],
    };
  },
  computed: {
    ...mapState("vector", ["quantityAll", "quantityAvailables"]),
  },
  created() {
    this.sortItems = [
      {
        text: "Descendente",
        value: "NEWER",
      },
      {
        text: "Ascendente",
        value: "OLDER",
      },
    ];
    //set default sort
    this.filter.sort = "NEWER";
    this.applyFilter();
  },
  methods: {
    ...mapMutations("vector", [
      "SET_FILTER_TABLE",
      "SET_SEARCH_TABLE",
      "SET_ITEMS_PER_PAGE_TABLE",
      "SET_HEADERS_TABLE",
    ]),
    applyFilter() {
      let auxFilter = {
        sort: null,
      };
      if (this.filter.sort) {
        switch (this.filter.sort) {
          case "NEWER":
            auxFilter.sort = { createdAt: -1 };
            break;
          case "OLDER":
            auxFilter.sort = { createdAt: 1 };
            break;
        }
      }
      this.SET_FILTER_TABLE(auxFilter);
      this.filterMenu = false;
    },
    cleanFilter() {
      this.filterMenu = false;
      this.filter = {
        sort: null,
      };
      this.SET_FILTER_TABLE(this.filter);
    },
  },
  watch: {
    async searchQuery(value) {
      this.SET_SEARCH_TABLE(value);
    },
    async numItems(value) {
      this.SET_ITEMS_PER_PAGE_TABLE(value);
    },
  },
};
</script>

<style scoped>
.headline {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>
