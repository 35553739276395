import axios from "axios";

/**
 * get all preserved in ceparium
 */
export async function getPrserved({ commit }, payload) {
  try {
    const { data } = await axios.get("/ceparium", {
      params: payload,
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    console.log(e);
    throw Error(e.message);
  }
}

export async function addPreservedID({ commit }, payload) {
  try {
    const { data } = await axios.post("/ceparium/add-preserved-id", payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.message);
  }
}

export async function updatePreservedID({ commit }, payload) {
  try {
    const { data } = await axios.put("/ceparium/update-preserved-id", {
      params: { preservedId: payload.preservedId },
      body: payload.body,
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.message);
  }
}

export async function getIsolatesPreserveds({ commit }, payload) {
  try {
    const { data } = await axios.post(
      "/isolate/get-isolate-preserveds",
      payload
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function findByMoSequenceId({ commit }, payload) {
  try {
    const { data } = await axios.post(
      "/isolate/get-isolate-by-sequence",
      payload
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function findExactByMoSequenceId({ commit }, payload) {
  try {
    const { data } = await axios.post(
      "/isolate/get-isolate-by-exact-sequence",
      payload
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function createPreservedIsolate({ commit }, payload) {
  try {
    const { data } = await axios.post(
      "/isolate/create-preserved-isolate",
      payload
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function saveConfiguration({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/isolate/save-configuration", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function saveGeneralData({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/isolate/save-general-data", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function savePurity({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/isolate/save-purity", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function saveMolecularConfirmation({ commit }, { params, body }) {
  try {
    const { data } = await axios.put(
      "/isolate/save-molecular-confirmation",
      body,
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function saveCepariumLeon({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/isolate/save-ceparium-leon", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function saveCepariumCMA({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/isolate/save-ceparium-CMA", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function saveObservations({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/isolate/save-observations", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function addValidationsLink({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/isolate/add-validations-link", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function removeValidationsLink({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/isolate/remove-validations-link", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function addMacroImage({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/isolate/add-macro-img", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function addMicroImage({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/isolate/add-micro-img", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}
