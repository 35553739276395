<template>
<v-dialog v-model="show" :max-width="dialogWidth" scrollable persistent>
    <v-card elevation="5" class="rounded-lg">
        <v-card-title class="text-h5 lighten-2">Añadir etiueta a {{ sequence != null ? sequence.tag : "" }}
        </v-card-title>
        <v-card-text>
            <v-form lazy-validation ref="form">
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="sequenceTag" label="Ingresa etiqueta de secuencia" hide-no-data outlined dense clearable type="text" :rules="validation()"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-container fluid>
                <v-row>
                    <v-col cols="">
                        <v-btn color="red" block @click="closeModal" dark>Cancelar</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn color="primary" block @click="confirmation">Aceptar</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

    
    
<script>
//vuex
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
//validations
import {
    requiredValidation
} from "@/helpers/validations.js";
export default {
    data: () => ({
        sequenceTag: null,
    }),
    props: {
        value: Boolean,
        sequence: {
            type: Object,
            default: null
        }
    },
    computed: {
        dialogWidth() {
            if (this.$vuetify.breakpoint.xs) return "90vw"; // Extra pequeño
            if (this.$vuetify.breakpoint.sm) return "70vw"; // Pequeño
            if (this.$vuetify.breakpoint.md) return "60vw"; // Mediano
            return "50vw"; // Grande en adelante
        },
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        closeModal() {
            this.show = false;
        },
        validation(name) {
            return requiredValidation(name);
        },
        async confirmation() {
            if (this.$refs.form.validate()) {
                this.$emit("addTag", this.sequenceTag);
                this.sequenceTag = null
                this.$refs.form.reset()
            }
        },
    },
    watch: {
        show(value){
            if(value){
                if(!this.sequence.sequenceTag) {
                    this.sequenceTag = this.sequence.name
                }else{
                    this.sequenceTag = this.sequence.sequenceTag
                }
          
            }
        }
    },
};
</script>
