<template>
<v-container fluid>
    <div v-if="!showExclusivitySameGenusToOtherSpecies && !showExclusivityOtherGenus" class="ma-2">
        <v-row class="box-gray" @click="showExclusivitySameGenusToOtherSpecies = true">
            <v-col>
                <span>
                    Prueba de exclusividad (Microorganismos mismo genero otra especie)
                </span>
            </v-col>
            <v-col cols="2" class="justify-end d-flex">
                <v-icon color="primary" v-if="oligoAndProbeDataSelected.exclusivitySameGenusToOtherSpecies.isTestDone">
                    mdi-check-circle
                </v-icon>
                <v-icon color="blue" v-else-if="oligoAndProbeDataSelected.exclusivitySameGenusToOtherSpecies.isSetUp">
                    mdi-clock-time-eight-outline
                </v-icon>
                <v-icon color="primary" v-else> mdi-chevron-right</v-icon>
            </v-col>
        </v-row>
        <v-row class="box-gray" @click="showExclusivityOtherGenus = true">
            <v-col>
                <span>
                    Prueba de esclusividad (Microorganismos de otro genero)
                </span>
            </v-col>
            <v-col cols="2" class="justify-end d-flex">
                <v-icon color="primary" v-if="oligoAndProbeDataSelected.exclusivityOtherGenus.isTestDone">
                    mdi-check-circle
                </v-icon>
                <v-icon color="blue" v-else-if="oligoAndProbeDataSelected.exclusivityOtherGenus.isSetUp">
                    mdi-clock-time-eight-outline
                </v-icon>
                <v-icon color="primary" v-else> mdi-chevron-right </v-icon>
            </v-col>
        </v-row>
    </div>
    <div v-if="showExclusivitySameGenusToOtherSpecies">
        <exclusivity-same-genus-to-other-species @close="showExclusivitySameGenusToOtherSpecies = false" />
    </div>
    <div v-if="showExclusivityOtherGenus">
        <exclusivity-other-genus @close="showExclusivityOtherGenus = false" />
    </div>
</v-container>
</template>

<script>
// vuex
import {
    mapState
} from 'vuex';
// components
import ExclusivityOtherGenus from './ExclusivityOtherGenus.vue';
import ExclusivitySameGenusToOtherSpecies from './ExclusivitySameGenusToOtherSpecies.vue';

export default {
    components: {
        ExclusivitySameGenusToOtherSpecies,
        ExclusivityOtherGenus,
    },
    data: () => ({
        showExclusivitySameGenusToOtherSpecies: false,
        showExclusivityOtherGenus: false,
    }),
    computed: {
        ...mapState("molecularMarker", ["oligoAndProbeDataSelected"]),
    }
};
</script>

<style scoped>
.box-gray {
    border-width: 2px;
    border-color: #8c8c8c;
    border-radius: 10px;
    border-style: solid;
    margin-top: 3px;
    margin-bottom: 3px;
    transition: transform 0.3s linear, box-shadow 0.3s linear,
        border-color 0.3s linear;
    padding: 0px;
}

.box-gray:hover {
    transform: translateX(5px);
    background-color: rgba(4, 193, 5, 0.1);
    cursor: pointer;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    border-color: #04c105;
}
</style>
