<template>
<v-dialog v-model="dialog" width="90%" scrollable>
    <v-card>
        <v-card-title class="text-h5 primary white--text">
            Añadir evidencia fotográfica</v-card-title>

        <v-card-text class="py-0">
            <v-container v-if="dialog">
                <v-row justify="center" align="center">
                    <v-col cols="12" sm="12" md="5" class="text-center py-0">
                        <v-container class="pb-0">
                            <v-file-input v-model="photoFile" color="secondary" label="Fotografía" placeholder="Selecióna la fotografía" prepend-icon="mdi-file-document-multiple-outline" class="py-2" accept="image/*" outlined hide-details @change="filesChangedEvent">
                                <template v-slot:selection="{ text }">
                                    <v-chip color="secondary" dark label>
                                        {{ text }}
                                    </v-chip>
                                </template>
                            </v-file-input>
                            <cropper ref="cropper" :src="imageUrl" :stencil-props="{
                    handlers: {},
                    movable: false,
                    scalable: false,
                    aspectRatio: 1,
                    previewClass: 'preview',
                  }" :resize-image="{
                    adjustStencil: false,
                  }" stencil-component="circle-stencil" image-restriction="none" @change="getPhoto" />
                            <span v-if="imageUrl != ''" class="hidden-sm-and-down">
                                Puedes usar click izquierdo sobre la imágen para moverla y la
                                rueda del ratón para hacer zoom in o zoom out.
                            </span>
                        </v-container>
                    </v-col>
                    <v-col cols="12" sm="12" md="7" class="text-center py-0">
                        <h2>Vista previa</h2>
                        <v-container>
                            <canvas ref="canvas" id="canvas" :style="
                    !borders
                      ? 'border-radius: 50%; border: dotted; max-width: 100%'
                      : 'border: dotted;'
                  "></canvas>
                            <canvas ref="canvasAux" id="canvasAux" :style="
                    !borders
                      ? 'border-radius: 50%; position: fixed; visibility: hidden;'
                      : 'position: fixed; visibility: hidden;'
                  "></canvas>
                            <v-row align="center" justify="center" class="pt-3">
                                <v-col cols="12" class="py-0">
                                    <span>Los puntos indican los bordes de la imágen</span>
                                </v-col>

                                <v-col cols="12" sm="4" class="py-0">
                                    <v-subheader class="pl-0 py-0 text-center">
                                        Rotación
                                    </v-subheader>
                                    <v-btn color="success" icon @click="rotateImage('l', -90)">
                                        <v-icon>mdi-rotate-left-variant</v-icon>
                                    </v-btn>
                                    <v-btn color="success" icon @click="rotateImage('r', 90)">
                                        <v-icon>mdi-rotate-right-variant</v-icon>
                                    </v-btn>
                                    <v-btn color="success" icon @click="rotateImage('l', -15)">
                                        <v-icon>mdi-restore</v-icon>
                                    </v-btn>
                                    <v-btn color="success" icon @click="rotateImage('r', 15)">
                                        <v-icon>mdi-reload</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="4" class="py-0">
                                    <v-subheader class="pl-0 py-0 text-center">
                                        Brillo
                                    </v-subheader>
                                    <v-slider v-model="brightness" :max="50" :min="-50" color="secondary" track-color="primary" thumb-label hide-details prepend-icon="mdi-brightness-6"></v-slider>
                                </v-col>
                                <v-col cols="12" sm="4" class="py-0">
                                    <v-subheader class="pl-0 py-0 text-center">
                                        Contraste
                                    </v-subheader>
                                    <v-slider v-model="contrast" :max="100" :min="-100" color="secondary" track-color="primary" thumb-label hide-details prepend-icon="mdi-contrast-box"></v-slider>
                                </v-col>
                                <!-- <v-col cols="2" class="pt-5">
                      <v-switch
                        v-model="borders"
                        :true-value="true"
                        :false-value="false"
                        color="primary"
                        input-value="true"
                        label="Imágen cuadrada"
                        class="text-center"
                        value
                        inset
                        hide-details
                      ></v-switch>
                    </v-col> -->
                                <v-col cols="12" sm="4">
                                    <v-btn color="primary" @click="restoreParameters">Restaurar parámetros</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="dialog = false" :disabled="loading">
                Cancelar
            </v-btn>
            <v-btn color="primary" :disabled="imageUrl == ''" text @click="saveImage" :loading="loading">
                Guardar
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
import {
    Cropper
} from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import Compressor from "compressorjs";
export default {
    components: {
        Cropper,
    },
    props: {
        value: Boolean,
        type: {
            type: String,
            default: "",
            required: false,
        },
        urlImage: {
            type: String,
            default: "",
            required: false,
        },
    },
    computed: {
        ...mapState("ceparium", ["preservedSelected"]),
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    data() {
        return {
            borders: false,
            loading: false,
            contrast: 0,
            brightness: 0,
            rotation: 0,
            image: {},
            canvas: {},
            canvasContext: {},
            canvasAux: {},
            canvasContextAux: {},
            widthHeight: 280,
            widthHeightAux: 450,
            imageUrl: "",
            originalImageUrl: "",
            photoFile: null,
        };
    },
    methods: {
        ...mapActions("biochemicalTest", ["uploadPhoto"]),
        ...mapActions("ceparium", ["addMacroImage", "addMicroImage"]),
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        getPhoto(data) {
            let imageB64URL = data.canvas.toDataURL();

            let newCoords = this.$refs.cropper.getResult().coordinates;
            let imageUrl = imageB64URL;
            this.originalImageUrl = imageB64URL;

            this.resizeImage(imageUrl, newCoords);
        },
        resizeImage(imagePath, coords) {
            //create an image object from the path
            const originalImage = new Image();
            originalImage.src = imagePath;
            originalImage.crossOrigin = "anonymous";

            this.canvas = this.$refs.canvas;
            this.canvasContext = this.canvas.getContext("2d");

            this.canvasAux = this.$refs.canvasAux;
            this.canvasContextAux = this.canvasAux.getContext("2d");

            originalImage.addEventListener("load", () => {
                this.canvas.width = this.widthHeight;
                this.canvas.height = this.widthHeight;

                this.canvasAux.width = this.widthHeightAux;
                this.canvasAux.height = this.widthHeightAux;

                this.canvasContext.drawImage(
                    originalImage,
                    0,
                    0,
                    coords.width,
                    coords.height,
                    0,
                    0,
                    this.widthHeight,
                    this.widthHeight
                );

                this.canvasContextAux.drawImage(
                    originalImage,
                    0,
                    0,
                    coords.width,
                    coords.height,
                    0,
                    0,
                    this.widthHeightAux,
                    this.widthHeightAux
                );

                this.applyParameters();

                // console.log(canvas.toDataURL());
            });

            this.image = originalImage;
        },
        redrawImage() {
            let coords = this.$refs.cropper.getResult().coordinates;
            this.drawImage(this.image, coords);
        },
        drawImage(image, coords) {
            this.canvasContext.drawImage(
                image,
                0,
                0,
                coords.width,
                coords.height,
                0,
                0,
                this.widthHeight,
                this.widthHeight
            );

            this.canvasContextAux.drawImage(
                image,
                0,
                0,
                coords.width,
                coords.height,
                0,
                0,
                this.widthHeightAux,
                this.widthHeightAux
            );
        },
        rotateImage(orientation, rotation) {
            if (orientation == "l") {
                if (this.rotation == 0) {
                    this.rotation = rotation;
                } else {
                    this.$refs.cropper.rotate(rotation);
                }
            } else {
                if (this.rotation == 0) {
                    this.rotation = rotation;
                } else {
                    this.$refs.cropper.rotate(rotation);
                }
            }
        },
        applyBrightness(data, brightness) {
            for (var i = 0; i < data.length; i += 4) {
                data[i] += 255 * (brightness / 100);
                data[i + 1] += 255 * (brightness / 100);
                data[i + 2] += 255 * (brightness / 100);
            }
        },
        applyContrast(data, contrast) {
            var factor = (259.0 * (contrast + 255.0)) / (255.0 * (259.0 - contrast));

            for (var i = 0; i < data.length; i += 4) {
                data[i] = this.truncateColor(factor * (data[i] - 128.0) + 128.0);
                data[i + 1] = this.truncateColor(
                    factor * (data[i + 1] - 128.0) + 128.0
                );
                data[i + 2] = this.truncateColor(
                    factor * (data[i + 2] - 128.0) + 128.0
                );
            }
        },
        truncateColor(value) {
            if (value < 0) {
                value = 0;
            } else if (value > 255) {
                value = 255;
            }

            return value;
        },
        applyParameters() {
            this.redrawImage();

            let imageData = this.canvasContext.getImageData(
                0,
                0,
                this.canvas.width,
                this.canvas.height
            );

            let imageDataAux = this.canvasContextAux.getImageData(
                0,
                0,
                this.canvasAux.width,
                this.canvasAux.height
            );

            this.applyContrast(imageData.data, this.contrast);
            this.applyBrightness(imageData.data, this.brightness);

            this.applyContrast(imageDataAux.data, this.contrast);
            this.applyBrightness(imageDataAux.data, this.brightness);

            this.canvasContext.putImageData(imageData, 0, 0);
            this.canvasContextAux.putImageData(imageDataAux, 0, 0);
        },
        async saveImage() {
            this.loading = true;
            if (!this.borders) {
                var cw, ch;
                cw = this.widthHeightAux;
                ch = this.widthHeightAux;
                this.canvasContextAux.globalCompositeOperation = "destination-in";
                this.canvasContextAux.beginPath();
                this.canvasContextAux.arc(cw / 2, ch / 2, ch / 2, 0, Math.PI * 2);
                this.canvasContextAux.closePath();
                this.canvasContextAux.fill();
            }

            try {
                let b64Image = this.canvasAux.toDataURL();
                let fileName = this.generateUniqueId();

                const options = {
                    quality: 0.6,
                    maxWidth: 800,
                    maxHeight: 800,
                };

                let file = this.b64ToFile(`${fileName}-small`, b64Image);
                let fileOriginal = this.b64ToFile(`${fileName}`, this.originalImageUrl);
                // console.log(file);
                // console.log(fileOriginal);
                // console.log(file instanceof File);
                // const fileSmallCompressed = await new Promise((resolve, reject) => {
                //   new Compressor(file, {
                //     ...options,
                //     success: resolve,
                //     error: reject,
                //   });
                // });
                // const fileOriginalCompressed = await new Promise((resolve, reject) => {
                //   new Compressor(fileOriginal, {
                //     ...options,
                //     success: resolve,
                //     error: reject,
                //   });
                // });

                var formData = new FormData();
                formData.append("file", file);

                var formDataOriginal = new FormData();
                formDataOriginal.append("file", fileOriginal);

                let response = await this.uploadPhoto(formData);
                let response2 = await this.uploadPhoto(formDataOriginal);
                // emit photo
                let payload = {
                    img: response,
                };
                let params = {
                    isolateId: this.preservedSelected._id,
                }
                let responseUpdated = null
                if (this.type == "MICRO") {
                    responseUpdated = await this.addMicroImage({
                        params: params,
                        body: payload
                    });
                } else if (this.type == "MACRO") {
                    responseUpdated = await this.addMacroImage({
                        params: params,
                        body: payload
                    });
                }
                this.preservedSelected.research_development.imagesMacro = responseUpdated.isolate.research_development.imagesMacro
                this.preservedSelected.research_development.imagesMicro = responseUpdated.isolate.research_development.imagesMicro
                this.$emit("fetchTable");
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: "Evidencia fotográfica guardada correctamente",
                    timeout: 4000,
                });
                this.loading = false;
            } catch (e) {
                console.log(e);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Error al guardar evidencia fotográfica. Error: ${e.message}`,
                    timeout: 4000,
                });

                this.loading = false;
            }
        },
        b64ToFile(fileName, b64) {
            let mimeType = "";
            if (b64.startsWith("data:image/png;base64,")) {
                mimeType = "image/png";
                b64 = b64.replace("data:image/png;base64,", "");
            } else if (b64.startsWith("data:image/jpeg;base64,")) {
                mimeType = "image/jpeg";
                b64 = b64.replace("data:image/jpeg;base64,", "");
            } else if (b64.startsWith("data:image/jpg;base64,")) {
                mimeType = "image/jpeg";
                b64 = b64.replace("data:image/jpg;base64,", "");
            }

            var bs = atob(b64);
            var buffer = new ArrayBuffer(bs.length);
            var ba = new Uint8Array(buffer);
            for (var i = 0; i < bs.length; i++) {
                ba[i] = bs.charCodeAt(i);
            }
            var blob = new Blob([ba], {
                type: mimeType
            });

            let file = new File([blob], fileName);

            return file;
        },
        restoreParameters() {
            this.contrast = 0;
            this.brightness = 0;
            this.borders = false;
        },
        filesChangedEvent() {
            if (this.photoFile) {
                var reader = new FileReader();
                reader.readAsDataURL(this.photoFile);
                reader.onload = () => {
                    this.imageUrl = reader.result;
                };
                reader.onerror = function (error) {
                    console.log("Error: ", error);
                };
            } else {
                this.imageUrl = "";
            }
        },
        generateUniqueId() {
            const fecha = new Date();

            const año = fecha.getFullYear();
            const mes = ("0" + (fecha.getMonth() + 1)).slice(-2); // Enero es 0, así que sumamos 1
            const dia = ("0" + fecha.getDate()).slice(-2);
            const hora = ("0" + fecha.getHours()).slice(-2);
            const minuto = ("0" + fecha.getMinutes()).slice(-2);
            const segundo = ("0" + fecha.getSeconds()).slice(-2);
            const milisegundo = ("00" + fecha.getMilliseconds()).slice(-3);

            return `${año}${mes}${dia}${hora}${minuto}${segundo}${milisegundo}`;
        },
    },
    watch: {
        brightness() {
            if (this.dialog) this.applyParameters();
        },
        contrast() {
            if (this.dialog) this.applyParameters();
        },
        rotation() {
            this.$refs.cropper.rotate(this.rotation);
        },
        dialog() {
            if (!this.dialog) {
                this.contrast = 0;
                this.brightness = 0;
                this.image = {};
                this.canvas = {};
                this.canvasContext = {};
                this.borders = false;
                this.imageUrl = "";
                this.photoFile = null;
            }
        },
    },
};
</script>

<style>
.preview {
    border: dashed 2px rgba(255, 255, 255, 0.75);
}

.vue-advanced-cropper__background,
.vue-advanced-cropper__foreground {
    opacity: 1;
    background: rgba(255, 0, 0, 0.25);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
}
</style>
