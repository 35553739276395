<template>
  <v-dialog v-model="show" width="50vw" scrollable persistent>
    <v-card height="auto" elevation="5" class="rounded-lg" min-height="200">
      <v-card-title class="text-h5 lighten-2">Nuevo vector</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form_vector" lazy-validation>
            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field outlined dense label="Ingresa ID vector" clearable v-model="form.ampliconId"
                  :rules="validation"></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-autocomplete dense outlined label="Selecciona microorganismo" clearable
                  :search-input.sync="searchIdentification" :items="identifications" item-text="name"
                  :loading="identificationsLoading" v-model="form.identification" return-object :rules="validation"
                  hide-no-data>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field outlined dense label="Cantidad" clearable v-model="form.quantity" type="number"
                  :rules="validation"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea filled name="input-7-4" label="Observaciones" v-model="form.notes"></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn color="gray" block @click="closeModal" dark :disabled="isLoading">Cerrar</v-btn>
            </v-col>
            <v-col>
              <v-btn color="primary" block @click="saveData" :loading="isLoading">Crear</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//vuex
import { mapState, mapMutations, mapActions } from "vuex";
import { requiredValidation } from "@/helpers/validations.js";
export default {
  data: () => ({
    searchIdentification: null,
    identifications: [],
    identificationsLoading: false,
    isLoading: false,
    form: {
      ampliconId: null,
      identification: null,
      notes: null,
      quantity: null,
    },
  }),
  props: {
    value: Boolean,
  },
  computed: {
    validation() {
      return requiredValidation();
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("identification", ["searchIdentifications"]),
    ...mapActions("vector", ["createVector"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async saveData() {
      if (this.$refs.form_vector.validate()) {
        try {
          this.isLoading = true
          let payload = {
            ampliconId: this.form.ampliconId,
            microorganism: {
              id_identification: this.form.identification._id,
              name: this.form.identification.name,
              moType: this.form.identification.moType,
            },
            status: "AVAILABLE",
            type: "PLASMID",
            notes: this.form.notes,
            quantity: this.form.quantity
          };
          await this.createVector(payload);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: `Registro agregado`,
            timeout: 3000,
          });
          this.cleanForm();
          this.$emit("close_update");
        } catch (error) {
          console.log(error);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: `Algo salió mal. Error: ${error.message}`,
            timeout: 5000,
          });
        }
        this.isLoading = false
      }
    },
    cleanForm() {
      this.$refs.form_vector.resetValidation();
      this.form = {
        ampliconId: null,
        identification: null,
        notes: null,
        quantity: null
      };
    },
    closeModal() {
      this.cleanForm();
      this.$emit("close");
    },
    async getIdentifications(value) {
      if (value) {
        try {
          this.identificationsLoading = true;
          const response = await this.searchIdentifications({
            name: value,
          });
          if (response.error) throw response;
          this.identifications = response.IDENTIFICATIONS;
        } catch (error) {
          console.log(error);
        }
        this.identificationsLoading = false;
      } else {
        this.identifications = [];
      }
    },
  },
  watch: {
    async searchIdentification(value) {
      await this.getIdentifications(value);
    },
  },
};
</script>
