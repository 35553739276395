<template>
  <div>
    <v-row>
      <v-col>
        <v-card flat class="mb-5 pa-2" min-height="80vh">
          <v-card-text class="black--text">
            <v-row>
              <v-col>
                <h3 class="title--gray" v-if="labelsTabSelected == 'plates'">{{translations.plates}}</h3>
                <h3 class="title--gray" v-else-if="labelsTabSelected == 'cultureMedias'">Medios de cultivo</h3>
              </v-col>
            </v-row>
            <v-row justify="center" class="text-center">
              <v-col cols="12" md="12" lg="6" class="px-0">
                <PrintLabels />
              </v-col>
              <v-col
                v-if="user.role === 'USER_LAB_ADMIN'"
                cols="12"
                md="12"
                lg="6"
                class="px-0"
              >
                <CreateCultureMedia />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-tabs
                  v-model="labelsTabSelected"
                  slider-color="secondary"
                  height="35"
                  grow
                  centered
                >
                  <v-tab href="#cultureMedias" v-if="permissions.find(per => per == 'lab_medias')"> Medios de cultivo </v-tab>
                  <v-tab href="#plates" v-if="permissions.find(per => per == 'lab_qpcr_plates')"> {{translations.cmda_plates}} </v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-tabs-items v-model="labelsTabSelected">
                  <v-tab-item value="cultureMedias" v-if="permissions.find(per => per == 'lab_medias')">
                    <v-row>
                      <!-- filter selected -->
                      <v-col cols="12" sm="12">
                        <v-select
                          v-model="cultureMediaSelected"
                          :items="cultureMediaList"
                          item-text="nutrientAgar"
                          multiple
                          clearable
                          label="Medio de cultivo"
                        >
                          <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0"
                              >{{ item.nutrientAgar }}
                            </span>
                            <span
                              v-if="index === 1"
                              class="grey--text text-caption"
                            >
                              (+{{ cultureMediaSelected.length - 1 }} más)
                            </span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <!-- filter by date -->
                    <v-row class="mt-0">
                      <v-col cols="12" class="mb-0 pb-0">
                        <span
                          >Filtrar medios usados en un rango de fechas:</span
                        >
                      </v-col>
                      <v-col>
                        <!-- start date -->
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          offset-y
                          transition="scale-transition"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                              clearable
                              prepend-icon="mdi-calendar"
                              label="Inicio"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            @input="menu = false"
                            :max="today"
                            :rules="rulesDate"
                            locale="es"
                          />
                        </v-menu>
                      </v-col>
                      <!-- end date -->
                      <v-col>
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          offset-y
                          transition="scale-transition"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date2"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                              clearable
                              prepend-icon="mdi-calendar"
                              label="Fin"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date2"
                            @input="menu2 = false"
                            :max="today"
                            :rules="rulesDate"
                            locale="es"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                    <!-- Table -->
                    <v-data-table
                      id="table-media"
                      :headers="headers"
                      :items="testMediaList"
                      :loading="loader"
                      disable-pagination
                      disable-filtering
                      hide-default-footer
                      sort-by="total"
                      no-data-text="No se han agregado medios de cultivo"
                      no-results-text="No se encontraron resultados"
                      loading-text="Cargando..."
                      :header-props="{
                        sortByText: 'Ordenar por',
                      }"
                    >
                    </v-data-table>
                  </v-tab-item>
                  <v-tab-item value="plates" v-if="permissions.find(per => per == 'lab_qpcr_plates')">
                    <v-row class="mt-0">
                      <v-col cols="12" class="mb-0 pb-0">
                        <span
                          >{{translations.filter_plates_used_in_date_range}}:</span
                        >
                      </v-col>
                      <v-col>
                        <!-- start date -->
                        <v-menu
                          v-model="menuPlates"
                          :close-on-content-click="false"
                          offset-y
                          transition="scale-transition"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                              clearable
                              prepend-icon="mdi-calendar"
                              :label="translations.start"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            @input="menu = false"
                            :max="today"
                            :rules="rulesDate"
                            locale="es"
                          />
                        </v-menu>
                      </v-col>
                      <!-- end date -->
                      <v-col>
                        <v-menu
                          v-model="menuPlates2"
                          :close-on-content-click="false"
                          offset-y
                          transition="scale-transition"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date2"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                              clearable
                              prepend-icon="mdi-calendar"
                              :label="translations.end"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date2"
                            @input="menu2 = false"
                            :max="today"
                            :rules="rulesDate"
                            locale="es"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-data-table
                      :headers="headersPlates"
                      :items="platesList"
                      disable-pagination
                      disable-filtering
                      hide-default-footer
                      :no-results-text="translations.no_results_found"
                      :loading-text="`${translations.loading}...`"
                      :header-props="{
                        sortByText: 'Ordenar por',
                      }"
                    >
                    </v-data-table>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// vuex
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
// components
import CreateCultureMedia from "./modals/CreateCultureMedia.vue";
import PrintLabels from "./modals/PrintLabels.vue";
// library
import axios from "axios";
import dayjs from "dayjs";

export default {
  name: "TestMedia",
  components: {
    CreateCultureMedia,
    PrintLabels,
  },
  data() {
    return {
      // loader
      loader: false,
      // filter date
      date: "",
      menu: false,
      menuPlates : false,
      menuPlates2 : false,
      modal: false,
      date2: "",
      menu2: false,
      modal2: false,
      //
      listIsReady: false,
      cultureMediaSelected: [],
      testMediaList: [],
      originalList: [],

      platesList : [],
      // table headers
      headers: [
        {
          text: "Medio de cultivo",
          value: "name",
        },
        { text: "Disponibles", value: "UNREGISTERED" },
        { text: "Usados", value: "REGISTERED" },
        { text: "Contaminados", value: "CONTAMINATED" },
      ],
      headersPlates: null,
      // validation rules
      rulesDate: [],
      // user data
      user: JSON.parse(localStorage.getItem("user")),

      // tabs
      labelsTabSelected: "plates"
      // labelsInfoSelected : '',
    };
  },
  async created() {
    this.headersPlates = [
        {
          text: this.translations.kitType,
          value: "type",
        },
        { text: this.translations.available, value: "available" },
        // { text: "Contaminados", value: "contaminated" },
        { text: this.translations.used, value: "used" },
      ]
      this.rulesDate =  [
        (v) => v > this.date2 || this.translations.check_start_date,
        (v) => v < this.date || this.translations.check_end_date,
      ]
    try {
      this.loading = true;

      let filters = {
        laboratory: this.user.laboratory._id,
      };
      // get test media created and count it
      const response = await this.countTestMedias(filters);
      if (response.error) throw response;

      // get plates stats
      const response2 = await this.getPlatesStats(filters)
      if (response2.error) throw response;

      this.formatPlatesStatsTable(response2)

      this.labelsTabSelected = this.permissions.find(per => per == 'lab_medias') ? "cultureMedias" : "plates";

      this.loading = false;
    } catch (error) {
      console.log(error);
      this.SET_ALERT_TYPE({
        visible: true,
        type: "error",
        text: error.message,
        timeout: 4000,
      });
    }
  },
  watch: {
    testMediaCounted: {
      handler(newValue, oldValue) {
        if (newValue.length > 0) {
          // if testMediaCounted change format data
          this.listIsReady = true;
          this.formatDataTable();
        } else {
          this.listIsReady = false;
        }
      },
      deep: true,
    },

    // if culture media is selected filter on client side
    async cultureMediaSelected(value, oldValue) {
      if (value && value.length > 0) {
        if (!oldValue) oldValue = [];
        if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
          this.filterByTestMediaType(value);
        }
      } else {
        await this.filterTestMedia();
      }
    },

    // filter when date changes
    async date(value, oldValue) {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.SET_CANCEL_TOKEN(axios.CancelToken.source());
      if (value) {
        if (!oldValue) oldValue = "";
        if (value.trim() !== oldValue.trim()) {
          if (value < this.date2) {
            await this.filterTestMedia();
          }
        }
      } else {
        if (value < this.date2 || this.date2 === null) {
          await this.filterTestMedia();
        }
      }
    },
    async date2(value, oldValue) {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.SET_CANCEL_TOKEN(axios.CancelToken.source());
      if (value) {
        if (!oldValue) oldValue = "";
        if (value.trim() !== oldValue.trim()) {
          if (value > this.date) {
            await this.filterTestMedia();
          }
        }
      } else {
        if (value < this.date || this.date === null) {
          await this.filterTestMedia();
        }
      }
    },
  },

  computed: {
    ...mapGetters("session", ["currentTranslations"]),
    translations() {
      return this.currentTranslations;
    },
    ...mapState("testMedia", ["testMediaCounted"]),
    ...mapState("cultureMedia", ["cultureMediaList"]),
    ...mapState(["cancelToken"]),
    ...mapState("session", ["permissions"]),
    today() {
      return dayjs().format("YYYY-MM-DD");
    },
  },

  methods: {
    ...mapMutations(["SET_CANCEL_TOKEN"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapMutations("testMedia", ["SET_TESTMEDIA_FORMATED"]),
    ...mapActions("testMedia", ["countTestMedias"]),
    ...mapActions("molecularPlate", ["getPlatesStats"]),

    async filterTestMedia() {
      this.loader = true;
      try {
        let filters = {
          startDate: this.date,
          endDate: this.date2,
          cultureMedia: this.cultureMediaSelected,
          laboratory: this.user.laboratory._id,
        };
        // get test medias with filters
        const response = await this.countTestMedias(filters);
        // format new data
        this.formatDataTable();

        if (response.error) throw response;

        // get plates stats
        const response2 = await this.getPlatesStats(filters)
        if (response2.error) throw response;

        this.formatPlatesStatsTable(response2)
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
      this.loader = false;
    },

    // filter only on client side
    filterByTestMediaType(arrayNames) {
      this.loader = true;

      if (this.testMediaList.length > 0) {
        // keep original data
        if (this.originalList.length <= 0) {
          this.originalList = this.testMediaList;
        }
        let filtered = this.originalList.filter((elem) => {
          return arrayNames.includes(elem.name);
        });

        this.testMediaList = filtered;
      } else {
        this.formatDataTable();
      }

      this.loader = false;
    },

    formatDataTable() {
      this.loader = true;
      if (this.listIsReady) {
        let formated = this.testMediaCounted.map((item) => {
          // sepate testmedia by status
          let obj = {
            UNREGISTERED: 0,
            REGISTERED: 0,
            CONTAMINATED: 0,
          };
          item.statusCount.map((val) => {
            obj[val.status] = val.count;
          });

          return {
            name: item._id.nutrientAgar,
            total: item.total,
            ...obj,
          };
        });

        this.testMediaList = formated;

        this.loader = false;
      }
    },

    formatPlatesStatsTable(data) {

      this.platesList = [
        {
          type : this.translations.well_plate,
          available : data.purification.available,
          contaminated : data.purification.contaminated,
          used : data.purification.used
        },
        {
          type : this.translations.pcr_plate,
          available : data.elution.available,
          contaminated : data.elution.contaminated,
          used : data.elution.used
        },
        {
          type : this.translations.mic_plate,
          available : data.amplification.available,
          contaminated : data.amplification.contaminated,
          used : data.amplification.used
        }
      ]
    }
  },
};
</script>

<style scoped></style>
