<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="900"
      persistent
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn small depressed block color="primary" v-bind="attrs" v-on="on">
          <!-- <v-icon left> mdi-molecule </v-icon> -->
          🧬 {{translations.molecular_identification}}
        </v-btn>
      </template>
      <template v-slot:default>
        <v-card>
          <v-toolbar color="primary" short dark
            >{{translations.molecular_identification}}</v-toolbar
          >
          <v-card-text>
            <v-container>
              <v-row class="pt-3">
                <span
                  >{{translations.select_files_to_process}}
                  </span
                >
              </v-row>
              <v-row
                v-if="
                  permissions.find((per) => per == 'lab_results_ignore_culture')
                "
              >
                <v-col>
                  <v-switch
                    :label="translations.results_without_culture"
                    v-model="ignoreCulture"
                    dense
                  >
                  </v-switch>
                </v-col>
              </v-row>
              <v-row class="pb-3" align="center" justify="center">
                <v-col cols="12">
                  <v-file-input
                    v-model="files"
                    color="secondary"
                    :label="`${translations.documents} (.csv)`"
                    multiple
                    :placeholder="translations.select_files"
                    prepend-icon="mdi-file-document-multiple-outline"
                    outlined
                    accept=".csv"
                    :loading="loadingSave"
                    @change="filesChangedEvent"
                    :disabled="loadingData || loadingSave"
                    hide-details
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 3"
                        color="secondary"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 3"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 3 }} {{translations.file_s}}
                      </span>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <p v-if="molecularTests.length > 0" class="pt-2">{{translations.results}} :</p>
              <v-row v-for="(data, index) in molecularTests" :key="index">
                <v-col cols="10">
                  <v-container>
                    <v-row class="py-0">
                      <span class="font-weight-bold warning--text"
                        >{{translations.file}}: {{ data.file }}</span
                      >
                    </v-row>
                    <v-row class="py-0" v-if="data.mosLowEfficiency > 0">
                      <small
                        v-if="data.mosLowEfficiency > 1"
                        class="font-weight-bold red--text"
                        >⚠ {{translations.detected}} {{ data.mosLowEfficiency }} {{ translations.low_detection_efficiency_results }}</small
                      >
                      <small v-else class="font-weight-bold red--text"
                        >⚠ {{translations.detected_single}} {{ data.mosLowEfficiency }} {{translations.low_detection_efficiency_result}}</small
                      >
                    </v-row>
                    <v-row class="py-0" v-if="data.mosCqHigh > 0">
                      <small
                        v-if="data.mosCqHigh > 1"
                        class="font-weight-bold red--text"
                        >⚠ {{translations.detected}} {{ data.mosCqHigh }}  {{ translations.high_cycle_detection_results }}</small
                      >
                      <small v-else class="font-weight-bold red--text"
                        >⚠ {{translations.detected_single}} {{ data.mosCqHigh }} {{ translations.high_cycle_detection_result }}</small
                      >
                    </v-row>
                    <v-row class="py-0" v-if="data.errorMos > 0">
                      <small
                        v-if="data.errorMos > 1"
                        class="font-weight-bold red--text"
                        >⚠ {{translations.detected}} {{ data.errorMos }} {{ translations.results_with_errors }}</small
                      >
                      <small v-else class="font-weight-bold red--text"
                        >⚠ {{translations.detected_single}} {{ data.errorMos }} {{ translations.result_with_error }}</small
                      >
                    </v-row>
                    <v-row
                      class="py-0"
                      v-if="data.mosLowEfficiency > 0 || data.mosCqHigh > 0"
                    >
                      <small
                        >{{translations.high_cycle_or_low_efficiency_results}}
                        <b>"{{translations.notDetected}}"</b></small
                      >
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    color="secondary"
                    small
                    block
                    @click="removeFile(index)"
                  >
                    <v-icon left> mdi-delete </v-icon>
                    {{translations.discard}}
                  </v-btn>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-data-table
                    v-model="molecularTestsSelected"
                    :headers="tableHeaders"
                    :items="data.tests"
                    :items-per-page="-1"
                    :no-data-text="translations.no_microorganisms_detected"
                    height="41vh"
                    checkbox-color="secondary"
                    item-key="_id"
                    fixed-header
                    hide-default-footer
                    show-select
                  >
                    <template v-slot:[`item.analysis`]="{ item }">
                      <span v-if="!item.error">{{ item.analysis }}</span>
                      <v-tooltip v-else bottom color="error">
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="warning--text"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ item.analysis }}
                          </span>
                        </template>
                        <span>{{ getErroType(item) }}</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:[`item.sample`]="{ item }">
                      {{ item.sample }}
                      <v-icon
                        v-if="item.isFromVegetalSample"
                        class="pl-1"
                        color="primary"
                        small
                        >mdi-sprout</v-icon
                      >
                    </template>
                    <template v-slot:[`item.crop`]="{ item }">
                      <span>{{ getCropSpanishName(item.crop) }}</span>
                    </template>
                    <template v-slot:[`item.efficiency`]="{ item }">
                      <template v-if="!item.efficiency">- - -</template>
                      <template v-else>
                        <b v-if="item.efficiency < 0.5" class="warning--text"
                          >⚠ {{ item.efficiency }}</b
                        >
                        <span v-else>{{ item.efficiency }}</span>
                      </template>
                    </template>
                    <template v-slot:[`item.concentration`]="{ item }">
                      <span
                        v-if="item.result == '-1' || item.isFromVegetalSample"
                        >---</span
                      >
                      <v-edit-dialog
                        v-else
                        :return-value.sync="item.concentration"
                        @close="closeConcentration(item)"
                      >
                        {{ parseInt(item.concentration).toExponential(2) }}
                        UFC/g
                        <template v-slot:input>
                          <div class="mt-4 text-h6">
                            Actualizar concentración
                          </div>
                          <v-text-field
                            v-model.number="item.concentration"
                            single-line
                          ></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template v-slot:[`item.index`]="{ item }">
                      <span v-if="item.umc">{{ item.umc }}</span>
                      <span v-else>- - -</span>
                    </template>
                    <template v-slot:[`item.result`]="{ item }">
                      <span v-if="item.result == '-1'">{{translations.not_detected}}</span>
                      <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-if="
                              parseFloat(item.result) >= item.cqMax ||
                              parseFloat(item.result) < item.cqMin
                            "
                            v-bind="attrs"
                            v-on="on"
                            class="font-weight-bold warning--text"
                            >{{ item.result }}</span
                          >
                          <span v-else>{{ item.result }}</span>
                        </template>
                        <span>{{translations.not_detected}}</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:[`item.mo`]="{ item }">
                      <template v-if="item.threat != null">
                        <span v-if="item.threat" class="warning--text">{{
                          item.mo
                        }}</span>
                        <span v-else class="primary--text">{{ item.mo }}</span>
                      </template>
                      <template v-else>
                        <span>{{ item.mo }}</span>
                      </template>
                    </template>
                  </v-data-table>
                  <v-row align="center" justify="center">
                    <v-col cols="12" class="text-right">
                      <small class="pr-5"
                        ><v-icon color="primary" small>mdi-sprout</v-icon>
                        {{translations.plant_sample}}</small
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <!-- <ControlPanel /> -->
            <v-btn text @click="cancelIdentification" :disabled="loadingSave"
              >{{translations.cancel}}</v-btn
            >
            <v-btn
              text
              color="primary"
              :loading="loadingSave"
              :disabled="molecularTestsSelected.length == 0"
              @click="addMolecularData"
              >{{translations.add}} ({{
                molecularTestsSelected.length + molecularTestsDescarted.length
              }})</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog v-model="loadingData" persistent width="300">
      <v-card color="secondary" dark>
        <v-card-text>
          {{translations.processing_files}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loadingErrorData" scrollable persistent width="770">
      <v-card>
        <v-toolbar color="warning" short dark
          >{{translations.errors}} ({{ molecularTestsWrong.length }})</v-toolbar
        >
        <v-card-text>
          <v-container fluid>
            <v-row align="center" justify="center" class="pt-5">
              <v-data-table
                height="70vh"
                fixed-header
                hide-default-footer
                :items-per-page="-1"
                :headers="wrongTableHeaders"
                :items="molecularTestsWrong"
              >
                <template v-slot:[`item.concentration`]="{ item }">
                  <span v-if="item.cycles == '-1'">---</span>
                  <span v-else
                    >{{
                      Number.parseFloat(item.concentration).toExponential(2)
                    }}
                    UFC/g</span
                  >
                </template>
                <template v-slot:[`item.cycles`]="{ item }">
                  <span v-if="item.cycles == '-1'">{{translations.no_presence}}</span>
                  <span v-else>{{ item.cycles }}</span>
                </template>
              </v-data-table>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeWrongTests">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import ControlPanel from "./ControlPanel.vue";
export default {
  data() {
    return {
      dialog: false,
      files: [],
      loadingData: false,
      loadingSave: false,
      loadingErrorData: false,
      molecularTests: [],
      molecularTestsDescarted: [],
      molecularTestsSelected: [],
      tableHeaders: [

      ],
      wrongTableHeaders: [

      ],
      molecularTestsWrong: [],
      user: JSON.parse(localStorage.getItem("user")),
      ignoreCulture: false,
    };
  },
  created(){
    this.tableHeaders = [
        { text: this.translations.analysis, value: "analysis" },
        { text: this.translations.sample, value: "sample" },
        { text: this.translations.crop, value: "crop" },
        { text: this.translations.identification, value: "mo" },
        { text: this.translations.efficiency, value: "efficiency" },
        { text: this.translations.result_x_bar, value: "result" },
        { text: "UMC", value: "umc" },
      ]
      this.wrongTableHeaders = [
        { text: this.translations.analysis, value: "analysis" },
        { text: this.translations.sample, value: "sample" },
        { text: this.translations.identification, value: "mo" },
        { text: this.translations.efficiency, value: "efficiency" },
        { text: this.translations.result_x_bar, value: "cycles" },
        { text: "UMC", value: "umc" },
        { text: this.translations.cause, value: "message" },
      ]
  },
  methods: {
    ...mapActions("molecularIdentification", [
      "uploadFile",
      "addIdentifications",
      "saveReportResult",
    ]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async filesChangedEvent() {
      try {
        if (this.files.length > 0) {
          this.loadingData = true;
          await this.processFiles();
          this.loadingData = false;
        } else {
          this.molecularTests = [];
          this.molecularTestsSelected = [];
          this.molecularTestsDescarted = [];
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `${this.translations.somethingWentWrong}: ${error.message}`,
          timeout: 5500,
        });

        this.loadingData = false;

        this.molecularTests = [];
        this.molecularTestsSelected = [];
        this.molecularTestsDescarted = [];
      }
    },
    async processFiles() {
      let molecularData = [];
      for (const file of this.files) {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("ignoreCulture", this.ignoreCulture);
        let request = await this.uploadFile(formData);
        if (request.error) {
          throw request;
        } else {
          let { data, mosLowEfficiency, mosCqHigh, errorMos } =
            this.formatDataTableData(request.molecularData);
          molecularData.push({
            file: request.molecularData.fileName,
            tests: data,
            mosLowEfficiency,
            mosCqHigh,
            errorMos,
          });
        }
      }
      this.molecularTests = molecularData;
    },
    formatDataTableData(obj) {
      let allMolecularData = [];
      let mosLowEfficiency = 0;
      let mosCqHigh = 0;
      let errorMos = 0;
      for (const test of obj.data) {
        let testData = {
          analysis: "",
          sample: "",
          mo: "",
          result: "",
          isSelectable: true,
          isFromVegetalSample: false,
          efficiency: 0,
        };
        testData.fileName = obj.fileName;
        testData.mo = test.mo;
        let index = 0;
        for (const molecularTest of test.tests) {
          if (molecularTest.sample != "M") {
            testData._id = `${index}${testData.mo}-${molecularTest.analysis}-${molecularTest.sample}-${molecularTest.result}`;
            testData.analysis = molecularTest.analysis;
            testData.sample = molecularTest.sample;
            testData.result = molecularTest.result;
            testData.umc = molecularTest.umc
              ? molecularTest.umc.toFixed(2)
              : null;
            testData.isFromVegetalSample = molecularTest.isFromVegetalSample;
            testData.efficiency = molecularTest.efficiency;
            testData.crop = molecularTest.crop;
            testData.threat =
              molecularTest.threat != undefined ? molecularTest.threat : null;
            testData.errorMessage = "";
            testData.error = false;
            testData.cqMax = molecularTest.cqMax;
            testData.cqMin = molecularTest.cqMin;
            testData.marker = molecularTest.marker;
            testData.type = molecularTest.type;
            // if (
            //   molecularTest.analysis.includes("Control") ||
            //   parseFloat(molecularTest.result) >= 35
            //   // || molecularTest.efficiency < 0.5
            // ) {
            //   // testData.isSelectable = false;
            //   if (
            //     !molecularTest.analysis.includes("Control") &&
            //     parseFloat(molecularTest.result) >= 35
            //   ) {
            //     // this.molecularTestsDescarted.push({ ...testData });
            //   }
            // } else {
            //   if (
            //     !molecularTest.efficiency ||
            //     molecularTest.efficiency >= 0.5
            //   ) {
            //     this.molecularTestsSelected.push({ ...testData });
            //   }
            //   testData.isSelectable = true;
            // }
            if (
              !molecularTest.analysis.includes("Control")
              // || molecularTest.efficiency < 0.5
            ) {
              if (!molecularTest.error) {
                this.molecularTestsSelected.push({ ...testData });
              }

              if (
                parseFloat(molecularTest.result) != -1 &&
                (parseFloat(molecularTest.result) >= molecularTest.cqMax ||
                  parseFloat(molecularTest.result) < molecularTest.cqMin)
              ) {
                mosCqHigh++;
              }

              if (molecularTest.error) {
                testData.isSelectable = false;
                testData.errorMessage = molecularTest.errorMessage;
                testData.error = true;
                errorMos++;
              } else {
                testData.isSelectable = true;
              }
            } else {
              testData.isSelectable = false;
            }

            if (molecularTest.efficiency && molecularTest.efficiency < 0.5) {
              mosLowEfficiency++;
            }
            allMolecularData.push({ ...testData });
          }

          index++;
        }
      }
      return { data: allMolecularData, mosLowEfficiency, mosCqHigh, errorMos };
    },
    removeFile(index) {
      let file = this.molecularTests[index].file;
      let molecularTestsAux = this.molecularTestsSelected.filter(
        (test) => test.fileName != file
      );
      this.molecularTestsSelected = molecularTestsAux;
      this.molecularTests.splice(index, 1);
      this.files.splice(index, 1);
    },
    cancelIdentification() {
      this.files = [];
      this.molecularTests = [];
      this.molecularTestsSelected = [];
      this.molecularTestsDescarted = [];
      this.dialog = false;
    },
    async addMolecularData() {
      this.loadingSave = true;
      try {
        let tests = this.molecularTestsSelected.map((test) => {
          if (
            parseFloat(test.result) >= test.cqMax ||
            parseFloat(test.result) < test.cqMin ||
            (test.efficiency && test.efficiency < 0.5)
          ) {
            return {
              isFromVegetalSample: test.isFromVegetalSample,
              file: test.fileName,
              analysis: test.analysis,
              sample: test.sample,
              mo: test.mo,
              efficiency: test.efficiency ? test.efficiency : 0,
              cycles: test.result,
              umc: test.umc,
              threat: test.threat,
              cqMax: test.cqMax,
              cqMin: test.cqMin,
              marker: test.marker
            };
          } else {
            return {
              isFromVegetalSample: test.isFromVegetalSample,
              file: test.fileName,
              analysis: test.analysis,
              sample: test.sample,
              mo: test.mo,
              efficiency: test.efficiency,
              cycles: test.result,
              umc: test.umc,
              threat: test.threat,
              cqMax: test.cqMax,
              cqMin: test.cqMin,
              marker: test.marker
            };
          }
        });
        // tests = tests.concat(
        //   this.molecularTestsDescarted.map((test) => {
        //     return {
        //       isFromVegetalSample: test.isFromVegetalSample,
        //       file: test.fileName,
        //       analysis: test.analysis,
        //       sample: test.sample,
        //       mo: test.mo,
        //       cycles: "-1",
        //       concentration: "0",
        //       threat : test.threat
        //     };
        //   })
        // );
        // console.log(tests)
        const testsByFile = this.groupBy(tests, "file");
        const testsByFileFormated = this.objectToArrayMap(testsByFile);
        let index = 0;
        for (const fileData of testsByFileFormated) {
          let testsFormated = fileData[1];

          let request = await this.addIdentifications({
            testsFormated,
            user: this.user._id,
          });

          var file = this.files[index];

          const fileInfo = {
            fileName: this.user.laboratory
              ? `${this.user.laboratory.name}/${file.name}`
              : file.name,
          };

          const json = JSON.stringify(fileInfo);
          const blobJSON = new Blob([json], {
            type: "application/json",
          });

          const data = new FormData();
          data.append("json", blobJSON);
          data.append("file", file);

          const docReq = await this.saveReportResult(data);

          if (request.error) {
            throw request;
          }

          if (docReq.error) {
            throw docReq;
          }
          if (request.wrongMolecularTests.length > 0) {
            let moWrong = {
              analysis: "",
              sample: "",
              mo: "",
              cycles: "",
              umc: "",
              message: "",
            };
            let wrongs = [];
            for (const analysis of request.wrongMolecularTests) {
              for (const moData of analysis[1]) {
                moWrong = {
                  analysis: moData.analysis,
                  sample: moData.sample,
                  mo: moData.mo,
                  cycles: moData.cycles,
                  umc: moData.umc,
                  message: analysis.message,
                };
                wrongs.push(moWrong);
              }
            }
            this.molecularTestsWrong = wrongs;
            this.loadingErrorData = true;
            this.SET_ALERT_TYPE({
              visible: true,
              type: "info",
              text: this.translations.some_identifications_not_saved,
              timeout: 3500,
            });
          }

          index++;
        }
        this.$emit("molecularIdentificationsAdded");
        this.cancelIdentification();
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: e.message,
          timeout: 3500,
        });
      }
      this.loadingSave = false;
    },
    closeWrongTests() {
      this.molecularTestsWrong = [];
      this.loadingErrorData = false;
    },
    groupBy(objectArray, property) {
      return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
        return acc;
      }, {});
    },
    objectToArrayMap(obj) {
      const map = new Map(Object.entries(obj));
      return Array.from(map);
    },
    closeConcentration(test) {
      let testToUpdated = this.molecularTestsSelected.find(
        (element) => element._id == test._id
      );
      testToUpdated.concentration = test.concentration;
    },
    getCropSpanishName(cropEnglishName) {
      let result = this.translations.crop_name_list[cropEnglishName];
      return result || this.translations.notAvailable;
    },
    getErroType(item){
      let type = item.type
      if(type == 1){  
        return this.translations.no_package_assigned
      }else if(type == 2){
        return `${this.translations.marker_not_associated} ${item.errorMessage}`
      }else if(type == 3){
        return this.translations.marker_no_cq_min_max
      }else if(type == 4){
        return this.translations.culture_not_contemplated_cmda
      }else if(type == 5){
        return this.translations.marker_not_in_available_list
      }else if(type == 6){
        return this.translations.package_not_associated_reaction
      }else if(type == 7){
        return this.translations.culture_not_valid_for_marker_detection
      }else{
        return item.errorMessage
      }
    }
  },
  components: { ControlPanel },
  computed: {
    ...mapGetters("session", ["currentTranslations"]),
    translations() {
      return this.currentTranslations;
    },
    ...mapState("session", ["permissions"]),
  },
};
</script>

<style></style>
