export function SET_FILTER_TABLE(state, filter) {
  state.filterTable = filter;
}

export function SET_SEARCH_TABLE(state, search) {
  state.searchTable = search;
}

export function SET_ITEMS_PER_PAGE_TABLE(state, itemsPerPage) {
  state.itemsPerPageTable = itemsPerPage;
}

export function SET_DATA_IDICATORS_TABLE(state, indicators) {
  state.quantityAll = indicators.quantityAll;
  state.dnaStoredQuantity = indicators.dnaStoredQuantity;
}

export function SET_ROW_TABLE(state, row) {
  state.rowTableSelected = row;
}
