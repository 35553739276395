<template>
<v-dialog v-model="show" max-width="100vw" scrollable persistent>
    <v-card elevation="5" class="rounded-lg">
        <v-card-title class="text-h5 lighten-2">Resultados
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row v-if="charts.length != 0">
                    <v-col class="text-center justify-center align-center d-flex">
                        <v-carousel height="400" hide-delimiters show-arrows-on-hover v-model="carousel">
                            <v-carousel-item v-for="(image, i) in charts" :key="i">
                                <v-row>
                                    <v-col cols="12">
                                        <span>{{ dateFormat(image.date) }}</span>
                                    </v-col>
                                    <v-col>
                                        <v-img :src="image.url" height="400" contain></v-img>
                                    </v-col>
                                </v-row>
                            </v-carousel-item>
                        </v-carousel>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-responsive class="overflow-x-auto">
                            <v-simple-table dense class="overflow-y-auto">
                                <thead>
                                    <tr>
                                        <th class="text-left">No.</th>
                                        <th class="text-left">ID</th>
                                        <th class="text-left">Microorganismo</th>
                                        <th class="text-left">Cq</th>
                                        <th class="text-left">Eficiencia</th>
                                        <th class="text-left">Eficiencia R2</th>
                                        <th class="text-left">Resultado</th>
                                        <th class="text-left">Fecha resultado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item, index) in results">
                                        <tr v-for="(strain, innerIndex) in item.replicasNumber" :key="`${index}-${innerIndex}`">
                                            <td class="text-center" v-if="index == 0">
                                                Control negativo
                                            </td>
                                            <td class="text-center" v-else>
                                                <v-row class="d-flex align-center justify-center" style="gap: 2px;">
                                                    <v-col cols="auto">
                                                        <div :style="`background-color: ${item.colors[innerIndex] || 'white'}; width: 15px; height: 15px;`">
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        {{ index }}{{ numberToLetter(innerIndex + 1) }}
                                                    </v-col>
                                                </v-row>
                                            </td>
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.type !== 'SOIL' ? (item.type !== 'WATER' ?
                                                            item.microorganism.name : '-') : 'Suelo' }}</td>
                                            <td>{{ item.cq[innerIndex] || '-' }}</td>
                                            <td>{{ item.efficience[innerIndex] || '-' }}</td>
                                            <td>{{ item.efficienceR2[innerIndex] || '-' }}</td>
                                            <td>{{ item.excluded[innerIndex]
                                                            ?getExcludedKind(item.excluded[innerIndex]) : '-' }}</td>
                                            <td>{{ item.uploadDate ? dateFormat(item.uploadDate) :
                                                            '--/--/----' }}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </v-simple-table>
                        </v-responsive>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-container fluid>
                <v-row>
                    <v-col cols="">
                        <v-btn color="gray" block @click="closeModal" dark>Cerrar</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
//Libraries
import dayjs from "dayjs";
//vuex
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
export default {
    data: () => ({
        charts: [],
        results: [],
        carousel: null,
    }),
    props: {
        value: Boolean,
        sequence: {
            type: Object,
            default: null
        }
    },
    computed: {
        ...mapState("molecularMarker", ["oligoAndProbeDataSelected"]),
        dialogWidth() {
            if (this.$vuetify.breakpoint.xs) return "90vw"; // Extra pequeño
            if (this.$vuetify.breakpoint.sm) return "70vw"; // Pequeño
            if (this.$vuetify.breakpoint.md) return "60vw"; // Mediano
            return "50vw"; // Grande en adelante
        },
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        closeModal() {
            this.show = false;
        },
        dateFormat(value) {
            const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
            return date || "--/--/----";
        },
        numberToLetter(n) {
            if (n < 1 || n > 26) {
                return "Valor fuera de rango"; // Solo manejamos del 1 al 26
            }
            const letra = String.fromCharCode(64 + n);
            return letra;
        },
        getExcludedKind(value) {
            return value ? "Excluded" : "Incluido"
        },
    },
    watch: {
        show(value) {
            if (value) {
                if (this.oligoAndProbeDataSelected.inclusivity.charts && this.oligoAndProbeDataSelected.inclusivity.charts.length != 0) {
                    this.charts = this.oligoAndProbeDataSelected.inclusivity.charts
                    this.results = this.oligoAndProbeDataSelected.inclusivity.results.filter(element => element.uploadDate == this.charts[0].date)
                }
            } else {
                this.charts = []
                this.carousel = null
            }
        },
        carousel(value) {
            if (value) {
                this.results = this.oligoAndProbeDataSelected.inclusivity.results.filter(element => element.uploadDate == this.charts[value].date)
            }

        },
    },
};
</script>
