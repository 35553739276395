import { render, staticRenderFns } from "./SoilStock.vue?vue&type=template&id=9f06a798"
import script from "./SoilStock.vue?vue&type=script&lang=js"
export * from "./SoilStock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports