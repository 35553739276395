<template>
    <v-dialog v-model="show" :max-width="dialogWidth" scrollable persistent>
        <v-card elevation="5" class="rounded-lg">
            <v-card-title class="text-h5 lighten-2">Notificación validaciones
            </v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="form">
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-select v-model="notificationKind" :items="notifications" item-text="title"
                                    label="Motivo de notificación" hide-no-data outlined dense clearable type="text"
                                    return-object :rules="validation()"></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-container fluid>
                    <v-row>
                        <v-col cols="">
                            <v-btn color="red" block @click="closeModal" dark :disabled="loading">Cancelar</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn color="primary" block @click="confirmation" :loading="loading">Aceptar</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
//vuex
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
//validations
import {
    requiredValidation
} from "@/helpers/validations.js";
export default {
    data: () => ({
        notificationKind: null,
        notifications: [{
            title: "Notificar liberación de Marcadores",
            kind: "NOTIFICATION_REALEASE"
        }, {
            title: "Notificar envio de marcadores a CMDA",
            kind: "NOTIFICATION_SEND"
        }],
        loading: false,
    }),
    props: {
        value: Boolean,
        markers: Array,
    },
    computed: {
        dialogWidth() {
            if (this.$vuetify.breakpoint.xs) return "90vw"; // Extra pequeño
            if (this.$vuetify.breakpoint.sm) return "70vw"; // Pequeño
            if (this.$vuetify.breakpoint.md) return "60vw"; // Mediano
            return "40vw"; // Grande en adelante
        },
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        ...mapActions("molecularMarker", ["notifyMarker"]),
        closeModal() {
            this.show = false;
        },
        validation(name) {
            return requiredValidation(name);
        },
        async confirmation() {
            if (this.$refs.form.validate()) {
                try {
                    this.loading = true
                    let markersIds = this.markers.map(element => element._id)
                    let payload = {
                        markersId: markersIds
                    }
                    let params = {
                        notificationKind: this.notificationKind.kind
                    }
                    await this.notifyMarker({
                        params: params,
                        body: payload,
                    })
                    this.notificationKind = null
                    this.$refs.form.reset()
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: `Notificación enviada con exito`,
                        timeout: 5000,
                    });
                    this.$emit("email_send")
                } catch (error) {
                    console.log(error);
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "error",
                        text: `Algo salió mal. Error: ${error.message}`,
                        timeout: 5000,
                    });
                } finally {
                    this.loading = false
                }

            }
        },
    },
    watch: {},
};
</script>
