<template>
    <v-dialog v-model="show" :max-width="dialogWidth" scrollable persistent>
        <v-card elevation="5" class="rounded-lg">
            <v-card-title class="text-h5 lighten-2">Finalizar validaciones
            </v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="form">
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="min" label="Ingresa nuevo cQ minimo" hide-no-data outlined dense
                                    clearable type="number" :rules="validation()"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="max" label="Ingresa nuevo cQ maximo" hide-no-data outlined dense
                                    clearable type="number" :rules="validation()"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-container fluid>
                    <v-row>
                        <v-col cols="">
                            <v-btn color="red" block @click="closeModal" dark :disabled="inProgress">Cancelar</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn color="primary" block @click="confirmation" :loading="inProgress">Aceptar</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
//vuex
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
//validations
import {
    requiredValidation
} from "@/helpers/validations.js";
export default {
    data: () => ({
        min: null,
        max: null,
        inProgress: false,
    }),
    props: {
        value: Boolean,
        range: {
            type: Object,
            default: null
        }
    },
    computed: {
        ...mapState("molecularMarker", ["oligoAndProbeDataSelected", "markerSelected"]),
        dialogWidth() {
            if (this.$vuetify.breakpoint.xs) return "90vw"; // Extra pequeño
            if (this.$vuetify.breakpoint.sm) return "70vw"; // Pequeño
            if (this.$vuetify.breakpoint.md) return "60vw"; // Mediano
            return "50vw"; // Grande en adelante
        },
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        ...mapActions("molecularMarker", ["finishValidationMarker"]),
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        closeModal() {
            this.min = null
            this.max = null
            this.show = false;
        },
        validation(name) {
            return requiredValidation(name);
        },
        async confirmation() {
            if (this.$refs.form.validate()) {
                try {
                    this.inProgress = true
                    let payload = {
                        oligoIdentifier: this.oligoAndProbeDataSelected.oligoIdentifier,
                        cqRanges: {
                            min: this.min,
                            max: this.max
                        }
                    }
                    let params = {
                        markerId: this.markerSelected._id,
                        oligoId: this.oligoAndProbeDataSelected._id,
                    }
                    let response = await this.finishValidationMarker({
                        params,
                        body: payload
                    })
                    this.markerSelected.version = response.version
                    this.markerSelected.validationStatus = "FINISHED"
                    this.markerSelected.cqMin = this.min
                    this.markerSelected.cqMax = this.max
                    this.oligoAndProbeDataSelected.validationFinished = true

                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: `Validaciones finalizadas`,
                        timeout: 3000,
                    });
                    this.closeModal()
                } catch (error) {
                    console.log(error);
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "error",
                        text: `Algo salió mal. Error: ${error.message}`,
                        timeout: 5000,
                    });
                }
                this.inProgress = false
            }
        },
    },
    watch: {
        show(value) {
            if (value) {
                if (this.range) {
                    this.min = this.range.min
                    this.max = this.range.max
                }
            }
        }
    },
};
</script>
