<template>
<v-dialog v-model="show" :max-width="dialogWidth" scrollable persistent>
    <v-card elevation="5" class="rounded-lg">
        <v-card-title class="text-h5 lighten-2">Actualizar número de replicas
        </v-card-title>
        <v-card-text>
            <v-form lazy-validation ref="form">
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="replicatesQuantity" label="Ingresa número de replicas" hide-no-data outlined dense clearable type="number" :rules="validation()"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-container fluid>
                <v-row>
                    <v-col cols="">
                        <v-btn color="red" block @click="closeModal" dark>Cancelar</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn color="primary" block @click="confirmation">Aceptar</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
//vuex
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
//validations
import {
    requiredValidation
} from "@/helpers/validations.js";
export default {
    data: () => ({
        replicatesQuantity: null,
    }),
    props: {
        value: Boolean,
        type: {
            type: String,
            default: null
        }
    },
    computed: {
        ...mapState("molecularMarker", ["oligoAndProbeDataSelected", "markerSelected", "resultItemSelected"]),
        dialogWidth() {
            if (this.$vuetify.breakpoint.xs) return "90vw"; // Extra pequeño
            if (this.$vuetify.breakpoint.sm) return "70vw"; // Pequeño
            if (this.$vuetify.breakpoint.md) return "60vw"; // Mediano
            return "50vw"; // Grande en adelante
        },
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        ...mapActions("molecularMarker", ["updateResultReplicasNumber"]),
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        closeModal() {
            this.show = false;
        },
        validation(name) {
            return requiredValidation(name);
        },
        async confirmation() {
            if (this.$refs.form.validate()) {
                try {
                    let payload = {
                        type: this.type,
                        replicasNumber: this.replicatesQuantity
                    }
                    let params = {
                        markerId: this.markerSelected._id,
                        oligoId: this.oligoAndProbeDataSelected._id,
                        resultId: this.resultItemSelected._id,

                    }
                    let response = await this.updateResultReplicasNumber({
                        params: params,
                        body: payload
                    })
                    this.oligoAndProbeDataSelected[this.type].results = response.results
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: `Número de replicas actualizado`,
                        timeout: 5000,
                    });
                    this.closeModal()
                } catch (error) {
                    console.log(error);
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "error",
                        text: `Algo salió mal. Error: ${error.message}`,
                        timeout: 5000,
                    });
                }
            }
        },
    },
    watch: {
        show(value) {
            if (value) {
                this.replicatesQuantity = this.resultItemSelected.replicasNumber
            } else {
                this.replicatesQuantity = null
            }
        }
    },
};
</script>
