<template>
<v-dialog v-model="show" width="70vw" scrollable persistent>
    <v-card elevation="12" class="mx-auto rounded-lg overflow-hidden" max-width="70vw">
        <v-app-bar color="primary accent-4" dark flat height="60">
            <v-avatar color="white" size="36" class="mr-4">
                <v-icon color="primary accent-4">mdi-dna</v-icon>
            </v-avatar>
            <v-toolbar-title class="headline font-weight-bold">
                Actualizar Oligonucleótidos y Sonda <span v-if="oligoAndProbeDataSelected">({{
                        oligoAndProbeDataSelected.oligoIdentifier }})</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-chip :color="markerStatusColor" dark label class="mr-2 rounded-xl">
                <v-icon left>mdi-circle-half-full</v-icon>
                {{ currentStatusLabel }}
            </v-chip>
        </v-app-bar>
        <v-card-text>
            <v-form ref="form" lazy-validation>
                <v-container>
                    <v-expansion-panels v-model="panels" multiple hover>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-col>
                                        <v-icon>mdi-cog</v-icon>
                                        Configuración inicial
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container>
                                    <v-row>
                                        <!-- Status Field -->
                                        <v-col cols="12" md="6">
                                            <v-select v-model="formData.status" :items="status" label="Estatus del Marcador" required :rules="validation()" outlined dense :disabled="formData.status === 'FINISHED'"></v-select>
                                        </v-col>
                                        <!-- Locus - Only enabled when status is 'FINISHED' -->
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="formData.ncbi" label="Ingresa ID de NCBI" :disabled="validationStatus()" outlined dense></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-for="(item, index) in formData.oligoAndProbeData" :key="index">
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-col>
                                        <v-icon>{{ item.icon }}</v-icon>
                                        {{ item.tabName }}
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container>
                                    <v-row>
                                        <!-- Forward Primer Section -->
                                        <v-col cols="12" md="6">
                                            <v-text-field ref="name_text_test" v-model="item.name" :label="`Nombre Oligo ${item.tag}`" :disabled="validationStatus()" :rules="summit && item.tag != 'PR' ? [v => !!v || 'El nombre del primer es requerido'] : []" outlined dense></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="item.sequence" :label="`Secuencia Oligo ${item.tag}`" :disabled="validationStatus()" :rules="summit && item.tag != 'PR' ? [
                                                        v => !!v || 'La secuencia es requerida',
                                                    ] : []" counter outlined dense></v-text-field>
                                        </v-col>
                                        <!-- Numeric Fields for Forward Primer -->
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model.number="item.gc" :label="`GC ${item.tag} (%)`" type="number" :disabled="validationStatus()" :rules="summit && item.tag != 'PR' ? [v => !!v || 'GC es requerido'] : []" outlined dense></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model.number="item.tm" :label="`Tm ${item.tag} (°C)`" type="number" step="0.1" :disabled="validationStatus()" :rules="summit && item.tag != 'PR' ? [v => !!v || 'Tm es requerido'] : []" outlined dense></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="item.hairpin" :label="`Hairpin Tm ${item.tag} (°C)`" type="text" :disabled="validationStatus()" :rules="summit && item.tag != 'PR' ? [v => !!v || 'Tm es requerido'] : []" outlined dense></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="item.selfDimer" :label="`Self-dimer Tm ${item.tag} (°C)`" type="text" step="0.1" :disabled="validationStatus()" :rules="summit && item.tag != 'PR' ? [v => !!v || 'Tm es requerido'] : []" outlined dense></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-col>
                                        <v-icon>mdi-dna</v-icon>
                                        Amplicon
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container>
                                    <v-row>
                                        <!-- Amplicon Size - Mandatory -->
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model.number="formData.ampliconSize" label="Tamaño del Amplicon (pb)" type="number" required :rules="summit ? [v => !!v || 'Tamaño del amplicon es requerido'] : []" outlined dense :disabled="validationStatus()"></v-text-field>
                                        </v-col>

                                        <!-- Control Strain - Optional -->
                                        <v-col cols="12" md="6">
                                            <v-autocomplete ref="search_strain" :disabled="validationStatus()" hint="Campo opcional" label="Buscar Cepa(s) Control" dense outlined prepend-inner-icon="mdi-magnify" v-model="strainFound" item-text="genomicSequence.moSequenceId" :items="isolates" :loading="loadingIsolate" :search-input.sync="searchIsolate" @change="updateValue" return-object hide-no-data></v-autocomplete>
                                            <v-chip color="primary" v-for="(strain, index) in formData.controlStrains" :key="index" @click:close="removeStrain(index)" close>
                                                {{ strain.moSequenceId }}
                                            </v-chip>
                                            <!-- <v-text-field v-model="formData.controlStrain" label="Cepa Control" :disabled="validationStatus()" hint="Campo opcional" outlined dense></v-text-field> -->
                                        </v-col>

                                        <!-- Amplicon Sequence - Mandatory -->
                                        <v-col cols="12">
                                            <v-text-field v-model="formData.ampliconSequence" label="Amplicon" :rules="summit ? [
                                                        v => !!v || 'Secuencia de amplicon es requerida',

                                                    ] : []" counter outlined dense :disabled="validationStatus()"></v-text-field>
                                        </v-col>

                                        <!-- Amplicon GC Content - Mandatory -->
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model.number="formData.ampliconGC" label="GC Amplicon (%)" type="number" step="0.1" required :rules="summit ? [v => !!v || 'Contenido de GC es requerido'] : []" outlined dense :disabled="validationStatus()"></v-text-field>
                                        </v-col>

                                        <!-- Secondary Structure - Optional -->
                                        <v-col cols="12" md="6">
                                            <v-select v-model="formData.secondaryStructure" :items="strengthLevels" label="Estructura Secundaria" item-text="text" hint="Campo opcional" outlined dense :disabled="validationStatus()"></v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-row>
                        <v-col cols="12">
                            <v-divider class="my-4"></v-divider>
                            <h3 class="text-h6">Suelos</h3>
                        </v-col>
                        <!-- Validation Soils - Optional -->
                        <v-col cols="12">
                            <v-row>
                                <v-col>
                                    <v-combobox dense outlined label="Buscar suelos para validación" clearable :search-input.sync="searchSample" :items="sampleBankList" item-text="folioAnalysis" :loading="loadingSamples" v-model="soilSelection" return-object :disabled="validationStatus()" prepend-inner-icon="mdi-magnify" ref="searchSampleInput">
                                    </v-combobox>
                                </v-col>
                                <v-col>
                                    <v-btn color="primary" small @click="updateItems">
                                        <v-icon>
                                            mdi-plus
                                        </v-icon>
                                        Agregar suelo
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-chip class="ma-2 truncate-chip" :close="formData.status && formData.status !== 'FINISHED'" v-for="(item, index) in soilSelected" :key="index" @click:close="removeItem(index)">
                                        {{ item.folioAnalysis }}
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <h3 class="text-h6">Notas</h3>
                        </v-col>
                        <!-- Considerations - Optional -->
                        <v-col cols="12">
                            <v-textarea v-model="formData.considerations" label="Consideraciones" hint="Notas adicionales sobre el amplicon" outlined dense :disabled="validationStatus()"></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-container fluid>
                <v-row>
                    <v-col>
                        <v-btn color="gray" block @click="closeModal" dark :disabled="isLoading">
                            Cerrar
                        </v-btn>
                    </v-col>
                    <v-col v-if="this.formData.status !== 'FINISHED'">
                        <v-btn color="primary" block @click="saveData" :loading="isLoading">
                            <v-icon>mdi-content-save</v-icon>
                            Guardar
                        </v-btn>
                    </v-col>
                    <v-col v-if="this.formData.status !== 'FINISHED'">
                        <v-btn color="warning" block @click="showFinishCofirmationModal = true" :loading="isLoading">
                            <v-icon>mdi-check-circle</v-icon>
                            Finalizar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
    <FinishCofirmationModal v-model="showFinishCofirmationModal" v-if="showFinishCofirmationModal" @confirmation="finishData()" :title="`¿Estás segura de que quieres terminar con Oligonucleótidos y Sonda (${oligoAndProbeDataSelected.oligoIdentifier})?`" />
</v-dialog>
</template>

<script>
//vuex
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
//validations
import {
    requiredValidation
} from "@/helpers/validations.js";
//components
import FinishCofirmationModal from "@/components/utils/GenericConfirmationModal.vue";
export default {
    components: {
        FinishCofirmationModal
    },
    data: () => ({
        panels: [0, 1, 2, 3, 4],
        isLoading: false,
        searchIsolate: null,
        strainFound: null,
        isolates: [],
        loadingIsolate: false,
        formData: {
            //initial information
            controlStrains: [],
            status: null,
            ncbi: null,
            // locus: '',
            // taxon: '',
            // project: '',
            //FR RV SO
            oligoAndProbeData: [{
                    tabName: "Forward",
                    icon: "mdi-arrow-right",
                    tag: "FW",
                    name: '',
                    sequence: '',
                    gc: null,
                    tm: null,
                    hairpin: null,
                    selfDimer: null,
                },
                {
                    tabName: "Reverse",
                    icon: "mdi-arrow-left",
                    tag: "RV",
                    name: '',
                    sequence: '',
                    gc: null,
                    tm: null,
                    hairpin: null,
                    selfDimer: null,
                },
                {
                    tabName: "Sonda",
                    icon: 'mdi-radar',
                    tag: "PR",
                    name: '',
                    sequence: '',
                    gc: null,
                    tm: null,
                    hairpin: null,
                    selfDimer: null,
                },
            ],
            //AMPLICON
            ampliconSize: null,
            controlStrain: '',
            ampliconSequence: '',
            ampliconGC: null,
            secondaryStructure: null,
            //NOTES
            considerations: '',
        },
        status: [{
                text: "Pendiente",
                value: "PENDING"
            },
            {
                text: "Diseño",
                value: "DESIGNING"
            },
        ],
        markerType: [{
                text: "Taxonómico",
                value: "MO"
            },
            {
                text: "Funcional",
                value: "FNC"
            },
            {
                text: "Taxonómico/Funcional",
                value: "MO_FNC"
            },
        ],
        taxonomicTypes: [{
                text: "Planta",
                value: "plant"
            },
            {
                text: "Bacteria",
                value: "bacteria"
            },
            {
                text: "Hongo",
                value: "fungi"
            },
            {
                text: "Nematodo",
                value: "nematode"
            },
            {
                text: "Insecto",
                value: "insect"
            },
            {
                text: "Virus",
                value: "virus"
            },
            {
                text: "Arqueas",
                value: "archaea"
            },
        ],
        texonomicCategories: [{
                text: "Dominio",
                value: "domain"
            },
            {
                text: "Phylum",
                value: "phylum"
            },
            {
                text: "Familia",
                value: "family"
            },
            {
                text: "Género",
                value: "gender"
            },
            {
                text: "Especie",
                value: "species"
            },
        ],
        strengthLevels: [{
                text: "Fuerte",
                value: "strong"
            },
            {
                text: "Medio",
                value: "mid"
            },
            {
                text: "Débil",
                value: "weak"
            },
        ],
        searchIdentification: null,
        identifications: [],
        identificationsLoading: false,
        searchSample: null,
        sampleBankList: [],
        loadingSamples: false,
        //SOILS
        soilSelected: [],
        soilSelection: null,
        symbiosis: [{
                text: "Patógeno",
                value: "pathogen"
            },
            {
                text: "Benéfico",
                value: "benefic"
            },
        ],
        validationMarker: [(v) => !!v || 'El campo es requerido'],
        summit: false,
        showFinishCofirmationModal: false,
    }),
    props: {
        value: Boolean,
    },
    computed: {
        ...mapState("molecularMarker", ["oligoAndProbeDataSelected", "markerSelected"]),
        markerStatusColor() {
            switch (this.formData.status) {
                case 'PENDING':
                    return 'orange'
                case 'DESIGNING':
                    return 'blue'
                case 'FINISHED':
                    return 'secondary'
                default:
                    return 'grey'
            }
        },
        currentStatusLabel() {
            const status = this.status.find(s => s.value === this.formData.status)
            return status ? status.text : 'No Seleccionado'
        },
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        ...mapActions("ceparium", ["findByMoSequenceId"]),
        ...mapActions("molecularMarker", ["getMolecularMarkerByName", "saveDraft", "finishOligo"]),
        ...mapActions("soilStore", ["getSamplesByFolio"]),
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        ...mapActions("identification", ["searchIdentifications"]),
        closeModal() {
            this.cleanForm()
            this.$emit("close")
        },
        validation(name) {
            return requiredValidation(name);
        },
        async saveData() {
            try {
                this.summit = false
                this.isLoading = true
                let payload = {
                    statusDesign: this.formData.status,
                    ncbi: this.formData.ncbi,
                    // locus: this.formData.locus,
                    // taxa: this.formData.taxon,
                    // project: this.formData.project,
                    oligoAndProbeData: JSON.parse(JSON.stringify(this.formData.oligoAndProbeData)),
                    ampliconSize: this.formData.ampliconSize,
                    // strainControl: this.formData.controlStrain,
                    controlStrains: this.formData.controlStrains,
                    amplicon: this.formData.ampliconSequence,
                    gcAmplicon: this.formData.ampliconGC,
                    secondaryStructure: this.formData.secondaryStructure,
                    notes: this.formData.considerations,
                    validationSoils: [],
                }

                for (let element of payload.oligoAndProbeData) {
                    delete element.icon
                    delete element.tabName
                }
                for (const sample of this.soilSelected) {
                    payload.validationSoils.push({
                        _sampleId: sample._id,
                        folio: sample.folioAnalysis,
                        crop: sample.cropCycle ? sample.cropCycle.crop.cropName : null,
                    })
                }
                let params = {
                    markerId: this.markerSelected._id,
                    oligoId: this.oligoAndProbeDataSelected._id,
                    oligoIdentifier: this.oligoAndProbeDataSelected.oligoIdentifier
                }
                await this.saveDraft({
                    params: params,
                    body: payload
                })
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Cambios guardados`,
                    timeout: 5000,
                });
                this.$emit("update")
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.isLoading = false;
        },
        async finishData() {
            await this.saveData()
            try {
                this.summit = true
                //add time to form active rules related to summit
                const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
                await delay(200);
                //validate form fields with rules
                if (this.$refs.form.validate()) {
                    this.isLoading = true
                    let params = {
                        markerId: this.markerSelected._id,
                        oligoId: this.oligoAndProbeDataSelected._id,
                        oligoIdentifier: this.oligoAndProbeDataSelected.oligoIdentifier
                    }
                    let payload = {
                        controlStrains: this.formData.controlStrains,
                    }
                    await this.finishOligo({
                        params: params,
                        body: payload
                    })
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: `Cambios guardados`,
                        timeout: 5000,
                    });
                    this.cleanForm()
                    this.$emit("close_finish")
                } else {
                    this.panels = [0, 1, 2, 3, 4]
                }
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.isLoading = false;
        },
        validationStatus() {
            return this.formData.status && this.formData.status !== 'FINISHED' ? this.formData.status !== 'DESIGNING' : true
        },
        validationBasicStatus() {
            return this.formData.status && this.formData.status !== 'FINISHED' ? false : true
        },
        async getIdentifications(value) {
            if (value) {
                try {
                    this.identificationsLoading = true;
                    const response = await this.searchIdentifications({
                        name: value,
                    });
                    if (response.error) throw response;
                    this.identifications = response.IDENTIFICATIONS;
                } catch (error) {
                    console.log(error);
                }
                this.identificationsLoading = false;
            } else {
                this.identifications = [];
            }
        },
        removeItem(index) {
            this.soilSelected.splice(index, 1);
        },
        updateItems() {
            if (typeof this.soilSelection == 'object') {
                if (this.soilSelection && !this.soilSelected.some(element => element.folioAnalysis === this.soilSelection.folioAnalysis)) {
                    this.soilSelected.push(this.soilSelection)
                    this.soilSelection = null
                    this.$refs["searchSampleInput"].reset();
                } else {
                    this.soilSelection = null
                    this.$refs["searchSampleInput"].reset();
                }
            } else {
                let newSoilSelected = {
                    folioAnalysis: this.soilSelection,
                    _id: null
                }
                if (this.soilSelection && !this.soilSelected.some(element => element.folioAnalysis === newSoilSelected.folioAnalysis)) {
                    this.soilSelected.push(newSoilSelected)
                    this.soilSelection = null
                    this.$refs["searchSampleInput"].reset();
                } else {
                    this.soilSelection = null
                    this.$refs["searchSampleInput"].reset();
                }

            }
        },
        async verifyMarker() {
            try {
                if (this.formData.identification && this.formData.symbiosis) {
                    let nameMo = null
                    if (typeof this.formData.identification == "object") {
                        //identification already in system
                        nameMo = this.formData.identification.name
                    } else {
                        //new identification name
                        nameMo = this.formData.identification
                    }
                    let payload = {
                        name: nameMo,
                        symbiosis: this.formData.symbiosis
                    }

                    let response = await this.getMolecularMarkerByName(payload)
                    if (response.molecularMarker) {
                        //add validation 
                        this.validationMarker.push((v) => false || 'El marcador ya existe')
                        this.$nextTick(() => {
                            this.$refs.microorganismField.validate();
                            this.$refs.symbiosisField.validate();
                        });
                    } else {
                        this.validationMarker = [(v) => !!v || 'El campo es requerido']
                        this.$nextTick(() => {
                            this.$refs.microorganismField.validate();
                            this.$refs.symbiosisField.validate();
                        });
                    }
                }
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
        },
        cleanForm() {
            this.formData = {
                //initial information
                status: null,
                // locus: '',
                // taxon: '',
                // project: '',
                //FR RV SO
                oligoAndProbeData: [{
                        tabName: "Forward",
                        icon: "mdi-arrow-right",
                        tag: "FW",
                        name: '',
                        sequence: '',
                        gc: null,
                        tm: null,
                        hairpin: null,
                        selfDimer: null,
                    },
                    {
                        tabName: "Reverse",
                        icon: "mdi-arrow-left",
                        tag: "RV",
                        name: '',
                        sequence: '',
                        gc: null,
                        tm: null,
                        hairpin: null,
                        selfDimer: null,
                    },
                    {
                        tabName: "Sonda",
                        icon: 'mdi-radar',
                        tag: "PR",
                        name: '',
                        sequence: '',
                        gc: null,
                        tm: null,
                        hairpin: null,
                        selfDimer: null,
                    },
                ],
                //AMPLICON
                ampliconSize: null,
                controlStrain: '',
                ampliconSequence: '',
                ampliconGC: null,
                secondaryStructure: null,
                //NOTES
                considerations: '',
            }
            this.panels = [0, 1, 2, 3, 4]
            this.searchIdentification = null
            this.identifications = []
            this.searchSample = null
            this.sampleBankList = []
            this.soilSelected = []
            this.soilSelection = null
            this.summit = false
            this.validationMarker = [(v) => !!v || 'El campo es requerido']
            this.status = [{
                    text: "Pendiente",
                    value: "PENDING"
                },
                {
                    text: "Diseño",
                    value: "DESIGNING"
                },
            ]
            this.$refs.form.reset()
        },
        mapData() {
            let auxOligoAndProbeData = [{
                    tabName: "Forward",
                    icon: "mdi-arrow-right",

                },
                {
                    tabName: "Reverse",
                    icon: "mdi-arrow-left",

                },
                {
                    tabName: "Sonda",
                    icon: 'mdi-radar',

                },
            ]
            let copyOligoAndProbeData = JSON.parse(JSON.stringify(this.oligoAndProbeDataSelected.oligoAndProbeData))
            for (let index = 0; index < auxOligoAndProbeData.length; index++) {
                copyOligoAndProbeData[index].tabName = auxOligoAndProbeData[index].tabName
                copyOligoAndProbeData[index].icon = auxOligoAndProbeData[index].icon
            }

            this.formData = {
                //initial information
                status: this.oligoAndProbeDataSelected.statusDesign,
                ncbi: this.oligoAndProbeDataSelected.ncbi,
                // locus: this.oligoAndProbeDataSelected.locus,
                // taxonomicCategory: this.oligoAndProbeDataSelected.taxonomicCategory,
                // taxon: this.oligoAndProbeDataSelected.taxa,
                // project: this.oligoAndProbeDataSelected.project,
                //FR RV SO
                oligoAndProbeData: copyOligoAndProbeData,
                //AMPLICON
                ampliconSize: this.oligoAndProbeDataSelected.ampliconSize,
                controlStrain: this.oligoAndProbeDataSelected.strainControl,
                ampliconSequence: this.oligoAndProbeDataSelected.amplicon,
                ampliconGC: this.oligoAndProbeDataSelected.gcAmplicon,
                secondaryStructure: this.oligoAndProbeDataSelected.secondaryStructure,
                //NOTES
                considerations: this.oligoAndProbeDataSelected.notes,
                controlStrains: this.oligoAndProbeDataSelected.controlStrains,
            }
            this.soilSelected = this.oligoAndProbeDataSelected.validationSoils.map(({
                folio,
                ...rest
            }) => ({
                ...rest,
                folioAnalysis: folio
            }))
            if (this.oligoAndProbeDataSelected.statusDesign == "FINISHED") {
                this.status = [{
                        text: "Pendiente",
                        value: "PENDING"
                    },
                    {
                        text: "Diseño",
                        value: "DESIGNING"
                    },
                    {
                        text: "Terminado",
                        value: "FINISHED"
                    },
                ]
            }
        },
        updateValue(value) {
            if (value) {
                if (!this.formData.controlStrains.find(element => element.moSequenceId == this.strainFound.genomicSequence.moSequenceId)) {
                    let mo = {
                        _identificationId: null,
                        name: null,
                        moType: null,
                    }
                    if (this.strainFound.genomicSequence.putativeAssignation) {
                        mo._identificationId = this.strainFound.genomicSequence.putativeAssignation._id
                        mo.name = this.strainFound.genomicSequence.putativeAssignation.name
                        mo.moType = this.strainFound.genomicSequence.putativeAssignation.moType
                    } else if (this.strainFound.genomicSequence.genomicAssignation) {
                        mo._identificationId = this.strainFound.genomicSequence.genomicAssignation._id
                        mo.name = this.strainFound.genomicSequence.genomicAssignation.name
                        mo.moType = this.strainFound.genomicSequence.genomicAssignation.moType
                    }
                    this.formData.controlStrains.push({
                        _isolateId: this.strainFound._id,
                        moSequenceId: this.strainFound.genomicSequence.moSequenceId,
                        mo: mo,
                    })
                    this.strainFound = null
                    this.$refs["search_strain"].reset();
                } else {
                    this.strainFound = null
                    this.$refs["search_strain"].reset();
                }
                console.log(this.formData.controlStrains);
            }
        },
        removeStrain(index) {
            this.formData.controlStrains.splice(index, 1)
        }
    },
    watch: {
        async searchIdentification(value) {
            await this.getIdentifications(value);
        },
        async searchSample(newValue) {
            if (!newValue) return;
            try {
                this.loadingSamples = true;
                let payload = {
                    search: newValue,
                };
                let response = await this.getSamplesByFolio(payload);
                this.sampleBankList = response.samples;
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.loadingSamples = false;
        },
        show(value) {
            if (value) {
                this.mapData()
            }
        },
        async searchIsolate(value) {
            try {
                let response = await this.findByMoSequenceId({
                    search: value
                })
                this.isolates = response.isolates
            } catch (error) {
                console.log(error);

            }
        },
    },
};
</script>

<style scoped>
.v-divider {
    margin: 20px 0;
}
</style>
