export default {
  currentSelection: null,
  receptions: [],
  analysesFounded: [],
  analysisResults: [],
  crops: {
    olive: "Aceituna",
    chard: "Acelga",
    achiote: "Achiote",
    agave: "Agave",
    avocado: "Aguacate",
    garlic: "Ajo",
    sesame: "Ajonjolí",
    basil: "Albahaca",
    artichoke: "Alcachofa",
    alcatraz: "Alcatraz",
    shrunkenAlfalfa: "Alfalfa achicalada",
    greenAlfalfa: "Alfalfa verde",
    cotton: "Algodón",
    almond: "Almendro",
    birdseed: "Alpiste",
    amaranth: "Amaranto",
    anise: "Anís",
    celery: "Apio",
    blueberry: "Arándano",
    myrtle: "Arrayán",
    rice: "Arroz",
    greenForageOats: "Avena forrajera en verde",
    oatGrain: "Avena grano",
    bamboo: "Bambú",
    eggplant: "Berenjena",
    beetroot: "Betabel",
    broccoli: "Brocoli",
    peanut: "Cacahuate",
    cocoa: "Cacao",
    cherryCoffee: "Café cereza",
    pumpkin: "Calabaza",
    sweetPotato: "Camote",
    sugarCane: "Caña de azúcar",
    canola: "Canola",
    carambolo: "Carambolo",
    safflower: "Cártamo",
    barley: "Cebada",
    greenForageBarley: "Cebada forrajera en verde",
    onion: "Cebolla",
    chives: "Cebollín",
    ryeGrain: "Centeno grano",
    cherry: "Cereza",
    chabacano: "Chabacano",
    pea: "Chícharo",
    squash: "Chayote",
    chia: "Chía",
    chilacayote: "Chilacayote",
    chillyPepper: "Chile / Pimiento",
    custardApple: "Chirimoya",
    coriander: "Cilantro",
    plum: "Ciruela",
    coconutFruit: "Coco fruta",
    cabbage: "Col (repollo)",
    brusselsSprouts: "Col de Bruselas",
    cauliflower: "Coliflor",
    cumin: "Comino",
    chrysanthemum: "Crisantemo (planta)",
    peach: "Durazno",
    date: "Dátil",
    greenBean: "Ejote",
    cornCob: "Elote",
    dill: "Eneldo",
    epazote: "Epazote",
    asparagus: "Espárrago",
    spicesAndMedicinals: "Especias y medicinales",
    spinach: "Espinaca",
    raspberry: "Frambuesa",
    strawberry: "Fresa",
    bean: "Frijol",
    chickpea: "Garbanzo",
    sunflower: "Girasol",
    pomegranate: "Granada",
    soursop: "Guanábana",
    guava: "Guayaba",
    henequen: "Henequén",
    peppermint: "Hierbabuena",
    aromaticHerbs: "Hierbas aromáticas",
    fig: "Higo",
    jamaica: "Jamaica",
    ginger: "Jenjibre",
    jicama: "Jícama",
    lettuce: "Lechuga",
    lentil: "Lenteja",
    lime: "Lima",
    lemon: "Limón",
    linseed: "Linaza",
    litchi: "Litchi",
    corn: "Maíz",
    macadamia: "Macadamia",
    maguey: "Maguey",
    mamey: "Mamey",
    tangerine: "Mandarina",
    mango: "Mango",
    mangosteen: "Mangostán",
    chamomile: "Manzanilla",
    appleTree: "Manzano",
    passionFruit: "Maracuyá",
    daisyFlower: "Margarita",
    marjoram: "Mejorana",
    melon: "Melón",
    quince: "Membrillo",
    mint: "Menta",
    moringa: "Moringa",
    turnip: "Nabo",
    nanche: "Nanche",
    napa: "Napa",
    orange: "Naranja",
    medlar: "Níspero",
    nochebuena: "Nochebuena (planta)",
    walnut: "Nogal",
    noni: "Noni",
    nopal: "Nopal",
    oregano: "Orégano",
    ornamental: "Ornamentales",
    orchid: "Orquídea (planta)",
    oilPalm: "Palma africana o de aceite",
    potato: "Papa",
    papaya: "Papaya",
    grasslands: "Pastizales",
    matGrass: "Pasto tapete (m2)",
    pasturesAndMeadows: "Pastos y praderas",
    cucumber: "Pepino",
    pearTree: "Peral",
    parsley: "Perejil",
    peron: "Perón",
    persimony: "Persimonio",
    pepper: "Pimienta",
    pineapple: "Piña",
    pinion: "Piñón",
    pistache: "Pistache",
    pitaya: "Pitaya",
    banana: "Plátano",
    pore: "Poro",
    radish: "Rábano",
    rambutan: "Rambután",
    romerito: "Romerito",
    rosemary: "Romero",
    aloe: "Sábila",
    watermelon: "Sandía",
    sorghum: "Sorgo",
    soy: "Soya",
    stevia: "Stevia",
    tobacco: "Tabaco",
    tamarind: "Tamarindo",
    tejocote: "Tejocote",
    tomato: "Tomate Rojo (jitomate)",
    greenTomato: "Tomate Verde",
    thyme: "Tomillo",
    grapefruit: "Toronja (pomelo)",
    clover: "Trébol",
    wheat: "Trigo",
    dutchTulip: "Tulipán holandés",
    tuna: "Tuna",
    grape: "Uva",
    vanilla: "Vainilla",
    purslane: "Verdolaga",
    vine: "Vid",
    cassavaFood: "Yuca alimenticia",
    carrot: "Zanahoria",
    sapodilla: "Zapote",
    blackberry: "Zarzamora",
    zempoalxochitl: "Zempoalxochitl",
    fallow: "Barbecho",
    "Pecan nut": "Nuez pecana",
    Roses: "Rosas",
    other: "Otro",
  },
  refreshReceptionTable: false,
  showUpdateFolioDialog: false,
  folioSelected: null,
  filters: null,
  searchFilter: null,
  folios: [],
  cleanParent: false,
  samplesToBeStored: [],
};
