export default {
  filterTableSoil: {
    sort: null,
    sequenciationSendStatus: null,
  },
  searchTableSoil: null,
  itemsPerPageTableSoil: 10,
  headers: [],
  quantityAll: 0,
  dnaStoredQuantity: 0,
  rowSelectedTableSoil: null,
};
