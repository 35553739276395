var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-0"},[(_vm.plates.length > 0)?_c('v-carousel',{attrs:{"hide-delimiters":"","show-arrows-on-hover":"","height":"auto"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.plates),function(plate,i){return _c('v-carousel-item',{key:i},[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(plate[0][0].plateFolio)+" "),(_vm.type == 'AMPLIFICATION')?_c('v-btn',{attrs:{"loading":_vm.generatingCSV,"color":"secondary","icon":""},on:{"click":function($event){return _vm.generateCSV(plate[0][0].plateFolio, _vm.reaction)}}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1):_vm._e()],1),(_vm.headers.length > 1)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[(_vm.type == 'ELUTION')?_vm._l((_vm.headers),function(item,index){return _c('th',{key:index,staticClass:"text-center pointer",on:{"click":function($event){return _vm.selectColumn(i, item.name)}}},[(item.name)?_c('span',[_vm._v(_vm._s(item.name))]):_c('span')])}):_vm._l((_vm.headers),function(item,index){return _c('th',{key:index,staticClass:"text-center"},[(item.name)?_c('span',[_vm._v(_vm._s(item.name))]):_c('span')])})],2)]),_c('tbody',[(_vm.type == 'ELUTION')?_vm._l((_vm.rows[i]),function(item,index){return _c('tr',{key:index},_vm._l((_vm.rows[i][index]),function(child,indexChild){return _c('td',{key:indexChild,staticClass:"text-center pa-0 pointer"},[(indexChild != 0)?[(!child.isWater)?_c('v-card',{staticClass:"ma-1 mx-auto",attrs:{"color":child.folioAnalysis &&
                        child.folioAnalysis.includes(_vm.analysis)
                          ? child.sampleType && child.sampleType.includes('VEGETABLE')
                            ? 'primary'
                            : 'warning'
                          : child.type == 'PURIFICATION' &&
                            child.valueNum != 1 &&
                            child.valueNum != 7
                          ? '#b2b2b2'
                          : '#f2f2f2',"width":"7vh","shaped":child.active,"elevation":child.active ? '4' : '0'},on:{"click":function($event){return _vm.selectSample(i, index, indexChild)}}},[_c('v-card-text',{staticClass:"px-0 py-2"},[(
                            child.folioAnalysis &&
                            child.folioAnalysis.includes(_vm.analysis)
                          )?_c('small',{staticClass:"white--text"},[_vm._v(" "+_vm._s(child.replica && child.replica != "1" ? `(${child.replica})` : '')+" "+_vm._s(child.folioAnalysis)+" ")]):(child.folioAnalysis)?_c('small',[_vm._v(" "+_vm._s(child.replica && child.replica != "1" ? `(${child.replica})` : '')+" "+_vm._s(child.folioAnalysis)+" ")]):_c('small',[_vm._v("   ")])])],1):_vm._e()]:[_vm._v(" "+_vm._s(child.value)+" ")]],2)}),0)}):_vm._l((_vm.rows[i]),function(item,index){return _c('tr',{key:index},_vm._l((_vm.rows[i][index]),function(child,indexChild){return _c('td',{key:indexChild,staticClass:"text-center pa-0"},[(indexChild != 0)?[(child.control == undefined)?_c('v-card',{staticClass:"ma-1 mx-auto",attrs:{"color":child.isWater ? 'blue' :
                        child.folioAnalysis && child.folioAnalysis.includes(_vm.analysis) ? 
                          child.sampleType && child.sampleType.includes('VEGETABLE') ? 'primary' : 'warning' : 
                          child.type == 'PURIFICATION' && child.valueNum != 1 && child.valueNum != 7 ? '#b2b2b2' : 
                        '#f2f2f2',"width":_vm.type == 'AMPLIFICATION' ? '10vh' : '7vh'}},[_c('v-card-text',{staticClass:"px-0 py-2"},[(child.folioAnalysis && child.folioAnalysis.includes(_vm.analysis))?_c('small',{staticClass:"white--text"},[_vm._v(" "+_vm._s(child.replica && child.replica != "1" ? `(${child.replica})` : '')+" "+_vm._s(child.type != 'AMPLIFICATION' ? child.folioAnalysis : `R${child.reaction} ${child.folioAnalysis}`)+" ")]):(child.folioAnalysis)?_c('small',[_vm._v(" "+_vm._s(child.replica && child.replica != "1" ? `(${child.replica})` : '')+" "+_vm._s(child.type != 'AMPLIFICATION' ? child.folioAnalysis : `R${child.reaction} ${child.folioAnalysis}`)+" ")]):(child.isWater)?_c('small',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.translations.water)+" ")]):_c('small',[_vm._v("   ")])])],1):_c('v-card',{staticClass:"ma-1 mx-auto",attrs:{"width":_vm.type == 'AMPLIFICATION' ? '10vh' : '7vh',"color":child.isVegetal ? 'secondary' : child.control ? 'red accent-2' : 'light-blue darken-4'}},[_c('v-card-text',{staticClass:"px-1 py-2"},[(child.isVegetal)?[_c('small',{staticClass:"white--text"},[_vm._v(" R"+_vm._s(child.reaction)+" C. "+_vm._s(_vm.translations.plant)+" ")])]:[(child.control)?_c('small',{staticClass:"white--text"},[_vm._v(" R"+_vm._s(child.reaction)+" Ctrol + ")]):_c('small',{staticClass:"white--text"},[_vm._v(" R"+_vm._s(child.reaction)+" Ctrol - ")])]],2)],1)]:[_vm._v(" "+_vm._s(child.value)+" ")]],2)}),0)})],2)]},proxy:true}],null,true)}):_vm._e()],1)}),1):_c('v-row',[_vm._v(" "+_vm._s(_vm.translations.no_results)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }