<template>
<div>
    <v-row class="ma-0 d-flex" style="gap: 8px;">
        <v-col class="pa-0" cols="12" md="4">
            <v-text-field outlined label="Buscar" prepend-inner-icon="mdi-magnify" background-color="white" dense v-model="searchQuery" clearable></v-text-field>
        </v-col>
        <v-col class="pa-0" cols="auto">
            <v-menu offset-y :close-on-click="true" :close-on-content-click="false" v-model="filterMenu">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on" class="ma-0">
                        <v-icon left>mdi-filter</v-icon>
                        Filtro
                    </v-btn>
                </template>
                <v-card width="350px">
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-autocomplete label="Ordenamiento" hide-details outlined clearable dense :items="sortItems" hide-no-data item-text="text" v-model="filter.sort"></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete label="Tipo" hide-details outlined clearable dense :items="soilTypes" item-text="text" hide-no-data v-model="filter.soilStockType"></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" @click="cleanFilter()">Restaurar</v-btn>
                        <v-btn color="primary" @click="applyFilter()">Aplicar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </v-col>
        <v-col class="pa-0" cols="auto">
            <v-combobox filled label="Columnas" :items="headersItems" item-text="text" clearable v-model="columnNames" multiple small-chips @change="updateHeaders" dense></v-combobox>
        </v-col>
    </v-row>
</div>
</template>

<script>
import {
    mapMutations,
    mapState
} from "vuex";
export default {
    data() {
        return {
            filterMenu: null,
            searchQuery: "",
            numItems: 10,
            columnNames: [{
                    text: "Fecha almacenamiento",
                    sortable: false,
                    value: "registrationDate",
                },
                // {
                //     text: "Cliente",
                //     sortable: false,
                //     value: "client",
                // },
                // {
                //     text: "Muestra información",
                //     sortable: false,
                //     value: "sampleType",
                // },
                {
                    text: "Folio",
                    sortable: false,
                    value: "bucketId",
                },
                // {
                //     text: "Ensamble",
                //     sortable: false,
                //     value: "assembly",
                //     align: "center",
                // },
                {
                    text: "Muestra de suelo",
                    sortable: false,
                    value: "hasSoilSample",
                    align: "center",
                },
                {
                    text: "Cantidad resguardada",
                    sortable: false,
                    value: "soilSampleQuantity",
                    align: "center",
                },
                {
                    text: "Muestra de DNA",
                    sortable: false,
                    value: "hasDnaSample",
                    align: "center",
                },
                {
                    text: "Volumen DNA resguardado",
                    sortable: false,
                    value: "dnaVolume",
                    align: "center",
                },
                // {
                //   text: "Envio a secuenciación",
                //   sortable: false,
                //   value: "sequenciationSendStatus",
                // },
                // {
                //     text: "Acciones",
                //     sortable: false,
                //     value: "_id",
                // },
            ],
            headersItems: [{
                    text: "Fecha almacenamiento",
                    sortable: false,
                    value: "registrationDate",
                },
                {
                    text: "Cliente",
                    sortable: false,
                    value: "client",
                },
                {
                    text: "Muestra información",
                    sortable: false,
                    value: "sampleType",
                },
                {
                    text: "ID bucket",
                    sortable: false,
                    value: "bucketId",
                },
                {
                    text: "Ensamble",
                    sortable: false,
                    value: "assembly",
                    align: "center",
                },
                {
                    text: "Muestra de suelo",
                    sortable: false,
                    value: "hasSoilSample",
                    align: "center",
                },
                {
                    text: "Cantidad resguardada",
                    sortable: false,
                    value: "soilSampleQuantity",
                    align: "center",
                },
                {
                    text: "Muestra de DNA",
                    sortable: false,
                    value: "hasDnaSample",
                    align: "center",
                },
                {
                    text: "Volumen DNA resguardado",
                    sortable: false,
                    value: "dnaVolume",
                    align: "center",
                },
                // {
                //   text: "Envio a secuenciación",
                //   sortable: false,
                //   value: "sequenciationSendStatus",
                // },
                // {
                //     text: "Acciones",
                //     sortable: false,
                //     value: "_id",
                // },
            ],
            filter: {
                sort: null,
                soilStockType: null,
            },
            sortItems: [],
            soilTypes: [{
                    text: "Suelo",
                    value: "SOIL",
                },
                {
                    text: "Suelo inoculado",
                    value: "INOCULATED_SOIL",
                },
            ],
        };
    },
    created() {
        // if (this.headers.length == 0) {
            this.SET_HEADERS_TABLE(this.columnNames);
        // } else {
        //     this.columnNames = this.headers;
        // }
        // set text source to variables
        this.sortItems = [{
                text: "Descendente",
                value: "NEWER",
            },
            {
                text: "Ascendente",
                value: "OLDER",
            },
        ];
        this.statusItems = [{
                text: "No enviada",
                value: "NOT_SEND",
            },
            {
                text: "Enviada",
                value: "SEND",
            },
            {
                text: "Pendiente",
                value: "PENDING",
            },
        ];
        this.searchQuery = null
        this.SET_SEARCH_TABLE(null);
        //set default sort
        this.filter.sort = "NEWER";
        this.applyFilter();
    },
    computed: {
        ...mapState("soilStore", ["headers", "quantityAll", "dnaStoredQuantity"]),
    },
    methods: {
        ...mapMutations("soilStore", [
            "SET_FILTER_TABLE",
            "SET_SEARCH_TABLE",
            "SET_ITEMS_PER_PAGE_TABLE",
            "SET_HEADERS_TABLE",
        ]),
        applyFilter() {
            let auxFilter = {
                sort: null,
                soilStockType: this.filter.soilStockType,
            };
            if (this.filter.sort) {
                switch (this.filter.sort) {
                    case "NEWER":
                        auxFilter.sort = {
                            registrationDate: -1
                        };
                        break;
                    case "OLDER":
                        auxFilter.sort = {
                            registrationDate: 1
                        };
                        break;
                }
            }
            this.SET_FILTER_TABLE(auxFilter);
            this.filterMenu = false;
        },
        cleanFilter() {
            this.filterMenu = false;
            this.filter = {
                sort: null,
                sequenciationSendStatus: null,
            };
            this.SET_FILTER_TABLE(this.filter);
        },
        updateHeaders() {
            this.SET_HEADERS_TABLE(this.columnNames);
        },
    },
    watch: {
        async searchQuery(value) {
            this.SET_SEARCH_TABLE(value);
        },
        async numItems(value) {
            this.SET_ITEMS_PER_PAGE_TABLE(value);
        },
    },
};
</script>

<style scoped>
.headline {
    font-size: 1.5rem;
    font-weight: bold;
}
</style>
