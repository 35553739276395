<template>
    <v-container fluid>
        <v-row class="align-center">
            <v-col class="d-flex align-center">
                <v-icon color="primary" size="36" class="mr-3">
                    mdi-dna
                </v-icon>
                <h2 class="text-h5 font-weight-medium">
                    Marcadores
                </h2>
            </v-col>
        </v-row>
        <v-row v-if="!showFlowComponent.flag">
            <v-col class="pb-0">
                <v-row>
                    <v-col cols="12" xl="5" lg="5" md="5">
                        <v-text-field outlined dense prepend-inner-icon="mdi-magnify" label="Buscar..." clearable
                            v-model="search" background-color="white">
                        </v-text-field>
                    </v-col>
                    <v-col cols="auto">
                        <v-menu offset-y :close-on-click="true" :close-on-content-click="false" v-model="filterMenu">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" v-bind="attrs" v-on="on" block>
                                    <v-icon left>mdi-filter</v-icon>
                                    Filtro
                                </v-btn>
                            </template>
                            <v-card width="auto">
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-autocomplete label="Tipo" hide-details outlined clearable dense
                                                    :items="moTypes" hide-no-data item-text="name"
                                                    v-model="filter.type"></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-autocomplete label="Simbiosis" hide-details outlined clearable dense
                                                    :items="symbiosis" item-text="name" hide-no-data
                                                    v-model="filter.symbiosis"></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-autocomplete label="Estatus" hide-details outlined clearable dense
                                                    :items="statusMarker" item-text="name" hide-no-data
                                                    v-model="filter.isActive"></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="warning" @click="cleanFilter()">Restaurar</v-btn>
                                    <v-btn color="primary" @click="applyFilter()">Aplicar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                    </v-col>
                    <v-col cols="auto" v-if="!hideValidations">
                        <v-btn color="primary" @click="showNewMarkerModalV2 = true"
                            :disabled="!permissions.find((permission) => permission == 'lab_marker_write')">
                            <v-icon>mdi-plus</v-icon>
                            Añadir marcador
                        </v-btn>
                    </v-col>
                    <v-col cols="auto" v-if="!hideValidations">
                        <v-btn color="primary" @click="exportData()" :disabled="selectedMarkers.length == 0"
                            :loading="isExporting">
                            <v-icon>mdi-file-excel</v-icon>
                            Exportar Oligo&nbsp;<span v-if="selectedMarkers.length != 0">{{ selectedMarkers.length
                            }}</span>
                        </v-btn>
                    </v-col>
                    <v-col cols="auto" v-if="!hideValidations">
                        <v-btn color="primary" @click="showValidationNotificationModal = true"
                            :disabled="selectedMarkers.length == 0" :loading="isSendingMessage">
                            <v-icon>mdi-bell</v-icon>
                            Enviar notificación&nbsp;<span v-if="selectedMarkers.length != 0">{{ selectedMarkers.length
                            }}</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12">
                                    <v-data-table v-model="selectedMarkers" :show-select="!hideSelectionTable"
                                        item-key="_id" :headers="headers" :items="markers" :page.sync="page"
                                        :items-per-page="10" :loading="loadTable" hide-default-footer fixed-header
                                        height="56vh" no-data-text="No hay niveles de riesgo" class="elevation-0">
                                        <template #[`item.molecularMarkerID`]="{ item }">
                                            <div v-if="item.molecularMarkerID">
                                                <span>
                                                    {{ item.molecularMarkerID }}
                                                </span>
                                            </div>
                                            <div v-else>--------</div>
                                        </template>
                                        <template #[`item.microorganism_meta.name`]="{ item }">
                                            <div v-if="item.markerType == 'MO'">
                                                <v-chip color="red" v-if="item.isActive == false">
                                                    <span class="white--text">
                                                        {{ item.microorganism_meta.name }}
                                                    </span>
                                                </v-chip>
                                                <v-chip color="primary" v-else>
                                                    <span>
                                                        {{ item.microorganism_meta.name }}
                                                    </span>
                                                </v-chip>

                                            </div>
                                            <div v-else>
                                                <v-chip color="primary">
                                                    <span>
                                                        {{ item.soilFunction.es }}
                                                    </span>
                                                </v-chip>
                                            </div>
                                        </template>
                                        <template #[`item.updatedAt`]="{ item }">
                                            <span>{{ dateFormat(item.updatedAt) }} </span>
                                        </template>
                                        <template #[`item.createdAt`]="{ item }">
                                            <span>{{ dateFormat(item.createdAt) }} </span>
                                        </template>
                                        <template #[`item.microorganism_meta.moType`]="{ item }">
                                            <div v-if="item.markerType == 'MO'">
                                                <span>{{ getMOType(item.microorganism_meta.moType) }}
                                                </span>
                                                <span v-if="item.isPathogen"> (Patógeno) </span>
                                                <span v-else> (Benéfico) </span>
                                            </div>
                                            <div v-else-if="item.markerType == 'FNC'">Funciónal</div>
                                            <div v-else-if="item.markerType == 'MO_FNC'">Funcional/Taxonómico</div>
                                            <div v-if="item.fluorophore" class="primary--text">
                                                {{ item.fluorophore }}
                                            </div>
                                        </template>

                                        <template #[`item._id`]="{ item }">

                                            <v-menu bottom right>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon v-bind="attrs" v-on="on">
                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list dense>
                                                    <v-list-item @click="openUpdateForm(item)" v-if="!hideValidations"
                                                        :disabled="!permissions.find((permission) => permission == 'lab_marker_write')">
                                                        <v-list-item-title>Actualizar </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="openOligoForm(item)" v-if="!hideValidations"
                                                        :disabled="!permissions.find((permission) => permission == 'lab_oligo_read')">
                                                        <v-list-item-title> Oligo </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="handleUpdateFluorophore(item)"
                                                        v-if="!hideValidations"
                                                        :disabled="!permissions.find((permission) => permission == 'lab_fluorophore_write')">
                                                        <v-list-item-title> Agregar fluoróforo </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="setRestoreModal(item)"
                                                        v-if="item.isActive == false && !hideValidations"
                                                        :disabled="!permissions.find((permission) => permission == 'lab_marker_write')">
                                                        <v-list-item-title> Restaurar </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="setDeleteModal(item)"
                                                        v-if="item.isActive == true && !hideValidations"
                                                        :disabled="!permissions.find((permission) => permission == 'lab_marker_write')">
                                                        <v-list-item-title> Eliminar </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="setTimeLine(item)">
                                                        <v-list-item-title> Historial </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                        <template #[`item.cqMax`]="{ item }">
                                            <span>min: {{ parseFloat(item.cqMin).toFixed(2) }} </span>
                                            <v-spacer></v-spacer>
                                            <span>max: {{ parseFloat(item.cqMax).toFixed(2)  }} </span>
                                            <v-spacer></v-spacer>
                                        </template>
                                        <template #[`item.oligoStatus`]="{ item }">
                                            <v-chip v-if="item.oligoStatus == 'NOT_STARTED'" color="primary">
                                                <span>{{ getOligoStatus(item.oligoStatus) }} </span>
                                            </v-chip>
                                            <v-chip v-if="item.oligoStatus == 'PENDING'" color="orange" dark>
                                                <span>{{ getOligoStatus(item.oligoStatus) }} </span>
                                            </v-chip>
                                            <v-chip v-if="item.oligoStatus == 'DESIGNING'" color="blue" dark>
                                                <span>{{ getOligoStatus(item.oligoStatus) }} </span>
                                            </v-chip>
                                            <v-chip v-if="item.oligoStatus == 'FINISHED'" color="secondary">
                                                <span>{{ getOligoStatus(item.oligoStatus) }} </span>
                                            </v-chip>
                                            <v-chip v-if="item.oligoStatus == 'DISCARDED'" color="red" dark>
                                                <span>{{ getOligoStatus(item.oligoStatus) }} </span>
                                            </v-chip>
                                        </template>
                                        <template #[`item.synthesisStatus`]="{ item }">
                                            <v-chip v-if="item.synthesisStatus == 'NOT_STARTED'" color="primary">
                                                <span>{{ getSynthesisStatus(item.synthesisStatus) }} </span>
                                            </v-chip>
                                            <v-chip v-if="item.synthesisStatus == 'IN_PROGRESS'" color="orange" dark>
                                                <span>{{ getSynthesisStatus(item.synthesisStatus) }} </span>
                                            </v-chip>
                                            <v-chip v-if="item.synthesisStatus == 'FINISHED'" color="secondary" dark>
                                                <span>{{ getSynthesisStatus(item.synthesisStatus) }} </span>
                                            </v-chip>
                                        </template>
                                        <template #[`item.validationStatus`]="{ item }">
                                            <v-chip v-if="item.validationStatus == 'NOT_STARTED'" color="primary">
                                                <span>{{ getValidationStatus(item.validationStatus) }} </span>
                                            </v-chip>
                                            <v-chip v-if="item.validationStatus == 'IN_PROGRESS'" color="orange" dark>
                                                <span>{{ getValidationStatus(item.validationStatus) }} </span>
                                            </v-chip>
                                            <v-chip v-if="item.validationStatus == 'FINISHED'" color="secondary" dark>
                                                <span>{{ getValidationStatus(item.validationStatus) }} </span>
                                            </v-chip>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-pagination v-model="page" :length="numPages" total-visible="10" color="primary"></v-pagination>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col v-if="showFlowComponent.component == 'FORM_MARKER'">
                <MolecularMarkerForm @back="
                    showFlowComponent = {
                        flag: false,
                        component: '',
                    }
                    " @back_update="fetchAndClear" />
            </v-col>
            <v-col v-if="showFlowComponent.component == 'FORM_REDESIGN'">
                <RedesignMarkerForm @back="
                    showFlowComponent = {
                        flag: false,
                        component: '',
                    }
                    " @back_update="fetchAndClear" />
            </v-col>
        </v-row>
        <TimeLineMarker v-model="showTimeLine" v-if="showTimeLine" />
        <DeleteCofirmationModal v-model="showDeleteCofirmationModal" v-if="showDeleteCofirmationModal"
            @confirmation="removeMarker()" :title="genericModalTitle" />
        <RestoreCofirmationModal v-model="showRestoreCofirmationModal" v-if="showRestoreCofirmationModal"
            @confirmation="activeMarker()" :title="genericModalTitle" />
        <AddNewValidationFormatModal v-model="showAddNewValidationFormatModal" v-if="showAddNewValidationFormatModal"
            @update="(showAddNewValidationFormatModal = false), fetchData()" />
        <new-marker-modal v-model="showNewMarkerModal" @close_update="fetchData(), showNewMarkerModal = false" />
        <NewMarkerModalV2 v-model="showNewMarkerModalV2" @close_update="fetchData(), showNewMarkerModalV2 = false" />
        <update-marker-modal v-model="showUpdateMarkerModal"
            @close_update="fetchData(), showUpdateMarkerModal = false" />
        <validation-notification-modal v-model="showValidationNotificationModal" :markers="selectedMarkers"
            @email_send="handleEmailSuccess" />
        <AddFluorophoreModal v-model="showAddFluorophoreModal" v-if="showAddFluorophoreModal"
            @confirmation="updateFluorophoreRequest" />
    </v-container>
</template>

<script>
//Vuex
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
//Libraries
import dayjs from "dayjs";
import xlsx from "json-as-xlsx";
//components
import MolecularMarkerForm from "@/components/cmda_admin/MolecularMarkerForm.vue";
import RedesignMarkerForm from "@/components/cmda_admin/RedesignMarkerForm.vue";
import TimeLineMarker from "@/components/cmda_admin/modals/TimeLineMarker.vue";
import DeleteCofirmationModal from "@/components/utils/GenericConfirmationModal.vue";
import RestoreCofirmationModal from "@/components/utils/GenericConfirmationModal.vue";
import AddNewValidationFormatModal from "@/components//validations/modals/AddNewValidationFormatModal.vue";

import NewMarkerModal from '../validations/modals/NewMarkerModal.vue';
import NewMarkerModalV2 from "../validations/modals/NewMarkerModalV2.vue";
import UpdateMarkerModal from '../validations/modals/UpdateMarkerModal.vue';
import ValidationNotificationModal from '../validations/modals/ValidationNotificationModal.vue';
import AddFluorophoreModal from "../validations/modals/AddFluorophoreModal.vue";

export default {
    props: {
        showAddNewFormattValidations: {
            type: Boolean,
            default: false,
        },
        hideValidations: {
            type: Boolean,
            default: true,
        },
        hideSelectionTable: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        MolecularMarkerForm,
        TimeLineMarker,
        RedesignMarkerForm,
        DeleteCofirmationModal,
        RestoreCofirmationModal,
        AddNewValidationFormatModal,
        NewMarkerModal,
        NewMarkerModalV2,
        UpdateMarkerModal,
        ValidationNotificationModal,
        AddFluorophoreModal
    },
    data: () => ({
        headers: [{
            text: "ID",
            sortable: false,
            value: "molecularMarkerID",
        },
        {
            text: "Oligo",
            sortable: false,
            value: "oligoStatus",
        },
        {
            text: "Síntesis",
            sortable: false,
            value: "synthesisStatus",
        },
        {
            text: "Validación",
            sortable: false,
            value: "validationStatus",
        },
        {
            text: "Versión",
            value: "version",
            sortable: false,
            align: "center"
        },

        {
            text: "Nombre",
            value: "microorganism_meta.name",
            sortable: false
        },
        {
            text: "Cq's",
            value: "cqMax",
            sortable: false
        },

        {
            text: "Tipo",
            value: "microorganism_meta.moType",
            sortable: false,
        },
        {
            text: "Fecha creación",
            value: "createdAt",
            sortable: false
        },
        {
            text: "Ultima actualización",
            value: "updatedAt",
            sortable: false
        },
        {
            text: "Acciones",
            value: "_id",
            sortable: false
        },
        ],
        markers: [],
        per_page: 10,
        page: 1,
        numPages: 1,
        sort: {
            createdAt: -1
        },
        search: null,
        filter: {
            symbiosis: null,
            type: null,
            isActive: null,
        },
        moTypes: [{
            name: "Bacteria",
            value: "bacteria",
        },
        {
            name: "Hongo",
            value: "fungi",
        },
        {
            name: "Nematodo",
            value: "nematode",
        },
        ],
        symbiosis: [{
            name: "Benéfico",
            value: false,
        },
        {
            name: "Patógeno",
            value: true,
        },
        ],
        statusMarker: [{
            name: "Activo",
            value: "active",
        },
        {
            name: "Suspendido",
            value: "suspended",
        },
        ],
        loadTable: false,
        showFlowComponent: {
            flag: false,
            component: "",
        },
        filterMenu: false,
        genericModalTitle: "",
        selectedMarkers: [],
        // loaders
        isExporting: false,
        isSendingMessage: false,
        // modals
        showTimeLine: false,
        showDeleteCofirmationModal: false,
        showRestoreCofirmationModal: false,
        showAddNewValidationFormatModal: false,
        showNewMarkerModal: false,
        showNewMarkerModalV2: false,
        showUpdateMarkerModal: false,
        showValidationNotificationModal: false,
        showAddFluorophoreModal: false
    }),
    async mounted() {
        await this.fetchData();
    },
    computed: {
        ...mapState("laboratory", ["molecularMarkerToUpdate"]),
        ...mapState("molecularMarker", ["markerSelected"]),
        ...mapState("session", ["permissions"]),
    },
    methods: {
        ...mapActions("laboratory", [
            "getMarkers",
            "deleteMarker",
            "restoreMarker",
        ]),
        ...mapMutations("laboratory", ["SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE"]),
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        ...mapMutations("molecularMarker", ["SET_MARKER_SELECTED"]),
        ...mapActions("molecularMarker", ["getLastOligoFromMarkerList", "updateFluorophore"]),
        async fetchData() {
            try {
                this.loadTable = true;
                let payload = {
                    filter: this.filter,
                    per_page: this.per_page,
                    page: this.page,
                    sort: this.sort,
                };
                let response = await this.getMarkers(payload);
                if (response.error) throw Error(response.message);
                this.numPages = response.pages;
                this.markers = response.molecularMarkers;
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.loadTable = false;
        },
        dateFormat(value) {
            const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
            return date || "--/--/----";
        },
        getMOType(type) {
            let itemsMoType = {
                all: "Todos",
                bacteria: "Bacteria",
                fungi: "Hongo",
                nematode: "Nemátodo",
            };
            return itemsMoType[type] || type;
        },
        async fetchAndClear() {
            this.showFlowComponent = {
                flag: false,
                component: "",
            };
            this.SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE(null);
            await this.fetchData();
        },
        async cleanFilter() {
            this.filterMenu = false;
            this.filter = {
                symbiosis: null,
                type: null,
                isActive: null,
            };
            await this.fetchData();
        },
        async applyFilter() {
            this.page = 1;
            this.numPages = 1;
            this.filterMenu = false;
            await this.fetchData();
        },
        update(item) {
            this.SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE(item);
            this.showFlowComponent = {
                flag: true,
                component: "FORM_MARKER",
            };
        },
        redesign(item) {
            this.SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE(item);
            this.showFlowComponent = {
                flag: true,
                component: "FORM_REDESIGN",
            };
        },
        validations(item) {
            this.$emit("nextValidations", item);
        },
        setTimeLine(item) {
            this.SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE(item);
            this.showTimeLine = true;
        },
        setDeleteModal(item) {
            this.SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE(item);
            this.genericModalTitle = `¿Estas seguro que quiere eliminar el marcador ${item.microorganism_meta.name} (${item.molecularMarkerID})?`;
            this.showDeleteCofirmationModal = true;
        },
        setRestoreModal(item) {
            this.SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE(item);
            this.genericModalTitle = `¿Estas seguro que quiere restaurar el marcador ${item.microorganism_meta.name} (${item.molecularMarkerID})?`;
            this.showRestoreCofirmationModal = true;
        },
        openFormNewMarrker() {
            this.showFlowComponent = {
                flag: true,
                component: "FORM_MARKER",
            };
            this.SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE(null);
        },
        async removeMarker() {
            try {
                this.molecularMarkerToUpdate.isDeleting = true;
                let payload = {
                    markerId: this.molecularMarkerToUpdate._id,
                };
                await this.deleteMarker(payload);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Marcador eliminado con exito`,
                    timeout: 5000,
                });

                this.page = 1;
                await this.fetchData();
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
        },
        async activeMarker() {
            try {
                this.molecularMarkerToUpdate.isDeleting = true;
                let payload = {
                    markerId: this.molecularMarkerToUpdate._id,
                };
                await this.restoreMarker(payload);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Marcador restaurado con exito`,
                    timeout: 5000,
                });

                this.page = 1;
                await this.fetchData();
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
        },
        openOligoForm(item) {
            this.SET_MARKER_SELECTED(item)
            this.$emit("openOligo")
        },
        openUpdateForm(item) {
            this.SET_MARKER_SELECTED(item)
            this.showUpdateMarkerModal = true
        },
        getOligoStatus(value) {
            let status = {
                NOT_STARTED: "Sin iniciar",
                PENDING: "Pendiente",
                DESIGNING: "Diseño",
                FINISHED: "Terminado",
                DISCARDED: "Descartado"
            }
            return status[value] || value
        },
        getSynthesisStatus(value) {
            let status = {
                NOT_STARTED: "Sin iniciar",
                IN_PROGRESS: "En progreso",
                FINISHED: "Terminado",
            }
            return status[value] || value
        },
        getValidationStatus(value) {
            let status = {
                NOT_STARTED: "Sin iniciar",
                IN_PROGRESS: "En progreso",
                FINISHED: "Terminado",
            }
            return status[value] || value
        },
        async exportData() {
            try {
                this.isExporting = true
                let exportHeaders1 = [{
                    label: "Name",
                    value: "name"
                },
                {
                    label: "Sequence",
                    value: "sequence"
                },
                {
                    label: "Scale",
                    value: "scale"
                },
                {
                    label: "Purification",
                    value: "purification"
                },
                {
                    label: "Oligo",
                    value: "oligoId"
                },
                ]
                let exportHeaders2 = [{
                    label: "Name",
                    value: "name"
                },
                {
                    label: "Sequence",
                    value: "sequence"
                },
                {
                    label: "Oligo",
                    value: "oligoId"
                },
                ]
                let probe = []
                let forwarAndReverse = []
                // get date from last oligo on each marker selected
                let payload = {
                    markers: this.selectedMarkers.map((marker) => {
                        return marker._id;
                    })
                }

                let response = await this.getLastOligoFromMarkerList(payload)
                probe = response.probe
                forwarAndReverse = response.forwarAndReverse

                const actually_date = new Date();
                let fileTitle1 = `Primers Forward and Reverse - ${actually_date
                    .toLocaleDateString()
                    .toString()
                    .replaceAll("/", "-")}`;

                let fileTitle2 = `Sonda - ${actually_date
                    .toLocaleDateString()
                    .toString()
                    .replaceAll("/", "-")}`;
                this.exportJsonToXlsx(exportHeaders1, forwarAndReverse, fileTitle1);
                this.exportJsonToXlsx(exportHeaders2, probe, fileTitle2);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: "Información exportada",
                    timeout: 4000,
                });
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.isExporting = false
        },
        exportJsonToXlsx(columns, content, name) {
            const settings = {
                fileName: name,
            };
            xlsx(
                [{
                    sheet: "Hoja 1",
                    columns,
                    content,
                },],
                settings
            );
        },
        async handleEmailSuccess() {
            this.showValidationNotificationModal = false
            this.selectedMarkers = []
            await this.fetchData()
        },
        handleUpdateFluorophore(item) {
            this.SET_MARKER_SELECTED(item)
            this.showAddFluorophoreModal = true
        },
        async updateFluorophoreRequest(fluorophore) {
            try {
                let params = {
                    markerId: this.markerSelected._id,
                    fluorophore: fluorophore
                };
                await this.updateFluorophore({
                    params: params,
                    body: {}
                });
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Fluoróforo actualizado`,
                    timeout: 5000,
                });
                await this.fetchData();
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
        }
    },
    watch: {
        async page() {
            await this.fetchData();
        },

        async search(value) {
            this.page = 1;
            this.numPages = 1;
            if (value) {
                this.filter = {
                    recommendationType: {
                        $exists: false
                    },
                    search: value,
                };
            } else {
                this.filter = {
                    recommendationType: {
                        $exists: false
                    },
                };
            }

            await this.fetchData();
        },
    },
};
</script>

<style scoped></style>
