<template>
  <v-dialog v-model="show" width="50vw" scrollable persistent>
    <v-card height="auto" elevation="5" class="rounded-lg" min-height="200">
      <v-card-title class="text-h5 lighten-2"> Actualizar suelo </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form_soil_stock" lazy-validation>
            <v-row>
              <v-col cols="12" lg="6">
                <v-autocomplete
                  label="Tipo de suelo"
                  outlined
                  dense
                  v-model="soilType"
                  :rules="validation"
                  :items="soilTypes"
                  item-text="text"
                  hide-no-data
                  return-object
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" lg="6">
                <v-autocomplete
                  label="Buscar folio..."
                  prepend-inner-icon="mdi-magnify"
                  outlined
                  dense
                  v-model="sampleSelected"
                  :search-input.sync="searchSample"
                  :rules="validation"
                  :items="samples"
                  item-text="folioAnalysis"
                  hide-no-data
                  :loading="loadingSamples"
                  return-object
                  disabled
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  outlined
                  dense
                  label="Ingresa ID bucket"
                  clearable
                  v-model="form.bucketId"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch
                  outlined
                  dense
                  label="Tiene ensamble?"
                  clearable
                  v-model="form.assembly"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="6">
                <v-switch
                  outlined
                  dense
                  label="Tiene muestra de suelo?"
                  clearable
                  v-model="form.hasSoilSample"
                  :rules="validation"
                ></v-switch>
              </v-col>
              <v-col cols="12" lg="6" v-if="form.hasSoilSample">
                <v-text-field
                  outlined
                  dense
                  label="Ingrese cantidad de suelo resguardado"
                  clearable
                  type="number"
                  v-model="form.soilSampleQuantity"
                  :rules="validation"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="6">
                <v-switch
                  outlined
                  dense
                  label="Tiene muestra de DNA?"
                  clearable
                  v-model="form.hasDnaSample"
                  :rules="validation"
                ></v-switch>
              </v-col>
              <v-col cols="12" lg="6" v-if="form.hasDnaSample">
                <v-text-field
                  outlined
                  dense
                  label="Ingrese volumen de DNA resguardado"
                  clearable
                  type="number"
                  v-model="form.dnaVolume"
                  :rules="validation"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  filled
                  name="input-7-4"
                  label="Observaciones"
                  v-model="form.storeNote"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                color="gray"
                block
                @click="closeModal"
                dark
                :disabled="isLoading"
                >Cerrar</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                block
                @click="saveData"
                :loading="isLoading"
                >Aceptar</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//vuex
import { mapState, mapMutations, mapActions } from "vuex";
import { requiredValidation } from "@/helpers/validations.js";
export default {
  data: () => ({
    loader: false,
    isLoading: false,
    loadingSamples: false,
    form: {
      bucketId: null,
      assembly: null,
      hasSoilSample: null,
      soilSampleQuantity: null,
      hasDnaSample: null,
      dnaVolume: null,
      storeNote: null,
      sampleId: null,
    },
    searchSample: null,
    sampleSelected: null,
    samples: [],
    soilType: null,
    soilTypes: [
      {
        text: "Suelo",
        value: "SOIL",
      },
      {
        text: "Suelo inoculado",
        value: "INOCULATED_SOIL",
      },
    ],
  }),
  props: {
    value: Boolean,
  },
  computed: {
    ...mapState("soilStore", ["rowSelectedTableSoil"]),
    validation() {
      return requiredValidation();
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("soilStore", ["getSamplesByFolio", "updateSoilStock"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async saveData() {
      if (this.$refs.form_soil_stock.validate()) {
        try {
          let payload = {
            bucketId: this.form.bucketId,
            assembly: this.form.assembly,
            hasSoilSample: this.form.hasSoilSample,
            soilSampleQuantity: this.form.soilSampleQuantity,
            hasDnaSample: this.form.hasDnaSample,
            dnaVolume: this.form.dnaVolume,
            storeNote: this.form.storeNote,
            soilStockType: this.soilType.value,
          };
          await this.updateSoilStock({ payload, id: this.sampleSelected._id });
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: `Registro actualizado`,
            timeout: 3000,
          });
          this.cleanForm();
          this.$emit("close_update");
        } catch (error) {
          console.log(error);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: `Algo salió mal. Error: ${error.message}`,
            timeout: 5000,
          });
        }
      }
    },
    cleanForm() {
      this.$refs.form_soil_stock.resetValidation();
      this.form = {
        bucketId: null,
        assembly: null,
        hasSoilSample: null,
        soilSampleQuantity: null,
        hasDnaSample: null,
        dnaVolume: null,
        storeNote: null,
        sampleId: null,
      };
      this.searchSample = null;
      this.sampleSelected = null;
      this.samples = [];
    },
    closeModal() {
      this.cleanForm();
      this.$emit("close");
    },
    setup() {
      this.sampleSelected = { ...this.rowSelectedTableSoil };
      this.samples = [{ ...this.rowSelectedTableSoil }];
      this.soilType = this.soilTypes.find(
        (element) => element.value == this.rowSelectedTableSoil.soilStockType
      );
      this.form = {
        bucketId: this.rowSelectedTableSoil.bucketId,
        assembly: this.rowSelectedTableSoil.assembly,
        hasSoilSample: this.rowSelectedTableSoil.hasSoilSample,
        soilSampleQuantity: this.rowSelectedTableSoil.soilSampleQuantity,
        hasDnaSample: this.rowSelectedTableSoil.hasDnaSample,
        dnaVolume: this.rowSelectedTableSoil.dnaVolume,
        storeNote: this.rowSelectedTableSoil.storeNote,
        sampleId: this.rowSelectedTableSoil.sampleId,
      };
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.setup();
      }
    },
  },
};
</script>
