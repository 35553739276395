<template>
<v-dialog v-model="show" width="70vw" scrollable persistent>
    <v-card elevation="12" class="mx-auto rounded-lg overflow-hidden" max-width="70vw">
        <v-app-bar color="primary accent-4" dark flat height="60">
            <v-avatar color="white" size="36" class="mr-4">
                <v-icon color="primary accent-4">mdi-dna</v-icon>
            </v-avatar>
            <v-toolbar-title class="headline font-weight-bold">
                Nuevo Marcador
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-chip :color="markerStatusColor" dark label class="mr-2 rounded-xl">
                <v-icon left>mdi-circle-half-full</v-icon>
                {{ currentStatusLabel }}
            </v-chip>
        </v-app-bar>
        <v-card-text>
            <v-form ref="form" lazy-validation>
                <v-container>
                    <v-expansion-panels v-model="panels" multiple hover>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-col>
                                        <v-icon>mdi-cog</v-icon>
                                        Configuración inicial
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container>
                                    <v-row>
                                        <!-- Status Field -->
                                        <v-col cols="12" md="6">
                                            <v-select v-model="formData.status" :items="status" label="Estatus del Marcador" required :rules="validation()" outlined dense></v-select>
                                        </v-col>

                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="formData.molecularMarkerID" label="ID marcador" :disabled="validationBasicStatus()" :rules="validation('ID marcador')" outlined dense></v-text-field>
                                        </v-col>
                                        <!-- Marker Type - Only enabled when status is 'DESIGNING' -->
                                        <!-- :rules="formData.status === 'DESIGNING' ? [v => !!v || 'El tipo de marcador es requerido'] : []" -->
                                        <v-col cols="12" md="6">
                                            <v-select v-model="formData.markerType" :items="markerType" label="Tipo de Marcador" :disabled="validationBasicStatus()" :rules="validation('tipo de marcador')" outlined dense></v-select>
                                        </v-col>

                                        <v-col cols="12" md="6" v-if="formData.markerType && (formData.markerType == 'MO' || formData.markerType == 'MO_FNC')">
                                            <v-combobox dense outlined label="Selecciona microorganismo" clearable :search-input.sync="searchIdentification" :items="identifications" item-text="name" :loading="identificationsLoading" v-model="formData.identification" return-object :rules="validationMarker" @change="verifyMarker" ref="microorganismField">
                                            </v-combobox>

                                        </v-col>
                                        <v-col cols="12" md="6" v-if="formData.markerType && (formData.markerType == 'MO' || formData.markerType == 'MO_FNC')">
                                            <v-autocomplete dense outlined label="Selecciona simbiosis" v-model="formData.symbiosis" :items="symbiosis" item-text="text" :rules="validationMarker" @change="verifyMarker" ref="symbiosisField"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="formData.markerType && (formData.markerType == 'FNC' || formData.markerType == 'MO_FNC')">
                                            <v-text-field dense outlined label="Ingresa función en suelo (EN)" clearable v-model="formData.soilFunctionEN" :rules="validation('función')">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="formData.markerType && (formData.markerType == 'FNC' || formData.markerType == 'MO_FNC')">
                                            <v-text-field dense outlined label="Ingresa proceso" clearable v-model="formData.process" :rules="validation('proceso')">
                                            </v-text-field>
                                        </v-col>

                                        <!-- Locus - Only enabled when status is 'DESIGNING' -->
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="formData.locus" label="Locus" :disabled="validationBasicStatus()" :rules="formData.status === 'DESIGNING' ? [v => !!v || 'El locus es requerido'] : []" outlined dense></v-text-field>
                                        </v-col>

                                        <!-- Taxonomic Group - Only enabled when status is 'DESIGNING' -->
                                        <v-col cols="12" md="6">
                                            <v-select v-model="formData.taxonomicGroup" :items="taxonomicTypes" label="Grupo Taxonómico" :disabled="validationBasicStatus()" :rules="validation()" outlined dense></v-select>
                                        </v-col>

                                        <!-- Taxonomic Category - Only enabled when status is 'DESIGNING' -->
                                        <v-col cols="12" md="6">
                                            <v-select v-model="formData.taxonomicCategory" :items="texonomicCategories" label="Categoría Taxonómica" :disabled="validationBasicStatus()" :rules="validation()" outlined dense></v-select>
                                        </v-col>

                                        <!-- Taxon - Only enabled when status is 'DESIGNING' -->
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="formData.taxon" label="Taxon" :disabled="validationStatus()" :rules="formData.status === 'DESIGNING' ? [v => !!v || 'El taxon es requerido'] : []" outlined dense></v-text-field>
                                        </v-col>

                                        <!-- Project - Only enabled when status is 'DESIGNING' -->
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="formData.project" label="Proyecto" :disabled="validationBasicStatus()" :rules="formData.status === 'DESIGNING' ? [v => !!v || 'El proyecto es requerido'] : []" outlined dense></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-for="(item, index) in formData.oligoAndProbeData" :key="index">
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-col>
                                        <v-icon>{{ item.icon }}</v-icon>
                                        {{ item.tabName }}
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container>
                                    <v-row>
                                        <!-- Forward Primer Section -->
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="item.name" :label="`Nombre Oligo ${item.tag}`" :disabled="validationStatus()" :rules="formData.status === 'DESIGNING' && item.tag != 'PR' ? [v => !!v || 'El nombre del primer es requerido'] : []" outlined dense></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="item.sequence" :label="`Secuencia Oligo ${item.tag}`" :disabled="validationStatus()" :rules="formData.status === 'DESIGNING' && item.tag != 'PR'? [
                                                        v => !!v || 'La secuencia es requerida',
                                                    ] : []" counter outlined dense></v-text-field>
                                        </v-col>
                                        <!-- Numeric Fields for Forward Primer -->
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model.number="item.gc" :label="`GC ${item.tag} (%)`" type="number" :disabled="validationStatus()" :rules="formData.status === 'DESIGNING' && item.tag != 'PR'? [v => !!v || 'GC es requerido'] : []" outlined dense></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model.number="item.tm" :label="`Tm ${item.tag} (°C)`" type="number" step="0.1" :disabled="validationStatus()" :rules="formData.status === 'DESIGNING' && item.tag != 'PR'? [v => !!v || 'Tm es requerido'] : []" outlined dense></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model.number="item.hairpin" :label="`Hairpin Tm ${item.tag} (°C)`" type="number" :disabled="validationStatus()" :rules="formData.status === 'DESIGNING' && item.tag != 'PR'? [v => !!v || 'GC es requerido'] : []" outlined dense></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model.number="item.selfDimer" :label="`Self-dimer Tm ${item.tag} (°C)`" type="number" step="0.1" :disabled="validationStatus()" :rules="formData.status === 'DESIGNING' && item.tag != 'PR'? [v => !!v || 'Tm es requerido'] : []" outlined dense></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-col>
                                        <v-icon>mdi-dna</v-icon>
                                        Amplicon
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container>
                                    <v-row>
                                        <!-- Amplicon Size - Mandatory -->
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model.number="formData.ampliconSize" label="Tamaño del Amplicon (pb)" type="number" required :rules="formData.status === 'DESIGNING'?[v => !!v || 'Tamaño del amplicon es requerido']:[]" outlined dense :disabled="validationStatus()"></v-text-field>
                                        </v-col>

                                        <!-- Control Strain - Optional -->
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="formData.controlStrain" label="Cepa Control" :disabled="validationStatus()" hint="Campo opcional" outlined dense></v-text-field>
                                        </v-col>

                                        <!-- Amplicon Sequence - Mandatory -->
                                        <v-col cols="12">
                                            <v-text-field v-model="formData.ampliconSequence" label="Amplicon" :rules="formData.status === 'DESIGNING'?[
                                                        v => !!v || 'Secuencia de amplicon es requerida',

                                                    ]:[]" counter outlined dense :disabled="validationStatus()"></v-text-field>
                                        </v-col>

                                        <!-- Amplicon GC Content - Mandatory -->
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model.number="formData.ampliconGC" label="GC Amplicon (%)" type="number" step="0.1" required :rules="formData.status === 'DESIGNING'?[v => !!v || 'Contenido de GC es requerido']:[]" outlined dense :disabled="validationStatus()"></v-text-field>
                                        </v-col>

                                        <!-- Secondary Structure - Optional -->
                                        <v-col cols="12" md="6">
                                            <v-select v-model="formData.secondaryStructure" :items="strengthLevels" label="Estructura Secundaria" item-text="text" hint="Campo opcional" outlined dense :disabled="validationStatus()"></v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-row>
                        <v-col cols="12">
                            <v-divider class="my-4"></v-divider>
                            <h3 class="text-h6">Suelos</h3>
                        </v-col>
                        <!-- Validation Soils - Optional -->
                        <v-col cols="12">
                            <v-autocomplete dense outlined label="Buscar suelos para validación" clearable :search-input.sync="searchSample" :items="sampleBankList" item-text="folioAnalysis" :loading="loadingSamples" v-model="soilSelection" return-object @change="updateItems" :disabled="validationStatus()" prepend-inner-icon="mdi-magnify" ref="searchSampleInput">
                            </v-autocomplete>
                            <v-chip class="ma-2 truncate-chip" close v-for="(item, index) in soilSelected" :key="index" @click:close="removeItem(index)">
                                {{ item.folioAnalysis }}
                            </v-chip>
                        </v-col>
                        <v-col cols="12">
                            <h3 class="text-h6">Notas</h3>
                        </v-col>
                        <!-- Considerations - Optional -->
                        <v-col cols="12">
                            <v-textarea v-model="formData.considerations" label="Consideraciones" hint="Notas adicionales sobre el amplicon" outlined dense :disabled="validationStatus()"></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-container fluid>
                <v-row>
                    <v-col cols="">
                        <v-btn color="red" block @click="closeModal" dark :disabled="isLoading">Cancelar</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn color="primary" block @click="confirmation" :loading="isLoading">
                            <v-icon>mdi-content-save</v-icon>
                            Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
//vuex
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
//validations
import {
    requiredValidation
} from "@/helpers/validations.js";
export default {
    data: () => ({
        panels: [0, 1, 2, 3, 4],
        isLoading: false,
        formData: {
            //initial information
            molecularMarkerID: null,
            status: null,
            markerType: null,
            identification: null,
            symbiosis: null,
            soilFunctionEN: null,
            process: null,
            locus: '',
            taxonomicGroup: null,
            taxonomicCategory: null,
            taxon: '',
            project: '',
            //FR RV SO
            oligoAndProbeData: [{
                    tabName: "Forward",
                    icon: "mdi-arrow-right",
                    tag: "FW",
                    name: '',
                    sequence: '',
                    gc: null,
                    tm: null,
                    hairpin: null,
                    selfDimer: null,
                },
                {
                    tabName: "Reverse",
                    icon: "mdi-arrow-left",
                    tag: "RV",
                    name: '',
                    sequence: '',
                    gc: null,
                    tm: null,
                    hairpin: null,
                    selfDimer: null,
                },
                {
                    tabName: "Sonda",
                    icon: 'mdi-radar',
                    tag: "PR",
                    name: '',
                    sequence: '',
                    gc: null,
                    tm: null,
                    hairpin: null,
                    selfDimer: null,
                },
            ],
            //AMPLICON
            ampliconSize: null,
            controlStrain: '',
            ampliconSequence: '',
            ampliconGC: null,
            secondaryStructure: null,
            //NOTES
            considerations: '',
        },
        status: [{
                text: "Pendiente",
                value: "PENDING"
            },
            {
                text: "Diseño",
                value: "DESIGNING"
            }
        ],
        markerType: [{
                text: "Taxonómico",
                value: "MO"
            },
            {
                text: "Funcional",
                value: "FNC"
            },
            {
                text: "Taxonómico/Funcional",
                value: "MO_FNC"
            },
        ],
        taxonomicTypes: [{
                text: "Planta",
                value: "plant"
            },
            {
                text: "Bacteria",
                value: "bacteria"
            },
            {
                text: "Hongo",
                value: "fungi"
            },
            {
                text: "Nematodo",
                value: "nematode"
            },
            {
                text: "Insecto",
                value: "insect"
            },
            {
                text: "Virus",
                value: "virus"
            },
            {
                text: "Arqueas",
                value: "archaea"
            },
        ],
        texonomicCategories: [{
                text: "Dominio",
                value: "domain"
            },
            {
                text: "Phylum",
                value: "phylum"
            },
            {
                text: "Familia",
                value: "family"
            },
            {
                text: "Género",
                value: "gender"
            },
            {
                text: "Especie",
                value: "species"
            },
        ],
        strengthLevels: [{
                text: "Fuerte",
                value: "strong"
            },
            {
                text: "Medio",
                value: "mid"
            },
            {
                text: "Débil",
                value: "weak"
            },
        ],
        searchIdentification: null,
        identifications: [],
        identificationsLoading: false,
        searchSample: null,
        sampleBankList: [],
        loadingSamples: false,
        //SOILS
        soilSelected: [],
        soilSelection: null,
        symbiosis: [{
                text: "Patógeno",
                value: "pathogen"
            },
            {
                text: "Benéfico",
                value: "benefic"
            },
        ],
        validationMarker: [(v) => !!v || 'El campo es requerido']
    }),
    props: {
        value: Boolean,
    },
    computed: {
        markerStatusColor() {
            switch (this.formData.status) {
                case 'PENDING':
                    return 'orange'
                case 'DESIGNING':
                    return 'blue'
                case 'FINISHED':
                    return 'secondary'
                default:
                    return 'grey'
            }
        },
        currentStatusLabel() {
            const status = this.status.find(s => s.value === this.formData.status)
            return status ? status.text : 'No Seleccionado'
        },
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        ...mapActions("molecularMarker", ["getMolecularMarkerByName", "createNewMarkerWithDraft"]),
        ...mapActions("soilStore", ["getSamplesByFolio"]),
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        ...mapActions("identification", ["searchIdentifications"]),
        closeModal() {
            this.cleanForm()
            this.show = false;
        },
        validation(name) {
            return requiredValidation(name);
        },
        async confirmation() {
            try {
                if (this.$refs.form.validate()) {
                    this.isLoading = true
                    let payload = {
                        process: this.formData.process,
                        molecularMarkerID: this.formData.molecularMarkerID,
                        identification: this.formData.identification,
                        markerType: this.formData.markerType,
                        symbiosis: this.formData.symbiosis,
                        soilFunctionEN: this.formData.soilFunctionEN,
                        statusDesign: this.formData.status,
                        locus: this.formData.locus,
                        taxonomicGroup: this.formData.taxonomicGroup,
                        taxonomicCategory: this.formData.taxonomicCategory,
                        taxa: this.formData.taxon,
                        project: this.formData.project,
                        oligoAndProbeData: this.formData.oligoAndProbeData,
                        ampliconSize: this.formData.ampliconSize,
                        strainControl: this.formData.controlStrain,
                        amplicon: this.formData.ampliconSequence,
                        gcAmplicon: this.formData.ampliconGC,
                        secondaryStructure: this.formData.secondaryStructure,
                        notes: this.formData.considerations,
                        validationSoils: [],
                    }
                    for (const sample of this.soilSelected) {
                        payload.validationSoils.push({
                            _sampleId: sample._id,
                            folio: sample.folioAnalysis,
                            crop: sample.cropCycle ? sample.cropCycle.crop.cropName : null,
                        })
                    }
                    await this.createNewMarkerWithDraft(payload)
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: `Nuevo marcador agregado`,
                        timeout: 5000,
                    });
                    this.cleanForm()
                    this.$emit("close_update")
                }
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.isLoading = false;
        },
        validationStatus() {
            return this.formData.status ? this.formData.status !== 'DESIGNING' : true
        },
        validationBasicStatus() {
            return this.formData.status ? false : true
        },
        async getIdentifications(value) {
            if (value) {
                try {
                    this.identificationsLoading = true;
                    const response = await this.searchIdentifications({
                        name: value,
                    });
                    if (response.error) throw response;
                    this.identifications = response.IDENTIFICATIONS;
                } catch (error) {
                    console.log(error);
                }
                this.identificationsLoading = false;
            } else {
                this.identifications = [];
            }
        },
        removeItem(index) {
            this.soilSelected.splice(index, 1);
        },
        updateItems() {
            if (this.soilSelection && !this.soilSelected.some(element => element.folioAnalysis === this.soilSelection.folioAnalysis)) {
                this.soilSelected.push(this.soilSelection)
                this.soilSelection = null
                this.$refs["searchSampleInput"].reset();
            } else {
                this.soilSelection = null
                this.$refs["searchSampleInput"].reset();
            }
        },
        async verifyMarker() {
            try {
                if (this.formData.identification && this.formData.symbiosis) {
                    let nameMo = null
                    if (typeof this.formData.identification == "object") {
                        //identification already in system
                        nameMo = this.formData.identification.name
                    } else {
                        //new identification name
                        nameMo = this.formData.identification
                    }
                    let payload = {
                        name: nameMo,
                        symbiosis: this.formData.symbiosis
                    }

                    let response = await this.getMolecularMarkerByName(payload)
                    if (response.molecularMarker) {
                        //add validation 
                        this.validationMarker.push((v) => false || 'El marcador ya existe')
                        this.$nextTick(() => {
                            this.$refs.microorganismField.validate();
                            this.$refs.symbiosisField.validate();
                        });
                    } else {
                        this.validationMarker = [(v) => !!v || 'El campo es requerido']
                        this.$nextTick(() => {
                            this.$refs.microorganismField.validate();
                            this.$refs.symbiosisField.validate();
                        });
                    }
                }
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
        },
        cleanForm() {
            this.formData = {
                //initial information
                status: null,
                markerType: null,
                identification: null,
                symbiosis: null,
                soilFunctionEN: null,
                locus: '',
                taxonomicGroup: null,
                taxonomicCategory: null,
                taxon: '',
                project: '',
                //FR RV SO
                oligoAndProbeData: [{
                        tabName: "Forward",
                        icon: "mdi-arrow-right",
                        tag: "FW",
                        name: '',
                        sequence: '',
                        gc: null,
                        tm: null,
                        hairpin: null,
                        selfDimer: null,
                    },
                    {
                        tabName: "Reverse",
                        icon: "mdi-arrow-left",
                        tag: "RV",
                        name: '',
                        sequence: '',
                        gc: null,
                        tm: null,
                        hairpin: null,
                        selfDimer: null,
                    },
                    {
                        tabName: "Sonda",
                        icon: 'mdi-radar',
                        tag: "PR",
                        name: '',
                        sequence: '',
                        gc: null,
                        tm: null,
                        hairpin: null,
                        selfDimer: null,
                    },
                ],
                //AMPLICON
                ampliconSize: null,
                controlStrain: '',
                ampliconSequence: '',
                ampliconGC: null,
                secondaryStructure: null,
                //NOTES
                considerations: '',
            }
            this.panels = [0, 1, 2, 3, 4]
            this.searchIdentification = null
            this.identifications = []
            this.searchSample = null
            this.sampleBankList = []
            this.soilSelected = []
            this.soilSelection = null
            this.validationMarker = [(v) => !!v || 'El campo es requerido']
            this.$refs.form.reset()
        },
    },
    watch: {
        async searchIdentification(value) {
            await this.getIdentifications(value);
        },
        async searchSample(newValue) {
            if (!newValue) return;
            try {
                this.loadingSamples = true;
                let payload = {
                    search: newValue,
                };
                let response = await this.getSamplesByFolio(payload);
                this.sampleBankList = response.samples;
                console.log(response.samples);

            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.loadingSamples = false;
        },
    },
};
</script>

<style scoped>
.v-divider {
    margin: 20px 0;
}
</style>
