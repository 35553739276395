<template>
  <v-dialog v-model="show" width="40vw" scrollable>
    <v-card height="auto" elevation="5" class="rounded-xl" min-height="200">
      <v-card-title class="text-h5 lighten-2">
        {{translations.time_records}}
      </v-card-title>
      <v-card-text>
        <div v-if="isLoading">
          <v-row class="text-start mt-2">
            <v-col>
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <span class="font-weight-bold font-italic ml-5"
                >{{ translations.loading_events }}...</span
              >
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item
              fill-dot
              v-for="(log, i) in logs"
              :key="i"
              :color="log.color"
              :icon="
                log.type == 'PCT'
                  ? 'mdi-test-tube'
                  : log.type == 'SEND'
                  ? 'mdi-send-clock'
                  : log.type == 'RECEIVED'
                  ? 'mdi-hand-extended'
                  : log.type.includes('MB_')
                  ? 'mdi-microscope'
                  : log.type.includes('BCT')
                  ? 'mdi-leaf-circle-outline'
                  : 'mdi-dna'
              "
            >
              <v-card color="primary" dark>
                <v-card-title class="text-subtitle-1">
                  {{ getType(log.type) }}</v-card-title
                >
                <v-card-text class="white text--primary">
                  <v-row no-gutters>
                    <v-col cols="12"
                      ><span class="text-caption"
                        ><span class="font-weight-bold font-italic">{{ translations.hour }}:</span>
                        {{ dateFormat(log.date) }}</span
                      ></v-col
                    >
                    <v-col cols="12"
                      ><span class="text-caption">
                        <span class="font-weight-bold font-italic"
                          >{{translations.user}}:</span
                        >
                        {{ log.user.name }} {{ log.user.lastName }}</span
                      ></v-col
                    >
                    <template
                      v-if="
                        log.type == 'RECEPTION' ||
                        log.type == 'SEND' ||
                        log.type == 'RECEIVED'
                      "
                    >
                      <v-col cols="12"
                        ><span class="text-caption">
                          <span class="font-weight-bold font-italic"
                            >{{translations.origin}}:</span
                          >
                          {{ log.origin.name }}, {{ log.origin.city }},
                          {{ log.origin.state }},{{ log.origin.country }},
                          {{ log.origin.zipCode }}</span
                        ></v-col
                      >
                      <v-col cols="12" v-if="log.to"
                        ><span class="text-caption">
                          <span class="font-weight-bold font-italic"
                            >{{translations.destination}}:</span
                          >
                          {{ log.to.name }}, {{ log.to.city }},
                          {{ log.to.state }},{{ log.to.country }},
                          {{ log.to.zipCode }}</span
                        ></v-col
                      >
                    </template>

                    <template
                      v-if="
                        log.type == 'SEQUENCE_ADD' ||
                        log.type == 'SEQUENCE_REPORT' || 
                        log.type == 'SEQUENCE_RESULT_PLATE'
                      "
                    >
                      <v-col>
                        <span class="text-caption">
                          <span class="font-weight-bold font-italic">
                            {{translations.name}}:
                          </span>
                          {{ log.sequencingReportId.name }}
                        </span>
                        <v-spacer></v-spacer>
                        <span class="text-caption">
                          <span class="font-weight-bold font-italic">
                            ID:
                          </span>
                          {{ log.sequencingReportId.plate.folio }}
                        </span>
                      </v-col>
                    </template>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
          <div v-if="!isLoading && logs.length == 0">
            <v-row class="text-center mt-2">
              <v-col>
                <span class="font-weight-bold font-italic"
                  >{{translations.no_event_records}}</span
                >
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
//Vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
//Libraries
import dayjs from "dayjs";
export default {
  data: () => ({
    isLoading: false,
    logs: [],
    analysisFound: null,
  }),
  props: {
    value: Boolean,
    analysis: {
      type: String,
      require: false,
    },
  },
  computed: {
    ...mapGetters("session", ["currentTranslations"]),
    translations() {
      return this.currentTranslations;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("reception", ["getTimeLineAnalysis"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    getType(value) {
      let values = {
        RECEPTION: this.translations.reception,
        SEND: this.translations.shipment,
        RECEIVED: this.translations.received,
        PCT: this.translations.physicochemical,
        MB_INIT: this.translations.measurement_start,
        MOL_INIT: this.translations.molecular_identification_start,
        BCT_INIT: this.translations.evaluation_start,
        MB_END: this.translations.measurement_end,
        MOL_END: this.translations.molecular_identification_end,
        BCT_END: this.translations.evaluation_end,
        SEQUENCE_ADD: this.translations.added_to_plate_sequence,
        SEQUENCE_REPORT: this.translations.report_generated,
        SEQUENCE_RESULT_PLATE: this.translations.result_added_to_plate,
      };

      return values[value];
    },
    dateFormat(value) {
      if (value) {
        const date = `${dayjs(value).format("DD-MM-YYYY - HH:mm")} hrs`;
        return date ? date : "N/A";
      } else {
        return "N/A";
      }
    },
  },
  async mounted() {
    if (this.analysis) {
      try {
        this.isLoading = true;
        let response = await this.getTimeLineAnalysis({ id: this.analysis });
        if (response) {
          if (!response.error) {
            this.logs = response.logs;
            this.analysisFound = response.analysis;
            this.isLoading = false;
          } else {
            throw response;
          }
        } else {
          throw response;
        }
      } catch (e) {
        this.isLoading = false;
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `${this.translations.somethingWentWrong} ${error.message}`,
          timeout: 5000,
        });
      }
    }
  },
  watch: {
    async show(value) {
      if (value) {
        if (this.analysis) {
          try {
            this.isLoading = true;
            let response = await this.getTimeLineAnalysis({
              id: this.analysis,
            });
            if (response) {
              if (!response.error) {
                this.logs = response.logs;
                this.analysisFound = response.analysis;
                this.isLoading = false;
              } else {
                throw response;
              }
            } else {
              throw response;
            }
          } catch (e) {
            this.isLoading = false;
            console.log(e);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: `${this.translations.somethingWentWrong} ${error.message}`,
              timeout: 5000,
            });
          }
        }
      }
    },
  },
};
</script>
