<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" class="text-start">
        <v-btn color="primary" @click="goBack">
          <v-icon> mdi-arrow-left-thick </v-icon>
          Atras
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-container>
          <v-row>
            <v-col>
              <v-autocomplete
                dense
                outlined
                label="Tipo de marcador"
                v-model="markerType"
                :items="markersTypes"
                item-text="name"
                return-object
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-form lazy-validation ref="form">
            <v-row v-if="markerType && markerType.value == 'taxonomico'">
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="Ingresa ID marcador"
                  clearable
                  v-model="form.molecularMarkerID"
                  :rules="validate('ID')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  dense
                  outlined
                  label="Selecciona microorganismo"
                  clearable
                  :search-input.sync="searchIdentification"
                  :items="identifications"
                  item-text="name"
                  :loading="identificationsLoading"
                  v-model="form.identification"
                  return-object
                  :rules="validate('microorganismo')"
                  :disabled="disabledUpdate"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-switch
                  dense
                  outlined
                  label="Patógeno"
                  clearable
                  v-model="form.isPathogen"
                  :disabled="disabledUpdate"
                >
                </v-switch>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="Ingresa ID marcador"
                  clearable
                  v-model="form.molecularMarkerID"
                  :rules="validate('ID')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="Ingresa función en suelo (ES)"
                  clearable
                  v-model="form.soilFunctionES"
                  :rules="validate('función')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="Ingresa función en suelo (EN)"
                  clearable
                  v-model="form.soilFunctionEN"
                  :rules="validate('función')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="Ingresa ruta metabólica"
                  clearable
                  v-model="form.metabolicPathway"
                  :rules="validate('ruta metabólica')"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="body-2 font-weight-bold">Rango de Cq's</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="CQ mínimo"
                  type="number"
                  clearable
                  v-model="form.cqMin"
                  :rules="validate('CQ mínimo')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="CQ maximo"
                  type="number"
                  clearable
                  v-model="form.cqMax"
                  :rules="validate('CQ maximo')"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-end">
              <v-col cols="2">
                <v-btn color="primary" block @click="saveData">
                  <v-icon>mdi-content-save</v-icon>
                  Guardar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
// validations
import { requiredValidation } from "@/helpers/validations.js";
export default {
  data: () => ({
    searchIdentification: null,
    identificationsLoading: false,
    identifications: [],
    markerType: null,
    markersTypes: [
      {
        name: "Taxonómico",
        value: "taxonomico",
      },
      {
        name: "Funcionales",
        value: "functional",
      },
    ],
    form: {
      identification: null,
      molecularMarkerID: null,
      isPathogen: false,
      cqMax: null,
      cqMin: null,
      soilFunctionES: null,
      soilFunctionEN: null,
      metabolicPathway: null,
    },
    disabledUpdate: false,
  }),
  computed: {
    ...mapState("laboratory", ["molecularMarkerToUpdate"]),
  },
  async mounted() {
    if (this.molecularMarkerToUpdate) {
      this.disabledUpdate = true;
      await this.mapContent();
    } else {
      if (this.markersTypes.length > 0) {
        this.markerType = this.markersTypes[0];
      }
    }
  },
  methods: {
    ...mapActions("identification", ["searchIdentifications"]),
    ...mapActions("laboratory", ["createMarker", "updateMarker"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async saveData() {
      try {
        if (this.$refs.form.validate()) {
          let payload = {};
          if (this.markerType.value == "taxonomico") {
            payload = {
              molecularMarkerID: this.form.molecularMarkerID,
              microorganism_meta: {
                _id: this.form.identification._id,
                name: this.form.identification.name,
                moType: this.form.identification.moType,
              },
              markerType: "MO",
              isPathogen: this.form.isPathogen,
              cqMax: this.form.cqMax,
              cqMin: this.form.cqMin,
              version: 1,
            };
          } else {
            payload = {
              molecularMarkerID: this.form.molecularMarkerID,
              microorganism_meta: {},
              metabolicPathway: this.form.metabolicPathway,
              soilFunction: {
                es: this.form.soilFunctionES,
                en: this.form.soilFunctionEN,
              },
              markerType: "FNC",
              cqMax: this.form.cqMax,
              cqMin: this.form.cqMin,
              version: 1,
            };
          }

          if (this.molecularMarkerToUpdate) {
            //update marker
            delete payload.microorganism_meta;
            delete payload.markerType;
            payload.version = this.molecularMarkerToUpdate.version;
            let request = {
              body: payload,
              params: { markerId: this.molecularMarkerToUpdate._id },
            };
            await this.updateMarker(request);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: `Marcador actualizado con exito`,
              timeout: 5000,
            });
            this.goBack();
          } else {
            //create new one
            await this.createMarker(payload);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: `Marcador creado con exito`,
              timeout: 5000,
            });
            this.goBack();
          }
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
    },
    goBack() {
      this.$emit("back_update");
    },
    validate(name) {
      return requiredValidation(name);
    },
    async mapContent() {
      if (this.molecularMarkerToUpdate.markerType == "MO") {
        this.markerType = this.markersTypes[0];
        await this.getIdentifications(
          this.molecularMarkerToUpdate.microorganism_meta.name
        );
        this.form.identification = this.identifications.find(
          (element) =>
            element._id == this.molecularMarkerToUpdate.microorganism_meta._id
        );
        this.form.molecularMarkerID =
          this.molecularMarkerToUpdate.molecularMarkerID;
        this.form.isPathogen = this.molecularMarkerToUpdate.isPathogen;
        this.form.cqMin = this.molecularMarkerToUpdate.cqMin;
        this.form.cqMax = this.molecularMarkerToUpdate.cqMax;
      } else {
        this.markerType = this.markersTypes[1];
        this.form.soilFunctionES = this.molecularMarkerToUpdate.soilFunction.es;
        this.form.soilFunctionEN = this.molecularMarkerToUpdate.soilFunction.en;
        this.form.metabolicPathway =
          this.molecularMarkerToUpdate.metabolicPathway;
        this.form.cqMin = this.molecularMarkerToUpdate.cqMin;
        this.form.cqMax = this.molecularMarkerToUpdate.cqMax;
      }
    },
    async getIdentifications(value) {
      if (value) {
        try {
          this.identificationsLoading = true;
          const response = await this.searchIdentifications({
            name: value,
          });
          if (response.error) throw response;
          this.identifications = response.IDENTIFICATIONS;
        } catch (error) {
          console.log(error);
        }
        this.identificationsLoading = false;
      } else {
        this.identifications = [];
      }
    },
  },
  watch: {
    async searchIdentification(value) {
      await this.getIdentifications(value);
    },
  },
};
</script>
