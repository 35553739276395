<template>
<v-dialog v-model="show" :max-width="dialogWidth" scrollable persistent>
    <v-card elevation="5" class="rounded-lg">
        <v-card-title class="text-h5 lighten-2">Replicas por selección
        </v-card-title>
        <v-card-text>
            <v-form lazy-validation ref="form">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="replicatesQuantity" label="Ingresa número de replicas" hide-no-data outlined dense clearable type="number" :rules="validation()"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="folio" label="Ingresa folio/nombre de suelo" hide-no-data outlined dense clearable type="text" :rules="validation()"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-container fluid>
                <v-row>
                    <v-col cols="">
                        <v-btn color="red" block @click="closeModal" dark>Cancelar</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn color="primary" block @click="confirmation">Aceptar</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

    
    
<script>
//vuex
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
//validations
import {
    requiredValidation
} from "@/helpers/validations.js";
export default {
    data: () => ({
        replicatesQuantity: null,
        folio: null
    }),
    props: {
        value: Boolean,
    },
    computed: {
        dialogWidth() {
            if (this.$vuetify.breakpoint.xs) return "90vw"; // Extra pequeño
            if (this.$vuetify.breakpoint.sm) return "70vw"; // Pequeño
            if (this.$vuetify.breakpoint.md) return "60vw"; // Mediano
            return "50vw"; // Grande en adelante
        },
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        closeModal() {
            this.show = false;
        },
        validation(name) {
            return requiredValidation(name);
        },
        async confirmation() {
            if (this.$refs.form.validate()) {
                this.$emit("addQuantity", {
                    quantity: this.replicatesQuantity,
                    item: {
                        folioAnalysis: this.folio
                    }
                });
                this.replicatesQuantity = null
                this.$refs.form.reset()
            }
        },
    },
    watch: {},
};
</script>
