<template>
  <div>
    <v-data-table
      dense
      height="100%"
      v-model="checkBoxesSelected"
      :headers="headers"
      :items="bcTestsFiltered"
      :item-class="row_classes"
      show-select
      item-key="_id"
      class="elevation-0"
      hide-default-footer
      :items-per-page="10"
      @click:row="rowSelect"
      :loading="loading"
    >
      <!-- analysisType -->
      <template v-slot:[`item.analysisType`]="{ item }">
        <p class="font-weight-bold mb-0">
          {{ item.analysisType }}
        </p>
      </template>

      <template v-slot:[`item.inoculum`]="{ item }">
        <p class="mb-0 text-center" v-if="item.analysisType != 'Confrontación'">
          <template v-if="item.strain.molecularTest">
            🧬{{ item.inoculum }}
          </template>
          <template v-else-if="item.strain.bioactiveBank">
            <span
              ><img
                src="/favicon.png"
                style="vertical-align: text-bottom"
                width="20px"
                height="20px"
              />{{ item.inoculum }}
            </span>
          </template>
          <template v-else>
            {{ item.inoculum }}
          </template>
        </p>
        <p class="mb-0 text-center" v-else>
          <strong
            class="warning--text"
            v-if="item.testData.biochemicalTest.isolate.strain.molecularTest"
            >🧬 {{ item.inoculumControl }}</strong
          >
          <strong
            class="warning--text"
            v-else-if="
              item.testData.biochemicalTest.isolate.strain.bioactiveBank
            "
          >
            <span
              ><img
                src="/favicon.png"
                style="vertical-align: text-bottom"
                width="20px"
                height="20px"
              />{{ item.inoculumControl }}
            </span>
          </strong>
          <strong class="warning--text" v-else>{{
            item.inoculumControl
          }}</strong>
          vs
          <template v-if="item.testData.beneficalStrain.molecularTest"
            >🧬 {{ item.inoculumBeneficial }}
          </template>
          <template v-else-if="item.testData.beneficalStrain.bioactiveBank">
            <span
              ><img
                src="/favicon.png"
                style="vertical-align: text-bottom"
                width="20px"
                height="20px"
              />{{ item.inoculumBeneficial }}
            </span>
          </template>
          <template v-else> {{ item.inoculumBeneficial }} </template>
        </p>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <p
          class="font-weight-bold text-caption warning--text mb-0"
          v-if="item.status.includes('Pendiente')"
        >
          {{ item.status }}
        </p>
        <p
          class="font-weight-bold text-caption secondary--text mb-0"
          v-else-if="item.status.includes('Recibida')"
        >
          {{ item.status }}
        </p>
        <p
          class="font-weight-bold text-caption primary--text mb-0"
          v-else-if="item.status.includes('Preservada')"
        >
          {{ item.status }}
        </p>
        <p class="font-weight-bold text-caption mb-0" v-else>
          {{ item.status }}
        </p>
      </template>
      <template slot="no-data">
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="10"> No hay ensayos para mostrar </v-col>
          </v-row>
        </v-container>
      </template>
    </v-data-table>
    <v-pagination
      v-model="page"
      @input="updatePagination"
      :length="length"
      total-visible="12"
      color="primary"
    ></v-pagination>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import axios from "axios";

export default {
  props: {
    search: {
      type: String,
      require: false,
    },
    reload: {
      type: Boolean,
      require: true,
    },
    active: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      loading: false,
      menuValuations: false,
      checkBoxesSelected: [],
      selected: null,
      headers: [
        { text: "Folio de análisis", sortable: false, value: "folioAnalysis" },
        { text: "ID de ensayo", sortable: false, value: "idTest" },
        {
          text: "Inóculo",
          sortable: false,
          value: "inoculum",
          align: "center",
        },
        { text: "Tipo de muestra", sortable: false, value: "sampleType" },
        {
          text: "Razón social del cliente",
          sortable: false,
          value: "businessName",
        },
        { text: "Tipo de análisis", sortable: false, value: "analysisType" },
        { text: "Laboratorio", sortable: false, value: "laboratory" },
        { text: "Estatus del ensayo", sortable: false, value: "status" },
      ],
      dataTestsArray: [],
      showDataTest: true,
      showAddDataTest: false,
      showFungiSelected: false,
      showBacteriaSelected: false,
      showReseedingSelected: false,
      showControlTest: false,
      showAntagonismTest: false,
      showNutritionTest: false,
      showBioEstimulationTest: false,
      selectedRow: null,
      itemsBQ: [
        { title: "Antagonismo" },
        { title: "Nutrición" },
        { title: "Bioestimulación" },
      ],
      user: JSON.parse(localStorage.getItem("user")),
      page: 1,
      countPagination: 0,
      pagination: {
        skip: 0,
        limit: 10,
        skipContaminated: 0,
        limitContaminated: 2,
      },
      strainSelected: null,
      isolate: null,
    };
  },
  mounted() {
    this.page = parseInt(this.$route.params.page);
  },
  watch: {
    selected() {
      this.showBioEstimulationTest = false;
      this.showAntagonismTest = false;
      this.showControlTest = false;
      this.showNutritionTest = false;
      this.showDataTest = false;
      this.showAddDataTest = false;
      this.showFungiSelected = false;
      this.showBacteriaSelected = false;
      this.showReseedingSelected = false;

      if (this.selected.analysisType != "Resiembra") {
        if (this.selected.analysisType == "Control") {
          this.showControlTest = true;
        } else if (this.selected.analysisType == "Confrontación") {
          this.showAntagonismTest = true;
        } else if (this.selected.analysisType.includes("S.")) {
          this.showNutritionTest = true;
        } else if (
          this.selected.analysisType.includes("P.") ||
          this.selected.analysisType.includes("A.")
        ) {
          this.showBioEstimulationTest = true;
        } else {
          this.showDataTest = true;
        }
        this.isolate = null;
        this.strainSelected = null;
      } else {
        // Fungi reseed
        if (this.selected.strain.identificationBCTest == undefined) {
          this.showFungiSelected = true;

          // Bacteria reseed
        } else {
          this.showBacteriaSelected = true;
        }
        this.strainSelected = this.selected.strain;
        this.isolate = this.selected.isolate;
      }
    },
    async search(value) {
      this.checkBoxesSelected = [];
      this.pagination.skip = 0;
      this.pagination.skipContaminated = 0;
      this.page = 1;

      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }

      //Save the cancel token for the current request
      this.SET_CANCEL_TOKEN(axios.CancelToken.source());
      if (this.search) {
        await this.getMBTests(this.search);
      } else {
        await this.getMBTests();
      }
    },
    async menuValuations() {
      if (this.menuValuations) {
        await this.getBioactiveBankStrains();
      }
    },
    page() {
      if (this.page != parseInt(this.$route.params.page)) {
        this.$router.replace({
          params: {
            page: this.page,
          },
        });
      }
    },
    checkBoxesSelected: {
      handler() {
        this.$emit("testChecked", this.checkBoxesSelected);
      },
      deep: true,
    },
    async reload() {
      await this.reloadDataTable();
    },
    active() {
      this.checkBoxesSelected = [];
    },
  },
  async created() {
    if (this.$route.params.page) {
      this.page = parseInt(this.$route.params.page);
    } else {
      this.page = 1;
    }

    this.pagination.skip = (this.page - 1) * 10;
    await this.getMBTests();
  },
  methods: {
    ...mapMutations(["SET_CANCEL_TOKEN"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapMutations("microbiologicalTest", ["SET_MBTEST_SELECTED"]),
    ...mapActions("strain", [
      "getStrainById",
      "removeStrain",
      "getBioactiveBankStrains",
    ]),
    ...mapActions("isolate", ["updateIsolate", "getPreservedIsolatesListS3"]),
    ...mapActions("testMedia", ["updateTestMedia"]),
    async getMBTests(search = undefined) {
      this.loading = true;
      console.log("GET ISOLATES");
      try {
        console.log({
          laboratory: this.user.laboratory._id,
          skip: this.pagination.skip,
          limit: this.pagination.limit,
          status: ["INCUBATING", "COMPLETED", "IN_PROGRESS"],
          type: "BC",
          search,
          ...JSON.parse(this.filters),
        });
        await this.getPreservedIsolatesListS3({
          laboratory: this.user.laboratory._id,
          skip: this.pagination.skip,
          limit: this.pagination.limit,
          status: ["INCUBATING", "COMPLETED", "IN_PROGRESS"],
          type: "BC",
          search,
          ...JSON.parse(this.filters),
        });
      } catch (error) {
        console.log(error);

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Error al obtener las resiembras",
          timeout: 4000,
        });
      }

      this.countPagination = this.count;
      await this.formatDataTable();

      this.loading = false;
    },
    async formatDataTable() {
      let mbTests = this.isolatesList;
      let mbTestsArray = [];
      console.log(this.isolatesList);
      for (const test of mbTests) {
        let isolateData = null;

        if (test.isolate.isolateType == "Preserved") {
          isolateData = {
            _id: test.isolate._id,
            folioAnalysis:
              test.soilSample &&
              test.soilSample.container &&
              test.soilSample.container.reception &&
              test.soilSample.container.reception.folioAnalysis
                ? test.soilSample.container.reception.folioAnalysis
                : "---" || (test.soilSample && test.soilSample.kit) || "---",
            idTest: test.isolate.testMedia ? test.isolate.testMedia._id : "NA",
            medium: "NA",
            inoculum: test.isolateStrain.identification
              ? test.isolateStrain.identification.name
              : "Sin identificación",
            sampleType: this.getSampleType(test.analysis),
            businessName: test.soilSample.container.client.companyName,
            analysisType: "Resiembra",
            status:
              test.isolate.ceparium_data.status == "PENDING"
                ? `Pendiente ${this.getDateFormated(test.isolate.updatedAt)}`
                : test.isolate.ceparium_data.status == "SENT"
                ? `Enviada ${this.getDateFormated(test.isolate.updatedAt)}`
                : test.isolate.ceparium_data.status == "RECEIVED"
                ? `Recibida ${this.getDateFormated(test.isolate.updatedAt)}`
                : test.isolate.ceparium_data.status == "PRESERVED"
                ? `Preservada ${this.getDateFormated(test.isolate.updatedAt)}`
                : test.isolate.ceparium_data.status,
            mbTestData: test.isolateStrain.microbiologicalTest,
            molTestData: test.isolateStrain.molecularTest,
            isolate: test.isolate,
            strain: test.isolateStrain,
            clicked: false,
            laboratory: test.laboratory ? test.laboratory.name : null,
            laboratory_id: test.laboratory ? test.laboratory._id : null,
          };
        } else if (test.isolate.isolateType == "Pure") {
          isolateData = {
            _id: test.isolate._id,
            folioAnalysis:
              test.soilSample &&
              test.soilSample.container &&
              test.soilSample.container.reception &&
              test.soilSample.container.reception.folioAnalysis
                ? test.soilSample.container.reception.folioAnalysis
                : "---" || (test.soilSample && test.soilSample.kit) || "---",
            idTest: test.isolate.testMedia ? test.isolate.testMedia._id : "NA",
            medium: "NA",
            inoculum: test.isolateStrain.identification
              ? test.isolateStrain.identification.name
              : "Sin identificación",
            sampleType: this.getSampleType(test.analysis),
            businessName: test.soilSample.container.client.companyName,
            analysisType: "Resiembra",
            status:
              test.isolate.ceparium_data.status == "PENDING"
                ? `Pendiente ${this.getDateFormated(test.isolate.updatedAt)}`
                : test.isolate.ceparium_data.status == "SENT"
                ? `Enviada ${this.getDateFormated(test.isolate.updatedAt)}`
                : test.isolate.ceparium_data.status == "RECEIVED"
                ? `Recibida ${this.getDateFormated(test.isolate.updatedAt)}`
                : test.isolate.ceparium_data.status == "PRESERVED"
                ? `Preservada ${this.getDateFormated(test.isolate.updatedAt)}`
                : test.isolate.ceparium_data.status,
            mbTestData: test.isolateStrain.microbiologicalTest,
            molTestData: test.isolateStrain.molecularTest,
            isolate: test.isolate,
            strain: test.isolateStrain,
            clicked: false,
            laboratory: test.laboratory ? test.laboratory.name : null,
            laboratory_id: test.laboratory ? test.laboratory._id : null,
          };
        } else {
          isolateData = {
            _id: test.isolate._id,
            folioAnalysis:
              test.soilSample &&
              test.soilSample.container &&
              test.soilSample.container.reception &&
              test.soilSample.container.reception.folioAnalysis
                ? (
                    test.soilSample &&
                    test.soilSample.container &&
                    test.soilSample.container.reception &&
                    test.soilSample.container.reception.folioAnalysis
                  ).slice(0, -2)
                : "---" || (test.soilSample && test.soilSample.kit) || "---",
            idTest: test.isolate.testMedia
              ? test.isolate.testMedia._id
              : `Legacy-${test.isolate.serialNumberStrain}`,
            medium: test.isolate.testMedia
              ? test.isolate.testMedia.cultureMedia.nutrientAgar
              : `Legacy-${test.isolate.serialNumberStrain}`,
            inoculum: test.isolateStrain.identification
              ? test.isolateStrain.identification.name
              : "Sin identificación",
            sampleType: this.getSampleType(test.analysis),
            businessName: test.soilSample.container.client.companyName,
            analysisType: "Control",
            status:
              test.isolate.ceparium_data &&
              test.isolate.ceparium_data.status == "PENDING"
                ? `Pendiente ${this.getDateFormated(test.isolate.updatedAt)}`
                : test.isolate.ceparium_data &&
                  test.isolate.ceparium_data.status == "SENT"
                ? `Enviada ${this.getDateFormated(test.isolate.updatedAt)}`
                : test.isolate.ceparium_data &&
                  test.isolate.ceparium_data.status == "RECEIVED"
                ? `Recibida ${this.getDateFormated(test.isolate.updatedAt)}`
                : test.isolate.ceparium_data &&
                  test.isolate.ceparium_data.status == "PRESERVED"
                ? `Preservada ${this.getDateFormated(test.isolate.updatedAt)}`
                : "Pendiente",
            mbTestData: test.isolateStrain.microbiologicalTest,
            molTestData: test.isolateStrain.molecularTest,
            isolate: test.isolate,
            strain: test.isolateStrain,
            clicked: false,
            laboratory: test.laboratory ? test.laboratory.name : null,
            laboratory_id: test.laboratory ? test.laboratory._id : null,
          };
        }

        mbTestsArray.push(isolateData);
      }

      if (mbTests.length > 0) {
        mbTestsArray[0].clicked = true;
        this.selected = mbTestsArray[0];
        this.SET_MBTEST_SELECTED(mbTestsArray[0].mbTestData);
        this.dataTestsArray = mbTestsArray;
        console.log(mbTestsArray);
        this.$emit("testSelected", this.selected);
      } else {
        this.dataTestsArray = [];
      }
    },
    getTestType(test) {
      let types = [
        { text: "production_siderophores", value: "P. Sideroforos" },
        { text: "production_indoleaceticAcid", value: "P. AIA" },
        { text: "cellulolytic_activity", value: "A. Celulolítica" },
        { text: "phosphorum_solubilization", value: "S. Fósforo" },
        { text: "potassium_solubilization", value: "S. Potasio" },
      ];

      let type = types.find((type) => type.text == test);

      return type.value;
    },
    getSampleType(value) {
      const analysisTypes = {
        INITIAL: "Inicial",
        MONITORING: "Seguimiento",
      };

      if (value) {
        return analysisTypes[value.type];
      } else {
        return "Inicial";
      }
    },
    async updatePagination(page) {
      try {
        this.page = page;
        this.pagination.skip = (page - 1) * 10;

        if (typeof this.cancelToken != typeof undefined) {
          this.cancelToken.cancel("Operation canceled due to new request.");
        }

        //Save the cancel token for the current request
        this.SET_CANCEL_TOKEN(axios.CancelToken.source());
        await this.getMBTests(this.search);
      } catch (error) {
        console.error("Error", error);
      }
    },
    getMoType(value) {
      let types = {
        all: "Todos",
        bacteria: "Bacteria",
        fungi: "Hongo",
        nematode: "Nemátodo",
      };
      return types[value];
    },
    showMbTestStrains() {
      if (this.isolate == null) {
        this.showDataTest = false;
        this.showBacteriaSelected = false;
        this.showAddDataTest = true;
      }
    },
    preAddStrain(type) {
      this.showAddDataTest = false;
      this.strainSelected = null;

      if (type == null) {
        this.showSection(this.selected.analysisType);
      } else {
        this.showSection(type == "fungi" ? "Hongo" : "Bacteria");
      }
    },
    async preUpdateStrain(_idStrain) {
      let response = await this.getStrainById(_idStrain);
      if (response.error) throw response;

      this.strainSelected = response.STRAIN;

      this.showAddDataTest = false;
      this.showSection(this.selected.analysisType);
    },
    async preDeleteStrain(_idStrain) {
      const response = await this.removeStrain({
        strainId: _idStrain,
        mbTestId: this.selected._id,
      });

      if (response.error) throw response;
      else
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Se ha eliminado la cepa correctamente",
          timeout: 4000,
        });
    },
    async reloadDataTable() {
      this.checkBoxesSelected = [];

      await this.getMBTests();
    },
    showSection(analisis) {
      if (analisis == "Hongo") {
        this.showFungiSelected = true;
      } else if (analisis == "Bacteria") {
        this.showBacteriaSelected = true;
      }
    },
    async rowSelect(rowValue) {
      // if(!rowValue.status.includes('Inoculado')){
      this.selectedRow = rowValue._id;
      this.SET_MBTEST_SELECTED(rowValue.mbTestData);
      this.selected = rowValue;
      this.selected.clicked = !this.selected.clicked;
      // }

      this.dataTestsArray = this.dataTestsArray.map((data) => {
        if (rowValue._id == data._id) {
          data.clicked = true;
        } else {
          data.clicked = false;
        }
        return data;
      });
      console.log(this.selected);
      this.$emit("testSelected", this.selected);
    },
    row_classes(value) {
      if (value.clicked) {
        return "custom-highlight-row";
      }
    },
    getDateFormated(dateString) {
      let date = new Date(dateString).toLocaleDateString("es-MX", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
      return `${date} hrs`;
    },
  },
  computed: {
    // ...mapState("biochemicalTest", ["biochemicalTestList", "count"]),
    ...mapState("isolate", ["isolatesList", "count"]),
    ...mapState(["cancelToken", "filters"]),
    // length of the paginator items
    length() {
      return Math.ceil(this.countPagination / 10);
    },
    bcTestsFiltered() {
      return this.dataTestsArray.map((x) => ({
        ...x,
        // isSelectable: !x.status.includes("Incubando"),
        isSelectable: true,
      }));
    },
  },
};
</script>

<style></style>
