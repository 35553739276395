<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn color="primary" block @click="updatePreserved">
          <v-icon> mdi-content-save </v-icon>
          Actualizar
        </v-btn>
      </v-col>
    </v-row>
    <v-card height="500" elevation="0" class="overflow-y-auto">
      <v-card-text>
        <v-row>
          <v-col>
            <span class="text-subtitle-1 font-weight-bold">
              Datos generales
            </span>
          </v-col>
        </v-row>
        <v-row v-if="preservedSelected.urlProject">
          <v-col>
            <span class="enlace mdi mdi-file-excel-box" @click="openLink">
              Enlace de projecto...
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="6">
            <v-text-field
              label="Origen"
              outlined
              dense
              clearable
              type="text"
              v-model="preservedSelected.companyName"
              :rules="validation()"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            v-if="
              preservedSelected.preservedType == 'bacteria' ||
              preservedSelected.preservedType == 'fungi'
            "
          >
            <v-text-field
              label="Fecha de inoculación"
              outlined
              dense
              clearable
              type="date"
              v-model="preservedSelected.inoculationDate"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            v-if="
              preservedSelected.preservedType == 'bacteria' ||
              preservedSelected.preservedType == 'fungi' ||
              preservedSelected.preservedType == 'nematode'
            "
          >
            <v-text-field
              label="Medio de cultivo"
              outlined
              dense
              clearable
              type="text"
              v-model="preservedSelected.cultureMedia"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            v-if="
              preservedSelected.preservedType == 'bacteria' ||
              preservedSelected.preservedType == 'fungi' ||
              preservedSelected.preservedType == 'nematode'
            "
          >
            <v-combobox
              :rules="validation()"
              v-model="identification"
              :search-input.sync="search"
              :items="identificationsFounded"
              :loading="isLoading"
              @keydown="changeIdentificationValue"
              label="Nombre microorganismo"
              item-text="name"
              clearable
              hide-no-data
              outlined
              dense
              disabled
            >
            </v-combobox>
          </v-col>
          <!-- <v-col
            cols="12"
            xl="6"
            v-if="
              preservedSelected.preservedType == 'bacteria' ||
              preservedSelected.preservedType == 'fungi'
            "
          >
            <v-text-field
              label="Número de cajas/tubos"
              outlined
              dense
              clearable
              type="text"
              v-model="preservedSelected.containerQty"
            ></v-text-field>
          </v-col> -->
          <v-col
            cols="12"
            xl="6"
            v-if="
              preservedSelected.preservedType == 'bacteria' ||
              preservedSelected.preservedType == 'fungi' ||
              preservedSelected.preservedType == 'nematode'
            "
          >
            <v-radio-group
              label="Confirmado/identificado"
              v-model="preservedSelected.identificationMedia"
            >
              <v-radio label="Microbiología" value="MB"></v-radio>
              <v-radio label="Molecular" value="MOL"></v-radio>
              <v-radio label="Otro" value="OTHER"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row
          v-if="
            preservedSelected.preservedType == 'bacteria' ||
            preservedSelected.preservedType == 'fungi' ||
            preservedSelected.preservedType == 'nematode'
          "
        >
          <v-col cols="12">
            <span class="text-subtitle-1 font-weight-bold">
              Confirmación de pureza
            </span>
          </v-col>
          <v-col cols="12" xl="6">
            <v-text-field
              label="Macroscópicamente axénica"
              outlined
              dense
              clearable
              type="text"
              v-model="preservedSelected.macroscopicallyAxenic"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            v-if="preservedSelected.preservedType == 'bacteria'"
          >
            <v-checkbox
              label="Colonias aisladas"
              outlined
              dense
              clearable
              type="text"
              v-model="preservedSelected.isolatedColonies"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            v-if="preservedSelected.preservedType == 'bacteria'"
          >
            <v-checkbox
              label="Células/Gram"
              outlined
              dense
              clearable
              type="text"
              v-model="preservedSelected.cells_gram"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            lg="6"
            v-if="
              preservedSelected.preservedType == 'bacteria' &&
              preservedSelected.cells_gram
            "
          >
            <v-autocomplete
              label="Selecciona tipo de gram"
              outlined
              dense
              :items="['gram +', 'gram -']"
              clearable
              type="text"
              v-model="preservedSelected.cells_gram_type"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            v-if="preservedSelected.preservedType == 'fungi'"
          >
            <v-checkbox
              label="Presencia de espora"
              outlined
              dense
              clearable
              type="text"
              v-model="preservedSelected.sporePresence"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            v-if="preservedSelected.preservedType == 'nematode'"
          >
            <v-checkbox
              label="J2 viables"
              outlined
              dense
              clearable
              type="text"
              v-model="preservedSelected.j2"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="text-subtitle-1 font-weight-bold">
              Datos cepario León
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="6">
            <v-text-field
              label="Fecha de recepción"
              outlined
              dense
              clearable
              type="date"
              v-model="preservedSelected.receptionDate"
              :rules="validation()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xl="6">
            <v-text-field
              label="Número de tubos"
              outlined
              dense
              clearable
              type="text"
              v-model="preservedSelected.containerQtyCeparium"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xl="6" lg="6">
            <v-text-field
              label="Fecha preservación"
              outlined
              dense
              clearable
              type="date"
              v-model="preservedSelected.preservationDate"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            lg="6"
            v-if="preservedSelected.preservedType != 'nematode'"
          >
            <v-autocomplete
              label="Metodo de preservación"
              outlined
              dense
              clearable
              v-model="preservedSelected.preservationMethod"
              :items="[
                { name: 'Tubo inclinado', value: 'INCLINED_TUBE' },
                { name: 'Discos de agr', value: 'AGAR_DISCS' },
                { name: 'Microtubo', value: 'MICROTUBE' },
              ]"
              item-text="name"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" xl="6" lg="6">
            <v-switch
              dense
              v-model="preservedSelected.viability"
              label="Viabilidad"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="text-subtitle-1 font-weight-bold">
              Datos de confirmación molecular
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="6">
            <v-text-field
              label="Fecha de envio"
              outlined
              dense
              clearable
              type="date"
              v-model="preservedSelected.shippingDateMolecular"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xl="6">
            <v-combobox
              label="Homología"
              outlined
              dense
              clearable
              item-text="name"
              hide-no-data
              v-model="identificationHomology"
              :search-input.sync="searchHomology"
              :items="identificationsFoundedHomology"
              :loading="isLoadingHomology"
              @keydown="changeIdentificationValueHomology"
              return-object
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              filled
              name="input-7-4"
              label="Observaciones"
              v-model="preservedSelected.observations"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="text-subtitle-1 font-weight-bold"> Datos de CMA </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="6" lg="6">
            <v-text-field
              label="Fecha de recepción"
              outlined
              dense
              clearable
              type="date"
              v-model="preservedSelected.receptionCMA"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xl="6" lg="6">
            <v-text-field
              label="Número de tubos"
              outlined
              dense
              clearable
              type="text"
              v-model="preservedSelected.containerCMA"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xl="6" lg="6">
            <v-text-field
              label="Fecha preservación"
              outlined
              dense
              clearable
              type="date"
              v-model="preservedSelected.preservationDateCMA"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            lg="6"
            v-if="preservedSelected.preservedType != 'nematode'"
          >
            <v-autocomplete
              label="Metodo de preservación"
              outlined
              dense
              clearable
              v-model="preservedSelected.preservationMethodCMA"
              :items="[
                { name: 'Tubo inclinado', value: 'INCLINED_TUBE' },
                { name: 'Discos de agr', value: 'AGAR_DISCS' },
                { name: 'Microtubo', value: 'MICROTUBE' },
              ]"
              item-text="name"
              return-object
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="6" lg="6">
            <v-text-field
              label="Url validación de primers"
              outlined
              dense
              clearable
              type="text"
              v-model="url"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn color="primary" @click="addURL"
              ><v-icon>mdi-plus</v-icon>Agregar link</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-chip
              class="ma-2 truncate-chip"
              close
              v-for="(item, index) in preservedSelected.validationDocuments"
              :key="index"
              @click:close="removeLink(index)"
              @click="copyLink(item)"
            >
              {{ truncateText(item, 34) }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row style="border-bottom: solid 2px lightgray !important">
          <v-col>
            <span class="text-subtitle-1 font-weight-bold">
              Imágenes macroscópicas
            </span>
          </v-col>
          <v-col>
            <v-btn color="primary" small @click="showAddMacro">
              <v-icon> mdi-image-plus-outline </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-carousel height="auto" v-show="filesMacroPhotos.length != 0">
              <v-carousel-item
                v-for="(item, index) in filesMacroPhotos"
                :key="index"
              >
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      x-small
                      color="warning"
                      fab
                      @click="openUpdateImgModal(item)"
                    >
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-img :src="item" contain @click="showPhoto(item)">
                    </v-img>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
            <v-carousel
              hide-delimiter-background
              v-show="filesMacroPhotos.length == 0"
            >
              <v-carousel-item>
                <v-img
                  :src="require('../../assets/img/no_image_strain.png')"
                  aspect-ratio="1.0"
                  contain
                ></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
        <v-row style="border-bottom: solid 2px lightgray !important">
          <v-col>
            <span class="text-subtitle-1 font-weight-bold"
              >Imágenes microscópicas
            </span>
          </v-col>
          <v-col>
            <v-btn color="primary" small @click="showAddMicro">
              <v-icon> mdi-image-plus-outline </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-carousel height="auto" v-show="filesMicroPhotos.length != 0">
              <v-carousel-item
                v-for="(item, index) in filesMicroPhotos"
                :key="index"
              >
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      x-small
                      color="warning"
                      fab
                      @click="openUpdateImgModal(item)"
                    >
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-img
                      :src="`${item}?${Date.now()}`"
                      contain
                      @click="showPhoto(item)"
                    >
                    </v-img>
                  </v-col>
                </v-row>
                <!-- <span>
                  {{ dateFormat(item.date) }}
                </span> -->
              </v-carousel-item>
            </v-carousel>
            <v-carousel
              hide-delimiter-background
              v-show="filesMicroPhotos.length == 0"
            >
              <v-carousel-item>
                <v-img
                  :src="require('../../assets/img/no_image_strain.png')"
                  aspect-ratio="1.0"
                  contain
                ></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <SubSamplePhoto
      :img="photos"
      :visible="isVisiblePhoto"
      @hideSubSampleModal="hidePhotos"
    />
    <AddEvidencePhotographCepariumModal
      v-model="showAddEvidencePhotographCepariumModal"
      :type="uploadFileType"
      @fetchTable="refreshTable"
    />
    <UpdateEvidencePhotographCepariumModal
      v-model="showUpdateEvidencePhotographCepariumModal"
      :imageUrl="imageToUpdate"
      @fetchTable="refreshTable"
    />
  </v-container>
</template>

<script>
//Vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
//validations
import { requiredValidation } from "@/helpers/validations.js";
//components
import SubSamplePhoto from "@/components/station1/modals/SubSamplePhoto";
import AddEvidencePhotographCepariumModal from "@/components/ceparium/modals/AddEvidencePhotographCepariumModal.vue";
import UpdateEvidencePhotographCepariumModal from "@/components/ceparium/modals/UpdateEvidencePhotographCepariumModal.vue";
//Libraries
import dayjs from "dayjs";
//componente config
export default {
  components: {
    SubSamplePhoto,
    AddEvidencePhotographCepariumModal,
    UpdateEvidencePhotographCepariumModal,
  },
  data: () => ({
    url: null,
    upLoadingImgsMicro: false,
    upLoadingImgsMacro: false,
    filesMacroPhotos: [],
    filesMicroPhotos: [],
    isAddPhotoCaseMicro: false,
    isAddPhotoCaseMacro: false,
    imagesMicro: [],
    imagesMacro: [],
    photos: [],
    isVisiblePhoto: false,
    isLoading: false,
    identification: null,
    identificationHomology: null,
    search: null,
    samplesTypes: [
      {
        text: "Suelo",
        value: "SOIL",
      },
      {
        text: "Vegetal",
        value: "PLANT",
      },
    ],
    methods: [],
    searchHomology: null,
    isLoadingHomology: false,
    identificationsFoundedHomology: [],
    showAddEvidencePhotographCepariumModal: false,
    uploadFileType: "",
    showUpdateEvidencePhotographCepariumModal: false,
    imageToUpdate: "",
  }),
  computed: {
    ...mapState("identification", ["identificationsFounded"]),
    ...mapState("ceparium", ["preservedSelected"]),
  },
  async mounted() {
    // map identification
    if (this.preservedSelected) {
      if (this.preservedSelected.identification) {
        this.identification = this.preservedSelected.identification;
      }
      if (this.preservedSelected.homology) {
        this.identificationHomology = this.preservedSelected.homology;
      }
      this.formattImages();
    }

    //clean data
    this.isAddPhotoCaseMicro = false;
    this.isAddPhotoCaseMacro = false;
  },
  methods: {
    ...mapActions("strain", ["uploadStrainPhoto"]),
    ...mapActions("ceparium", ["updatePreservedID"]),
    ...mapMutations("ceparium", ["SET_PRESERVED_SELECTED"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("identification", [
      "searchIdentifications",
      "addNematodeIdentification",
      "searchIdentificationsHomology",
    ]),
    validation(name) {
      return requiredValidation(name);
    },
    changeIdentificationValue() {
      if (!this.search) {
        this.identification = null;
      }
    },
    changeIdentificationValueHomology() {
      if (!this.searchHomology) {
        // this.form.homology = null;
      }
    },
    async getIdentification() {
      try {
        if (typeof this.identification !== "object" && this.search) {
          // New.
          this.identification = {
            name: this.search,
            moType: this.preservedSelected.preservedType,
          };
          const response = await this.addNematodeIdentification(
            this.identification
          );
          if (response.error) throw response;
          return response.IDENTIFICATION;
        } else {
          // identification selected
          return this.identification;
        }
      } catch (e) {
        console.log("Error on getIdentificationId", e);
        return e;
      }
    },
    async getIdentificationHomology() {
      try {
        if (
          typeof this.identificationHomology !== "object" &&
          this.searchHomology
        ) {
          // New.
          this.identification = {
            name: this.searchHomology,
            moType: this.type.value,
          };
          const response = await this.addNematodeIdentification(
            this.identification
          );
          if (response.error) throw response;
          return response.IDENTIFICATION;
        } else {
          // identification selected
          return this.identificationHomology;
        }
      } catch (e) {
        console.log("Error on getIdentificationId", e);
        return e;
      }
    },
    async updatePreserved() {
      try {
        let payload = {
          body: {
            receptionDate: this.preservedSelected.receptionDate,
            companyName: this.preservedSelected.companyName,
            shippingWay: this.preservedSelected.shippingWay,
            sampleType: this.preservedSelected.sampleType,
            suspectedDisease: this.preservedSelected.suspectedDisease,
            inoculationDate: this.preservedSelected.inoculationDate,
            cultureMedia: this.preservedSelected.cultureMedia,
            containerQty: this.preservedSelected.containerQty,
            identificationMedia: this.preservedSelected.identificationMedia,
            isolationMedia: this.preservedSelected.isolationMedia,
            macroscopicConformation:
              this.preservedSelected.macroscopicConformation,
            microscopicConformation:
              this.preservedSelected.microscopicConformation,
            macroscopicallyAxenic: this.preservedSelected.macroscopicallyAxenic,
            isolatedColonies: this.preservedSelected.isolatedColonies,
            cells_gram: this.preservedSelected.cells_gram,
            cells_gram_type: this.preservedSelected.cells_gram_type,
            sporePresence: this.preservedSelected.sporePresence,
            j2: this.preservedSelected.j2,
            containerQtyCeparium: this.preservedSelected.containerQtyCeparium,
            shippingDateMolecular: this.preservedSelected.shippingDateMolecular,
            observations: this.preservedSelected.observations,
            preservationDate: this.preservedSelected.preservationDate,
            preservationMethod: this.preservedSelected.preservationMethod,
            receptionCMA: this.preservedSelected.receptionCMA,
            containerCMA: this.preservedSelected.containerCMA,
            validationDocuments: this.preservedSelected.validationDocuments,
            viability: this.preservedSelected.viability,
          },
          preservedId: this.preservedSelected._id,
        };

        let identification = await this.getIdentification();
        payload.body.identification = identification._id;
        payload.body.identificationName = identification.name;

        if (this.identificationHomology) {
          let identificationHomology = await this.getIdentificationHomology();
          payload.body.homology = identificationHomology._id;
          payload.body.homologyName = identificationHomology.name;

          payload.body.identification = identificationHomology._id;
          payload.body.identificationName = identificationHomology.name;
        }
        let response = await this.updatePreservedID(payload);
        if (!response) throw response;
        this.$emit("fetchTable", response.updatedPreserved);
        this.formattImages();
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Información actualizada",
          timeout: 3500,
        });
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al actualizar preservada, contacta a soporte.",
          timeout: 3500,
        });
      }
    },
    refreshTable(data) {
      this.showAddEvidencePhotographCepariumModal = false;
      this.showUpdateEvidencePhotographCepariumModal = false;
      this.$emit("fetchTable", data);
      this.formattImages();
    },
    openLink() {
      window.open(this.preservedSelected.urlProject, "_blank");
    },
    hidePhotos() {
      this.photos = [];
      this.isVisiblePhoto = false;
    },
    showPhoto(item) {
      this.photos = [
        {
          title: "",
          src: item,
        },
      ];
      this.isVisiblePhoto = true;
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
      return date || "--/--/----";
    },
    showAddMicro() {
      this.uploadFileType = "MICRO";
      this.showAddEvidencePhotographCepariumModal = true;
      // this.isAddPhotoCaseMicro = true;
    },
    showAddMacro() {
      this.uploadFileType = "MACRO";
      this.showAddEvidencePhotographCepariumModal = true;
      // this.isAddPhotoCaseMacro = true;
    },
    addURL() {
      if (this.url.length != 0) {
        this.preservedSelected.validationDocuments.push(this.url);
        this.url = null;
      }
    },
    removeLink(index) {
      this.preservedSelected.validationDocuments.splice(index, 1);
    },
    copyLink(item) {
      console.log(item);
      window.open(item, "_blank");
    },
    truncateText(text, maxLength) {
      if (text.length <= maxLength) {
        return text;
      } else {
        return text.slice(0, maxLength) + "...";
      }
    },
    openUpdateImgModal(item) {
      let originalURL = item.split("-small");
      this.imageToUpdate = `${originalURL[0]}${originalURL[1]}`;
      this.showUpdateEvidencePhotographCepariumModal = true;
    },
    formattImages() {
      this.filesMicroPhotos = [];
      this.filesMacroPhotos = [];
      for (let item of this.preservedSelected.imagesMicro) {
        this.filesMicroPhotos.push(`${item}?${Date.now()}`);
      }
      for (let item of this.preservedSelected.imagesMacro) {
        this.filesMacroPhotos.push(`${item}?${Date.now()}`);
      }
    },
  },
  watch: {
    async search(value) {
      if (value) {
        this.isLoading = true;
        try {
          const response = await this.searchIdentifications({
            name: value,
            moType: this.preservedSelected.preservedType,
          });
          if (response.error) throw response;
        } catch (e) {
          console.log(e);
        }
        this.isLoading = false;
        // Validate error text
        if (this.errorText && this.search) {
          this.errorText = false;
        }
      }
    },
    preservedSelected(value) {
      if (value) {
        if (this.preservedSelected.identification) {
          this.identification = this.preservedSelected.identification;
        }
        this.formattImages();
      }

      //clean data
      this.isAddPhotoCaseMicro = false;
      this.isAddPhotoCaseMacro = false;
    },
    async searchHomology(value) {
      if (value) {
        this.isLoadingHomology = true;
        try {
          const response = await this.searchIdentificationsHomology({
            name: value,
            moType: this.preservedSelected.preservedType,
          });
          if (response.error) throw response;

          this.identificationsFoundedHomology = response.IDENTIFICATIONS;
        } catch (e) {
          console.log(e);
        }
        this.isLoadingHomology = false;
      }
    },
  },
};
</script>

<style scoped>
.enlace {
  color: #04c105;
  text-decoration: none;
  cursor: pointer;
}

.enlace:hover {
  text-decoration: underline;
}
</style>
