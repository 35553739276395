<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="text-center">
          <v-dialog v-model="dialogT" width="80%" persistent>
            <v-card>
              <v-card-text class="px-0 py-0">
                <vue-pdf-app style="height: 80vh" :pdf="urlPreview"></vue-pdf-app>
              </v-card-text>
              <v-card-actions>
                <template v-if="cmda_package_data && resultType != 'MB'">
                  <v-row align="center" justify="center">
                    <v-col cols="6" class="pa-2">
                      <b>{{ translations.package }}:</b>
                      <span>
                        {{ cmda_package_data.package }} ({{
                          cmda_package_data.mm_package
                        }}
                        MM)</span>
                    </v-col>
                    <v-col cols="6" class="pa-2">
                      <b :class="cmda_package_data.mm_package ==
                        cmda_package_data.mm_processed
                        ? 'primary--text'
                        : 'warning--text'
                        ">{{ cmda_package_data.mm_processed }} {{ translations.processedMolecularMarkersInFolio }}</b>
                    </v-col>
                  </v-row>
                </template>
                <v-spacer></v-spacer>
                <v-btn text color="warning" @click="closeResultDialog" :loading="isLoadingDelete"
                  :disabled="creatingNewReport">
                  {{ translations.close }}
                </v-btn>
                <v-btn v-if="showGenerate" text color="success" :disabled="isLoadingDelete ||
                  (cmda_package_data &&
                    cmda_package_data.mm_package !=
                    cmda_package_data.mm_processed &&
                    resultType != 'MB')
                  " :loading="creatingNewReport" @click="generateNewReport()">
                  {{ translations.generateFinalReport }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <v-row class="mt-2" justify="start">
          <v-col cols="12" md="3" class="py-1">
            <v-text-field class="py-0" prepend-inner-icon="mdi-magnify" v-model="search"
              :label="`${translations.search}`" hide-actions clearable>
            </v-text-field>
          </v-col>
          <v-col cols="12" :md="samplesForSequencing.length > 0 ? '3' : '2'" class="py-1">
            <v-dialog v-model="filterDialog" width="500" scrollable>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on" rounded
                  small><v-icon>mdi-filter-multiple</v-icon></v-btn>
              </template>

              <v-card>
                <v-card-title> {{ translations.filters }} </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row v-if="
                      permissions.find((per) => per == 'lab_molecular_search')
                    ">
                      <v-col>
                        <span> {{ translations.kitFocus }} </span>
                        <v-autocomplete v-model="form.kitAprproach" :items="kitAprproaches"
                          :label="translations.selectKitFocus" hide-no-data solo dense item-text="name" clearable
                          return-object></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span> {{ translations.reception }} </span>
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates"
                          transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-combobox v-model="dates" multiple chips small-chips :label="translations.selectDateRange"
                              prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" clearable solo
                              dense></v-combobox>
                          </template>
                          <v-date-picker v-model="dates" multiple no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                              {{ translations.cancel }}
                            </v-btn>
                            <v-btn text color="primary" @click="handleSaveReception" :disabled="dates.length !== 2">
                              {{ translations.accept }}
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span> {{ translations.resultsIssuance }}</span>
                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :return-value.sync="dates2"
                          transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-combobox v-model="dates2" multiple chips small-chips
                              :label="translations.selectDateRange" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                              v-on="on" clearable solo dense></v-combobox>
                          </template>
                          <v-date-picker v-model="dates2" multiple no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu2 = false">
                              {{ translations.cancel }}
                            </v-btn>
                            <v-btn text color="primary" @click="handleSaveEmission" :disabled="dates2.length !== 2">
                              {{ translations.accept }}
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <span> {{ translations.package }} </span>
                        <v-autocomplete :search-input.sync="searchPackage" v-model="form.package" :items="packages"
                          :label="translations.package" hide-no-data solo dense item-text="name" clearable return-object
                          prepend-inner-icon="mdi-magnify"></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row v-if="
                      permissions.find((per) => per == 'lab_molecular_search')
                    ">
                      <v-col>
                        <span> {{ translations.laboratory }} </span>
                        <v-autocomplete :search-input.sync="searchLab" v-model="labFound" :items="laboratories"
                          :label="`${translations.search}`" hide-no-data solo dense item-text="name" clearable
                          return-object prepend-inner-icon="mdi-magnify"></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span> {{ translations.producer }} </span>
                        <v-autocomplete :search-input.sync="searchFarm" v-model="farmFound" :items="farms"
                          :label="`${translations.search}`" hide-no-data solo dense :item-text="formatItemText"
                          clearable return-object prepend-inner-icon="mdi-magnify"></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row v-if="
                      permissions.find((per) => per == 'lab_molecular_search')
                    ">
                      <v-col>
                        <span> {{ translations.sampleGuard }} </span>
                        <v-autocomplete v-model="form.samplesGuard" :items="sampleGuardStats"
                          :label="translations.selectStatusType" hide-no-data solo dense item-text="text" clearable
                          return-object></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span> {{ translations.analysisType }} </span>
                        <v-autocomplete v-model="form.analysisType" :items="analysisType"
                          :label="translations.selectAnalysisType" :no-data-text="translations.noCrop" solo dense
                          clearable></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span> {{ translations.status }} </span>
                        <v-autocomplete v-model="form.status" :items="status" :label="translations.selectStatus"
                          hide-no-data solo dense clearable></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <span> {{ translations.reportGenerated }} </span>
                        <v-autocomplete v-model="form.report" :items="reports" :label="translations.selectType"
                          hide-no-data solo dense clearable></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span> {{ translations.sampleType }} </span>
                        <v-autocomplete v-model="form.sampleType" :items="sampleType"
                          :label="translations.selectSampleType" solo hide-no-data dense clearable></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <span> {{ translations.crop }} </span>
                        <v-autocomplete v-model="form.cropCycle" :items="crop_items" :label="translations.selectCrop"
                          hide-no-data solo dense clearable></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row v-if="
                      !permissions.find((per) => per == 'lab_qpcr_plates')
                    ">
                      <v-col>
                        <span> {{ translations.registrationErrors }} </span>
                        <v-autocomplete v-model="form.error" :items="errors" :label="translations.selectErrorType"
                          hide-no-data solo dense clearable></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="gray" dark @click="filterDialog = false">
                    {{ translations.cancel }}
                  </v-btn>
                  <v-btn color="info" @click="cleanFilters">
                    {{ translations.clearFilters }}
                  </v-btn>
                  <v-btn color="primary" @click="applyFilters"> {{ translations.apply }} </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn color="primary" rounded small class="ml-1" @click="fetchData">
              <v-icon> mdi-refresh </v-icon>
            </v-btn>
            <v-btn color="primary" rounded small class="ml-1" @click="guardSamples" v-if="
              selectedParend.length != 0 &&
              permissions.find((per) => per == 'lab_export_guard_sample')
            ">
              <v-icon> mdi-account-details </v-icon>
            </v-btn>
            <v-btn color="primary" rounded small class="ml-1"
              v-if="permissions.find((per) => per == 'lab_export_guard_sample')"
              @click="showExportSamplesGuardModal = true">
              <v-icon> mdi-file-export-outline </v-icon>
            </v-btn>
            <samples-for-sequencing-modal :samples="samplesForSequencing" @samplesUpdated="samplesAssignedToReport" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <!-- @click:row="(item) => handleClick(item)" -->
            <v-data-table width="100%" height="53vh" :headers="headers" :items="analysis" :single-expand="true"
              :expanded.sync="expanded" show-expand show-select fixed-header dense v-model="selectedParend"
              :items-per-page="10" :no-data-text="translations.noReceipts" :loading-text="translations.loadingReceipts"
              :page.sync="page" hide-default-footer :loading="fetchingData" item-key="_id">
              <template #[`item.laboratory`]="{ item }">
                <span class="text-caption">{{ item.laboratory }}</span>
              </template>
              <template #[`item.folio`]="{ item }">
                <span class="text-caption" v-if="!item.project_kit && !item.setup_kit">{{ item.folio }}</span>
                <v-tooltip bottom v-else-if="item.project_kit">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip small color="secondary" v-bind="attrs" v-on="on">
                      {{ item.folio }}
                    </v-chip>
                  </template>
                  <span>{{ translations.projectKit }}</span>
                </v-tooltip>
                <v-tooltip bottom v-else-if="item.setup_kit">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip small color="warning" v-bind="attrs" v-on="on">
                      {{ item.folio }}
                    </v-chip>
                  </template>
                  <span>{{ translations.setupKit }}</span>
                </v-tooltip>
              </template>
              <template #[`item.status`]="{ item }">
                <v-chip color="#dadada">
                  <span class="text-caption">
                    {{ getStatus(item.status) }}
                  </span>
                </v-chip>
                <v-tooltip bottom v-if="item.statusReception === 'ERROR'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="red" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
                  </template>
                  <span>{{ translations.containersWithoutSamples }}</span>
                </v-tooltip>

                <v-tooltip bottom v-else-if="item.statusReception === 'PENDING'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="info" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
                  </template>
                  <span>{{ translations.pendingContainers }}</span>
                </v-tooltip>
                <v-tooltip bottom v-else-if="item.statusReception === 'SEND'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
                  </template>
                  <span>{{ translations.sampleSentToLab }}</span>
                </v-tooltip>
              </template>
              <template #[`item.analysis_monitoring`]="{ item }">
                <span class="text-caption">
                  {{ getAnalysisType(item.analysis_monitoring) }}
                  {{ getType(item.analysis_type) }}
                </span>
              </template>
              <template #[`item.kit_type`]="{ item }">
                <template v-if="item.kit_package">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip :color="item.match_kit_package ? 'primary' : 'warning'" small v-bind="attrs" v-on="on"
                        v-if="item.kit_type">{{ getKitType(item.kit_type) }}
                      </v-chip>
                      <v-chip :color="item.match_kit_package ? 'primary' : 'warning'" v-bind="attrs" v-on="on" small
                        v-else>N.E.</v-chip>
                    </template>
                    <span>{{ translations.package }} CMDA: {{ item.kit_package }}</span>
                  </v-tooltip>
                </template>
                <template v-else>
                  <span class="text-caption" v-if="item.kit_type">{{
                    getKitType(item.kit_type)
                    }}</span>
                  <span class="text-caption" v-else>N.E.</span>
                </template>
              </template>

              <template #[`item.client_name`]="{ item }">
                <span class="text-caption">{{ item.client_name }}</span>
              </template>
              <template #[`item.qty_samples`]="{ item }">
                <span class="text-caption">{{ item.qty_samples }}</span>
              </template>
              <template #[`item.date_reception`]="{ item }">
                <span class="text-caption">
                  {{ dateFormat(item.date_reception) }}
                </span>

                <v-icon @click="showDialogTimeLine(item._id)" color="primary">mdi-timeline-clock</v-icon>
              </template>
              <template #[`item.lastResultDate`]="{ item }">
                <div v-if="item.lastResultDate">
                  <span class="text-caption">
                    {{ dateFormat(item.lastResultDate) }}
                  </span>
                </div>
                <div v-else>
                  <span class="text-caption"> {{ translations.notGenerated }} </span>
                </div>
              </template>
              <template #[`item.results`]="{ item }">
                <template v-if="item.results.length > 0">
                  <v-tooltip bottom v-if="item.results.includes('Measure')">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="primary"
                        @click.stop="showReport(item.results)">mdi-file-document
                      </v-icon>
                    </template>
                    <span>{{ translations.measurementReport }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else-if="item.results.includes('Valuation')">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="secondary"
                        @click.stop="showReport(item.results)">mdi-file-document
                      </v-icon>
                    </template>
                    <span>{{ translations.valuationReport }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else-if="item.results.includes('qPCR')">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="blue"
                        @click.stop="showReport(item.results)">mdi-file-document
                      </v-icon>
                    </template>
                    <span>{{ translations.qPCRReport }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else-if="item.results.includes('Prescription')">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="blue"
                        @click.stop="showReport(item.results)">mdi-file-document
                      </v-icon>
                    </template>
                    <span>{{ translations.qPCRReport }}</span>
                  </v-tooltip>
                </template>
                <template v-else>
                  <v-icon color="grey lighten-3">mdi-file-document</v-icon>
                </template>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-div v-if="item.actions"></v-div>
                <v-row no-gutters align="center" justify="center">
                  <!-- <v-col
                    cols="6"
                    xl="2"
                    lg="2"
                    class="text-center"
                    v-if="permissions.find((per) => per == 'lab_project_kits')"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="item.setup_kit"
                          color="secondary"
                          v-bind="attrs"
                          v-on="on"
                          class="mx-2"
                          @click="kitSetUp(item)"
                          >mdi-archive-cog</v-icon
                        >
                        <v-icon
                          v-else
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          class="mx-2"
                          @click="kitSetUp(item)"
                          >mdi-archive-cog</v-icon
                        >
                      </template>
      <span>kit de setup</span>
      </v-tooltip>
  </v-col> -->
                  <v-col cols="6" xl="2" lg="2" class="text-center"
                    v-if="permissions.find((per) => per == 'lab_project_kits')">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="item.project_kit" color="secondary" v-bind="attrs" v-on="on" class="mx-2"
                          @click="kitProject(item)">mdi-badge-account</v-icon>
                        <v-icon v-else color="primary" v-bind="attrs" v-on="on" class="mx-2"
                          @click="kitProject(item)">mdi-badge-account-outline</v-icon>
                      </template>
                      <span>{{ translations.projectKit }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="6" xl="2" lg="2" class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" v-bind="attrs" v-on="on" class="mx-2"
                          @click="updateFolio(item._id)">mdi-pencil</v-icon>
                      </template>
                      <span>{{ translations.updateFolio }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="6" xl="2" lg="2" class="text-center"
                    v-if="permissions.find((per) => per == 'lab_reception_pct')">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" v-bind="attrs" v-on="on" class="mx-2"
                          @click="handleClick(item)">mdi-test-tube</v-icon>
                      </template>
                      <span>{{ translations.physicochemical }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="6" xl="2" lg="2" class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text :loading="item.isLoading" v-bind="attrs" v-on="on" x-small
                          @click="showReportGenerate(item)" :disabled="item.generateReport == false">
                          <v-icon color="primary" class="mx-0">mdi-file-chart</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ translations.results }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="6" xl="2" lg="2" class="text-center" v-if="
                    permissions.find((per) => per == 'lab_reception_qpcr')
                  ">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" class="mx-2" v-bind="attrs" v-on="on"
                          @click="goToDetail(item._id)">mdi-dna</v-icon>
                      </template>
                      <span>qPCR</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="6" xl="2" lg="2" class="text-center">
                    <template v-if="
                      item.status == 'RECEIVED' &&
                      item.statusReception != 'SEND'
                    ">
                      <v-tooltip bottom color="warning">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="warning" class="mx-2" v-bind="attrs" v-on="on"
                            @click="deleteFolioEvent(item.folio)">mdi-delete-outline</v-icon>
                        </template>
                        <span>{{ translations.deleteFolio }}</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <v-icon color="default lighten-3" class="mx-2" disabled>mdi-delete-outline</v-icon>
                    </template>
                  </v-col>
                </v-row>
              </template>
              <template #[`item.result_date`]="{ item }">
                <div v-if="item.result_date">
                  <v-menu top :close-on-content-click="false" v-if="
                    authenticatedUser &&
                    authenticatedUser.permission &&
                    authenticatedUser.permission.updateResultDate
                  ">
                    <template v-slot:activator="{ on, attrs }">
                      <span class="text-caption" v-bind="attrs" v-on="on"
                        @click="(resultDate = item.result_date), (folio = item)">
                        {{ item.result_date }}
                      </span>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-container>
                          <v-row no-gutters>
                            <v-col cols="12">
                              <v-text-field dense outlined label="Fecha estimada" type="date" v-model="resultDate"
                                :error="errorResultDate.error" :error-messages="errorResultDate.message"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-btn small color="primary" @click="updateResultDate" :loading="isLoadingDateUpdate">{{
                                translations.update
                                }}</v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <span class="text-caption" v-else>
                    {{ item.result_date }}
                  </span>
                </div>
                <div v-else>
                  <span class="text-caption"> {{ translations.noEstimatedDate }} </span>
                </div>
              </template>

              <template #expanded-item="{ headers, item }">
                <td class="px-0 py-2" :colspan="headers.length">
                  <template>
                    <v-data-table v-model="selectedChild" :headers="headersChild" :items="item.receptions"
                      item-key="qr_container" hide-default-footer elevation="0" :items-per-page="100">
                      <template #[`item.folio_sample`]="{ item }">
                        <span class="primary--text">{{
                          item.folio_sample
                          }}</span>
                      </template>
                      <template #[`item.sample_type`]="{ item }">
                        {{ getAnalysisType(item.tracingStatus) }}
                        {{ getSampleType(item.sample_type) }}
                      </template>
                      <template #[`item.crop`]="{ item }">
                        {{ getCrop(item.crop) }}
                      </template>
                      <template #[`item.date_field_registration`]="{ item }">
                        {{ dateFormat(item.date_field_registration) }}
                      </template>
                      <template #[`item.last_update`]="{ item }">
                        {{ lastUpdate(item.last_update) }}
                      </template>
                      <template #[`item.analysis_stage`]="{ item }">
                        <v-chip color="#dadada">
                          {{ getStatus(item.analysis_stage) }}
                        </v-chip>
                      </template>
                      <template #[`item.sequencingReport`]="{ item }">
                        <v-btn v-if="item.sequencingReport != null" icon color="primary" class="text-center" disabled>
                          <v-icon>mdi-molecule</v-icon>
                        </v-btn>
                        <v-btn v-else-if="checkSampleSequencing(item)" icon color="warning" class="text-center"
                          @click="discardSampleForSequencing(item)">
                          <v-icon>mdi-molecule</v-icon>
                        </v-btn>
                        <v-btn v-else-if="!checkSampleSequencing(item)" icon color="secondary" class="text-center"
                          @click="addSampleForSequencing(item)">
                          <v-icon>mdi-molecule</v-icon>
                        </v-btn>
                      </template>
                      <template #[`item.irrigation_unit_name`]="{ item }">
                        <span v-if="item.farm_unit_id" class="primary--text"
                          @click="goToDetailTracing(item.farm_unit_id)">
                          {{ item.irrigation_unit_name }}
                        </span>
                        <span v-else>
                          {{ item.irrigation_unit_name }}
                        </span>
                      </template>
                    </v-data-table>
                  </template>
                </td>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <v-pagination v-model="page" :length="numPages" total-visible="10" color="primary"></v-pagination>
          </v-col>
        </v-row>
        <v-row class="text-center" v-if="showFetchingDataButton">
          <v-col>
            <v-btn color="primary" rounded @click="fetchData"><v-icon>mdi-refresh</v-icon></v-btn>
          </v-col>
        </v-row>
        <TimeLineReception v-model="showTimeLine" :analysis="timeLineAnalysis" />
        <delete-folio-modal @delete-confirmed="deleteFolio" @canceled="cancelDeletion"
          :title="`${translations.deleteFolioConfirmation} ${folioForDelete}?`"
          :message="translations.enterFolioNumberToDelete" :folio="folioForDelete"
          :showDialog="showDeleteFolioDialog" />
      </v-col>
    </v-row>
    <stored-samples-modal v-model="showStoredSamplesModal" v-if="showStoredSamplesModal"
      @fetchData="reloadTable"></stored-samples-modal>
    <export-samples-guard-modal v-model="showExportSamplesGuardModal"
      v-if="showExportSamplesGuardModal"></export-samples-guard-modal>
    <choose-result-modal v-model="showChooseResultModal" v-if="showChooseResultModal" @result="generateDataResult" />
  </v-container>
</template>
<script>
//Vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
//Libraries
import dayjs from "dayjs";
//pdf viewer
import VuePdfApp from "vue-pdf-app";
import TimeLineReception from "@/components/reception/modals/TimeLineReception.vue";
//axios
import axios from "axios";
//components
import SamplesForSequencingModal from "./modals/SamplesForSequencingModal.vue";
import DeleteFolioModal from "./modals/DeleteFolioModal.vue";
import StoredSamplesModal from "./modals/StoredSamplesModal.vue";
import ExportSamplesGuardModal from "./modals/ExportSamplesGuardModal.vue";
import ChooseResultModal from "./modals/ChooseResultModal.vue";
export default {
  components: {
    VuePdfApp,
    TimeLineReception,
    SamplesForSequencingModal,
    DeleteFolioModal,
    StoredSamplesModal,
    ExportSamplesGuardModal,
    ChooseResultModal,
  },
  data: () => ({
    searchLab: null,
    labFound: null,
    laboratories: [],
    showExportSamplesGuardModal: false,
    showDeleteFolioDialog: false,
    showChooseResultModal: false,
    folioForDelete: "",
    timeLineAnalysis: null,
    headers: [],
    headersChild: [],
    urlPreview: "",
    dialogT: false,
    expanded: [],
    analysis: [],
    fetchingData: false,
    showGenerate: false,
    selectedChild: [],
    selectedParend: [],
    creatingNewReport: false,
    page: 1,
    currentFolioToGenerate: null,
    heyReport: null,
    isLoadingDelete: false,
    numPages: 0,
    search: "",
    showFetchingDataButton: false,
    filterDialog: false,
    crop_items: [],
    status: [],
    sampleType: [],
    analysisType: [],
    reports: [],
    errors: [],
    form: {
      cropCycle: null,
      sampleType: null,
      analysisType: null,
      status: null,
      report: null,
      error: null,
    },
    user: JSON.parse(localStorage.getItem("user")),
    showTimeLine: false,
    resultDate: null,
    errorResultDate: {
      error: false,
      message: "",
    },
    folio: null,
    isLoadingDateUpdate: false,
    samplesForSequencing: [],
    cmda_package_data: null,
    showStoredSamplesModal: false,
    sampleGuardStats: [],
    farmFound: null,
    searchFarm: null,
    farms: [],
    folioSelected: null,
    resultType: null,
    kitAprproaches: [],
    menu: null,
    dates: [],
    menu2: null,
    dates2: [],
    packages: [],
    searchPackage: null,
  }),
  computed: {
    ...mapGetters("session", ["currentTranslations"]),
    translations() {
      return this.currentTranslations;
    },
    ...mapState("reception", [
      "crops",
      "refreshReceptionTable",
      "filters",
      "searchFilter",
      "cleanParent",
    ]),
    ...mapState(["cancelToken"]),
    ...mapState("session", ["authenticatedUser", "permissions"]),
  },
  created() {
    this.analysisType = [
      {
        text: this.translations.initial,
        value: "INITIAL",
      },
      {
        text: this.translations.tracking,
        value: "MONITORING",
      },
    ]
    this.status = [
      {
        text: this.translations.received,
        value: "RECEIVED",
      },
      {
        text: this.translations.measuring,
        value: "MEASURING",
      },
      {
        text: this.translations.measured,
        value: "MEASURED",
      },
      {
        text: this.translations.valuing,
        value: "VALUATING",
      },
      {
        text: this.translations.valued,
        value: "VALUATED",
      },
    ]
    this.reports = [
      {
        text: this.translations.measurement,
        value: "measuring",
      },
      {
        text: this.translations.valuation,
        value: "valuation",
      },
    ]
    this.sampleType = [
      {
        text: "this.translations.soil",
        value: "CLASSIC",
      },
      {
        text: this.translations.plant,
        value: "MAS_VEGETABLE",
      },
      {
        text: this.translations.vegetalWithoutGeoreference,
        value: "VEGETABLE",
      },
      {
        text: this.translations.compost,
        value: "COMPOST",
      },
      {
        text: this.translations.product,
        value: "PRODUCT",
      },
      {
        text: this.translations.substrate,
        value: "SUBSTRATUM",
      },
      {
        text: this.translations.soilWithoutAdvisor,
        value: "NO_ADVISOR",
      }
    ]
    this.headers = [
      { text: this.translations.folio, value: "folio", sortable: false },
      { text: this.translations.status, value: "status", sortable: false },
      {
        text: this.translations.analysisType,
        value: "analysis_monitoring",
        sortable: false,
      },
      {
        text: this.translations.kitType,
        value: "kit_type",
        sortable: false,
      },
      { text: this.translations.producer, value: "client_name", sortable: false },
      { text: this.translations.laboratory, value: "laboratory", sortable: false },
      {
        text: this.translations.samples,
        value: "qty_samples",
        align: "center",
        sortable: false,
      },
      { text: this.translations.reception, value: "date_reception", sortable: false },
      {
        text: this.translations.results,
        value: "results",
        align: "center",
        sortable: false,
      },
      {
        text: this.translations.estimatedResultDate,
        value: "result_date",
        align: "center",
        sortable: false,
      },
      {
        text: this.translations.lastReportSent,
        value: "lastResultDate",
        sortable: false,
      },
      { text: this.translations.actions, value: "actions", align: "center", sortable: false },
    ]
    this.headersChild = [
      {
        text: this.translations.sampleFolio,
        value: "folio_sample",
        align: "center",
        sortable: false,
      },
      { text: this.translations.containerQR, value: "qr_container", sortable: false },
      { text: this.translations.sampleType, value: "sample_type", sortable: false },
      { text: `# ${this.translations.subsamples}`, value: "sub_samples", sortable: false },
      {
        text: this.translations.samplingZoneName,
        value: "irrigation_unit_name",
        sortable: false,
      },
      { text: this.translations.crop, value: "crop", sortable: false },
      { text: this.translations.responsible, value: "sampler_name", sortable: false },
      {
        text: this.translations.fieldRegistration,
        value: "date_field_registration",
        sortable: false,
      },
      { text: this.translations.lastModification, value: "last_update", sortable: false },
      { text: this.translations.analysisStage, value: "analysis_stage", sortable: false },
    ]
    this.errors = [
      {
        text: this.translations.noErrors,
        value: "SUCCESS",
      },
      {
        text: this.translations.withErrors,
        value: "ERROR",
      },
      {
        text: this.translations.pending,
        value: "PENDING",
      },
    ]
    this.sampleGuardStats = [
      {
        text: this.translations.all,
        value: "ALL",
      },
      {
        text: this.translations.availableSamples,
        value: "AVAILABLE",
      },
      {
        text: this.translations.unavailableSamples,
        value: "UNAVAILABLE",
      },
    ]
    this.kitAprproaches = [
      {
        name: this.translations.projectKit,
        value: "project_kit",
      },
      {
        name: this.translations.setupKit,
        value: "setup_kit",
      },
    ]
  },
  async mounted() {
    window.addEventListener("resize", this.myEventHandler);
    this.page = parseInt(this.$route.params.page, 10);
    this.setCropItems();
    if (this.filters) {
      this.form = this.filters;
    }
    if (this.searchFilter && this.searchFilter.length > 0) {
      this.search = this.searchFilter;
    }
    await this.fetchData();
    this.checkPermissions();
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    handleSaveReception() {
      if (this.dates.length === 2) {
        this.$refs.menu.save(this.dates);
      }
    },
    handleSaveEmission() {
      if (this.dates2.length === 2) {
        this.$refs.menu2.save(this.dates2);
      }
    },
    formatItemText(item) {
      let template = "";
      if (item.billingInformation) {
        template = `${item.billingInformation.companyName} - ${item.companyName}`;
      } else {
        template = `${item.companyName}`;
      }
      return template;
    },
    ...mapMutations(["SET_CANCEL_TOKEN"]),
    ...mapActions("reception", [
      "getReceptions2",
      "getFolioAnalysis",
      "generateDiagnosis",
      "generatePrescription",
      "generateMonitoring",
      "deleteTempFiles",
      "generateResults",
      "updateResult",
      "setProjectKit",
      "setSetUpKit",
    ]),
    ...mapActions("laboratory", [
      "getMolecuclarLaboratories",
      "getPackagesByName",
    ]),
    ...mapActions("analysis", ["deleteAnalysisFiltered"]),
    ...mapActions("organization", ["getFarms"]),
    ...mapMutations("reception", [
      "SET_REFRESH_TABLE",
      "SHOW_UPDATE_FOLIO_DIALOG",
      "SET_FOLIO_SELECTED",
      "SET_SELECTED_RECEPTION",
      "SET_FILTERS",
      "SET_SEARCH",
      "SET_FOLIOS_SELECTED",
      "CLEAN_PARENT",
      "SET_STORED_SAMPLES",
    ]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async kitProject(folio) {
      try {
        const analysis_id = folio._id;
        const req = await this.setProjectKit(analysis_id);

        if (req.error) {
          throw req;
        }

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: this.translations.folioUpdatedSuccessfully,
          timeout: 5000,
        });

        await this.fetchData();
      } catch (error) {
        console.error(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `${this.translations.somethingWentWrong} ${error.message}`,
          timeout: 5000,
        });
      }
    },
    async kitSetUp(folio) {
      try {
        const analysis_id = folio._id;
        const req = await this.setSetUpKit(analysis_id);

        if (req.error) {
          throw req;
        }

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: this.translations.folioUpdatedSuccessfully,
          timeout: 5000,
        });

        await this.fetchData();
      } catch (error) {
        console.error(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `${this.translations.somethingWentWrong} ${error.message}`,
          timeout: 5000,
        });
      }
    },
    async fetchData() {
      this.fetchingData = true;
      this.SET_FILTERS(this.form);
      this.SET_SEARCH(this.search);
      try {
        if (typeof this.cancelToken != "undefined") {
          this.cancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        this.SET_CANCEL_TOKEN(axios.CancelToken.source());
        let payload = {
          page: this.page,
          search: this.search,
          laboratory: this.user.laboratory._id,
          filters: this.form,
        };

        let response = await this.getReceptions2(payload);
        if (response) {
          if (!response.error) {
            //hide loaders
            this.fetchingData = false;
            this.showFetchingDataButton = false;
            //set sample data
            this.analysis = response.analysis;
            this.numPages = response.pages;
          } else {
            throw response;
          }
        }
      } catch (e) {
        //print log
        console.log(e);
        //hide loaders
        this.fetchingData = false;
        //show attempt button to try fetch data manually
        this.showFetchingDataButton = true;
        //show error to user
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `${this.translations.somethingWentWrong} ${error.message}`,
          timeout: 5000,
        });
      }
    },
    async cleanFilters() {
      this.laboratories = [];
      this.searchLab = null;
      this.labFound = null;
      this.farms = [];
      this.searchFarm = null;
      this.farmFound = null;
      this.search = null;
      this.filterDialog = false;
      this.menu = null;
      this.dates = [];
      this.menu2 = null;
      this.dates2 = [];
      this.packages = [];
      this.searchPackage = null;
      this.form = {
        cropCycle: null,
        sampleType: null,
        analysisType: null,
        status: null,
        report: null,
        error: null,
      };
      await this.fetchData();
    },
    async applyFilters() {
      this.form.farmFound = this.farmFound;
      this.form.labFound = this.labFound;
      if (this.dates.length == 2) {
        this.form.receptionDateRange = this.dates;
      } else {
        this.form.receptionDateRange = null;
      }
      if (this.dates2.length == 2) {
        this.form.emissionDateRange = this.dates2;
      } else {
        this.form.emissionDateRange = null;
      }
      this.filterDialog = false;
      this.page = 1;
      await this.fetchData();
    },
    setCropItems() {
      let cropsArray = Object.entries(this.translations.crop_name_list);
      for (let crop of cropsArray) {
        this.crop_items.push({
          text: crop[1],
          value: crop[0],
        });
      }
    },
    getStatus(value) {
      const status = {
        RECEIVED: this.translations.received,
        MEASURING: this.translations.measuring,
        MEASURED: this.translations.measured,
        VALUATING: this.translations.valuing,
        VALUATED: this.translations.valued,
      };
      let newValue = status[value];
      return newValue ? newValue : `${this.translations.waiting}...`;
    },
    dateFormat(value) {
      if (value) {
        const date = `${dayjs(value).format("DD-MM-YYYY - HH:mm")} hrs`;
        return date ? date : "N/A";
      } else {
        return "N/A";
      }
    },
    dateFormatShort(value) {
      if (value) {
        const date = `${dayjs(value).format("DD-MM-YYYY")}`;
        return date ? date : "N/A";
      } else {
        return "N/A";
      }
    },
    getCrop(value) {
      let result = this.translations.crop_name_list[value];
      return result || this.translations.notAvailable;
    },
    getAnalysisType(value) {
      if (value == "INITIAL" || value == "Initial") {
        return this.translations.initial;
      } else {
        return this.translations.tracking;
      }
    },
    getType(value) {
      let types = {
        CMDA: "CMDA Solena",
        SOLENA: this.translations.traditional,
      };
      let type = types[value];
      if (type) {
        return type;
      } else {
        return "";
      }
    },
    getKitType(value) {
      let types = {
        CLASSIC: this.translations.traditional,
        HYBRID: this.translations.hybrid,
        QPCR: "qPCR",
        OTHER: this.translations.other,
        SEQUENCING: this.translations.sequencing,
      };
      let type = types[value];
      if (type) {
        return type;
      } else {
        return "N.E.";
      }
    },
    getSampleType(value) {
      const types = {
        CLASSIC: this.translations.soil,
        MAS_VEGETABLE: this.translations.plant,
        VEGETABLE: this.translations.vegetalWithoutGeoreference,
        COMPOST: this.translations.compost,
        PRODUCT: this.translations.product,
        SUBSTRATUM: this.translations.substrate,
        NO_ADVISOR: this.translations.soilWithoutAdvisor,
      };
      if (value.type) {
        let temp = types[value.type];
        if (value.type == "COMPOST" || value.type == "PRODUCT") {
          if (value.composition) {
            let compositions = {
              LIQUID: this.translations.liquid,
              SOLID: this.translations.solid,
            };
            temp += ` ${compositions[value.composition]}`;
          }
        } else if (value.type == "MAS_VEGETABLE" || value.type == "VEGETABLE") {
          if (value.vegetableType) {
            let vegetableTypes = {
              Seedling: this.translations.vegetalSampleTypes["Seedling"],
              Root: this.translations.vegetalSampleTypes["Root"],
              Seed: this.translations.vegetalSampleTypes["Seed"],
              Stem: this.translations.vegetalSampleTypes["Stem"],
              Leaf: this.translations.vegetalSampleTypes["Leaf"],
              Fruit: this.translations.vegetalSampleTypes["Fruit"],
              Flower: this.translations.vegetalSampleTypes["Flower"],
            };
            if (vegetableTypes[value.vegetableType]) {
              temp += ` ${vegetableTypes[value.vegetableType]}`;
            }
          }
        }
        return temp;
      } else {
        return "N/A";
      }
    },
    downloadPDF(value) {
      if (value.length > 0) {
        var a = document.createElement("a");
        a.href = value;
        let fileName = value.split("/").pop();
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(value);
        a.remove();
      }
    },
    lastUpdate(date) {
      if (date) {
        let now = new Date();
        let update = new Date(date);
        const diffTime = Math.abs(update - now);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        var cd = 24 * 60 * 60 * 1000,
          ch = 60 * 60 * 1000,
          d = Math.floor(diffTime / cd),
          h = Math.floor((diffTime - d * cd) / ch),
          m = Math.round((diffTime - d * cd - h * ch) / 60000),
          pad = function (n) {
            return n < 10 ? "0" + n : n;
          };
        if (m === 60) {
          h++;
          m = 0;
        }
        if (h === 24) {
          d++;
          h = 0;
        }
        let template = "";
        let day = d;
        let houres = pad(h);
        let minutes = pad(m);
        let nothing = 0;
        if (day > 0) {
          if (day <= 1) {
            template += `${day} ${this.translations.day} `;
          } else {
            template += `${day} ${this.translations.days} `;
          }
        } else {
          nothing++;
        }

        if (houres > 0) {
          if (houres <= 1) {
            template += `${houres} ${this.translations.hour} `;
          } else {
            template += `${houres} ${this.translations.hours} `;
          }
        } else {
          nothing++;
        }

        if (minutes > 0) {
          if (minutes <= 1) {
            template += `${minutes} ${this.translations.minute}`;
          } else {
            template += `${minutes} ${this.translations.minutes}`;
          }
        } else {
          nothing++;
        }

        if (nothing++ == 3) {
          template += `${this.translations.seconds}`;
        }

        return template;
      } else {
        return "N/A";
      }
    },
    showReport(url) {
      this.urlPreview = url;
      this.dialogT = true;
      this.showGenerate = false;
    },
    async showReportGenerate(item) {
      try {
        if (this.permissions.find((per) => per == "lab_result_switch")) {
          this.showChooseResultModal = true;
          this.folioSelected = item;
        } else {
          if (item.analysis_monitoring === "INITIAL") {
            item.isLoading = true;
            let response = null;
            if (item.kit_package_id) {
              // if (item.kit_package_id == "6509cfb6c62066cb7dd26cb0") {
              // BASF
              response = await this.generatePrescription({
                analysisId: item._id,
                package_analysis: item.kit_package_id,
                language: localStorage.getItem("language")
              });
            } else {
              response = await this.generateDiagnosis({
                analysisId: item._id,
                laboratory: this.user.laboratory._id,
              });
            }
            if (response) {
              if (!response.error) {
                if (response.package_analysis) {
                  this.cmda_package_data = {
                    package_id: response.package_analysis._id,
                    package: response.package_analysis.name,
                    mm_package: response.package_analysis.mos.length,
                    mm_processed:
                      response.mm_processed.length != 0
                        ? Math.floor(
                          response.mm_processed.length /
                          response.receptionsQty
                        )
                        : 0,
                  };
                } else {
                  this.cmda_package_data = null;
                }

                this.currentFolioToGenerate = item._id;
                let url = response.result;
                this.heyReport = response.key;
                item.isLoading = false;
                this.urlPreview = url;
                this.dialogT = true;
                this.showGenerate = true;

                console.log("Diagnosis", response);

                // cmda_package_data
              } else {
                item.isLoading = false;
                throw response;
              }
            } else {
              item.isLoading = false;
              throw response;
            }
          } else {
            item.isLoading = true;
            let response = null;
            // if (item.kit_package_id == "6509cfb6c62066cb7dd26cb0") {
            if (item.kit_package_id) {
              response = await this.generatePrescription({
                analysisId: item._id,
                package_analysis: item.kit_package_id,
                language: localStorage.getItem("language")
              });
            } else {
              response = await this.generateMonitoring({
                analysisId: item._id,
                laboratory: this.user.laboratory._id,
              });
            }
            if (response) {
              if (!response.error) {
                if (response.package_analysis) {
                  this.cmda_package_data = {
                    package: response.package_analysis.name,
                    mm_package: response.package_analysis.mos.length,
                    mm_processed:
                      response.mm_processed && response.mm_processed.length != 0
                        ? Math.floor(
                          response.mm_processed.length /
                          response.receptionsQty
                        )
                        : 0,
                  };
                } else {
                  this.cmda_package_data = null;
                }

                this.currentFolioToGenerate = item._id;
                let url = response.result;
                this.heyReport = response.key;
                item.isLoading = false;
                this.urlPreview = url;
                this.dialogT = true;
                this.showGenerate = true;

                console.log("Monitoring", response);
              } else {
                item.isLoading = false;
                throw response;
              }
            } else {
              item.isLoading = false;
              throw response;
            }
          }
        }
      } catch (e) {
        item.isLoading = false;
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `${this.translations.somethingWentWrong} ${error.message}`,
          timeout: 5000,
        });
      }
    },
    async generateDataResult(type) {
      try {
        this.resultType = type;
        this.showChooseResultModal = false;
        if (this.folioSelected.analysis_monitoring === "INITIAL") {
          this.folioSelected.isLoading = true;
          let response = null;
          if (type == "ML" && this.folioSelected.kit_package_id) {
            // if (item.kit_package_id == "6509cfb6c62066cb7dd26cb0") {
            // BASF
            response = await this.generatePrescription({
              analysisId: this.folioSelected._id,
              package_analysis: this.folioSelected.kit_package_id,
              language: localStorage.getItem("language")
            });
          } else {
            response = await this.generateDiagnosis({
              analysisId: this.folioSelected._id,
              laboratory: this.user.laboratory._id,
              type: type,
            });
          }
          if (response) {
            if (!response.error) {
              if (response.package_analysis) {
                this.cmda_package_data = {
                  package_id: response.package_analysis._id,
                  package: response.package_analysis.name,
                  mm_package: response.package_analysis.mos.length,
                  mm_processed:
                    response.mm_processed.length != 0
                      ? Math.floor(
                        response.mm_processed.length / response.receptionsQty
                      )
                      : 0,
                };
              } else {
                this.cmda_package_data = null;
              }

              this.currentFolioToGenerate = this.folioSelected._id;
              let url = response.result;
              this.heyReport = response.key;
              this.folioSelected.isLoading = false;
              this.urlPreview = url;
              this.dialogT = true;
              this.showGenerate = true;

              console.log("Diagnosis", response);

              // cmda_package_data
            } else {
              this.folioSelected.isLoading = false;
              throw response;
            }
          } else {
            this.folioSelected.isLoading = false;
            throw response;
          }
        } else {
          this.folioSelected.isLoading = true;
          let response = null;
          // if (item.kit_package_id == "6509cfb6c62066cb7dd26cb0") {
          if (type == "ML" && this.folioSelected.kit_package_id) {
            response = await this.generatePrescription({
              analysisId: this.folioSelected._id,
              package_analysis: this.folioSelected.kit_package_id,
              language: localStorage.getItem("language")
            });
          } else {
            response = await this.generateMonitoring({
              analysisId: this.folioSelected._id,
              laboratory: this.user.laboratory._id,
              type: type,
            });
          }
          if (response) {
            if (!response.error) {
              if (response.package_analysis) {
                this.cmda_package_data = {
                  package: response.package_analysis.name,
                  mm_package: response.package_analysis.mos.length,
                  mm_processed:
                    response.mm_processed && response.mm_processed.length != 0
                      ? Math.floor(
                        response.mm_processed.length / response.receptionsQty
                      )
                      : 0,
                };
              } else {
                this.cmda_package_data = null;
              }

              this.currentFolioToGenerate = this.folioSelected._id;
              let url = response.result;
              this.heyReport = response.key;
              this.folioSelected.isLoading = false;
              this.urlPreview = url;
              this.dialogT = true;
              this.showGenerate = true;

              console.log("Monitoring", response);
            } else {
              this.folioSelected.isLoading = false;
              throw response;
            }
          } else {
            this.folioSelected.isLoading = false;
            throw response;
          }
        }
      } catch (e) {
        this.folioSelected.isLoading = false;
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `${this.translations.somethingWentWrong} ${error.message}`,
          timeout: 5000,
        });
      }
    },
    async updateFolio(_id) {
      try {
        // console.log(_id);
        let payload = {
          id: _id,
          laboratory: this.user.laboratory._id,
        };
        let response = await this.getFolioAnalysis(payload);
        if (response) {
          if (!response.error) {
            console.log(response.analysis);
            this.SET_FOLIO_SELECTED(response.analysis);
            this.SHOW_UPDATE_FOLIO_DIALOG(1);
          } else {
            throw response;
          }
        } else {
          throw response;
        }
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `${this.translations.somethingWentWrong} ${error.message}`,
          timeout: 5000,
        });
      }
    },
    handleClick(item) {
      this.SET_SELECTED_RECEPTION(item);
      this.$router.push(`/physicochemical-test/${item._id}`);
    },
    async generateNewReport() {
      try {
        this.creatingNewReport = true;
        // let folios = [this.currentFolioToGenerate];
        let response = await this.generateResults({
          _id: this.currentFolioToGenerate,
          url: this.urlPreview,
          laboratory: this.user.laboratory._id,
          packageId: this.cmda_package_data
            ? this.cmda_package_data.package_id
            : null,
          type: this.resultType,
        });
        if (response) {
          if (response.error) throw response;
          this.creatingNewReport = false;
          this.urlPreview = null;
          await this.closeResultDialog();
          await this.fetchData();
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: this.translations.reportGeneratedSuccessfully,
            timeout: 5000,
          });
        } else {
          throw response;
        }
      } catch (e) {
        this.creatingNewReport = false;
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `${this.translations.somethingWentWrong} ${error.message}`,
          timeout: 5000,
        });
      }
    },
    async closeResultDialog() {
      if (this.showGenerate) {
        this.isLoadingDelete = true;
        //delete temp file
        if (this.urlPreview)
          await this.deleteTempFiles({ key: this.urlPreview });
        this.isLoadingDelete = false;
        this.dialogT = false;
        this.urlPreview = "";
      } else {
        this.dialogT = false;
        this.urlPreview = "";
      }
    },
    goToDetail(_id) {
      this.$router.push(`/tracing-sample/${_id}`);
    },
    goToDetailTracing(_id) {
      this.$router.push(`/tracing/${_id}`);
    },
    myEventHandler(e) {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          this.headers = this.headers;
          break;
        case "lg":
          this.headers = this.headers;
          break;
        case "md":
          this.headers = this.headers;
          break;
        case "sm":
          this.headers = this.headers;
          break;
        case "xs":
          this.headers = this.headers;
          break;
      }
    },
    showDialogTimeLine(value) {
      this.showTimeLine = true;
      this.timeLineAnalysis = value;
    },
    async updateResultDate() {
      if (this.resultDate) {
        this.isLoadingDateUpdate = true;
        try {
          let response = await this.updateResult({
            analysisId: this.folio._id,
            date: this.resultDate,
          });
          if (response) {
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: this.translations.dateUpdated,
              timeout: 5000,
            });
            await this.fetchData();
            this.errorResultDate.error = false;
            this.errorResultDate.message = "";
            this.folio = null;
          }
        } catch (error) {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: `${this.translations.somethingWentWrong} ${error.message}`,
            timeout: 5000,
          });
        }
        this.isLoadingDateUpdate = false;
      } else {
        // show error
        this.errorResultDate.error = true;
        this.errorResultDate.message = this.translations.requiredField;
      }
    },
    addSampleForSequencing(reception) {
      this.samplesForSequencing.push(reception);

      this.SET_ALERT_TYPE({
        visible: true,
        type: "success",
        text: this.translations.samplePreparedForSequencing,
        timeout: 5000,
      });
    },
    discardSampleForSequencing(reception) {
      const indexReception = this.samplesForSequencing.findIndex(
        (r) => r.folio_sample === reception.folio_sample
      );

      if (indexReception !== -1) {
        this.samplesForSequencing.splice(indexReception, 1);

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: this.translations.sampleDiscarded,
          timeout: 5000,
        });
      }
    },
    checkSampleSequencing(item) {
      const reception = this.samplesForSequencing.find(
        (s) => s.folio_sample == item.folio_sample
      );

      if (reception) return true;
      else return false;
    },
    async samplesAssignedToReport() {
      this.samplesForSequencing = [];
      await this.fetchData();
    },
    async deleteFolio() {
      try {
        const request = await this.deleteAnalysisFiltered({
          folio: this.folioForDelete,
        });

        if (request.error) {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: `${this.translations.somethingWentWrong} ${error.message}`,
            timeout: 5000,
          });

          this.showDeleteFolioDialog = false;
        } else {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: this.translations.folioDeletedSuccessfully,
            timeout: 4000,
          });

          this.showDeleteFolioDialog = false;
          await this.fetchData();
        }
      } catch (error) {
        console.error(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `${this.translations.somethingWentWrong} ${error.message}`,
          timeout: 5000,
        });
      }
    },
    cancelDeletion() {
      this.showDeleteFolioDialog = false;
    },
    deleteFolioEvent(folio) {
      this.folioForDelete = folio;
      this.showDeleteFolioDialog = true;
    },
    checkPermissions() {
      let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : "";
      if (this.permissions.find((per) => per == "lab_reception_seq")) {
        this.headersChild.push({
          text: this.translations.sequencing,
          value: "sequencingReport",
          sortable: false,
          align: "center",
        });
      }
      if (user.laboratory.type && user.laboratory.type.includes("MOLECULAR")) {
        this.sampleType = [
          {
            text: this.translations.soil,
            value: "CLASSIC",
          },
          {
            text: this.translations.plant,
            value: "MAS_VEGETABLE",
          },
          {
            text: this.translations.vegetalWithoutGeoreference,
            value: "VEGETABLE",
          },
          {
            text: this.translations.compost,
            value: "COMPOST",
          },
          {
            text: this.translations.product,
            value: "PRODUCT",
          },
          {
            text: this.translations.substrate,
            value: "SUBSTRATUM",
          },
          {
            text: this.translations.liquid,
            value: "LIQUID",
          },
        ];
      }
    },
    async guardSamples() {
      this.SET_STORED_SAMPLES(this.selectedParend);
      this.showStoredSamplesModal = true;
    },
    async reloadTable() {
      this.selectedParend = [];
      await this.fetchData();
    },
  },
  watch: {
    async search() {
      this.page = 1;
      await this.fetchData();
    },
    async page() {
      if (this.page != parseInt(this.$route.params.page)) {
        this.$router.replace({
          params: {
            page: this.page,
          },
        });
      }
      await this.fetchData();
    },
    async refreshReceptionTable(value) {
      if (value) {
        this.SET_REFRESH_TABLE(false);
        await this.fetchData();
      }
    },
    selectedParend(value) {
      this.SET_FOLIOS_SELECTED(value);
    },
    cleanParent(value) {
      if (value) {
        this.selectedParend = [];
        this.CLEAN_PARENT(false);
      }
    },
    async searchFarm(value) {
      try {
        console.log(value);
        if (value) {
          let payload = {
            search: value,
            page: 1,
          };
          let response = await this.getFarms(payload);
          if (response.error) throw response;
          this.farms = response.famrs;
        } else {
          this.farms = [];
          this.farmFound = null;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async searchLab(value) {
      try {
        if (value) {
          let payload = {
            search: value,
          };
          let response = await this.getMolecuclarLaboratories(payload);
          if (response.error) throw response;
          this.laboratories = response.laboratories;
        } else {
          this.laboratories = [];
          this.labFound = null;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async searchPackage(value) {
      try {
        const req = await this.getPackagesByName({ name: value });

        if (req.error) throw req;

        this.packages = req.packages;
        console.log(req);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped></style>
