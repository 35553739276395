<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ message }}
        <v-text-field v-model="inputFolio" label="Folio" :hint="folio" :error-messages="errorMessages" class="mx-6" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="cancelDelete" :disabled="loading">
          {{ translations.cancel }}
        </v-btn>
        <v-btn color="green" text @click="confirmDelete" :loading="loading">
          {{ translations.confirm }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//Vuex
import { mapGetters } from "vuex";
export default {
  props: {
    title: String,
    message: String,
    folio: String,
    showDialog: Boolean
  },
  data() {
    return {
      dialog: false,
      inputFolio: '',
      errorMessages: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters("session", ["currentTranslations"]),
    translations() {
      return this.currentTranslations;
    },
  },
  methods: {
    confirmDelete() {
      this.errorMessages = [];
      if (this.inputFolio === this.folio) {
        this.loading = true;
        this.$emit('delete-confirmed', this.folio);
      } else {
        this.errorMessages = [this.translations.enterCorrectFolioToDelete];
      }
    },
    cancelDelete() {
      this.$emit('canceled')
    }
  },
  watch: {
    showDialog() {
      this.inputFolio = '';
      this.errorMessages = [];
      this.loading = false;
      this.dialog = this.showDialog;
    }
  }
}
</script>
