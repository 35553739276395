<template>
  <v-container class="align-self-start">
    <v-row>
      <v-col cols="12" lg="3" class="mb-4">
        <FilterSoilTable />
      </v-col>
      <v-col cols="12" lg="9">
        <SoilTable />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SoilTable from "@/components/soil_storage/SoilTable.vue";
import FilterSoilTable from "@/components/soil_storage/FilterSoilTable.vue";

export default {
  components: {
    SoilTable,
    FilterSoilTable,
  },
};
</script>
