<template>
<v-container>
    <v-row>
        <v-col align="end" cols="12">
            <v-btn color="primary" @click="addItems" :disabled="itemsSelected.length == 0">
                <v-icon> mdi-plus </v-icon>
                Agregar
            </v-btn>
        </v-col>
        <v-col>
            <v-data-table v-model="itemsSelected" item-key="_id" :headers="headers" :items="vectors" :items-per-page="10" class="elevation-0" no-data-text="No hay registros" hide-default-footer fixed-header :style="{ maxHeight: '400px', overflowY: 'auto' }" :loading="isLoadingTable" show-select>
                <template #[`item.createdAt`]="{ item }">
                    <span>
                        {{ dateFormat(item.createdAt) }}
                    </span>
                </template>
                <template #[`item.microorganism`]="{ item }">
                    <span>
                        {{ getMicroorganismLabel(item.microorganism) }}
                    </span>
                </template>
                <template #[`item.status`]="{ item }">
                    <span>
                        {{ getStatus(item.status) }}
                    </span>
                </template>
                <template #[`item._id`]="{ item }">
                    <v-container>
                        <v-btn color="primary" small class="mx-1" @click="openUpdateModal(item)" v-if="item.status == 'AVAILABLE'">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                        <v-btn color="warning" small class="mx-1" @click="openDeleteModal(item)" v-if="item.status == 'AVAILABLE'">
                            <v-icon>
                                mdi-delete-forever
                            </v-icon>
                        </v-btn>
                        <v-btn color="blue" small class="mx-1" @click="openRestoreModal(item)" v-if="item.status == 'NOT_AVAILABLE'" dark>
                            <v-icon>
                                mdi-delete-restore
                            </v-icon>
                        </v-btn>
                    </v-container>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="numPages" total-visible="10" color="primary"></v-pagination>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
// VUEX
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
//Libraries
import dayjs from "dayjs";
export default {
    data: () => ({
        headers: [{
                text: "Fecha almacenamiento",
                sortable: false,
                align: "center",
                value: "createdAt",
            },
            {
                text: "ID",
                sortable: false,
                value: "ampliconId",
            },
            {
                text: "Microorganismo",
                sortable: false,
                value: "microorganism",
            },
            {
                text: "Cantidad",
                sortable: false,
                value: "quantity",
            },
            {
                text: "Estatus",
                sortable: false,
                value: "status",
            },
        ],
        vectors: [],
        page: 1,
        numPages: 1,
        isLoadingTable: false,
        showFormCreateVectorModal: false,
        showFormUpdateVectorModal: false,
        showConfirmationDeleteVectorModal: false,
        showConfirmationRestoreVectorModal: false,
        itemsSelected: [],
    }),
    computed: {
        ...mapState("vector", ["filterTable", "searchTable", "itemsPerPageTable"]),
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        ...mapActions("vector", ["getVectors"]),
        ...mapMutations("vector", ["SET_DATA_IDICATORS_TABLE", "SET_ROW_TABLE"]),
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        async fetchData() {
            try {
                this.isLoadingTable = true;
                let payload = {
                    filter: this.filterTable,
                    page: this.page,
                    search: this.searchTable,
                    perPageItems: this.itemsPerPageTable,
                };
                let response = await this.getVectors(payload);
                this.numPages = response.pages;
                this.vectors = response.vectors;
                this.SET_DATA_IDICATORS_TABLE({
                    quantityAll: response.quantityAll,
                    quantityAvailables: response.quantityAvailables,
                });
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.isLoadingTable = false;
        },
        async reloadTable() {
            this.page = 1;
            await this.fetchData();
        },
        dateFormat(value) {
            return `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs` || "--/--/----";
        },
        getStatus(value) {
            let status = {
                AVAILABLE: "Disponible",
                NOT_AVAILABLE: "No disponible"
            }
            return status[value] || "NA"
        },
        getMicroorganismLabel(value) {
            let moTypes = {
                "fungi": "Hongo",
                "nematode": "Nematodo",
                "bacteria": "Bacteria",
            }
            return `${value.name} (${moTypes[value.moType]})`
        },
        openUpdateModal(item) {
            this.SET_ROW_TABLE(item)
            this.showFormUpdateVectorModal = true
        },
        openDeleteModal(item) {
            this.SET_ROW_TABLE(item)
            this.showConfirmationDeleteVectorModal = true
        },
        openRestoreModal(item) {
            this.SET_ROW_TABLE(item)
            this.showConfirmationRestoreVectorModal = true
        },
        addItems() {
            this.$emit("addItems", {
                items: this.itemsSelected,
                type: "VECTOR"
            })
        }
    },
    watch: {
        async searchTable() {
            await this.reloadTable();
        },
        async itemsPerPageTable() {
            await this.reloadTable();
        },
        async page() {
            await this.fetchData();
        },
        async filterTable() {
            await this.reloadTable();
        },
    },
};
</script>

<style scoped>
.headline {
    font-size: 1.5rem;
    font-weight: bold;
}
</style>
