import axios from "axios";

export async function getVectors(context, payload) {
  try {
    const { data } = await axios.get(`/vectors`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function createVector(context, payload) {
  try {
    const { data } = await axios.post(`/vectors/create`, payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function updateVector(context, { payload, id }) {
  try {
    const { data } = await axios.put(`/vectors/update`, payload, {
      params: { vector_id: id },
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function deleteVector(context, id) {
  try {
    const { data } = await axios.delete(`/vectors/delete`, {
      params: { vector_id: id },
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function enableVector(context, id) {
  try {
    const { data } = await axios.put(`/vectors/enable`, null, {
      params: { vector_id: id },
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}
