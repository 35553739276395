export function SET_FILTER_TABLE(state, filter) {
  state.filterTableSoil = filter;
}

export function SET_SEARCH_TABLE(state, search) {
  state.searchTableSoil = search;
}

export function SET_ITEMS_PER_PAGE_TABLE(state, itemsPerPage) {
  state.itemsPerPageTableSoil = itemsPerPage;
}

export function SET_HEADERS_TABLE(state, headers) {
  state.headers = headers;
}

export function SET_DATA_IDICATORS_TABLE(state, indicators) {
  state.quantityAll = indicators.quantityAll;
  state.dnaStoredQuantity = indicators.dnaStoredQuantity;
}

export function SET_ROW_TABLE(state, row) {
  state.rowSelectedTableSoil = row;
}
