<template>
<v-container class="align-self-start">
    <v-row class="align-center">
        <v-col class="d-flex align-center">
            <v-icon color="primary" size="36" class="mr-3">
                mdi-bacteria
            </v-icon>
            <h2 class="text-h5 font-weight-medium">
                Cepario
            </h2>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <!-- <v-tabs color="primary" grow>
          <v-tab> Cepario de trabajo </v-tab>
          <v-tab> Cepario I+D </v-tab>
          <v-tab-item eager>
            <ceparium-working />
          </v-tab-item>
          <v-tab-item eager>
            <ceparium-i-d />
          </v-tab-item>
        </v-tabs> -->
            <!-- <ceparium-i-d /> -->
            <general-ceparium />
        </v-col>
    </v-row>
</v-container>
</template>

<script>
// vuex
import {
    mapActions,
    mapMutations
} from "vuex";
//Libraries
import dayjs from "dayjs";
// components
import CepariumWorking from "@/components/ceparium/CepariumWorking.vue";
import CepariumID from "@/components/ceparium/CepariumID.vue";
import GeneralCeparium from '../components/ceparium/GeneralCeparium.vue';

export default {
    components: {
        CepariumWorking,
        CepariumID,
        GeneralCeparium,
    },
    data: () => ({
        menu: null,
        solubilizationP: null, //phosphorus
        solubilizationK: null, //potasium
        bioControl: null,
        identification: null,
        searchMo: null,
        isLoading: false,
        closeOnClick: false,
        biocontrols: ["Muy malo", "Malo", "Deficiente", "Bueno", "Muy Bueno"],
        solubilizations: [{
                text: "Mala",
                value: "Low",
            },
            {
                text: "Media",
                value: "Medium",
            },
            {
                text: "Buena",
                value: "Good",
            },
            {
                text: "Muy buena",
                value: "Very good",
            },
        ],
        microorganisms: [],
        page: 1,
        numPages: 1,
        search: null,
        filter: null,
        loadTable: false,
        tab: "#reseed",
        headers: [{
                text: "ID. Cepa",
                sortable: false,
                value: "strainId",
            },
            {
                text: "Folio",
                value: "folio",
                sortable: false
            },
            {
                text: "Fecha de preservación",
                value: "preservedDate",
                sortable: false,
            },
            {
                text: "Organismo",
                value: "identificationName",
                sortable: false
            },
            {
                text: "# Tubos",
                value: "tubesQuantityAvailable",
                sortable: false
            },
        ],
        preserved_strains: [],
        strainPreservedSelected: null,
    }),
    methods: {
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        ...mapActions("ceparium", ["getPrserved"]),
        ...mapActions("identification", ["searchIdentifications"]),
        changeIdentificationValue() {
            if (!this.searchMo) {
                this.identification = null;
            }
        },
        dateFormat(value) {
            const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
            return date || "--/--/----";
        },
        rowSelect(rowValue) {
            this.preserved_strains = this.preserved_strains.map((data) => {
                if (rowValue._id == data._id) {
                    data.clicked = true;
                } else {
                    data.clicked = false;
                }
                return data;
            });
            this.strainPreservedSelected = this.mapSelection(rowValue);
        },
        row_classes(value) {
            if (value.clicked) {
                return "custom-highlight-row";
            }
        },
        closeDetail() {
            this.strainPreservedSelected = null;
            this.preserved_strains = this.preserved_strains.map((data) => {
                data.clicked = false;
                return data;
            });
        },
        mapSelection(preserved) {
            return {
                _id: preserved.isolate._id,
                folioAnalysis: preserved.folio,
                idTest: "NA",
                medium: "NA",
                inoculum: preserved.identificationName,
                sampleType: "",
                businessName: "",
                analysisType: "Resiembra",
                status: preserved.isolate.ceparium_data.status,
                mbTestData: null,
                molTestData: null,
                isolate: preserved.isolate,
                strain: preserved.isolate.strain,
                laboratory: null,
                laboratory_id: null,
            };
        },
        async applyFilter() {
            this.filter = {};
            if (this.identification) {
                this.filter.control = this.identification._id;
            }
            if (this.bioControl) {
                this.filter.bioControl = this.bioControl;
            }
            if (this.solubilizationP) {
                this.filter.solubilizationP = this.solubilizationP.value;
            }
            if (this.solubilizationK) {
                this.filter.solubilizationK = this.solubilizationK.value;
            }
            this.menu = false;
            this.strainPreservedSelected = null;
            await this.fetchData();
        },
        async clearFilter() {
            this.identification = null;
            this.bioControl = null;
            this.solubilizationP = null;
            this.solubilizationK = null;
            this.menu = false;
            this.filter = null;
            this.strainPreservedSelected = null;
            await this.fetchData();
        },
        async fetchData() {
            try {
                this.loadTable = true;
                let payload = {
                    page: this.page,
                    filter: this.filter,
                    search: this.search,
                };
                let response = await this.getPrserved(payload);
                if (!response) throw Error(response);
                this.preserved_strains = response.preserved;
                this.numPages = response.pages;
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 4000,
                });
            }
            this.loadTable = false;
        },
    },
    async mounted() {
        await this.fetchData();
    },
    watch: {
        async page() {
            await this.fetchData();
        },
        async search() {
            this.strainPreservedSelected = null;
            this.page = 1;
            this.numPages = 1;
            await this.fetchData();
        },
        async searchMo(value) {
            if (value) {
                this.isLoading = true;
                try {
                    const response = await this.searchIdentifications({
                        name: value,
                    });
                    if (response.error) throw response;
                    this.microorganisms = response.IDENTIFICATIONS;
                } catch (e) {
                    console.log(e);
                }
                this.isLoading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-highlight-row {
    background-color: rgba(4, 193, 5, 0.13);
    font-weight: bold;
}
</style>
