<template>
    <v-dialog v-model="show" width="40vw" scrollable>
        <v-card height="auto" elevation="5" class="rounded-xl" min-height="200">
            <v-card-title class="text-h5 lighten-2">
                Registros en tiempo
            </v-card-title>
            <v-card-text>
                <div v-if="isLoading">
                    <v-row class="text-start mt-2">
                        <v-col>
                            <v-progress-circular :size="70" :width="7" color="primary"
                                indeterminate></v-progress-circular>
                            <span class="font-weight-bold font-italic ml-5">Cargando eventos...</span>
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
                        <v-timeline-item fill-dot v-for="(log, i) in logs" :key="i" :color="log.color"
                            :icon="getIcon(log.type)">
                            <v-card color="primary" dark>
                                <v-card-title class="text-subtitle-1">
                                    {{ getType(log.type) }}</v-card-title>
                                <v-card-text class="white text--primary">
                                    <v-row no-gutters>
                                        <v-col cols="12"><span class="text-caption"><span
                                                    class="font-weight-bold font-italic">Hora:</span>
                                                {{ dateFormat(log.date) }}</span></v-col>
                                        <v-col cols="12"><span class="text-caption">
                                                <span class="font-weight-bold font-italic">Usuario:</span>
                                                {{ log.user.name }} {{ log.user.lastName }}</span></v-col>
                                        <template v-if="
                                            log.type == 'REDESIGN' || log.type == 'SIMPLE_CHANGE' || log.type == 'END_VALIDATION_PROCESS'
                                        ">
                                            <v-col cols="12"><span class="text-caption">
                                                    <span class="font-weight-bold font-italic">Version:</span>
                                                    {{ log.version }}</span></v-col>
                                            <v-col cols="12">
                                                <span class="text-caption">
                                                    <span class="font-weight-bold font-italic">Cambios:
                                                    </span>
                                                    <v-spacer></v-spacer>
                                                    <span>min anterior: {{ log.meta_data.cq_min_old }}
                                                    </span>

                                                    <span>max anterior: {{ log.meta_data.cq_max_old }}
                                                    </span>
                                                    <v-spacer></v-spacer>
                                                    <span>min nuevo: {{ log.meta_data.cq_min_new }}
                                                    </span>

                                                    <span>max nuevo: {{ log.meta_data.cq_max_new }}
                                                    </span>
                                                    <v-spacer></v-spacer>
                                                    <span class="font-weight-bold font-italic"
                                                        v-if="log.meta_data.oligoIdentifier">Oligo:
                                                    </span>
                                                    {{ log.meta_data.oligoIdentifier }}
                                                </span></v-col>
                                        </template>
                                        <template v-if="log.type == 'VERSION_USE'">
                                            <v-col cols="12">
                                                <span class="text-caption">
                                                    <span class="font-weight-bold font-italic">Version:
                                                    </span>
                                                    {{ log.version }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12">
                                                <span class="text-caption">
                                                    <span class="font-weight-bold font-italic">Laboratorio:
                                                    </span>
                                                    {{ log.laboratory.name }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12">
                                                <span class="text-caption">
                                                    <span class="font-weight-bold font-italic">Rango cq:
                                                    </span>
                                                    <v-spacer></v-spacer>
                                                    <span>min: {{ log.meta_data.cq_min }} </span>

                                                    <span>max: {{ log.meta_data.cq_max }} </span>
                                                </span>
                                            </v-col>
                                        </template>
                                        <template v-if="log.type == 'CREATION'">
                                            <v-col cols="12">
                                                <span class="text-caption">
                                                    <span class="font-weight-bold font-italic">Version:
                                                    </span>
                                                    {{ log.version }}
                                                </span>
                                            </v-col>
                                        </template>
                                        <template
                                            v-if="log.type == 'UPDATE_OLIGO' || log.type == 'FINISH_OLIGO' || log.type == 'DISCARDED_OLIGO' || log.type == 'NEW_OLIGO' || log.type == 'SYNTHESIS_IN_PROGRESS' || log.type == 'SYNTHESIS_FINISHED' || log.type == 'CMDA_RELEASE'">
                                            <v-col cols="12">
                                                <span class="text-caption">
                                                    <span class="font-weight-bold font-italic">Version:
                                                    </span>
                                                    {{ log.version }}
                                                </span>
                                            </v-col>
                                            <!-- <v-col cols="12">
                                            <span class="text-caption">
                                                <span class="font-weight-bold font-italic">Laboratorio:
                                                </span>
                                                {{ log.laboratory.name }}
                                            </span>
                                        </v-col> -->
                                            <v-col cols="12">
                                                <span class="text-caption">
                                                    <span class="font-weight-bold font-italic">Oligo:
                                                    </span>
                                                    {{ log.meta_data.oligoIdentifier }}
                                                </span>
                                            </v-col>
                                        </template>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>
                    <div v-if="!isLoading && logs.length == 0">
                        <v-row class="text-center mt-2">
                            <v-col>
                                <span class="font-weight-bold font-italic">No hay registros de eventos</span>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-card-text>
            <v-divider></v-divider>
        </v-card>
    </v-dialog>
</template>

<script>
//Vuex
import {
    mapActions,
    mapState,
    mapGetters,
    mapMutations
} from "vuex";
//Libraries
import dayjs from "dayjs";
export default {
    data: () => ({
        isLoading: false,
        logs: [],
    }),
    props: {
        value: Boolean,
    },
    computed: {
        ...mapState("laboratory", ["molecularMarkerToUpdate"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        getType(value) {
            let values = {
                VERSION_USE: "Uso de marcador en laboratoro",
                REDESIGN: "Rediseño",
                SIMPLE_CHANGE: "Cambio de valores",
                RESTORE: "Restaurado",
                DELETE: "Eliminado",
                CREATION: "Creación",
                UPDATE_OLIGO: "Actualización de Oligo",
                FINISH_OLIGO: "Oligo Terminado",
                DISCARDED_OLIGO: "Oligo Descartado",
                NEW_OLIGO: "Nuevo Oligo agregado",
                SYNTHESIS_IN_PROGRESS: "Inicio síntesis",
                SYNTHESIS_FINISHED: "Finalizo síntesis",
                "START_VALIDATION_PROCESS": "Inicio de validaciones",
                "END_VALIDATION_PROCESS": "Nueva version marcador",
                "CMDA_RELEASE": "Notificación de nueva versión",
                "CMDA_SEND": "Notificación de envio de marcadores a CMDA",
            };
            return values[value] || value;
        },
        dateFormat(value) {
            if (value) {
                const date = `${dayjs(value).format("DD-MM-YYYY - HH:mm")} hrs`;
                return date ? date : "N/A";
            } else {
                return "N/A";
            }
        },
        getIcon(type) {
            let icon = "";
            switch (type) {
                case "REDESIGN":
                    icon = "mdi-swap-horizontal";
                    break;

                case "END_VALIDATION_PROCESS":
                    icon = "mdi-swap-horizontal";
                    break;

                case "SIMPLE_CHANGE":
                    icon = "mdi-swap-horizontal";
                    break;

                case "VERSION_USE":
                    icon = "mdi-counter";
                    break;

                case "DELETE":
                    icon = "mdi-delete";
                    break;

                case "RESTORE":
                    icon = "mdi-delete-restore";
                    break;

                case "DISCARDED_OLIGO":
                    icon = "mdi-delete";
                    break;

                default:
                    icon = "mdi-calendar";
                    break;
            }

            return icon;
        },
    },
    mounted() {
        try {
            this.isLoading = true;
            this.logs = this.molecularMarkerToUpdate.logs.reverse() || [];
        } catch (error) {
            console.log(error);
            this.SET_ALERT_TYPE({
                visible: true,
                type: "error",
                text: `Algo salió mal. Error: ${error.message}`,
                timeout: 5000,
            });
        }
        this.isLoading = false;
    },
};
</script>
