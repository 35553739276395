<template>
  <v-dialog v-model="dialogStatus" persistent max-width="450">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small color="success" v-bind="attrs" v-on="on" :disabled="nextReplica == 5 || (stage != 'LISIS' && samples.length == 0 || (stage != 'LISIS' && !samplesPerReplicas[nextReplica]))">
        <v-icon left dark> mdi-plus </v-icon>
        {{translations.new_repetition}}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        <template> {{translations.new_repetition}} </template>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" v-if="stage == 'LISIS'"> {{translations.sample_selection}}.</v-col>
            <v-col cols="12">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-autocomplete
                  v-model="samplesSelected"
                  :items="samplesToSelect"
                  :rules="autocompleteRules"
                  :disabled="stage != 'LISIS'"
                  item-text="folioAnalysis"
                  :label="translations.samples"
                  return-object
                  multiple
                  chips
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :close="stage == 'LISIS'"
                      @click="data.select"
                      @click:close="remove(data.item)"
                      :color="
                        data.item.sampleType.includes('VEGETABLE')
                          ? 'primary'
                          : 'warning'
                      "
                    >
                      {{ data.item.folioAnalysis }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <!-- <v-list-item-avatar>
                    <img :src="data.item.avatar" />
                  </v-list-item-avatar> -->
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.folioAnalysis"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="
                          data.item.sampleType.includes('VEGETABLE')
                            ? translations.plant.toUpperCase()
                            : translations.soil.toUpperCase()
                        "
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <!-- <small v-if="stage == 'ELUTION'">Las muestras serán asignadas en una placa de repeticiónes</small> -->
                <!-- <v-select
                    v-if="stage == 'ELUTION'"
                    label="Placa"
                >
                </v-select> -->
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="notAssign"> {{translations.cancel}} </v-btn>
        <v-btn color="warning darken-1" text @click="assign"> {{translations.accept}} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  data() {
    return {
      dialogStatus: false,
      samplesSelected: [],
      valid: true,
      autocompleteRules: [
       
      ],
    };
  },
  mounted(){
    this.autocompleteRules.push(
      (v) => v.length > 0 || this.translations.select_at_least_one_sample,
    )
  },
  props: {
    stage: {
      type: String,
      required: true,
    },
    samples: {
      type: Array,
      required: true,
    },
    replicas: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("session", ["currentTranslations"]),
    translations() {
      return this.currentTranslations;
    },
    nextReplica(){
        const lastReplica = parseInt(this.replicas[this.replicas.length - 1]);
        return (lastReplica + 1)
    },
    ...mapState('molecularLisisTube', ['samplesPerReplicas']),
    samplesToSelect(){
      if(this.dialogStatus){
        if(this.stage == 'LISIS'){
          return this.samples
        }else{

          return this.samples.filter(s => 
            this.samplesPerReplicas[this.nextReplica].find(sample => 
              s.folioAnalysis == sample.folioAnalysis
            ) 
          )
        }
      }else{
        return [];
      }
    }
  },
  methods: {
    assign() {
      if (this.$refs.form.validate()) {
        this.dialogStatus = false;
        this.$emit("acepted", this.samplesSelected);
      }
    },
    notAssign() {
      this.dialogStatus = false;
      this.$emit("canceled");
    },
    remove(item) {
      const index = this.samplesSelected.map((e) => e._id).indexOf(item._id);
      if (index >= 0) this.samplesSelected.splice(index, 1);
    },
  },
  watch: {
    dialogStatus() {
      if (this.dialogStatus) {
        this.samplesSelected = [...this.samplesToSelect];
      } else {
        this.samplesSelected = [];
      }
    },
  },
};
</script>

<style>
</style>