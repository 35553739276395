<template>
    <v-dialog v-model="show" width="50vw" scrollable persistent>
        <v-card height="auto" elevation="5" class="rounded-lg" min-height="200">
            <v-card-title class="text-h5 lighten-2">Inhabilitar</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <span class="subtitle-1 font-weight-bold" v-if="rowTableSelected">
                                ¿Estas seguro que deseas inhabilitar el vector {{ rowTableSelected.ampliconId }}?
                            </span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-btn color="gray" block @click="closeModal" dark :disabled="isLoading">Cerrar</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn color="primary" block @click="saveData" :loading="isLoading">Aceptar</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
//vuex
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    data: () => ({
        isLoading: false,
    }),
    props: {
        value: Boolean,
    },
    computed: {
        ...mapState("vector", ["rowTableSelected"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        ...mapActions("vector", ["deleteVector"]),
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        async saveData() {
            try {
                this.isLoading = true
                await this.deleteVector(this.rowTableSelected._id);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Registro inhabilitado`,
                    timeout: 3000,
                });
                this.$emit("close_update");
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.isLoading = false

        },
        closeModal() {
            this.cleanForm();
            this.$emit("close");
        },
    },
};
</script>