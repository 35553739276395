import axios from "axios";
import { DeviceUUID } from "device-uuid";

/**
 * Function to login
 * @param {function} { commit } - Vuex function
 * @param {Object} payload - email, password, keepSessionOpen
 * @returns {data} -  Returns user data
 */
export async function login({ commit }, payload) {
  try {
    const { data } = await axios.post(`/login`, {
      ...payload,
      device: getDeviceInfo(),
    });
    if (data.error) throw data;

    localStorage.setItem("user", JSON.stringify(data.USER));
    localStorage.setItem("refresh_token", data.TOKEN);
    localStorage.setItem("token_expiry", data.TOKEN_EXPIRY);
    localStorage.setItem("session", data.SESSION);

    commit("SET_USER_DATA", data);
    commit("SET_AUTHENTICATED_STATUS", true);

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get new token
 * @param {function} { commit } - Vuex function
 * @param {String} refreshToken - User refresh token
 * @returns {data} -  Returns token and token expiry data
 */
export async function getNewToken({ commit }, refreshToken) {
  try {
    const { data } = await axios.post(`/refreshToken`, { refreshToken });
    if (data.error) throw data;
    const token = data.TOKEN;
    localStorage.setItem("token_expiry", data.TOKEN_EXPIRY);
    commit("SET_TOKEN", token);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get new token
 * @param {function} { commit } - Vuex function
 * @param {String} refreshToken - User refresh token
 * @returns {data} -  Returns token and token expiry data
 */
export async function setCurrentToken({ commit }) {
  let data = {
    USER: localStorage.getItem("user"),
    TOKEN: localStorage.getItem("refresh_token"),
    SESSION: localStorage.getItem("session"),
  };
  commit("SET_USER_DATA", data);
}

/**
 * Function to logout a user
 * @param {function} { commit } - Vuex function
 * @param {String} refreshToken - User refresh token
 */
export async function logout({ commit }, { refreshToken, session }) {
  try {
    const { data } = await axios.post("/logout", { refreshToken, session });
    if (data.error) throw data;
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("token_expiry");
    localStorage.removeItem("user");
    localStorage.removeItem("session");
    
    commit("SET_LOG_OUT");
    commit("SET_AUTHENTICATED_STATUS", false);

    return data;
  } catch (e) {
    return e.response.data;
  }
}

function getDeviceInfo() {
  const du = new DeviceUUID().parse();

  const dua = [
    du.language,
    du.platform,
    du.os,
    du.cpuCores,
    du.isDesktop,
    du.isMobile,
    du.isTablet,
    du.isWindows,
    du.isLinux,
    du.isLinux64,
    du.isMac,
    du.isiPad,
    du.isiPhone,
    du.isiPod,
    // du.isAuthoritative,
    // du.silkAccelerated,
    // du.isKindleFire,
    // du.isSmartTV,
    // du.pixelDepth,
    // du.isTouchScreen
  ];

  var uuid = du.hashMD5(dua.join(":"));

  return {
    deviceId: uuid,
    deviceInfo: du,
  };
}

// check user permission
export async function updateInfo({ commit }, payload) {
  try {
    const { data } = await axios.get("/user/updateInfo", { params: payload });
    if (data.error) throw data;
    if (data.user) {
      commit("SET_AUTHENTICATED_STATUS", true);
      commit("SET_PERMISSIONS", data.user.roles);

      delete data.user.roles;
      
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(data.user));
    }
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Commits a mutation to change the application language.
 * 
 * This function triggers the mutation `SET_LANGUAGE` to update the application's current language
 * in the state and persists the change using the provided language code.
 * 
 * @param {Object} context - The Vuex action context object, used to commit mutations.
 * @param {String} language - The target language code to set (e.g., 'en', 'es', 'pt').
 */
export function changeLanguage({ commit }, language) {
  commit("SET_LANGUAGE", language);
}
