export default {
  authenticatedUser: null,
  token: null,
  error: null,
  session: null,
  permissions: [],
  authenticated: null,
  // transltaions app configuration
  language: localStorage.getItem("language") || "en",
  translations: {
    en: {
      result_added_to_plate: "Result added to plate",
      report_generated: "Report generated",
      added_to_plate_sequence: "Added to plate sequence",
      evaluation_end: "Evaluation End",
      molecular_identification_end: "Molecular Identification End",
      measurement_end: "Measurement End",
      evaluation_start: "Evaluation Start",
      molecular_identification_start: "Molecular Identification Start",
      measurement_start: "Measurement Start",
      received: "Received",
      shipment: "Shipment",
      no_event_records: "There are no event records",
      destination: "Destination",
      origin: "Origin",
      user: "User",
      loading_events: "Loading events",
      time_records: "Time records",
      culture_not_valid_for_marker_detection: "The culture is not valid to detect the marker in this package",
      package_not_associated_reaction: "The package is not associated with any reaction",
      marker_not_in_available_list: "The marker is not in the list of available markers for detection",
      culture_not_contemplated_cmda: "The culture is not contemplated for the CMDA",
      marker_no_cq_min_max: "The marker doesn't have cQ min or cQ max",
      marker_not_associated: "The marker is not associated with the package",
      no_package_assigned: "The sample doesn't have an assigned package",
      some_identifications_not_saved: "Some identifications could not be saved",
      select_files: "Select the files",
      file_s: "File(s)",
      cause: "Cause",
      result_x_bar: "Result (x̄)",
      identification: "Identification",
      no_presence: "No presence",
      errors: "Errors",
      processing_files: "Processing file(s)",
      add: "Add",
      plant_sample: "Plant sample",
      not_detected: "Not detected",
      no_microorganisms_detected: "No microorganisms detected",
      discard: "Discard",
      high_cycle_or_low_efficiency_results: "Results with high detection cycle (cq) and/or low detection efficiency will be interpreted as",
      result_with_error: "Result with an error",
      results_with_errors: "Results with errors",
      high_cycle_detection_result: "Result with high detection cycle (cq)",
      high_cycle_detection_results: "Results with high detection cycle (cq)",
      low_detection_efficiency_result: "Result with low detection efficiency",
      detected_single: "Detected",
      low_detection_efficiency_results: "Results with low detection efficiency",
      detected: "Detected",
      documents: "Document(s)",
      results_without_culture: "Results without considering the culture",
      select_files_to_process: "Select one or more files to process, view, and save identified MOs.",
      sensitivity_limit: "Sensitivity limit 15 - 34",
      molecular_identification: "Molecular Identification",
      identification_date: "Identification date",
      file: "File",
      client_company_name: "Client company name",
      no_tests_to_revert: "No tests to revert",
      test_deleted_successfully: "Test(s) deleted successfully",
      analysis_type: "Analysis type",
      inoculum: "Inoculum",
      medium: "Medium",
      test_id: "Test ID",
      analysis_id: "Analysis ID",
      delete_tests: "Delete tests",
      tests: "Tests",
      reload_table: "Reload table",
      molecular_id: "Molecular ID",
      nematode: "Nematode",
      bacteria: "Bacteria",
      fungus: "Fungi",
      functional: "Functional",
      efficiency: "Efficiency",
      type: "Type",
      name: "Name",
      microorganisms_found: "Microorganisms found",
      source_file: "Source file",
      no_plates_selected: "No plates selected",
      plate_updated_successfully: "Plate updated successfully",
      plant_tissue: "Plant tissue",
      tools: "Tools",
      analysis_status: "Analysis status",
      analysis_stage: "Analysis stage",
      availability: "Availability",
      unit: "Unit",
      unit_id: "Unit ID",
      completed: "Completed",
      in_progress: "In progress",
      contaminated: "Contaminated",
      unregistered: "Unregistered",
      amplification: "Amplification",
      no_plates_to_show: "No plates to show",
      mic_plate: "MIC plate",
      pcr_plate: "PCR plate",
      well_plate: "Well plate",
      check_end_date: "Check the end date",
      check_start_date: "Check the start date",
      used: "Used",
      loading: "Loading",
      no_results_found: "No results found",
      end: "End",
      start: "Start",
      filter_plates_used_in_date_range: "Filter plates used in a date range",
      plates_added_successfully: "Plates added successfully",
      amplification_plate: "Amplification plate",
      elution_plate: "Elution plate",
      purification_plate: "Purification plate",
      print: "Print",
      number_of_plates: "No. of plates",
      choose_kit_type: "Choose kit type",
      label_type: "Label type",
      label_generation: "Label generation",
      print_labels: "Print labels",
      cmda_plates: "CMDA plates",
      click_cells_to_select_samples_for_amplification:
        "Click on the cells to select the samples for the amplification stage.",
      repetition: "Repetition",
      water: "Water",
      no_results: "No results",
      repetitions: "Repetitions",
      assign_samples_to_plate: "Assign samples to plate",
      select_at_least_one_sample: "You must select at least one sample",
      sample_selection: "Sample selection",
      new_repetition: "New repetition",
      the_sample: "The sample",
      sample_observations: "Sample observations",
      samples_in_repetitions: "Samples in repetitions",
      print_tube_labels: "Print tube labels",
      analysis: "Analysis",
      elución: "Elution",
      purificación: "Purification",
      lisis: "Lysis",
      finished: "Finished",
      inProcess: "In process",
      loadingSamplesInTransit: "Loading samples in transit",
      noSamplesInTransit: "No samples in transit",
      samplesExported: "Samples exported",
      noSamplesFoundToExport: "No samples found to export",
      observations: "Observations",
      plantSamples: "Plant samples",
      soilSamples: "Soil samples",
      month: "Month",
      notAvailable: "Not available",
      available: "Available",
      sampleStatus: "Sample status",
      exportToExcel: "Export to Excel",
      exportStoredSamples: "Export stored samples",
      experimentalUnit: "Experimental unit",
      noValuation: "No valuation",
      cellulolyticActivity: "Cellulolytic activity",
      siderophores: "Siderophores",
      potassiumSolubilization: "Potassium (K) solubilization",
      phosphorusSolubilization: "Phosphorus (P) solubilization",
      negativeControl: "Negative control",
      nutrition: "Nutrition",
      control: "Control",
      soilDiseaseControl: "Soil disease control",
      noNematodes: "No nematodes",
      nematodes: "Nematodes",
      noFungi: "No fungi",
      fungi: "Fungi",
      noBacteria: "No bacteria",
      concentration: "Concentration",
      bacteria: "Bacteria",
      noNotDetected: "No 'not detected'",
      notDetected: "Not detected",
      noPathogens: "No pathogens",
      pathogens: "Pathogens",
      noBeneficials: "No beneficials",
      beneficials: "Beneficials",
      microorganismIdentification: "Microorganism Identification",
      noSoilHealthParameters: "No soil health parameters",
      soilTexture: "Soil Texture",
      carbonCapturePOX: "Carbon Capture (POX)",
      organicMatter: "Organic Matter",
      soilHealthParameters: "Soil Health Parameters",
      samplingZoneNotAvailable: "Sampling zone not available",
      receptionDate: "Reception Date",
      qrCode: "QR Code",
      noDescription: "No description",
      sampleDescription: "Sample Description",
      projects: "Projects",
      labels: "Labels",
      profitability: "Profitability",
      qpcrResults: "QPCR Results",
      preservation: "Preservation",
      receptionNematodes: "Reception / Nematodes",
      mediaAndInoculation: "Media and inoculation",
      plates: "Plates",
      logInAgain: "Log in again",
      reload: "Reload",
      sessionExpiredMessage:
        "You can log in again or refresh the page if you have already done so.",
      sessionExpired: "The session has expired",
      logout: "Log out",
      sessionExpired: "The session has expired",
      sessionExpiresIn: "The session expires in",
      sampleDiscarded: "Sample discarded",
      samplePreparedForSequencing: "Sample prepared for sequencing",
      dateUpdated: "Date updated",
      reportGeneratedSuccessfully: "Report generated successfully",
      folioUpdatedSuccessfully: "Folio updated successfully",
      noEstimatedDate: "No estimated date",
      update: "Update",
      selectErrorType: "Select error type",
      registrationErrors: "Registration errors",
      generateFinalReport: "Generate final report",
      processedMolecularMarkersInFolio:
        "Molecular markers processed in the folio",
      unavailableSamples: "Unavailable samples",
      availableSamples: "Available samples",
      all: "All",
      pending: "Pending",
      withErrors: "With errors",
      noErrors: "No errors",
      responsible: "Responsible",
      analysisStage: "Analysis stage",
      lastModification: "Last modification",
      fieldRegistration: "Field registration",
      samplingZoneName: "Sampling zone name",
      subsamples: "Subsamples",
      containerQR: "Container QR",
      sampleFolio: "Sample folio",
      actions: "Actions",
      lastReportSent: "Last report sent",
      estimatedResultDate: "Estimated result date",
      samples: "Samples",
      kitType: "Kit type",
      folio: "Folio",
      seconds: "A few seconds",
      minutes: "Minutes",
      minute: "Minute",
      hours: "Hours",
      hour: "Hour",
      days: "Days",
      day: "Day",
      deleteFolio: "Delete folio",
      results: "Results",
      physicochemical: "Physicochemical",
      sampleSentToLab: "Sample sent to laboratory",
      pendingContainers: "Pending containers",
      containersWithoutSamples: "Containers without samples",
      qPCRReport: "qPCR report",
      valuationReport: "Valuation report",
      measurementReport: "Measurement report",
      notGenerated: "Not generated",
      vegetalSampleTypes: {
        Seedling: "Seedling",
        Root: "Root",
        Seed: "Seed",
        Stem: "Stem",
        Leaf: "Leaf",
        Fruit: "Fruit",
        Flower: "Flower",
      },
      waiting: "Waiting",
      setupKit: "Setup kit",
      projectKit: "Project kit",
      loadingReceipts: "Loading receipts",
      noReceipts: "No receipts",
      notAvailable: "Not available",
      solid: "Solid",
      liquid: "Liquid",
      soilWithoutAdvisor: "Soil without advisor",
      substrate: "Substrate",
      product: "Product",
      compost: "Compost",
      vegetalWithoutGeoreference: "Vegetal without georeference",
      plant: "Plant",
      soil: "Soil",
      valuation: "Valuation",
      measurement: "Measurement",
      clearFilters: "Clear filters",
      apply: "Apply",
      selectCrop: "Select a crop",
      selectSampleType: "Select type of sample",
      sampleType: "Type of sample",
      selectType: "Select the type",
      reportGenerated: "Report generated",
      status: "Status",
      selectStatus: "Select status",
      noCrop: "No crop",
      selectAnalysisType: "Select type of analysis",
      analysisType: "Type of analysis",
      sampleGuard: "Sample guard",
      selectStatusType: "Select status type",
      producer: "Farmer",
      search: "Search",
      laboratory: "Laboratory",
      package: "Package",
      resultsIssuance: "Results issuance",
      selectDateRange: "Select date range",
      reception: "Reception",
      kitFocus: "Kit focus",
      selectKitFocus: "Select kit focus.",
      filters: "Filters",
      folioDeletedSuccessfully: "Folio deleted successfully.",

      enterCorrectFolioToDelete:
        "You must correctly enter the folio you want to delete.",
      confirm: "Confirm",
      enterFolioNumberToDelete: "Enter the folio number to delete it.",
      deleteFolioConfirmation: "Are you sure you want to delete the folio",
      //## Login
      login: "Login",
      email: "Email",
      password: "Password",
      keepSessionOpen: "Keep session open",
      accept: "Accept",
      sessionDuration: "Your session will last 2 hours",
      acceptTerms: "You must accept the terms to continue",
      welcome: "Welcome",
      requiredField: "The field is required",
      validEmail: "You must enter a valid email address",
      incorrectCredentials: "Incorrect username or password",
      incorrectPassword: "Incorrect password",
      //## Reception,
      registeredSamples: "Registered samples",
      samplesInAnalysis: "Samples under analysis",
      analyzedSamples: "Samples analyzed",
      receiveSamples: "Receive samples",
      addEvidence: "Add evidence",
      images: "Images",
      kitReception: "Kit reception",
      sample: "Sample",
      addSamplesToAnalysis: "Add samples to analysis",
      continue: "Continue",
      receptionConfirmation: "Reception confirmation",
      confirmAddSamplesToReceptions:
        "Are you sure you want to add these samples to receipts?",
      cancel: "Cancel",
      create: "Create",
      selectKitType: "Select the kit type",
      kitObservations: "Kit observations",
      transferSamples: "Transfer samples",
      sampleTransfer: "Sample transfer",
      selectLaboratoryToSend: "Select the laboratory to send to",
      noAvailableLaboratory: "No available laboratory found",
      send: "Send",
      samplesInLaboratory: "Samples in laboratory",
      samplesInTransit: "Samples in transit",
      soilPreservation: "Soil preservation",
      generatedReports: "Generated reports",
      download: "Download",
      close: "Close",
      sampleInformation: "Sample information",
      sampleDetail: "Sample detail",
      sampleType: "Sample type",
      samplingZone: "Sampling zone",
      crop: "Crop",
      norCrop: "No crop",
      samplingDate: "Sampling date",
      sampler: "Sampler",
      viewEvidence: "View evidence",
      images: "Images",
      updateFolio: "Update folio",
      analysisFolio: "Analysis folio",
      addSamplesToAnalysis: "Add samples to analysis",
      confirmUpdateFolio: "Are you sure you want to update this folio?",
      update: "Update",
      validateSamples: "Validate samples",
      delete: "Delete",
      addTags: "Add tags",
      analysisFolio: "Analysis folio",
      analysisStatus: "Analysis status",
      reportGeneration: "Report generation",
      report: "Report",
      traditional: "Traditional",
      hybrid: "Hybrid",
      other: "Other",
      sequencing: "Sequencing",
      qrDuplicated: "The QR is duplicated",
      containerReceived: "The container has already been received",
      sampleReceived: "The sample has already been received",
      qrNotRegistered: "QR not registered",
      qrDoesNotExist: "QR does not exist",
      somethingWentWrong: "Something went wrong. Error:",
      receptionUpdated: "Reception updated",
      receptionCreated: "Reception created",
      received: "Received",
      measuring: "Measuring",
      measured: "Measured",
      valuing: "Valuing",
      valued: "Valued",
      crop_name_list: {
        olive: "Olive",
        chard: "Chard",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Avocado",
        garlic: "Garlic",
        sesame: "Sesame",
        basil: "Basil",
        artichoke: "Artichoke",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Shrunken Alfalfa",
        greenAlfalfa: "Green Alfalfa",
        cotton: "Cotton",
        almond: "Almond",
        birdseed: "Birdseed",
        amaranth: "Amaranth",
        anise: "Anise",
        celery: "Celery",
        blueberry: "Blueberry",
        myrtle: "Myrtle",
        rice: "Rice",
        greenForageOats: "Green Forage Oats",
        oatGrain: "Oat Grain",
        bamboo: "Bamboo",
        eggplant: "Eggplant",
        beetroot: "Beetroot",
        broccoli: "Broccoli",
        peanut: "Peanut",
        cocoa: "Cocoa",
        cherryCoffee: "Cherry Coffee",
        pumpkin: "Pumpkin",
        sweetPotato: "Sweet Potato",
        sugarCane: "Sugar Cane",
        canola: "Canola",
        carambolo: "Carambola",
        safflower: "Safflower",
        barley: "Barley",
        greenForageBarley: "Green Forage Barley",
        onion: "Onion",
        chives: "Chives",
        ryeGrain: "Rye Grain",
        cherry: "Cherry",
        chabacano: "Apricot",
        pea: "Pea",
        squash: "Squash",
        chia: "Chia",
        chilacayote: "Chilacayote",
        chillyPepper: "Chili / Pepper",
        custardApple: "Custard Apple",
        coriander: "Coriander",
        plum: "Plum",
        coconutFruit: "Coconut Fruit",
        cabbage: "Cabbage",
        brusselsSprouts: "Brussels Sprouts",
        cauliflower: "Cauliflower",
        cumin: "Cumin",
        chrysanthemum: "Chrysanthemum",
        peach: "Peach",
        date: "Date",
        greenBean: "Green Bean",
        cornCob: "Corn Cob",
        dill: "Dill",
        epazote: "Epazote",
        asparagus: "Asparagus",
        spicesAndMedicinals: "Spices and Medicinals",
        spinach: "Spinach",
        raspberry: "Raspberry",
        strawberry: "Strawberry",
        bean: "Bean",
        chickpea: "Chickpea",
        sunflower: "Sunflower",
        pomegranate: "Pomegranate",
        soursop: "Soursop",
        guava: "Guava",
        henequen: "Henequen",
        peppermint: "Peppermint",
        aromaticHerbs: "Aromatic Herbs",
        fig: "Fig",
        jamaica: "Hibiscus",
        ginger: "Ginger",
        jicama: "Jicama",
        lettuce: "Lettuce",
        lentil: "Lentil",
        lime: "Lime",
        lemon: "Lemon",
        linseed: "Linseed",
        litchi: "Lychee",
        corn: "Corn",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Tangerine",
        mango: "Mango",
        mangosteen: "Mangosteen",
        chamomile: "Chamomile",
        appleTree: "Apple Tree",
        passionFruit: "Passion Fruit",
        daisyFlower: "Daisy",
        marjoram: "Marjoram",
        melon: "Melon",
        quince: "Quince",
        mint: "Mint",
        moringa: "Moringa",
        turnip: "Turnip",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Orange",
        medlar: "Medlar",
        nochebuena: "Christmas Eve Plant",
        walnut: "Walnut",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Oregano",
        ornamental: "Ornamental",
        orchid: "Orchid",
        oilPalm: "Oil Palm",
        potato: "Potato",
        papaya: "Papaya",
        grasslands: "Grasslands",
        matGrass: "Mat Grass",
        pasturesAndMeadows: "Pastures and Meadows",
        cucumber: "Cucumber",
        pearTree: "Pear Tree",
        parsley: "Parsley",
        peron: "Peron",
        persimony: "Persimony",
        pepper: "Pepper",
        pineapple: "Pineapple",
        pinion: "Pine Nut",
        pistache: "Pistachio",
        pitaya: "Pitaya",
        banana: "Banana",
        pore: "Pore",
        radish: "Radish",
        rambutan: "Rambutan",
        romerito: "Romero",
        rosemary: "Rosemary",
        aloe: "Aloe",
        watermelon: "Watermelon",
        sorghum: "Sorghum",
        soy: "Soy",
        stevia: "Stevia",
        tobacco: "Tobacco",
        tamarind: "Tamarind",
        tejocote: "Tejocote",
        tomato: "Red Tomato",
        greenTomato: "Green Tomato",
        thyme: "Thyme",
        grapefruit: "Grapefruit",
        clover: "Clover",
        wheat: "Wheat",
        dutchTulip: "Dutch Tulip",
        tuna: "Prickly Pear",
        grape: "Grape",
        vanilla: "Vanilla",
        purslane: "Purslane",
        vine: "Vine",
        cassavaFood: "Cassava",
        carrot: "Carrot",
        sapodilla: "Sapodilla",
        blackberry: "Blackberry",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Fallow",
        "pecan nut": "Pecan Nut",
        roses: "Roses",
        other: "Other",
      },
      analysisAdded: "The analysis has already been added",
      initial: "Initial",
      tracking: "Monitoring",
      codeLengthError: "The code must have at least 14 digits",
    },
    es: {
      result_added_to_plate: "Se agregó resultado en placa",
      report_generated: "Se generó reporte",
      added_to_plate_sequence: "Se añadió a placa secuencia",
      evaluation_end: "Fin valuación",
      molecular_identification_end: "Fin identificación molecular",
      measurement_end: "Fin Medición",
      evaluation_start: "Inicio valuación",
      molecular_identification_start: "Inicio identificación molecular",
      measurement_start: "Inicio Medición",
      received: "Recibido",
      shipment: "Envío",
      no_event_records: "No hay registros de eventos",
      destination: "Destino",
      origin: "Origen",
      user: "Usuario",
      loading_events: "Cargando eventos",
      time_records: "Registros en tiempo",
      culture_not_valid_for_marker_detection: "El cultivo no es válido para detectar el marcador en este paquete",
      package_not_associated_reaction: "El paquete no está asociado a ninguna reacción",
      marker_not_in_available_list: "El marcador no está en la lista de marcadores disponibles para su detección",
      culture_not_contemplated_cmda: "El cultivo no está contemplado para el CMDA",
      marker_no_cq_min_max: "El marcador no tiene cQ min o cQ max",
      marker_not_associated: "El marcador no está asociado al paquete",
      no_package_assigned: "No tiene un paquete asignado la muestra",
      some_identifications_not_saved: "Algunas identificaciones no se pudieron guardar",
      select_files: "Selecciona los archivos",
      file_s: "Archivo(s)",
      cause: "Causa",
      result_x_bar: "Resultado (x̄)",
      identification: "Identificación",
      no_presence: "Sin presencia",
      errors: "Errores",
      processing_files: "Procesando archivo/s",
      add: "Agregar",
      plant_sample: "Muestra Vegetal",
      not_detected: "No detectado",
      no_microorganisms_detected: "No se detectaron microorganismos",
      discard: "Descartar",
      high_cycle_or_low_efficiency_results: "Los resultados con ciclo alto de detección (cq) y/o baja eficiencia de detección se interpretarán como",
      result_with_error: "Resultado con un error",
      results_with_errors: "Resultados con errores",
      high_cycle_detection_result: "Resultado con el ciclo alto de detección (cq)",
      high_cycle_detection_results: "Resultados con el ciclo alto de detección (cq)",
      low_detection_efficiency_result: "Resultado con baja eficiencia de detección",
      detected_single: "Se detectó",
      low_detection_efficiency_results: "Resultados con baja eficiencia de detección",
      detected: "Se detectaron",
      documents: "Documento/s",
      results_without_culture: "Resultados sin considerar el cultivo",
      select_files_to_process: "Seleccionar uno o más archivos para procesar, visualizar y guardar los MOs identificados.",
      sensitivity_limit: "Límite de sensibilidad 15 - 34",
      molecular_identification: "Identificación Molecular",
      identification_date: "Fecha de identificación",
      file: "Archivo",
      client_company_name: "Razón social del cliente",
      no_tests_to_revert: "No hay ensayos por revertir",
      test_deleted_successfully: "Ensayo/s eliminados correctamente",
      analysis_type: "Tipo de análisis",
      inoculum: "Inóculo",
      medium: "Medio",
      test_id: "ID ensayo",
      analysis_id: "ID análisis",
      delete_tests: "Eliminar ensayos",
      tests: "Ensayos",
      reload_table: "Recargar tabla",
      molecular_id: "ID. Molecular",
      nematode: "Nematodo",
      bacteria: "Bacteria",
      fungus: "Hongo",
      functional: "Funcional",
      efficiency: "Eficiencia",
      type: "Tipo",
      name: "Nombre",
      microorganisms_found: "Microorganismos encontrados",
      source_file: "Archivo de origen",
      no_plates_selected: "No se han seleccionado placas",
      plate_updated_successfully: "Placa actualizada correctamente",
      plant_tissue: "Tejido vegetal",
      tools: "Herramientas",
      analysis_status: "Estatus de análisis",
      analysis_stage: "Etapa del análisis",
      availability: "Disponibilidad",
      unit: "Unidad",
      unit_id: "ID de unidad",
      completed: "Completada",
      in_progress: "En progreso",
      contaminated: "Contaminada",
      unregistered: "Sin registrar",
      amplification: "Amplificación",
      no_plates_to_show: "No hay placas por mostrar",
      mic_plate: "Placa MIC",
      pcr_plate: "Placa PCR",
      well_plate: "Placa de pozo",
      check_end_date: "Verifica la fecha de fin",
      check_start_date: "Verifica la fecha de inicio",
      used: "Utilizados",
      loading: "Cargando",
      no_results_found: "No se encontraron resultados",
      end: "Fin",
      start: "Inicio",
      filter_plates_used_in_date_range:
        "Filtrar placas usadas en un rango de fechas",
      plates_added_successfully: "Placas agregadas correctamente",
      amplification_plate: "Placa amplificación",
      elution_plate: "Placa elución",
      purification_plate: "Placa purificación",
      print: "Imprimir",
      number_of_plates: "No. de placas",
      choose_kit_type: "Elegir tipo de kit",
      label_type: "Tipo de etiqueta",
      label_generation: "Generación de etiquetas",
      print_labels: "Imprimir etiquetas",
      cmda_plates: "Placas CMDA",
      click_cells_to_select_samples_for_amplification:
        "Click sobre las celdas para seleccionar las muestras para la etapa de amplificación.",
      repetition: "Repetición",
      water: "Agua",
      no_results: "No hay resultados",
      repetitions: "Repeticiones",
      assign_samples_to_plate: "Asignar muestras en placa",
      select_at_least_one_sample: "Debes seleccionar mínimo una muestra",
      sample_selection: "Selección de muestras",
      new_repetition: "Nueva repetición",
      the_sample: "La muestra",
      sample_observations: "Observaciones de las muestras",
      samples_in_repetitions: "Muestras en repeticiones",
      print_tube_labels: "Imprimir etiquetas de tubos",
      analysis: "Análisis",
      elución: "Elución",
      purificación: "Purificación",
      lisis: "Lisis",
      finished: "Finalizado",
      inProcess: "En proceso",
      loadingSamplesInTransit: "Cargando muestras en tránsito",
      noSamplesInTransit: "No hay muestras en tránsito",
      samplesExported: "Muestras exportadas",
      noSamplesFoundToExport: "No se encontraron muestras para exportar",
      observations: "Observaciones",
      plantSamples: "Muestras vegetales",
      soilSamples: "Muestras suelo",
      month: "Mes",
      notAvailable: "No disponibles",
      available: "Disponibles",
      sampleStatus: "Estatus muestra",
      exportToExcel: "Exportar a Excel",
      exportStoredSamples: "Exportar muestras resguardadas",
      experimentalUnit: "Unidad experimental",
      noValuation: "No hay valuación",
      cellulolyticActivity: "Actividad celulolítica",
      siderophores: "Sideróforos",
      potassiumSolubilization: "Solubilización de potasio (K)",
      phosphorusSolubilization: "Solubilización de fósforo (P)",
      negativeControl: "Control negativo",
      nutrition: "Nutrición",
      control: "Control",
      soilDiseaseControl: "Control de enfermedades de suelo",
      noNematodes: "No hay nematodos",
      nematodes: "Nematodos",
      noFungi: "No hay hongos",
      fungi: "Hongos",
      noBacteria: "No hay bacterias",
      concentration: "Concentración",
      bacteria: "Bacterias",
      noNotDetected: "No hay no detectados",
      notDetected: "No detectados",
      noPathogens: "No hay patógenos",
      pathogens: "Patógenos",
      noBeneficials: "No hay benéficos",
      beneficials: "Benéficos",
      microorganismIdentification: "Identificación de microorganismos",
      noSoilHealthParameters: "No hay parámetros de salud del suelo",
      soilTexture: "Textura de suelo",
      carbonCapturePOX: "Captura de carbono (POX)",
      organicMatter: "Materia orgánica",
      soilHealthParameters: "Parámetros de salud de suelo",
      samplingZoneNotAvailable: "Zona de muestreo no disponible",
      receptionDate: "Fecha de recepción",
      qrCode: "Código QR",
      noDescription: "Sin descripción",
      sampleDescription: "Descripción de la muestra",
      projects: "Proyectos",
      labels: "Etiquetas",
      profitability: "Rentabilización",
      qpcrResults: "Resultados QPCR",
      preservation: "Preservación",
      receptionNematodes: "Recepción / Nemátodos",
      mediaAndInoculation: "Medios e inoculación",
      plates: "Placas",
      logInAgain: "Volver a iniciar sesión",
      reload: "Recargar",
      sessionExpiredMessage:
        "Puedes volver a iniciar sesión o recargar la página si es que ya lo hiciste.",
      sessionExpired: "La sesión caducó",
      logout: "Cerrar sesión",
      sessionExpired: "La sesión ha expirado",
      sessionExpiresIn: "La sesión expira en",
      sampleDiscarded: "Muestra descartada",
      samplePreparedForSequencing: "Muestra preparada para secuenciación",
      dateUpdated: "Fecha actualizada",
      reportGeneratedSuccessfully: "Reporte generado exitosamente",
      folioUpdatedSuccessfully: "Folio actualizado correctamente",
      noEstimatedDate: "Sin fecha estimada",
      update: "Actualizar",
      selectErrorType: "Seleccione tipo de error",
      registrationErrors: "Errores de registro",
      generateFinalReport: "Generar reporte final",
      processedMolecularMarkersInFolio:
        "Marcador/es moleculares procesados en el folio",
      unavailableSamples: "Muestras no disponibles",
      availableSamples: "Muestras disponibles",
      all: "Todas",
      pending: "Pendiente",
      withErrors: "Con errores",
      noErrors: "Sin errores",
      responsible: "Responsable",
      analysisStage: "Etapa de análisis",
      lastModification: "Última modificación",
      fieldRegistration: "Registro en campo",
      samplingZoneName: "Nombre de la zona de muestreo",
      subsamples: "Submuestras",
      containerQR: "QR contenedor",
      sampleFolio: "Folio muestra",
      actions: "Acciones",
      lastReportSent: "Último reporte enviado",
      estimatedResultDate: "Fecha estimada de resultado",
      samples: "Muestras",
      kitType: "Tipo de kit",
      folio: "Folio",
      seconds: "Unos segundos",
      minutes: "Minutos",
      minute: "Minuto",
      hours: "Horas",
      hour: "Hora",
      days: "Días",
      day: "Día",
      deleteFolio: "Eliminar folio",
      results: "Resultados",
      physicochemical: "Fisicoquímicos",
      sampleSentToLab: "Muestra enviada a laboratorio",
      pendingContainers: "Contenedores pendientes",
      containersWithoutSamples: "Contenedores sin muestras",
      qPCRReport: "Reporte de qPCR",
      valuationReport: "Reporte de valuación",
      measurementReport: "Reporte de medición",
      notGenerated: "No generado",
      vegetalSampleTypes: {
        Seedling: "Plántula",
        Root: "Raíz",
        Seed: "Semilla",
        Stem: "Tallo",
        Leaf: "Hoja",
        Fruit: "Fruto",
        Flower: "Flor",
      },
      waiting: "Esperando",
      setupKit: "Kit de setup",
      projectKit: "Kit de proyecto",
      loadingReceipts: "Cargando recepciones",
      noReceipts: "No hay recepciones",
      notAvailable: "No disponible",
      solid: "Sólido",
      liquid: "Líquida",
      soilWithoutAdvisor: "Suelo sin asesor",
      substrate: "Sustrato",
      product: "Producto",
      compost: "Composta",
      vegetalWithoutGeoreference: "Vegetal sin georeferencia",
      plant: "Vegetal",
      soil: "Suelo",
      valuation: "Valuación",
      measurement: "Medición",
      clearFilters: "Limpiar filtros",
      apply: "Aplicar",
      selectCrop: "Seleccione un cultivo",
      selectSampleType: "Seleccione tipo de muestra",
      sampleType: "Tipo de muestra",
      selectType: "Seleccione el tipo",
      reportGenerated: "Reporte generado",
      status: "Estatus",
      selectStatus: "Seleccione estatus",
      noCrop: "No hay cultivo",
      selectAnalysisType: "Seleccione tipo de análisis",
      analysisType: "Tipo de análisis",
      sampleGuard: "Resguardo muestras",
      selectStatusType: "Seleccione tipo estatus",
      producer: "Productor",
      search: "Buscar",
      laboratory: "Laboratorio",
      package: "Paquete",
      resultsIssuance: "Emisión resultados",
      selectDateRange: "Selecciona rango de fechas",
      reception: "Recepción",
      kitFocus: "Enfoque de kit",
      selectKitFocus: "Seleccione enfoque de kit.",
      filters: "Filtros",
      folioDeletedSuccessfully: "Folio eliminado correctamente.",
      enterCorrectFolioToDelete:
        "Debes escribir correctamente el folio que quieres eliminar.",
      confirm: "Confirmar",
      enterFolioNumberToDelete: "Escribe el número del folio para eliminarlo.",
      deleteFolioConfirmation: "¿Está seguro que desea eliminar el folio",
      //## Login
      login: "Inicio de sesión",
      email: "Correo",
      password: "Contraseña",
      keepSessionOpen: "Mantener sesión abierta",
      accept: "Aceptar",
      sessionDuration: "Tu sesión durará 2 horas",
      acceptTerms: "Debes aceptar los términos para continuar",
      welcome: "Bienvenido/a",
      requiredField: "El campo es obligatorio",
      validEmail: "Debes ingresar un correo electrónico válido",
      incorrectCredentials: "Usuario o contraseña incorrectos",
      incorrectPassword: "Contraseña incorrecta",
      //## Reception
      registeredSamples: "Muestras registradas",
      samplesInAnalysis: "Muestras en análisis",
      analyzedSamples: "Muestras analizadas",
      receiveSamples: "Recibir muestras",
      addEvidence: "Agregar evidencia",
      images: "Imágenes",
      kitReception: "Recepción de kit",
      sample: "Muestra",
      addSamplesToAnalysis: "Añadir muestras al análisis",
      continue: "Continuar",
      receptionConfirmation: "Confirmación de recepción",
      confirmAddSamplesToReceptions:
        "¿Estás seguro(a) que desea agregar estas muestras a recepciones?",
      cancel: "Cancelar",
      create: "Crear",
      selectKitType: "Selecciona el tipo de kit",
      kitObservations: "Observaciones del kit",
      transferSamples: "Transferir muestras",
      selectLaboratoryToSend: "Seleccione el laboratorio a enviar",
      noAvailableLaboratory: "No se encontró laboratorio disponible",
      send: "Enviar",
      samplesInLaboratory: "Muestras en laboratorio",
      samplesInTransit: "Muestras en tránsito",
      soilPreservation: "Preservación de suelos",
      generatedReports: "Reportes generados",
      download: "Descargar",
      close: "Cerrar",
      sampleInformation: "Información de muestra",
      sampleDetail: "Sample detail",
      sampleType: "Tipo de muestra",
      samplingZone: "Zona de muestreo",
      crop: "Cultivo",
      norCrop: "Sin cultivo",
      samplingDate: "Fecha de toma",
      sampler: "Muestreador",
      viewEvidence: "Ver evidencia",
      images: "Imágenes",
      updateFolio: "Actualizar folio",
      analysisFolio: "Folio de análisis",
      addSamplesToAnalysis: "Añadir muestras al análisis",
      confirmUpdateFolio: "¿Estás seguro(a) que desea actualizar este folio?",
      update: "Atualizar",
      validateSamples: "Validar muestras",
      delete: "Eliminar",
      addTags: "Añadir etiquetas",
      analysisFolio: "Folio de análisis",
      analysisStatus: "Estatus análisis",
      reportGeneration: "Generación de reporte",
      report: "Reporte",
      traditional: "Tradicional",
      hybrid: "Híbrido",
      other: "Otro",
      sequencing: "Secuenciación",
      qrDuplicated: "El QR está duplicado",
      containerReceived: "El contenedor ya se recibió",
      sampleReceived: "La muestra ya se recibió",
      qrNotRegistered: "QR sin registrar",
      qrDoesNotExist: "QR no existe",      
      somethingWentWrong: "Algo salió mal. Error:",
      receptionUpdated: "Recepción actualizada",
      receptionCreated: "Recepción creada",
      received: "Recibida",
      measuring: "Midiendo",
      measured: "Medido",
      valuing: "Valuando",
      valued: "Valuado",
      crop_name_list: {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        "pecan nut": "Nuez pecana",
        roses: "Rosas",
        other: "Otro",
      },
      analysisAdded: "El análisis ya se agregó",
      initial: "Inicial",
      tracking: "Seguimiento",
      codeLengthError: "El código debe tener al menos 14 dígitos",
    },
    pt: {
      result_added_to_plate: "Resultado adicionado à placa",
      report_generated: "Relatório gerado",
      added_to_plate_sequence: "Adicionado à sequência de placa",
      evaluation_end: "Fim avaliação",
      molecular_identification_end: "Fim identificação molecular",
      measurement_end: "Fim Medição",
      evaluation_start: "Início avaliação",
      molecular_identification_start: "Início identificação molecular",
      measurement_start: "Início Medição",
      received: "Recebido",
      shipment: "Envio",
      no_event_records: "Não há registros de eventos",
      destination: "Destino",
      origin: "Origem",
      user: "Usuário",
      loading_events: "Carregando eventos",
      time_records: "Registros em tempo",
      culture_not_valid_for_marker_detection: "A cultura não é válida para detectar o marcador neste pacote",
      package_not_associated_reaction: "O pacote não está associado a nenhuma reação",
      marker_not_in_available_list: "O marcador não está na lista de marcadores disponíveis para detecção",
      culture_not_contemplated_cmda: "A cultura não está contemplada para o CMDA",
      marker_no_cq_min_max: "O marcador não tem cQ min ou cQ max",
      marker_not_associated: "O marcador não está associado ao pacote",
      no_package_assigned: "A amostra não tem um pacote atribuído",
      some_identifications_not_saved: "Algumas identificações não puderam ser salvas",
      select_files: "Selecione os arquivos",
      file_s: "Arquivo(s)",
      cause: "Causa",
      result_x_bar: "Resultado (x̄)",
      identification: "Identificação",
      no_presence: "Sem presença",
      errors: "Erros",
      processing_files: "Processando arquivo(s)",
      add: "Adicionar",
      plant_sample: "Amostra Vegetal",
      not_detected: "Não detectado",
      no_microorganisms_detected: "Nenhum microrganismo detectado",
      discard: "Descartar",
      high_cycle_or_low_efficiency_results: "Os resultados com ciclo alto de detecção (cq) e/ou baixa eficiência de detecção serão interpretados como",
      result_with_error: "Resultado com um erro",
      results_with_errors: "Resultados com erros",
      high_cycle_detection_result: "Resultado com o ciclo alto de detecção (cq)",
      high_cycle_detection_results: "Resultados com o ciclo alto de detecção (cq)",
      low_detection_efficiency_result: "Resultado com baixa eficiência de detecção",
      detected_single: "Foi detectado",
      low_detection_efficiency_results: "Resultados com baixa eficiência de detecção",
      detected: "Foram detectados",
      documents: "Documento(s)",
      results_without_culture: "Resultados sem considerar a cultura",
      select_files_to_process: "Selecionar um ou mais arquivos para processar, visualizar e salvar os MOs identificados.",
      sensitivity_limit: "Limite de sensibilidade 15 - 34",
      molecular_identification: "Identificação Molecular",
      identification_date: "Data de identificação",
      file: "Arquivo",
      client_company_name: "Razão social do cliente",
      no_tests_to_revert: "Não há ensaios para reverter",
      test_deleted_successfully: "Teste(s) excluído(s) com sucesso",
      analysis_type: "Tipo de análise",
      inoculum: "Inóculo",
      medium: "Meio",
      test_id: "ID do ensaio",
      analysis_id: "ID de análise",
      delete_tests: "Excluir ensaios",
      tests: "Ensaios",
      reload_table: "Recarregar tabela",
      molecular_id: "ID. Molecular",
      nematode: "Nematóide",
      bacteria: "Bactéria",
      fungus: "Fungo",
      functional: "Funcional",
      efficiency: "Eficiência",
      type: "Tipo",
      name: "Nome",
      microorganisms_found: "Microrganismos encontrados",
      source_file: "Arquivo de origem",
      no_plates_selected: "Nenhuma placa selecionada",
      plate_updated_successfully: "Placa atualizada corretamente",
      plant_tissue: "Tecido vegetal",
      tools: "Ferramentas",
      analysis_status: "Status da análise",
      analysis_stage: "Etapa da análise",
      availability: "Disponibilidade",
      unit: "Unidade",
      unit_id: "ID da unidade",
      completed: "Completada",
      in_progress: "Em progresso",
      contaminated: "Contaminada",
      unregistered: "Não registrado",
      amplification: "Amplificação",
      no_plates_to_show: "Não há placas para mostrar",
      mic_plate: "Placa MIC",
      pcr_plate: "Placa PCR",
      well_plate: "Placa de poço",
      check_end_date: "Verifique a data de fim",
      check_start_date: "Verifique a data de início",
      used: "Utilizados",
      loading: "Carregando",
      no_results_found: "Nenhum resultado encontrado",
      end: "Fim",
      start: "Início",
      filter_plates_used_in_date_range:
        "Filtrar placas usadas em um intervalo de datas",
      plates_added_successfully: "Placas adicionadas corretamente",
      amplification_plate: "Placa amplificação",
      elution_plate: "Placa eluição",
      purification_plate: "Placa purificação",
      print: "Imprimir",
      number_of_plates: "Nº de placas",
      choose_kit_type: "Escolher tipo de kit",
      label_type: "Tipo de etiqueta",
      label_generation: "Geração de etiquetas",
      print_labels: "Imprimir etiquetas",
      cmda_plates: "Placas CMDA",
      click_cells_to_select_samples_for_amplification:
        "Clique sobre as células para selecionar as amostras para a etapa de amplificação.",
      repetition: "Repetição",
      water: "Água",
      no_results: "Não há resultados",
      repetitions: "Repetições",
      assign_samples_to_plate: "Atribuir amostras à placa",
      select_at_least_one_sample: "Você deve selecionar pelo menos uma amostra",
      sample_selection: "Seleção de amostras",
      new_repetition: "Nova repetição",
      the_sample: "A amostra",
      sample_observations: "Observações das amostras",
      samples_in_repetitions: "Amostras em repetições",
      print_tube_labels: "Imprimir etiquetas de tubos",
      analysis: "Análise",
      elución: "Eluição",
      purificación: "Purificação",
      lisis: "Lise",
      finished: "Finalizado",
      inProcess: "Em processo",
      loadingSamplesInTransit: "Carregando amostras em trânsito",
      noSamplesInTransit: "Não há amostras em trânsito",
      samplesExported: "Amostras exportadas",
      noSamplesFoundToExport: "Não foram encontradas amostras para exportar",
      observations: "Observações",
      plantSamples: "Amostras vegetais",
      soilSamples: "Amostras de solo",
      month: "Mês",
      notAvailable: "Não disponíveis",
      available: "Disponíveis",
      sampleStatus: "Status da amostra",
      exportToExcel: "Exportar para Excel",
      exportStoredSamples: "Exportar amostras armazenadas",
      experimentalUnit: "Unidade experimental",
      noValuation: "Sem avaliação",
      cellulolyticActivity: "Atividade celulolítica",
      siderophores: "Sideróforos",
      potassiumSolubilization: "Solubilização de potássio (K)",
      phosphorusSolubilization: "Solubilização de fósforo (P)",
      negativeControl: "Controle negativo",
      nutrition: "Nutrição",
      control: "Controle",
      soilDiseaseControl: "Controle de doenças do solo",
      noNematodes: "Não há nematóides",
      nematodes: "Nematodos",
      noFungi: "Não há fungos",
      fungi: "Fungos",
      noBacteria: "Não há bactérias",
      concentration: "Concentração",
      bacteria: "Bactérias",
      noNotDetected: "Não há não detectados",
      notDetected: "Não detectados",
      noPathogens: "Não há patógenos",
      pathogens: "Patógenos",
      noBeneficials: "Não há benéficos",
      beneficials: "Benéficos",
      microorganismIdentification: "Identificação de microrganismos",
      noSoilHealthParameters: "Não há parâmetros de saúde do solo",
      soilTexture: "Textura do solo",
      carbonCapturePOX: "Captura de carbono (POX)",
      organicMatter: "Matéria orgânica",
      soilHealthParameters: "Parâmetros de saúde do solo",
      samplingZoneNotAvailable: "Zona de amostragem não disponível",
      receptionDate: "Data de recepção",
      qrCode: "Código QR",
      noDescription: "Sem descrição",
      sampleDescription: "Descrição da amostra",
      projects: "Projetos",
      labels: "Etiquetas",
      profitability: "Rentabilização",
      qpcrResults: "Resultados QPCR",
      preservation: "Preservação",
      receptionNematodes: "Recepção / Nematóides",
      mediaAndInoculation: "Meios e inoculação",
      plates: "Placas",
      logInAgain: "Entrar novamente",
      reload: "Recarregar",
      sessionExpiredMessage:
        "Você pode fazer login novamente ou atualizar a página se já o fez.",
      sessionExpired: "A sessão expirou",
      logout: "Sair",
      sessionExpired: "A sessão expirou",
      sessionExpiresIn: "A sessão expira em",
      sampleDiscarded: "Amostra descartada",
      samplePreparedForSequencing: "Amostra preparada para sequenciamento",
      dateUpdated: "Data atualizada",
      reportGeneratedSuccessfully: "Relatório gerado com sucesso",
      folioUpdatedSuccessfully: "Folio atualizado corretamente",
      noEstimatedDate: "Sem data estimada",
      update: "Atualizar",
      selectErrorType: "Selecione o tipo de erro",
      registrationErrors: "Erros de registro",
      generateFinalReport: "Gerar relatório final",
      processedMolecularMarkersInFolio:
        "Marcadores moleculares processados no folio",
      unavailableSamples: "Amostras não disponíveis",
      availableSamples: "Amostras disponíveis",
      all: "Todas",
      pending: "Pendente",
      withErrors: "Com erros",
      noErrors: "Sem erros",
      responsible: "Responsável",
      analysisStage: "Etapa de análise",
      lastModification: "Última modificação",
      fieldRegistration: "Registro em campo",
      samplingZoneName: "Nome da zona de amostragem",
      subsamples: "Subamostras",
      containerQR: "QR do contêiner",
      sampleFolio: "Fólio da amostra",
      actions: "Ações",
      lastReportSent: "Último relatório enviado",
      estimatedResultDate: "Data estimada de resultado",
      samples: "Amostras",
      kitType: "Tipo de kit",
      folio: "Fólio",
      seconds: "Alguns segundos",
      minutes: "Minutos",
      minute: "Minuto",
      hours: "Horas",
      hour: "Hora",
      days: "Días",
      day: "Dia",
      deleteFolio: "Excluir folio",
      results: "Resultados",
      physicochemical: "Físico-químicos",
      sampleSentToLab: "Amostra enviada ao laboratório",
      pendingContainers: "Contêineres pendentes",
      containersWithoutSamples: "Contêineres sem amostras",
      qPCRReport: "Relatório de qPCR",
      valuationReport: "Relatório de valoração",
      measurementReport: "Relatório de medição",
      notGenerated: "Não gerado",
      vegetalSampleTypes: {
        Seedling: "Plântula",
        Root: "Raiz",
        Seed: "Semente",
        Stem: "Caule",
        Leaf: "Folha",
        Fruit: "Fruto",
        Flower: "Flor",
      },
      waiting: "Aguardando",
      setupKit: "Kit de configuração",
      projectKit: "Kit de projeto",
      loadingReceipts: "Carregando recepções",
      noReceipts: "Não há recepções",
      notAvailable: "Não disponível",
      solid: "Sólido",
      liquid: "Líquida",
      soilWithoutAdvisor: "Solo sem assessor",
      substrate: "Substrato",
      product: "Produto",
      compost: "Composta",
      vegetalWithoutGeoreference: "Vegetal sem georreferência",
      plant: "Vegetal",
      soil: "Solo",
      valuation: "Valoração",
      measurement: "Medição",
      clearFilters: "Limpar filtros",
      apply: "Aplicar",
      selectCrop: "Selecione uma cultura",
      selectSampleType: "Selecione o tipo de amostra",
      sampleType: "Tipo de amostra",
      selectType: "Selecione o tipo",
      reportGenerated: "Relatório gerado",
      status: "Status",
      selectStatus: "Selecione o status",
      noCrop: "Não há cultivo",
      selectAnalysisType: "Selecione o tipo de análise",
      analysisType: "Tipo de análise",
      sampleGuard: "Guarda de amostras",
      selectStatusType: "Selecione o tipo de status",
      producer: "Produtor",
      search: "Buscar",
      laboratory: "Laboratório",
      package: "Pacote",
      resultsIssuance: "Emissão de resultados",
      selectDateRange: "Selecione o intervalo de datas",
      reception: "Recepção",
      kitFocus: "Foco do kit",
      selectKitFocus: "Selecione o foco do kit.",
      filters: "Filtros",
      folioDeletedSuccessfully: "Folio excluído com sucesso.",
      enterCorrectFolioToDelete:
        "Você deve digitar corretamente o folio que deseja excluir.",
      confirm: "Confirmar",
      enterFolioNumberToDelete: "Digite o número do folio para excluí-lo.",
      deleteFolioConfirmation: "Tem certeza de que deseja excluir o folio",
      //## Login
      login: "Início de sessão",
      email: "Correio",
      password: "Senha",
      keepSessionOpen: "Manter sessão aberta",
      accept: "Aceitar",
      sessionDuration: "Sua sessão durará 2 horas",
      acceptTerms: "Você deve aceitar os termos para continuar",
      welcome: "Bem-vindo/a",
      requiredField: "O campo é obrigatório",
      validEmail: "Você deve inserir um endereço de e-mail válido",
      incorrectCredentials: "Usuário ou senha incorretos",
      incorrectPassword: "Senha incorreta",
      //## Reception
      registeredSamples: "Amostras registradas",
      samplesInAnalysis: "Amostras em análise",
      analyzedSamples: "Amostras analisadas",
    },
  },
};
