// Guard
import { privateGuard } from "@/middlewares/guards";

// Layouts
import NavLayout from "../layouts/NavLayout.vue";

// Views
import SoilStock from "../views/SoilStock.vue";

export default [
  {
    path: "/soil-stock",
    beforeEnter: privateGuard,
    component: NavLayout,
    children: [
      {
        path: "",
        name: "Banco de suelos",
        component: SoilStock,
        meta: {
          title: "Banco de suelos",
        },
      },
    ],
  },
];
