<template>
<v-container>
    <v-stepper v-model="steps" elevation="0">
        <v-stepper-header>
            <v-stepper-step :complete="steps > 1" step="1">
                Armado de prueba
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="steps > 2" step="2">
                Subir resultados
            </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <v-row v-if="!showStrainPanel">
                    <v-col align="end">
                        <v-btn color="primary" @click="setupDone()" :disabled="!permissions.find((permission) => permission == 'lab_validation_write')"> Continuar </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="!showStrainPanel">
                    <v-col cols="auto">
                        <v-btn color="primary" @click="showStrainPanel = true" :disabled="oligoAndProbeDataSelected.sensibility.results.length > 1 || oligoAndProbeDataSelected.validationFinished || !permissions.find((permission) => permission == 'lab_validation_write')">
                            <v-icon> mdi-plus </v-icon>
                            Agregar
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn color="red" @click="showDeleteResultsDataConfirmationModal = true" :disabled="validateAdd()" :dark="!validateAdd()">
                            <v-icon> mdi-delete </v-icon>
                            Eliminar
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="!showStrainPanel">
                    <v-col>
                        <v-responsive class="overflow-x-auto">
                            <v-simple-table dense class="overflow-y-auto">
                                <thead>
                                    <tr>
                                        <th class="text-center">Dilución</th>
                                        <th class="text-center">ID</th>
                                        <th class="text-left">Microorganismo</th>
                                        <th class="text-center">Número de réplicas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in oligoAndProbeDataSelected.sensibility.results" :key="index">
                                        <td class="text-center" v-if="index == 0">-</td>
                                        <td class="text-center" v-else>{{ item.dilution }}</td>
                                        <td class="text-center" v-if="index == 0">
                                            Control negativo
                                        </td>
                                        <td class="text-center" v-else>
                                            {{ item.name }} {{ item.dilution }}
                                        </td>
                                        <td>{{ item.type === 'SOIL' ? 'Suelo' : (item.type === 'WATER' ? 'Water'
                                                : item.microorganism.name) }}</td>
                                        <td class="text-center" v-if="oligoAndProbeDataSelected.validationFinished || !permissions.find((permission) => permission == 'lab_validation_write')">
                                            {{ item.replicasNumber }}
                                        </td>
                                        <td v-else class="text-center">{{ item.replicasNumber }}
                                            <v-btn color="blue" x-small fab @click="setItemResultSelected(item)" dark v-if="!oligoAndProbeDataSelected.sensibility.isTestDone">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-responsive>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-btn text color="primary" @click="showStrainPanel = false">
                                    <v-icon> mdi-keyboard-backspace </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-tabs>
                                <v-tab> Cepario </v-tab>
                                <v-tab> Plásmidos / Vector </v-tab>
                                <v-tab> Suelos </v-tab>
                                <v-tab-item>
                                    <general-ceparium-selectable @addItems="openAddItemsConfirmation" />
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row>
                                        <v-col cols="12" class="pb-0">
                                            <SimpleVectorFilter />
                                        </v-col>
                                        <v-col class="pt-0">
                                            <SelectableVectorTable @addItems="openAddItemsConfirmation">
                                            </SelectableVectorTable>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-col cols="12" class="pb-0">
                                        <simple-filter-soil-table></simple-filter-soil-table>
                                    </v-col>
                                    <v-col>
                                        <selectable-soil-table @addItems="openAddItemsConfirmation"></selectable-soil-table>
                                    </v-col>
                                </v-tab-item>
                            </v-tabs>
                        </v-row>
                    </v-col>
                </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
                <v-card class="overflow-y-auto" max-height="740">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="2">
                                <v-btn text color="primary" @click="steps = 1" :disabled="!permissions.find((permission) => permission == 'lab_validation_write')">
                                    <v-icon> mdi-keyboard-backspace </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-row justify="end">
                                    <v-col cols="auto">
                                        <v-btn color="blue" dark block @click="showDeleteAllResultsDataConfirmationModal = true" :loading="isCleaningResults" :disabled="oligoAndProbeDataSelected.validationFinished || !permissions.find((permission) => permission == 'lab_validation_write')">
                                            <v-icon>mdi-delete-variant</v-icon>
                                            Limpiar resultados
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3">
                            <v-col cols="12" sm="6">
                                <v-file-input dense outlined label="Ingresa resultados (XLSX)" clearable v-model="fileResult" accept=".xlsx" :loading="uploadingResults" ref="result_field" append-icon="mdi-file-excel" :disabled="oligoAndProbeDataSelected.validationFinished || !permissions.find((permission) => permission == 'lab_validation_write')">
                                </v-file-input>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-textarea filled name="input-7-4" label="Obervaciones" v-model="observations" :disabled="oligoAndProbeDataSelected.validationFinished || !permissions.find((permission) => permission == 'lab_validation_write')"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-row>
                                    <v-col class="text-start">
                                        <span class="text-subtitle-1 font-weight-bold">
                                            Resultados
                                        </span>
                                    </v-col>
                                    <v-col v-if="oligoAndProbeDataSelected.sensibility.urls && oligoAndProbeDataSelected.sensibility.urls.length != 0">
                                        <v-autocomplete label="Descarga resultado" clearable dense v-model="fileTodownload" :items="oligoAndProbeDataSelected.sensibility.urls" item-text="url" outlined ref="download_result_input">
                                            <template #item="{ item }">
                                                <div>
                                                    <strong> Resultado (XLSX) - {{ dateFormat(item.date) }}</strong>
                                                </div>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-responsive class="overflow-x-auto">
                                    <v-simple-table dense class="overflow-y-auto">
                                        <thead>
                                            <tr>
                                                <th class="text-left">Dilución</th>
                                                <th class="text-left">ID</th>
                                                <th class="text-left">Microorganismo</th>

                                                <th class="text-left">Cq</th>
                                                <th class="text-left">Eficiencia</th>
                                                <th class="text-left">Eficiencia R2</th>
                                                <th class="text-left">Resultado</th>
                                                <th class="text-left">Fecha resultado</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(item, index) in oligoAndProbeDataSelected.sensibility.results">
                                                <tr v-for="(strain, innerIndex) in item.replicasNumber" :key="`${index}-${innerIndex}`">

                                                    <td class="text-center" v-if="item.type === 'WATER'">-</td>

                                                    <td class="text-center" v-else>
                                                        <v-row class="d-flex align-center justify-center" style="gap: 0px;">
                                                            <v-col cols="auto">
                                                                <div :style="`background-color: ${item.colors[innerIndex] || 'white'}; width: 15px; height: 15px;`">
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                {{ item.dilution }}
                                                            </v-col>
                                                        </v-row>
                                                    </td>
                                                    <td class="text-center" v-if="item.type === 'WATER'">
                                                        Control negativo
                                                    </td>
                                                    <td class="text-center" v-else>
                                                        {{ item.name }} {{ item.dilution }}
                                                    </td>
                                                    <td>{{ item.type === 'SOIL' ? 'Suelo' : (item.type === 'WATER' ?
                                                            'Water'
                                                            : item.microorganism.name) }}</td>

                                                    <td>{{ item.cq[innerIndex] || '-' }}</td>
                                                    <td>{{ item.efficience[innerIndex] || '-' }}</td>
                                                    <td>{{ item.efficienceR2[innerIndex] || '-' }}</td>
                                                    <td>{{ item.excluded[innerIndex]
                                                            ? getExcludedKind(item.excluded[innerIndex]) : '-' }}</td>
                                                    <td>{{ item.uploadDate ? dateFormat(item.uploadDate) :
                                                            '--/--/----' }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </v-simple-table>
                                </v-responsive>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <span class="text-subtitle-1 font-weight-bold"> Promedio </span>
                            </v-col>
                            <v-col>
                                <v-responsive class="overflow-x-auto">
                                    <v-simple-table dense class="overflow-y-auto">
                                        <thead>
                                            <tr>
                                                <th>Dilución</th>
                                                <th>ID</th>
                                                <th>Cq</th>
                                                <th>Eficiencia</th>
                                                <th>Eficiencia R2</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item,index) in summary" :key="index">
                                                <td>{{ item.dilution }}</td>
                                                <td>{{ item.type === 'SOIL' ? 'Suelo' : (item.type === 'WATER' ?
                                                            'Water'
                                                            : item.microorganism.name) }}</td>

                                                <td>{{ item.cq }}</td>
                                                <td>{{ item.efficience }}</td>
                                                <td>{{ item.efficienceR2 }}</td>
                                            </tr>

                                        </tbody>
                                    </v-simple-table>
                                </v-responsive>
                            </v-col>
                        </v-row>
                        <v-row v-if="charts.length != 0">
                            <v-col class="text-center justify-center align-center d-flex">
                                <v-carousel height="400" hide-delimiters show-arrows-on-hover>
                                    <v-carousel-item v-for="(image, i) in charts" :key="i">
                                        <v-row>
                                            <v-col cols="12">
                                                <span>{{ dateFormat(image.date) }}</span>
                                                <v-icon color="primary" class="ml-2" @click="showResultsSensitivityModal = true">mdi-eye</v-icon>
                                                <v-tooltip bottom v-if="image.standardCurve" color="primary">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="primary" dark v-bind="attrs" v-on="on" class="ml-2">
                                                            mdi-information
                                                        </v-icon>
                                                    </template>
                                                    <span>
                                                        Ecuación: {{ image.standardCurve.equation }}
                                                    </span>
                                                    <v-spacer></v-spacer>
                                                    <span>
                                                        Eficiencia: {{ image.standardCurve.efficience }}
                                                    </span>
                                                    <v-spacer></v-spacer>
                                                    <span>
                                                        R²: {{ image.standardCurve.r2 }}
                                                    </span>
                                                </v-tooltip>
                                            </v-col>
                                            <v-col>
                                                <v-img :src="image.url" height="400" contain></v-img>
                                            </v-col>
                                        </v-row>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                        </v-row>
                    </v-card-text>

                </v-card>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
    <add-replicates-and-dilutions-modal v-model="showAddReplicatesAndDilutionsModal" @addQuantity="addItems" />
    <DeleteResultsDataConfirmationModal v-model="showDeleteResultsDataConfirmationModal" @confirmation="removeItem()" :title="`¿Estás seguro de que deseas eliminar todos los elementos?`" />
    <update-replicates-modal v-model="showUpdateReplicatesModal" :type="'sensibility'" />
    <DeleteAllResultsDataConfirmationModal v-model="showDeleteAllResultsDataConfirmationModal" @confirmation="cleanResults()" :title="`¿Estás seguro de que deseas eliminar todos los elementos?`" />
    <results-sensitivity-modal v-model="showResultsSensitivityModal" />
</v-container>
</template>

<script>
//Libraries
import dayjs from "dayjs";
//vuex
import {
    mapActions,
    mapState,
    mapMutations,
} from "vuex";
import SelectableVectorTable from "@/components/vectors/SelectableVectorTable.vue";
import SimpleVectorFilter from "@/components/vectors/SimpleVectorFilter.vue";
import GeneralCepariumSelectable from '../ceparium/GeneralCepariumSelectable.vue';
import SimpleFilterSoilTable from '../soil_storage/SimpleFilterSoilTable.vue';
import SelectableSoilTable from '../soil_storage/SelectableSoilTable.vue';
import AddReplicatesAndDilutionsModal from './modals/AddReplicatesAndDilutionsModal.vue';
import DeleteResultsDataConfirmationModal from "@/components/utils/GenericConfirmationModal.vue";
import DeleteAllResultsDataConfirmationModal from "@/components/utils/GenericConfirmationModal.vue";
import UpdateReplicatesModal from './modals/UpdateReplicatesModal.vue';
import ResultsSensitivityModal from './modals/ResultsSensitivityModal.vue';
import NewMarkerVersionModal from './modals/NewMarkerVersionModal.vue';

// components
export default {
    components: {
        SelectableVectorTable,
        SimpleVectorFilter,
        GeneralCepariumSelectable,
        SimpleFilterSoilTable,
        SelectableSoilTable,
        AddReplicatesAndDilutionsModal,
        DeleteResultsDataConfirmationModal,
        UpdateReplicatesModal,
        DeleteAllResultsDataConfirmationModal,
        ResultsSensitivityModal,
        NewMarkerVersionModal,
    },
    data: () => ({
        steps: 1,
        showStrainPanel: false,
        tempItems: [],
        lastType: null,
        observations: null,
        fileResult: null,
        charts: [],
        fileTodownload: null,
        uploadingResults: false,
        isCleaningResults: false,
        summary: [],
        // modals
        showAddReplicatesAndDilutionsModal: false,
        showDeleteResultsDataConfirmationModal: false,
        showUpdateReplicatesModal: false,
        showDeleteAllResultsDataConfirmationModal: false,
        showResultsSensitivityModal: false
    }),
    computed: {
        ...mapState("session", ["permissions"]),
        ...mapState("molecularMarker", ["oligoAndProbeDataSelected", "markerSelected"]),
    },
    mounted() {
        if (this.oligoAndProbeDataSelected.sensibility.isSetUp) {
            this.steps = 2
        }
        this.observations = this.oligoAndProbeDataSelected.sensibility.observations
        if (this.oligoAndProbeDataSelected.sensibility.charts && this.oligoAndProbeDataSelected.sensibility.charts.length != 0) {
            this.charts = this.oligoAndProbeDataSelected.sensibility.charts
        }
        const clonedArray = JSON.parse(JSON.stringify(this.oligoAndProbeDataSelected.sensibility.results));
        clonedArray.shift();
        this.summary = this.calculateAverages(clonedArray)
    },
    methods: {
        ...mapActions("molecularMarker", ["addItemToResult", "removeItemToResult", "sensibilitySetupDone", "addSensibilityObservations", "uploadResultsSensibility", "cleanAllResultsSensibility"]),
        ...mapMutations("molecularMarker", ["SET_RESULT_ITEM_SELECTED"]),
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        async setupDone() {
            if (this.oligoAndProbeDataSelected.sensibility.isSetUp) {
                this.steps = 2
            } else {
                try {
                    let payload = {}
                    let params = {
                        markerId: this.markerSelected._id,
                        oligoId: this.oligoAndProbeDataSelected._id,
                    }
                    await this.sensibilitySetupDone({
                        params: params,
                        body: payload
                    })
                    this.oligoAndProbeDataSelected.sensibility.isSetUp = true
                    this.steps = 2
                } catch (error) {
                    console.log(error);
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "error",
                        text: `Algo salió mal. Error: ${error.message}`,
                        timeout: 5000,
                    });
                }
            }

        },
        openAddItemsConfirmation({
            items,
            type
        }) {
            this.tempItems = items
            this.lastType = type
            this.showAddReplicatesAndDilutionsModal = true
        },
        async addItems({
            quantity,
            dilution
        }) {
            try {
                // always select the first item selected
                let item = this.tempItems[0]
                if (this.lastType == "VECTOR") {
                    let register = {
                        microorganism: item.microorganism,
                        name: item.ampliconId,
                        type: "VECTOR",
                        vectorId: item._id,
                        cq: [],
                        efficience: [],
                        efficienceR2: [],
                        excluded: [],
                        colors: [],
                        replicasNumber: quantity,
                    }
                    let params = {
                        markerId: this.markerSelected._id,
                        oligoId: this.oligoAndProbeDataSelected._id,
                        type: "sensibility",
                        dilution: dilution
                    }
                    let response = await this.addItemToResult({
                        params: params,
                        body: register,
                    })
                    this.oligoAndProbeDataSelected.sensibility.results.push(...response.item)
                }
                if (this.lastType == "STRAIN") {
                    let register = {
                        microorganism: item.genomicSequence.putativeAssignation || item.genomicSequence.genomicAssignation,
                        name: item.genomicSequence.moSequenceId,
                        type: "STRAIN",
                        strainId: item._id,
                        cq: [],
                        efficience: [],
                        efficienceR2: [],
                        excluded: [],
                        colors: [],
                        replicasNumber: quantity
                    }
                    let params = {
                        markerId: this.markerSelected._id,
                        oligoId: this.oligoAndProbeDataSelected._id,
                        type: "sensibility",
                        dilution: dilution
                    }
                    let response = await this.addItemToResult({
                        params: params,
                        body: register,
                    })
                    this.oligoAndProbeDataSelected.sensibility.results.push(...response.item)
                }
                if (this.lastType == "SOIL") {
                    let register = {
                        microorganism: null,
                        name: item.folioAnalysis,
                        type: "SOIL",
                        // controlNumber: controlNumber,
                        sampleId: item._id,
                        cq: [],
                        efficience: [],
                        efficienceR2: [],
                        excluded: [],
                        colors: [],
                        replicasNumber: quantity
                    }
                    let params = {
                        markerId: this.markerSelected._id,
                        oligoId: this.oligoAndProbeDataSelected._id,
                        type: "sensibility",
                        dilution: dilution
                    }
                    let response = await this.addItemToResult({
                        params: params,
                        body: register,
                    })
                    this.oligoAndProbeDataSelected.sensibility.results.push(...response.item)
                }
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Se agregaron correctamente`,
                    timeout: 3000,
                });
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.showAddReplicatesAndDilutionsModal = false
            this.showStrainPanel = false
        },
        async removeItem() {
            try {
                let params = {
                    markerId: this.markerSelected._id,
                    oligoId: this.oligoAndProbeDataSelected._id,
                    type: "sensibility",
                }
                let response = await this.removeItemToResult({
                    params: params,
                    body: {}
                })
                this.oligoAndProbeDataSelected.sensibility.results = [response.markerUpdated]
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Se removio correctamente`,
                    timeout: 3000,
                });
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
        },
        validateAdd() {
            return this.oligoAndProbeDataSelected.sensibility.results.length == 1 || this.oligoAndProbeDataSelected.sensibility.isTestDone || this.oligoAndProbeDataSelected.validationFinished || !this.permissions.find((permission) => permission == 'lab_validation_write')
        },
        setItemResultSelected(item) {
            this.SET_RESULT_ITEM_SELECTED(item)
            this.showUpdateReplicatesModal = true
        },
        getExcludedKind(value) {
            return value ? "Excluded" : "Incluido"
        },
        dateFormat(value) {
            const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
            return date || "--/--/----";
        },
        async cleanResults() {
            try {
                this.isCleaningResults = true
                let payload = {}
                let params = {
                    markerId: this.markerSelected._id,
                    oligoId: this.oligoAndProbeDataSelected._id,
                }
                let response = await this.cleanAllResultsSensibility({
                    params: params,
                    body: payload
                })
                this.oligoAndProbeDataSelected.sensibility.results = response.results
                this.oligoAndProbeDataSelected.sensibility.isTestDone = false
                this.charts = []
                this.summary = []
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Resultados eliminados`,
                    timeout: 5000,
                });
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.isCleaningResults = false
        },
        calculateAverages(results) {
            return results.map((result) => {
                // List of fields to calculate the average
                const fields = ["cq", "efficience", "efficienceR2"];
                const averages = {};

                // Calculate the average for each field
                fields.forEach((field) => {
                    if (!Array.isArray(result[field]) || result[field].length === 0) {
                        averages[field] = null; // Assign null if no data
                    } else {
                        const sum = result[field].reduce((acc, value) => acc + value, 0);
                        averages[field] = sum / result[field].length;
                    }
                });

                // Return the necessary original data along with the averages
                return {
                    _id: result._id,
                    microorganism: result.microorganism,
                    dilution: result.dilution,
                    type: result.type,
                    ...averages, // Add the calculated averages
                };
            });
        },
    },
    watch: {
        async observations(value) {
            try {
                let payload = {
                    observations: value
                }
                let params = {
                    markerId: this.markerSelected._id,
                    oligoId: this.oligoAndProbeDataSelected._id,
                }
                await this.addSensibilityObservations({
                    params: params,
                    body: payload
                })
                this.oligoAndProbeDataSelected.sensibility.observations = value
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
        },
        async fileResult(value) {
            if (value) {
                try {
                    this.uploadingResults = true
                    var formData = new FormData();
                    formData.append("file", value);
                    formData.append("markerId", this.markerSelected._id);
                    formData.append("oligoId", this.oligoAndProbeDataSelected._id);
                    let response = await this.uploadResultsSensibility(formData)
                    this.charts = response.charts
                    this.oligoAndProbeDataSelected.sensibility.charts = response.charts
                    this.oligoAndProbeDataSelected.sensibility.results = response.results
                    const clonedArray = JSON.parse(JSON.stringify(this.oligoAndProbeDataSelected.sensibility.results));
                    clonedArray.shift();
                    this.summary = this.calculateAverages(clonedArray)
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: `Resultados guardados`,
                        timeout: 5000,
                    });
                    this.fileResult = null
                    this.$refs.result_field.$el.querySelector('input').blur();
                } catch (error) {
                    console.log(error);
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "error",
                        text: `Algo salió mal. Error: ${error.message}`,
                        timeout: 5000,
                    });
                }
                this.uploadingResults = false
            }

        },
        fileTodownload(value) {
            if (value) {
                var a = document.createElement("a");
                a.href = value;
                let fileName = value.split("/").pop();
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(value);
                a.remove();
                this.fileTodownload = null
                this.$refs["download_result_input"].reset();
                this.$refs.download_result_input.$el.querySelector('input').blur();
            }
        },

    }
};
</script>
