<template>
  <v-container class="px-0">
    <v-carousel
      v-model="model"
      hide-delimiters
      show-arrows-on-hover
      height="auto"
      v-if="plates.length > 0"
    >
      <v-carousel-item v-for="(plate, i) in plates" :key="i">
        <h3 class="text-center">{{ plate[0][0].plateFolio }} <v-btn @click="generateCSV(plate[0][0].plateFolio, reaction)" v-if="type == 'AMPLIFICATION'" :loading="generatingCSV" color="secondary" icon><v-icon>mdi-file-excel</v-icon></v-btn></h3>
        <v-simple-table dense v-if="headers.length > 1">
          <template v-slot:default>
            <thead>
              <tr>
                <template v-if="type == 'ELUTION'">
                  <th
                    class="text-center pointer"
                    v-for="(item, index) of headers"
                    :key="index"
                    @click="selectColumn(i, item.name)"
                  >
                    <span v-if="item.name">{{ item.name }}</span>
                    <span v-else></span>
                  </th>
                </template>
                <template v-else>
                  <th
                    class="text-center"
                    v-for="(item, index) of headers"
                    :key="index"
                  >
                    <span v-if="item.name">{{ item.name }}</span>
                    <span v-else></span>
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-if="type == 'ELUTION'">
                <tr v-for="(item, index) in rows[i]" :key="index">
                  <td
                    v-for="(child, indexChild) in rows[i][index]"
                    :key="indexChild"
                    class="text-center pa-0 pointer"
                  >
                    <template v-if="indexChild != 0">
                      <v-card
                        v-if="!child.isWater"
                        :color="
                          child.folioAnalysis &&
                          child.folioAnalysis.includes(analysis)
                            ? child.sampleType && child.sampleType.includes('VEGETABLE')
                              ? 'primary'
                              : 'warning'
                            : child.type == 'PURIFICATION' &&
                              child.valueNum != 1 &&
                              child.valueNum != 7
                            ? '#b2b2b2'
                            : '#f2f2f2'
                        "
                        class="ma-1 mx-auto"
                        width="7vh"
                        @click="selectSample(i, index, indexChild)"
                        :shaped="child.active"
                        :elevation="child.active ? '4' : '0'"
                      >
                        <v-card-text class="px-0 py-2">
                          <!-- {{ child.value }} -->
                          <small
                            v-if="
                              child.folioAnalysis &&
                              child.folioAnalysis.includes(analysis)
                            "
                            class="white--text"
                          >
                            {{child.replica && child.replica != "1" ? `(${child.replica})` : ''}} {{ child.folioAnalysis }}
                          </small>
                          <small v-else-if="child.folioAnalysis">
                            {{child.replica && child.replica != "1" ? `(${child.replica})` : ''}} {{ child.folioAnalysis }}
                          </small>
                          <small v-else> &nbsp; </small>
                        </v-card-text>
                      </v-card>
                    </template>
                    <template v-else>
                      {{ child.value }}
                    </template>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="(item, index) in rows[i]" :key="index">
                  <td
                    v-for="(child, indexChild) in rows[i][index]"
                    :key="indexChild"
                    class="text-center pa-0"
                  >
                    <template v-if="indexChild != 0">
                      <v-card
                        v-if="child.control == undefined"
                        :color="
                          child.isWater ? 'blue' :
                          child.folioAnalysis && child.folioAnalysis.includes(analysis) ? 
                            child.sampleType && child.sampleType.includes('VEGETABLE') ? 'primary' : 'warning' : 
                            child.type == 'PURIFICATION' && child.valueNum != 1 && child.valueNum != 7 ? '#b2b2b2' : 
                          '#f2f2f2'
                        "
                        class="ma-1 mx-auto"
                        :width="type == 'AMPLIFICATION' ? '10vh' : '7vh'"
                      >
                        <v-card-text class="px-0 py-2">
                          <!-- {{ child.value }} -->
                          <small
                            v-if="child.folioAnalysis && child.folioAnalysis.includes(analysis)" class="white--text"
                          >
                            {{child.replica && child.replica != "1" ? `(${child.replica})` : ''}} {{ child.type != 'AMPLIFICATION' ? child.folioAnalysis : `R${child.reaction} ${child.folioAnalysis}` }}
                          </small>
                          <small v-else-if="child.folioAnalysis">
                            {{child.replica && child.replica != "1" ? `(${child.replica})` : ''}} {{ child.type != 'AMPLIFICATION' ? child.folioAnalysis : `R${child.reaction} ${child.folioAnalysis}` }}
                          </small>
                          <small class="white--text" v-else-if="child.isWater">
                            {{translations.water}}
                          </small>
                          <small v-else> &nbsp; </small>
                        </v-card-text>
                      </v-card>
                      <v-card v-else class="ma-1 mx-auto" :width="type == 'AMPLIFICATION' ? '10vh' : '7vh'" :color="child.isVegetal ? 'secondary' : child.control ? 'red accent-2' : 'light-blue darken-4'">
                        <v-card-text class="px-1 py-2">
                          <template v-if="child.isVegetal">
                            <small class="white--text">
                              R{{child.reaction}} C. {{translations.plant}}
                            </small>
                          </template>
                          <template v-else>
                            <small v-if="child.control" class="white--text">
                              R{{child.reaction}} Ctrol +
                            </small>
                            <small v-else class="white--text">
                              R{{child.reaction}} Ctrol -
                            </small>
                          </template>
                        </v-card-text>
                      </v-card>
                    </template>
                    <template v-else>
                      {{ child.value }}
                    </template>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-carousel-item>
    </v-carousel>
    <v-row v-else>
      {{ translations.no_results }}
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

export default {
  data: () => ({
    model: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
    sample: null,
    data: 5,
    rows: [],
    headers: [{ name: null }],
    platesData: [],
    wellsPlateSelected: [],
    generatingCSV : false
  }),
  props: {
    analysis: {
      type: String,
      require: true,
    },
    plates: {
      type: Array,
      require: true,
    },
    type: {
      type: String,
      require: true,
    },
    reaction : {
      type : Number,
      require : true
    },
    updateTab : {
      type : Boolean,
      require : false,
      default: null
    }
  },
  computed: {
    ...mapGetters("session", ["currentTranslations"]),
    translations() {
      return this.currentTranslations;
    },
    ...mapState("molecularPlateWell", ["wellsSelected"]),
  },
  mounted() {
    this.formatPlates()
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions('molecularPlateWell', ['getWellsByFolioAndReaction']),
    async generateCSV(folio, reaction){
      this.generatingCSV = true
      try {
        let request = await this.getWellsByFolioAndReaction({folio, reaction});
        // console.log(request)
        if (request.error) throw request;

        let headers = {
          Well: "Well",
          "Source Name": "Source Name",
          Groups: "Groups",
          "": "",
          "ID PLATE": "ID PLATE",
        };
        let wellsData = [];
        let row = 1
        for (const well of request.plate.wells) {
          let data = null;
          
          // console.log(well)

          if(row == 1){
            let plateFolio = request.plate.folio;

            data = {
                Row : row,
                // Well: '',
                "Source Name": '',
                Groups: '',
                "": "",
                "ID PLATE": "ID PLATE",
              };

            headers[plateFolio] = plateFolio;
            data[plateFolio] = plateFolio;

            row++;
            wellsData.push(data);
            
          }else{

            if(!well.control){
              if(well.soilSample){
                data = {
                  Row : row,
                  // Well: `${well.wellPlate[1]}${well.wellPlate[0]}`,
                  "Source Name": well.soilSample.folioAnalysis,
                  Groups: well.soilSample.sampleType && well.soilSample.sampleType.includes("VEGETABLE")
                    ? "Tejido vegetal"
                    : "Suelo",
                  "": "",
                  "ID PLATE": "",
                };

                row++;
                wellsData.push(data);
              }else{
                // data = {
                //   Row : row,
                //   // Well: `${well.wellPlate[1]}${well.wellPlate[0]}`,
                //   "Source Name": "Agua",
                //   Groups: '',
                //   "ID PLATE": "",
                // };
              }
              
            }else{
              
              data = {
                Row : row,
                // Well: `${well.wellPlate[1]}${well.wellPlate[0]}`,
                "Source Name": well.isVegetal == true ? 'Control vegetal' : well.control == true ? 'Control positivo' : 'Control negativo',
                Groups: '',
                "": "",
                "ID PLATE": "",
              };

              row++;
              wellsData.push(data);
            }
          }
        }

        if(wellsData.length < 48){
          // console.log(wellsData.length)
          for (let index = (wellsData.length + 1); index <= 48; index++) {
            let data = {
              Row : index,
            };
            wellsData.push(data);
          }
        }

        this.exportCSVFile(headers, wellsData, `${request.plate.folio}-R${reaction}`)

        // console.log(request)
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error al generar el reporte. Error: ${error.message}`,
          timeout: 4000,
        });
      }
      this.generatingCSV = false
    },
    exportCSVFile(headers, items, fileTitle) {
      // if (headers) {
      //   items.unshift(headers);
      // }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle + ".csv" || "export.csv";

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },
    formatPlates() {
      if (this.plates) {
        this.headers = [{ name: null }]
        this.rows = []
        // columns
        let COLUMNS = 0;
        if (this.type == "PURIFICATION" || this.type == "ELUTION") {
          COLUMNS = 12;
        } else {
          COLUMNS = 6;
        }

        for (let index = 0; index < COLUMNS; index++) {
          this.headers.push({ name: index + 1 });
        }
        // console.log(this.plates);

        for (const plate of this.plates) {
          let rowsPlate = [];
          for (let index = 0; index < plate.length; index++) {
            let row = [];

            for (let i = 0; i < COLUMNS; i++) {
              if (i == 0) {
                row.push({
                  value: this.chartFromIndex(index),
                  valueNum: -1,
                  active: false,
                });
              }
              row.push({
                value: i + 1 + " " + this.chartFromIndex(index),
                valueNum: i + 1,
                active: false,
                ...plate[index][i],
              });
            }
            rowsPlate.push(row);
          }

          this.rows.push(rowsPlate);
        }
      }
    },
    chartFromIndex(index) {
      let initialChart = "A";
      var character = String.fromCharCode(initialChart.charCodeAt(0) + index);
      return character;
    },
    toggle(indexFather, indexChild) {
      this.rows[indexFather][indexChild].active =
        !this.rows[indexFather][indexChild].active;
    },
    addSample(indexFather, indexChild) {
      if (this.sample) {
        if (typeof this.sample === "object") {
          this.rows[indexFather][indexChild].sample = this.sample;
          this.sample = null;
        }
      } else {
        this.rows[indexFather][indexChild].sample = null;
      }
    },
    selectSample(index0, index1, index2) {
      let test = this.rows[index0][index1][index2];

      if (test.folioAnalysis) {
        test.active = !test.active;

        if (test.active) {
          // this.wellsPlateSelected.push(test);
          this.$emit("cellClicked", test);
        } else {
          // let index = this.wellsPlateSelected.findIndex(
          //   (well) => well.folioAnalysis == test.folioAnalysis
          // );

          // this.wellsPlateSelected.splice(index, 1);
          this.$emit("cellUnclicked", test);
        }

        // this.$emit("cellClicked", this.wellsPlateSelected);
      }
    },
    selectColumn(plateIndex, column) {
      let wellsColumn = [];
      let plateSelected = this.rows[plateIndex];

      for (const plateRow of plateSelected) {
        let cell = plateRow[column];

        if (cell.folioAnalysis) {
          cell.active = !cell.active;
          // cell.active = true
          if (cell.active) {
            // wellsColumn.push(cell);
            this.$emit("cellClicked", cell);
          } else {
            // let index = this.wellsPlateSelected.findIndex(
            //   (well) => well.folioAnalysis == cell.folioAnalysis
            // );
            // this.wellsPlateSelected.splice(index, 1);
            this.$emit("cellUnclicked", cell);
          }
        }
      }

      // for (const well of wellsColumn) {
      //   let wellOnArray = this.wellsPlateSelected.find(
      //     (w) => w.folioAnalysis == well.folioAnalysis && w.replica == well.replica
      //   );

      //   if (!wellOnArray) {
      //     this.wellsPlateSelected.push(well);
      //   }
      // }

      // this.$emit("cellClicked", this.wellsPlateSelected);
    },
  },
  watch: {
    plates: {
      handler() {
        this.formatPlates()
      },
      deep: true,
    },
    updateTab() {
      if(this.updateTab != null){
        // console.log(this.wellsSelected)
        this.wellsPlateSelected = this.wellsSelected;
      }
    }
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>