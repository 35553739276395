<template>
<v-container>
    <v-row>
        <v-col cols="12" md="6">
            <v-text-field outlined label="Buscar..." prepend-inner-icon="mdi-magnify" background-color="white" dense clearable v-model="searchQuery"></v-text-field>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
// VUEX
import {
    mapMutations,
} from "vuex";
export default {
    data() {
        return {
            searchQuery: "",
            filter: {
                sort: null,
            },
        };
    },
    created() {
        //set default sort
        this.filter.sort = "NEWER";
        this.SET_SEARCH_TABLE(null);
        this.applyFilter();
    },
    methods: {
        ...mapMutations("vector", [
            "SET_FILTER_TABLE",
            "SET_SEARCH_TABLE",
        ]),
        applyFilter() {
            let auxFilter = {
                sort: null,
            };
            if (this.filter.sort) {
                switch (this.filter.sort) {
                    case "NEWER":
                        auxFilter.sort = {
                            createdAt: -1
                        };
                        break;
                    case "OLDER":
                        auxFilter.sort = {
                            createdAt: 1
                        };
                        break;
                }
            }
            this.SET_FILTER_TABLE(auxFilter);
            this.filterMenu = false;
        }
    },
    watch: {
        async searchQuery(value) {
            this.SET_SEARCH_TABLE(value);
        },
    },
};
</script>

<style scoped>
.headline {
    font-size: 1.5rem;
    font-weight: bold;
}
</style>
