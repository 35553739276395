<template>
<v-container fluid>
    <v-row class="flex-wrap">
        <v-col :class="['left-column', { collapsed: collapsed }]" :cols="collapsed ? 1 : 12" :xl="collapsed ? 1 : 5" :lg="collapsed ? 1 : 6" class="d-flex flex-column">
            <v-expand-x-transition>
                <div>
                    <div :class="['collapse-transition', { collapsed: collapsed }]">
                        <v-row v-show="!collapsed">
                            <v-col cols="12">
                                <v-card height="auto" outlined>
                                    <v-card-title>
                                        <v-row class="mb-4 align-center">
                                            <v-col class="d-flex align-center">
                                                <v-btn fab color="primary" small class="mr-4" @click="hideValidations">
                                                    <v-icon>mdi-arrow-left</v-icon>
                                                </v-btn>
                                                <h2 class="text-h5 font-weight-medium">
                                                    Datos generales
                                                </h2>
                                            </v-col>
                                            <v-col cols="auto" class="text-right">
                                                <v-btn @click="toggleCollapse" class="text-center" color="primary" small v-if="!collapsed">
                                                    <v-icon>
                                                        {{ collapsed ? "mdi-chevron-right" : "mdi-chevron-left" }}
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-card-title>
                                    <v-container class="ma-1">
                                        <v-row>
                                            <v-col cols="12" class="pb-0 pt-0">
                                                <span> Detección de </span>
                                                <span class="primary--text"> {{ getMarkerName() }} </span>
                                            </v-col>
                                            <v-col cols="12">
                                                Etiqueta del primer <span class="primary--text">
                                                    {{ markerSelected.molecularMarkerID }}
                                                    <span v-if="(markerSelected.markerType == 'FNC' || markerSelected.markerType == 'MO_FNC') && markerSelected.soilFunction">
                                                        {{ markerSelected.soilFunction.es }}
                                                    </span>
                                                </span>
                                            </v-col>

                                            <v-col cols="12">
                                                <v-row>
                                                    <v-col cols="auto">
                                                        <v-btn-toggle v-model="activeTab" mandatory>
                                                            <v-btn :value="0" :class="{ active: activeTab === 0 }" outlined>
                                                                Oligo
                                                            </v-btn>
                                                            <v-btn :value="1" :class="{ active: activeTab === 1 }" outlined>
                                                                Suelos
                                                            </v-btn>
                                                            <v-btn :value="2" :class="{ active: activeTab === 2 }" outlined>
                                                                Notas
                                                            </v-btn>
                                                        </v-btn-toggle>
                                                    </v-col>
                                                    <v-col v-if="markerSelected.validationStatus == 'NOT_STARTED'">
                                                        <v-btn color="primary" @click="showStartValidationsConfirmationModal = true" :loading="startingValidations" :disabled="!permissions.find((permission) => permission == 'lab_validation_write')">Iniciar con
                                                            validaciones</v-btn>
                                                    </v-col>
                                                    <!-- <v-col v-if="oligoAndProbeDataSelected.validationFinished && !oligoAndProbeDataSelected.releasedVersion">
                                                        <v-btn color="primary" @click="showSubmitToCMDAConfirmationModal = true" :loading="startingValidations" :disabled="!permissions.find((permission) => permission == 'lab_validation_write')">Marcar como entregado a
                                                            CMDA</v-btn>
                                                    </v-col>
                                                    <v-col v-if="oligoAndProbeDataSelected.releasedVersion">
                                                        <span>
                                                            Entregado a CMDA: {{
                                                                dateFormat(oligoAndProbeDataSelected.releasedDate) }}
                                                        </span>
                                                    </v-col> -->
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-carousel height="auto" hide-delimiter-background :show-arrows="false" v-model="activeTab">
                                    <v-carousel-item>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <v-card outlined class="pa-2">
                                                            <v-card-title class="text-center">Información
                                                                Adicional</v-card-title>
                                                            <v-card-text class="py-1">
                                                                <div class="d-flex justify-space-between">
                                                                    <span class="font-weight-medium">Locus:</span>
                                                                    <span>{{ oligoAndProbeDataSelected.locus
                                                                            }}</span>
                                                                </div>
                                                                <div class="d-flex justify-space-between">
                                                                    <span class="font-weight-medium">Taxa:</span>
                                                                    <span>{{ oligoAndProbeDataSelected.taxa
                                                                            }}</span>
                                                                </div>
                                                                <div class="d-flex justify-space-between">
                                                                    <span class="font-weight-medium">Proyecto:</span>
                                                                    <span>{{ oligoAndProbeDataSelected.project
                                                                            }}</span>
                                                                </div>
                                                            </v-card-text>
                                                            <v-card-title class="text-center">Amplicon</v-card-title>
                                                            <v-card-text class="py-1">
                                                                <div>
                                                                    <span class="font-weight-medium">Secuencia:</span>
                                                                    <v-spacer></v-spacer>
                                                                    <span>{{
                                                                            truncateString(oligoAndProbeDataSelected.amplicon,
                                                                                25) }}</span>
                                                                    <v-icon color="primary" small @click="copyToClipboard(oligoAndProbeDataSelected.amplicon)">mdi-content-copy</v-icon>
                                                                </div>

                                                                <div class="d-flex justify-space-between">
                                                                    <span class="font-weight-medium">Tamaño del
                                                                        amplicon en
                                                                        pb:</span>
                                                                    <span>{{ oligoAndProbeDataSelected.ampliconSize
                                                                            }}</span>
                                                                </div>
                                                                <div class="d-flex justify-space-between">
                                                                    <span class="font-weight-medium">Cepa
                                                                        control:</span>
                                                                    <span>{{ oligoAndProbeDataSelected.strainControl
                                                                            }}</span>
                                                                </div>
                                                                <div class="d-flex justify-space-between">
                                                                    <span class="font-weight-medium">GC
                                                                        amplicon:</span>
                                                                    <span>{{ oligoAndProbeDataSelected.gcAmplicon
                                                                            }}</span>
                                                                </div>
                                                                <div class="d-flex justify-space-between">
                                                                    <span class="font-weight-medium">Estructura
                                                                        secundaria:</span>
                                                                    <span>{{
                                                                            oligoAndProbeDataSelected.secondaryStructure
                                                                            }}</span>
                                                                </div>
                                                            </v-card-text>
                                                        </v-card>

                                                    </v-col>
                                                    <v-col cols="12" sm="6" v-for="(item, index) in oligoAndProbeDataSelected.oligoAndProbeData" :key="index">
                                                        <v-card outlined class="pa-2">
                                                            <v-card-title class="text-center">{{ item.tag
                                                                    }}</v-card-title>
                                                            <v-card-text class="py-1">
                                                                <div v-if="item.tag == 'PR' && markerSelected.fluorophore">
                                                                    <span class="font-weight-medium">Fluoróforo: </span>
                                                                    <span class="primary--text">{{ markerSelected.fluorophore }}</span>
                                                                </div>
                                                                <div>
                                                                    <span class="font-weight-medium">Etiqueta:</span>
                                                                    <span class="label_pointer primary--text" @click="permissions.find((permission) => permission == 'lab_validation_write')?openAddTagToSequence(item):undefined">{{
                                                                                item.sequenceTag || 'Agregar etiqueta'
                                                                            }}</span>
                                                                </div>
                                                                <div>
                                                                    <span class="font-weight-medium">Secuencia:</span>
                                                                    <v-spacer></v-spacer>
                                                                    <span>{{ item.sequence }}</span>
                                                                    <v-icon color="primary" small @click="copyToClipboard(item.sequence)">mdi-content-copy</v-icon>
                                                                </div>
                                                                <div class="d-flex justify-space-between">
                                                                    <span class="font-weight-medium">No. Pb:</span>
                                                                    <span>{{ item.sequence.length }}</span>
                                                                </div>
                                                                <div class="d-flex justify-space-between">
                                                                    <span class="font-weight-medium">TM:</span>
                                                                    <span>{{ item.tm }}</span>
                                                                </div>
                                                                <div class="d-flex justify-space-between">
                                                                    <span class="font-weight-medium">Nombre:</span>
                                                                    <span>{{ item.name }}</span>
                                                                </div>
                                                                <div class="d-flex justify-space-between">
                                                                    <span class="font-weight-medium">%GC:</span>
                                                                    <span>{{ item.gc }}</span>
                                                                </div>
                                                                <div class="d-flex justify-space-between">
                                                                    <span class="font-weight-medium">Hairpin Tm
                                                                        ºC:</span>
                                                                    <span>{{ item.hairpin }}</span>
                                                                </div>
                                                                <div class="d-flex justify-space-between">
                                                                    <span class="font-weight-medium">Self dimer Tm
                                                                        ºC:</span>
                                                                    <span>{{ item.selfDimer }}</span>
                                                                </div>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-carousel-item>
                                    <v-carousel-item>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-card height="auto" outlined>
                                                    <v-card-title>
                                                        <span> Folios Metagenoma </span>
                                                    </v-card-title>
                                                    <v-container class="ma-1">
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <v-simple-table class="overflow-y-auto" height="300">
                                                                    <template v-slot:default>
                                                                        <thead>
                                                                            <tr>
                                                                                <th class="text-center">ID</th>
                                                                                <th class="text-center">Cultivo</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody class="text-center">
                                                                            <tr v-for="(item, index) in oligoAndProbeDataSelected.validationSoils" :key="index">
                                                                                <td>{{ item.folio }}</td>
                                                                                <td>{{ item.crop || 'No disponible'
                                                                                        }}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </template>
                                                                </v-simple-table>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-carousel-item>
                                    <v-carousel-item>
                                        <v-row>
                                            <v-col>
                                                <v-col cols="12">
                                                    <v-card outlined class="pa-2">
                                                        <v-card-title class="text-center">Consideraciones</v-card-title>
                                                        <v-card-text class="py-1">
                                                            <div class="d-flex justify-space-between">
                                                                <span>{{ oligoAndProbeDataSelected.notes }}</span>
                                                            </div>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-expand-x-transition>
        </v-col>
        <v-col :class="['right-column', { collapsed: collapsed }]" :cols="collapsed ? 12 : 12" :xl="collapsed ? 12 : 7" :lg="collapsed ? 12 : 6" class="d-flex flex-column" v-if="markerSelected.validationStatus != 'NOT_STARTED'">
            <v-card height="auto" outlined class="rounded-lg flex d-flex flex-column">
                <v-container>
                    <v-row >
                        <v-col>
                            <v-btn @click="permissions.find((permission) => permission == 'lab_hide_oligo_detail') ? hideValidations():toggleCollapse()" class="text-center" color="primary" small v-if="collapsed">
                                <v-icon>
                                    {{ collapsed ? permissions.find((permission) => permission == 'lab_hide_oligo_detail') ? "mdi-chevron-left" :"mdi-chevron-right" : "mdi-chevron-left" }}
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-tabs color="primary" grow>
                        <v-tab> Inclusividad </v-tab>
                        <v-tab> Exclusividad </v-tab>
                        <v-tab> Sensibilidad </v-tab>
                        <v-tab> Prueba en suelo</v-tab>

                        <v-tab-item eager>
                            <Inclusivity />
                        </v-tab-item>
                        <v-tab-item eager>
                            <Exclusivity />
                        </v-tab-item>
                        <v-tab-item eager>
                            <Sensitivity />
                        </v-tab-item>
                        <v-tab-item eager>
                            <SoilTest />
                        </v-tab-item>
                    </v-tabs>
                </v-container>
            </v-card>
        </v-col>
        <v-col :class="['right-column', { collapsed: collapsed }]" :cols="collapsed ? 12 : 12" :xl="collapsed ? 12 : 7" :lg="collapsed ? 12 : 6" class="d-flex flex-column" v-else>
            <v-card height="auto" outlined class="rounded-lg flex d-flex flex-column">
                <v-container>
                    <v-row>
                        <v-col>
                            <v-btn @click="toggleCollapse" class="text-center" color="primary" small v-if="collapsed">
                                <v-icon>
                                    {{ collapsed ? "mdi-chevron-right" : "mdi-chevron-left" }}
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-tabs color="primary" grow v-model="steps">
                        <v-tab disabled> Inclusividad </v-tab>
                        <v-tab disabled> Exclusividad </v-tab>
                        <v-tab disabled> Sensibilidad </v-tab>
                        <v-tab disabled> Prueba en suelo</v-tab>

                        <v-tab-item eager>
                            <v-container>
                                <v-stepper elevation="0">
                                    <v-stepper-header>
                                        <v-stepper-step :complete="false" step="1">
                                            Armado de prueba
                                        </v-stepper-step>
                                        <v-divider></v-divider>
                                        <v-stepper-step :complete="false" step="2">
                                            Subir resultados
                                        </v-stepper-step>
                                    </v-stepper-header>
                                    <v-stepper-items>
                                        <v-stepper-content step="1">
                                            <v-row>
                                                <v-col align="end">
                                                    <v-btn color="primary" disabled> Continuar </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-btn color="primary" disabled>
                                                        <v-icon> mdi-plus </v-icon>
                                                        Agregar
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-responsive class="overflow-x-auto">
                                                        <v-simple-table dense class="overflow-y-auto">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-left">No.</th>
                                                                    <th class="text-left">ID</th>
                                                                    <th class="text-left">Microorganismo</th>
                                                                    <th class="text-left">Número de réplicas</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>-</td>
                                                                    <td>-</td>
                                                                    <td>-</td>
                                                                    <td>-</td>
                                                                </tr>
                                                            </tbody>
                                                        </v-simple-table>
                                                    </v-responsive>
                                                </v-col>
                                            </v-row>

                                        </v-stepper-content>

                                        <v-stepper-content step="2">

                                        </v-stepper-content>
                                    </v-stepper-items>
                                </v-stepper>
                            </v-container>
                        </v-tab-item>
                        <v-tab-item eager>
                            <v-container>

                            </v-container>
                        </v-tab-item>
                        <v-tab-item eager>
                            <v-container>

                            </v-container>
                        </v-tab-item>
                        <v-tab-item eager>
                            <v-container>

                            </v-container>
                        </v-tab-item>
                    </v-tabs>
                </v-container>
            </v-card>
        </v-col>
    </v-row>
    <add-tag-to-sequence-modal v-model="showAddTagToSequenceModal" v-show="showAddTagToSequenceModal" :sequence="tempSequenceSelection" @addTag="addTagToSequence" />
    <StartValidationsConfirmationModal v-model="showStartValidationsConfirmationModal" @confirmation="startProcess()" :title="`¿Estás segura de que deseas iniciar las validaciones?`" />
    <StartValidationsConfirmationModal v-model="showSubmitToCMDAConfirmationModal" @confirmation="submitPrimer()" :title="`¿Estás segura de que deseas marcar el primer como entregado a CMDA?`" />
</v-container>
</template>

<script>
//Libraries
import dayjs from "dayjs";
import {
    mapState,
    mapMutations,
    mapActions
} from "vuex";
// components
import Inclusivity from "@/components/validations/Inclusivity.vue";
import Exclusivity from "@/components/validations/Exclusivity.vue";
import Sensitivity from "@/components/validations/Sensitivity.vue";
import SoilTest from "@/components/validations/SoilTest.vue";
import AddTagToSequenceModal from './modals/AddTagToSequenceModal.vue';
import StartValidationsConfirmationModal from "@/components/utils/GenericConfirmationModal.vue";
import SubmitToCMDAConfirmationModal from "@/components/utils/GenericConfirmationModal.vue";

export default {
    components: {
        Inclusivity,
        Exclusivity,
        Sensitivity,
        SoilTest,
        AddTagToSequenceModal,
        StartValidationsConfirmationModal,
        SubmitToCMDAConfirmationModal,
    },
    data: () => ({
        startingValidations: false,
        showEditLabel: false,
        label: null,
        activeTab: 0,
        showAddTagToSequenceModal: false,
        tempSequenceSelection: null,
        steps: 0,
        showStartValidationsConfirmationModal: false,
        showSubmitToCMDAConfirmationModal: false,
        collapsed: false
    }),
    computed: {
        ...mapState("molecularMarker", ["oligoAndProbeDataSelected", "markerSelected"]),
        ...mapState("session", ["permissions"]),
        completedTasks() {
            return this.tasks.filter((task) => task.done).length;
        },
        progress() {
            return (this.completedTasks / this.tasks.length) * 100;
        },
        remainingTasks() {
            return this.tasks.length - this.completedTasks;
        },
    },
    mounted(){
        if(this.permissions.find((permission) => permission == 'lab_hide_oligo_detail')){
            this.collapsed = true
        }
    },
    methods: {
        ...mapActions("molecularMarker", ["addTagOligo", "startValidations", "releasePrimerVersion"]),
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        toggleCollapse() {
            this.collapsed = !this.collapsed;
        },
        hideValidations() {
            this.$emit("hide_validations");
        },
        getMarkerName() {
            if (this.markerSelected) {
                if (this.markerSelected.markerType == 'MO') {
                    return this.markerSelected.microorganism_meta.name
                } else {
                    return this.markerSelected.soilFunction.es
                }
            }
        },
        truncateString(str, num) {
            // If the length of str is less than or equal to num
            // just return str--don't truncate it.
            if (str.length <= num) {
                return str;
            }
            // Return str truncated with '...' concatenated to the end of str.
            return str.slice(0, num) + "...";
        },
        async copyToClipboard(textToCopy) {
            try {
                // Copiar texto al portapapeles
                await navigator.clipboard.writeText(textToCopy);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Texto copiado al portapapeles.`,
                    timeout: 5000,
                });
            } catch (error) {
                console.error("Error al copiar al portapapeles: ", error);
            }
        },
        async addTagToOligo() {
            try {
                if (this.$refs.form_label.validate()) {
                    let payload = {
                        tag: this.label
                    }
                    let params = {
                        markerId: this.markerSelected._id,
                        oligoId: this.oligoAndProbeDataSelected._id,
                    }
                    await this.addTagOligo({
                        body: payload,
                        params: params
                    })
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: `Etiqueta agregada`,
                        timeout: 5000,
                    });
                    this.showEditLabel = false
                    this.oligoAndProbeDataSelected.tagMarker = this.label
                    this.label = null
                }
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
        },
        copyTagToLable(item) {
            this.label = item
            this.showEditLabel = true
        },
        openAddTagToSequence(item) {
            console.log(item);

            this.tempSequenceSelection = item
            this.showAddTagToSequenceModal = true
        },
        async addTagToSequence(label) {
            try {
                let payload = {
                    sequenceTag: label
                }
                let params = {
                    markerId: this.markerSelected._id,
                    oligoId: this.oligoAndProbeDataSelected._id,
                    tag: this.tempSequenceSelection.tag
                }
                await this.addTagOligo({
                    body: payload,
                    params: params
                })
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Etiqueta agregada`,
                    timeout: 5000,
                });
                this.showAddTagToSequenceModal = false
                let aux = this.oligoAndProbeDataSelected.oligoAndProbeData.find(element => element.tag == this.tempSequenceSelection.tag)
                if (aux) {
                    aux.sequenceTag = label
                    this.tempSequenceSelection = null
                }
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
        },
        async startProcess() {
            try {
                this.startingValidations = true
                let payload = {}
                let params = {
                    markerId: this.markerSelected._id,
                    oligoId: this.oligoAndProbeDataSelected._id,
                    oligoIdentifier: this.oligoAndProbeDataSelected.oligoIdentifier,
                }
                await this.startValidations({
                    body: payload,
                    params: params
                })
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Validaciones iniciadas`,
                    timeout: 5000,
                });

                this.markerSelected.validationStatus = "IN_PROGRESS"
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.startingValidations = false
        },
        async submitPrimer() {
            try {
                let payload = {
                    oligoIdentifier: this.oligoAndProbeDataSelected.oligoIdentifier
                }
                let params = {
                    markerId: this.markerSelected._id,
                    oligoId: this.oligoAndProbeDataSelected._id,
                }
                await this.releasePrimerVersion({
                    params,
                    body: payload
                })
                this.$set(this.oligoAndProbeDataSelected, 'releasedVersion', true);
                this.$set(this.oligoAndProbeDataSelected, 'releasedDate', new Date());
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: `Marcador entregado a CMDA`,
                    timeout: 5000,
                });
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
        },
        dateFormat(value) {
            const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
            return date || "--/--/----";
        },
    },
};
</script>

<style scoped>
.label_pointer:hover {
    cursor: pointer;
}

.active {
    background-color: #04C105 !important;
    /* Color primary */
    color: white !important;
    /* Texto blanco para destacar */

}
</style>
