<template>
    <v-dialog v-model="show" width="400" persistent :content-class="$vuetify.theme.dark ? 'bg-dark' : 'bg-light'"
        transition="dialog-bottom-transition">
        <v-card elevation="8" class="rounded-xl overflow-hidden">
            <!-- Header con gradiente -->
            <div class="pa-4 primary" :class="$vuetify.theme.dark ? 'darken-1' : ''">
                <v-card-title class="text-h5 font-weight-bold white--text px-0 pb-1">
                    Confirmación
                </v-card-title>
                <v-card-subtitle v-if="title" class="white--text text-subtitle-1 px-0 py-2">
                    {{ title }}
                </v-card-subtitle>
            </div>

            <v-card-text class="pt-6 px-6">
                <v-fade-transition>
                    <div>
                        <div class="text-center mb-4">
                            <v-icon size="40" color="primary" class="mb-2">mdi-flask-outline</v-icon>
                            <h3 class="text-h6 font-weight-medium">{{ subtitle }}</h3>
                        </div>

                        <v-select label="Selecciona Fluoróforo" variant="outlined" density="comfortable" clearable
                            hide-details prepend-inner-icon="mdi-molecule" v-model="fluorophore"
                            :items="fluorophoreOptions" class="rounded-lg mb-4" menu-props="auto" item-text="title"
                            :hint="fluorophore ? `Fluoróforo ${fluorophore} seleccionado` : 'Opcional'" return-object
                            persistent-hint></v-select>
                        <span class="text-caption" v-if="fluorophore">
                            {{ fluorophore.description }}
                        </span>
                    </div>
                </v-fade-transition>
            </v-card-text>

            <!-- Botones -->
            <v-divider></v-divider>

            <v-card-actions class="pa-4">
                <v-spacer></v-spacer>
                <v-btn color="grey" variant="text" @click="closeModal" :disabled="isLoading" class="text-capitalize"
                    dark>
                    <v-icon start>mdi-close</v-icon>
                    Cancelar
                </v-btn>
                <v-btn color="primary" @click="confirmation" :loading="isLoading" class="text-capitalize"
                    variant="elevated" elevation="2">
                    <v-icon start>mdi-check</v-icon>
                    Aceptar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
    data: () => ({
        isLoading: false,
        fluorophore: null,
        subtitle: null,
        fluorophoreOptions: [
            { title: 'HEX', value: 'HEX', description: 'Hexaclorofluoresceína' },
            { title: 'FAM', value: 'FAM', description: 'Carboxifluoresceína' },
            { title: 'CY5', value: 'CY5', description: 'Cianina 5' }
        ]
    }),
    props: {
        value: Boolean,
        title: String,
    },
    mounted() {
        this.subtitle = this.markerSelected?.fluorophore
            ? "Último Fluoróforo Asignado"
            : "Asignar Fluoróforo";
        if (this.markerSelected.fluorophore) {
            this.fluorophore = this.fluorophoreOptions.find(element => element.value == this.markerSelected.fluorophore)
        }
    },
    computed: {
        ...mapState("molecularMarker", ["markerSelected"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        closeModal() {
            this.show = false;
        },
        confirmation() {
            this.$emit("confirmation", this.fluorophore.value);
            this.closeModal();
        },
    },
};
</script>

<style scoped>
.v-card {
    position: relative;
}

/* Animación al abrir el diálogo */
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
    transition: all 0.3s ease-out;
}

.dialog-bottom-transition-enter-from,
.dialog-bottom-transition-leave-to {
    transform: translateY(20px);
    opacity: 0;
}
</style>