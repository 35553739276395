<template>
<v-dialog v-model="show" :max-width="dialogWidth" scrollable persistent>
    <v-card elevation="5" class="rounded-lg" ref="card">
        <v-app-bar color="primary accent-4" dark flat height="60">
            <v-avatar color="white" size="36" class="mr-4">
                <v-icon color="primary accent-4">mdi-bacteria</v-icon>
            </v-avatar>
            <v-toolbar-title class="headline font-weight-bold">
                Registro cepa
                <template v-if="preservedSelected">
                    <span v-if="preservedSelected.isolate">({{ preservedSelected.genomicSequence.moSequenceId }}-{{ preservedSelected.isolateSerialNumber }})</span>
                    <span v-else>({{ preservedSelected.genomicSequence.moSequenceId }})</span>
                </template>
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-app-bar>
        <v-card-text>
            <v-container v-if="preservedSelected">
                <v-expansion-panels v-model="panels" multiple hover>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col>
                                    <v-icon>mdi-cog</v-icon>
                                    Configuración
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-form ref="form_configuration" lazy-validation>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field dense outlined :rules="rulesCode" label="Ingresa ID cepa" v-model="form.strainId" type="text" @change="verifyCode" ref="strain_code" clearable disabled></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-autocomplete outlined dense label="Selecciona tipo de microorganismo" return-object item-text="text" :items="types" v-model="type" hide-no-data></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-combobox :rules="validation()" v-model="identification" :search-input.sync="search" :items="identificationsFounded" :loading="isLoading" @keydown="changeIdentificationValue" label="Nombre microorganismo" item-text="name" clearable hide-no-data outlined dense :disabled="!type">
                                        </v-combobox>
                                    </v-col>

                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-switch dense v-model="form.viability" label="Viabilidad"></v-switch>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-switch label="Asociado a un folio" v-model="isRelatedToSample" dense></v-switch>
                                    </v-col>
                                    <v-col cols="12" md="6" v-if="isRelatedToSample">
                                        <v-text-field label="Folio" dense outlined v-model="form.folio"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-switch label="Asociado a cepa" v-model="isRelatedToIsolate" dense disabled></v-switch>
                                    </v-col>
                                    <v-col cols="12" md="6" v-if="isRelatedToIsolate">
                                        <v-autocomplete ref="search_strain" label="Buscar cepa" dense outlined prepend-inner-icon="mdi-magnify" v-model="strainFound" item-text="genomicSequence.moSequenceId" :items="isolates" :loading="loadingIsolate" :search-input.sync="searchIsolate" @change="updateValue" return-object hide-no-data disabled></v-autocomplete>
                                        <v-chip color="primary" v-if="form.isolate">
                                            {{ form.isolate.genomicSequence.moSequenceId }}
                                        </v-chip>
                                    </v-col>

                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-switch label="Cepa proyecto" v-model="isProject" dense></v-switch>
                                    </v-col>
                                    <v-col cols="12" md="6" v-if="isProject">
                                        <v-text-field v-model="linkProject" label="Enlace" type="url" :rules="validation('Enlace')" outlined required dense color="primary" :append-icon="miniatura
                                                        ? miniatura == 'doc'
                                                            ? 'mdi-file-excel-box'
                                                            : 'mdi-file-document'
                                                        : 'mdi-link'
                                                        " @change="generarMiniatura" clearable></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="text-end">
                                        <v-btn color="primary" :loading="isLoadingSaveConfigurationRequest" @click="saveConfigurationRequest()" :disabled="!permissions.find((permission) => permission == 'lab_strains_write')">
                                            <v-icon>
                                                mdi-content-save
                                            </v-icon>
                                            Guardar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="type">
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col>
                                    <v-icon>mdi-information-outline</v-icon>
                                    Datos generale
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-form ref="form_general_data" lazy-validation>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-combobox dense label="Cultivo" outlined :items="crops_items" item-text="name" hide-no-data v-model="form.crop"></v-combobox>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field dense label="Estado de origen" outlined v-model="form.stateOrigin"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Origen (Empresa / Institución / Otro)" outlined dense clearable type="text" v-model="form.companyName"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" v-if="type.value == 'bacteria' || type.value == 'fungi'">
                                        <v-text-field label="Fecha de inoculación" outlined dense clearable type="date" v-model="form.inoculationDate"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Medio de cultivo" outlined dense clearable type="text" v-model="form.cultureMedia"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="text-end">
                                        <v-btn color="primary" :loading="isLoadingSaveGeneralDataRequest" @click="saveGeneralDataRequest()" :disabled="!permissions.find((permission) => permission == 'lab_strains_write')">
                                            <v-icon>
                                                mdi-content-save
                                            </v-icon>
                                            Guardar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="type">
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col>
                                    <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                                    Confirmación de pureza
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-form ref="form_purity" lazy-validation>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Macroscópicamente axénica" outlined dense clearable type="text" v-model="form.macroscopicallyAxenic"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" v-if="type.value == 'bacteria'">
                                        <v-checkbox label="Colonias aisladas" outlined dense clearable type="text" v-model="form.isolatedColonies"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="6" v-if="type.value == 'bacteria'">
                                        <v-checkbox label="Células/Gram" outlined dense clearable type="text" v-model="form.cells_gram"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="6" v-if="type.value == 'bacteria' && form.cells_gram">
                                        <v-autocomplete label="Selecciona tipo de gram" outlined dense :items="['gram +', 'gram -']" clearable type="text" v-model="form.cells_gram_type"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="6" v-if="type.value == 'fungi'">
                                        <v-checkbox label="Presencia de espora" outlined dense clearable type="text" v-model="form.sporePresence"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="6" v-if="type.value == 'nematode'">
                                        <v-checkbox label="J2 viables" outlined dense clearable type="text" v-model="form.j2"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-radio-group label="Confirmado/identificado" v-model="form.identificationMedia">
                                            <v-radio label="Microbiología" value="MB"></v-radio>
                                            <v-radio label="Molecular" value="MOL"></v-radio>
                                            <v-radio label="Otro" value="OTHER"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="text-end">
                                        <v-btn color="primary" :loading="isLoadingSavePurityRequest" @click="savePurityRequest()" :disabled="!permissions.find((permission) => permission == 'lab_strains_write')">
                                            <v-icon>
                                                mdi-content-save
                                            </v-icon>
                                            Guardar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="type">
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col>
                                    <v-icon>mdi-magnify</v-icon>
                                    Datos de confirmación molecular
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-form ref="form_molecular_confirmation" lazy-validation>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Fecha de envio" outlined dense clearable type="date" v-model="form.shippingDateMolecular"></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12" md="6">
                                        <v-combobox label="Homología (Microorganismo)" outlined dense clearable item-text="name" hide-no-data v-model="identificationHomology" :search-input.sync="searchHomology" :items="identificationsFoundedHomology" :loading="isLoadingHomology" @keydown="changeIdentificationValueHomology"></v-combobox>
                                    </v-col> -->
                                </v-row>
                                <v-row>
                                    <v-col class="text-end">
                                        <v-btn color="primary" :loading="isLoadingSaveMolecularConfirmationRequest" @click="saveMolecularConfirmationRequest()" :disabled="!permissions.find((permission) => permission == 'lab_strains_write')">
                                            <v-icon>
                                                mdi-content-save
                                            </v-icon>
                                            Guardar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="type">
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col>
                                    <v-icon>mdi-flask</v-icon>
                                    Datos cepario León
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-form ref="form_ceparium_leon" lazy-validation>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Fecha de recepción" outlined dense clearable type="date" v-model="form.receptionDate"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Número de tubos" outlined dense clearable type="text" v-model="form.containerQtyCeparium"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Fecha preservación" outlined dense clearable type="date" v-model="form.preservationDate"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" v-if="type.value != 'nematode'">
                                        <v-autocomplete label="Metodo de preservación" outlined dense clearable v-model="form.preservationMethod" :items="methods" item-text="name" return-object></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-icon>mdi-warehouse</v-icon>
                                        Datos de Condiciones de Almacenamiento y Ubicación
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Localización" dense outlined v-model="form.locationCentralPlant.container"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Localización (Preservaciones PH/Congelador)" dense outlined v-model="form.locationCentralPlant.container_preservation"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Recipiente preservación" dense outlined v-model="form.locationCentralPlant.saline_solution_container"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Preservación" dense outlined v-model="form.preservationCentralPlant"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Viales (4°C)" dense outlined v-model="form.vialsCentralPlant.vial_4"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Viales (-80ºC)" dense outlined v-model="form.vialsCentralPlant.vial_80"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Viales (-20ºC)" dense outlined v-model="form.vialsCentralPlant.vial_20"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Separacion Leon (-20ºC)" dense outlined v-model="form.vialsCentralPlant.separation_20"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="text-end">
                                        <v-btn color="primary" :loading="isLoadingSaveCepariumLeonRequest" @click="saveCepariumLeonRequest()" :disabled="!permissions.find((permission) => permission == 'lab_strains_write')">
                                            <v-icon>
                                                mdi-content-save
                                            </v-icon>
                                            Guardar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="type">
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col>
                                    <v-icon>mdi-flask</v-icon>
                                    Datos de CMA
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-form ref="form_ceparium_cma" lazy-validation>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Fecha de recepción" outlined dense clearable type="date" v-model="form.receptionCMA"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Número de tubos" outlined dense clearable type="text" v-model="form.containerCMA"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Fecha preservación" outlined dense clearable type="date" v-model="form.preservationDateCMA"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" v-if="type.value != 'nematode'">
                                        <v-autocomplete label="Metodo de preservación" outlined dense clearable v-model="form.preservationMethodCMA" :items="methods" item-text="name" return-object></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-icon>mdi-warehouse</v-icon>
                                        Datos de Condiciones de Almacenamiento y Ubicación
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Localización" dense outlined v-model="form.location.container"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Localización (Preservaciones PH/Congelador)" dense outlined v-model="form.location.container_preservation"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Recipiente preservación" dense outlined v-model="form.location.saline_solution_container"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Preservación" dense outlined v-model="form.preservation"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Viales (4°C)" dense outlined v-model="form.vials.vial_4"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Viales (-80ºC)" dense outlined v-model="form.vials.vial_80"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Viales (-20ºC)" dense outlined v-model="form.vials.vial_20"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Separacion Leon (-20ºC)" dense outlined v-model="form.vials.separation_20"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="text-end">
                                        <v-btn color="primary" :loading="isLoadingSaveCepariumCMARequest" @click="saveCepariumCMARequest()" :disabled="!permissions.find((permission) => permission == 'lab_strains_write')">
                                            <v-icon>
                                                mdi-content-save
                                            </v-icon>
                                            Guardar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="type">
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col>
                                    <v-icon>mdi-image</v-icon>
                                    Evidencia fotográfica
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row style="border-bottom: solid 2px lightgray !important">
                                <v-col>
                                    <span class="text-subtitle-1 font-weight-bold">
                                        Imágenes macroscópicas
                                    </span>
                                </v-col>
                                <v-col>
                                    <v-btn color="primary" small @click="showAddMacro">
                                        <v-icon> mdi-image-plus-outline </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-carousel height="auto" v-show="filesMacroPhotos.length != 0">
                                        <v-carousel-item v-for="(item, index) in filesMacroPhotos" :key="index">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-btn x-small color="warning" fab @click="openUpdateImgModal(item)">
                                                        <v-icon> mdi-pencil </v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col>
                                                    <v-img :src="item" contain @click="showPhoto(item)">
                                                    </v-img>
                                                </v-col>
                                            </v-row>
                                        </v-carousel-item>
                                    </v-carousel>
                                    <v-carousel hide-delimiter-background v-show="filesMacroPhotos.length == 0">
                                        <v-carousel-item>
                                            <v-img :src="require('../../../assets/img/no_image_strain.png')" aspect-ratio="1.0" contain></v-img>
                                        </v-carousel-item>
                                    </v-carousel>
                                </v-col>
                            </v-row>
                            <v-row style="border-bottom: solid 2px lightgray !important">
                                <v-col>
                                    <span class="text-subtitle-1 font-weight-bold">Imágenes microscópicas
                                    </span>
                                </v-col>
                                <v-col>
                                    <v-btn color="primary" small @click="showAddMicro">
                                        <v-icon> mdi-image-plus-outline </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-carousel height="auto" v-show="filesMicroPhotos.length != 0">
                                        <v-carousel-item v-for="(item, index) in filesMicroPhotos" :key="index">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-btn x-small color="warning" fab @click="openUpdateImgModal(item)">
                                                        <v-icon> mdi-pencil </v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col>
                                                    <v-img :src="`${item}?${Date.now()}`" contain @click="showPhoto(item)">
                                                    </v-img>
                                                </v-col>
                                            </v-row>
                                            <!-- <span>
                  {{ dateFormat(item.date) }}
                </span> -->
                                        </v-carousel-item>
                                    </v-carousel>
                                    <v-carousel hide-delimiter-background v-show="filesMicroPhotos.length == 0">
                                        <v-carousel-item>
                                            <v-img :src="require('../../../assets/img/no_image_strain.png')" aspect-ratio="1.0" contain></v-img>
                                        </v-carousel-item>
                                    </v-carousel>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="type">
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col>
                                    <v-icon>mdi-bacteria</v-icon>
                                    Cepario producción / atlas
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12">
                                    <span>Identificacion secuenciación: </span>
                                    <span class="blue--text" v-if="preservedSelected.genomicSequence && preservedSelected.genomicSequence.genomicAssignation"> {{ preservedSelected.genomicSequence.genomicAssignation.name }}</span>
                                    <span v-else>No especificada</span>
                                </v-col>
                                <v-col cols="12">
                                    <span>Datos de producción: </span>
                                    <div v-if="!preservedSelected.strainRequest">
                                        <span>No relacionada</span>
                                    </div>
                                    <div v-else>
                                        <v-spacer></v-spacer>
                                        <span>Número de peticiones: </span><span> {{preservedSelected.strainRequest.countRequest}}</span>
                                        <v-spacer></v-spacer>
                                        <span>Stock: </span><span>{{ preservedSelected.strainRequest.countStock }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-row class="mt-5">
                    <v-col>
                        <v-textarea filled name="input-7-4" label="Observaciones" v-model="observations"></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field label="Url validación de primers" outlined dense clearable type="text" v-model="url"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn color="primary" @click="addValidationsLinkRequest()" :disabled="!url">
                            <v-icon>mdi-plus</v-icon>Agregar link
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-chip class="ma-2" close v-for="(item, index) in form.validationDocuments" :key="index" @click:close="removeValidationsLinkRequest(index)" @click="copyLink(item)">
                            {{ truncateText(item, 34)  }}
                        </v-chip>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-container fluid>
                <v-row>
                    <v-col>
                        <v-btn dark color="gray" block @click="closeDialog()" :disabled="isLoadingAdd">
                            Cerrar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
    <SubSamplePhoto :img="photos" :visible="isVisiblePhoto" @hideSubSampleModal="hidePhotos" />
    <AddEvidencePhotographCepariumModal v-model="showAddEvidencePhotographCepariumModal" :type="uploadFileType" @fetchTable="formattImages(), showAddEvidencePhotographCepariumModal = false" />
    <UpdateEvidencePhotographCepariumModal v-model="showUpdateEvidencePhotographCepariumModal" :imageUrl="imageToUpdate" @fetchTable="formattImages()" />
</v-dialog>
</template>

<script>
//libaries
import Compressor from "compressorjs";
//Vuex
import {
    mapActions,
    mapState,
    mapGetters,
    mapMutations
} from "vuex";
import {
    requiredValidation
} from "@/helpers/validations.js";
// components
import SubSamplePhoto from "@/components/station1/modals/SubSamplePhoto";
import AddEvidencePhotographCepariumModal from "@/components/ceparium/modals/AddEvidencePhotographCepariumModal.vue";
import UpdateEvidencePhotographCepariumModal from "@/components/ceparium/modals/UpdateEvidencePhotographCepariumModal.vue";
export default {
    components: {
        SubSamplePhoto,
        AddEvidencePhotographCepariumModal,
        UpdateEvidencePhotographCepariumModal,
    },
    data: () => ({
        panels: [],
        filesMicroPhotos: [],
        filesMacroPhotos: [],
        miniatura: null,
        isProject: false,
        linkProject: null,
        isLoading: false,
        samplesTypes: [{
                text: "Suelo",
                value: "SOIL",
            },
            {
                text: "Vegetal",
                value: "PLANT",
            },
        ],
        methods: [{
                name: "Microtubo",
                value: "MICROTUBE"
            },
            {
                name: "Tubo inclinado",
                value: "INCLINED_TUBE"
            },
            {
                name: "Discos de agr",
                value: "AGAR_DISCS"
            },
        ],
        type: null,
        identification: null,
        identificationHomology: null,
        url: null,
        isRelatedToSample: false,
        isRelatedToIsolate: false,
        searchIsolate: null,
        isolates: [],
        loadingIsolate: false,
        form: {
            strainId: null,
            receptionDate: null,
            companyName: null,
            inoculationDate: null,
            cultureMedia: null,
            identification: null,
            identificationMedia: null,
            macroscopicallyAxenic: null,
            isolatedColonies: null,
            cells_gram: null,
            sporePresence: null,
            j2: null,
            containerQtyCeparium: null,
            shippingDateMolecular: null,
            homology: null,
            validationDocuments: [],
            viability: false,
            //new fields
            folio: null,
            isolate: null,
            crop: null,
            stateOrigin: null,
            location: {
                container: null,
                container_preservation: null,
                saline_solution_container: null,
            },
            locationCentralPlant: {
                container: null,
                container_preservation: null,
                saline_solution_container: null,
            },
            preservation: null,
            preservationCentralPlant: null,
            vials: {
                vial_4: null,
                vial_80: null,
                vial_20: null,
                separation_20: null,
            },
            vialsCentralPlant: {
                vial_4: null,
                vial_80: null,
                vial_20: null,
                separation_20: null,
            },
        },
        crops_items: [],
        types: [{
                text: "Bacteria",
                value: "bacteria",
            },
            {
                text: "Hongo",
                value: "fungi",
            },
            {
                text: "Nemátodo",
                value: "nematode",
            },
            // {
            //   text: "Suelo/Vegetal",
            //   value: "sample",
            // },
        ],
        search: null,
        isLoadingAdd: false,
        searchHomology: null,
        isLoadingHomology: false,
        identificationsFoundedHomology: [],
        strainFound: null,
        rulesCode: [(v) => !!v || "Campo requerido"],
        isLoadingSaveConfigurationRequest: false,
        isLoadingSaveGeneralDataRequest: false,
        isLoadingSavePurityRequest: false,
        isLoadingSaveMolecularConfirmationRequest: false,
        isLoadingSaveCepariumLeonRequest: false,
        isLoadingSaveCepariumCMARequest: false,
        observations: null,
        showAddEvidencePhotographCepariumModal: false,
        uploadFileType: "",
        showUpdateEvidencePhotographCepariumModal: false,
        imageToUpdate: "",
        photos: [],
        isVisiblePhoto: false,
    }),
    props: {
        value: Boolean,
    },
    computed: {
        ...mapState("session", ["permissions"]),
        ...mapState("ceparium", ["preservedSelected"]),
        ...mapState("reception", ["crops"]),
        ...mapState("identification", ["identificationsFounded"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        dialogWidth() {
            if (this.$vuetify.breakpoint.xs) return "90vw"; // Extra pequeño
            if (this.$vuetify.breakpoint.sm) return "70vw"; // Pequeño
            if (this.$vuetify.breakpoint.md) return "60vw"; // Mediano
            return "50vw"; // Grande en adelante
        },
    },
    methods: {
        ...mapActions("ceparium", ["createPreservedIsolate", "findByMoSequenceId", "findExactByMoSequenceId", "saveConfiguration", "saveGeneralData", "savePurity", "saveMolecularConfirmation", "saveCepariumLeon", "saveCepariumCMA", "saveObservations", "addValidationsLink", "removeValidationsLink", ]),
        ...mapActions("strain", ["uploadStrainPhoto"]),
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        ...mapActions("identification", [
            "searchIdentifications",
            "addNematodeIdentification",
            "searchIdentificationsHomology",
        ]),
        validation(name) {
            return requiredValidation(name);
        },
        changeIdentificationValue() {
            if (!this.search) {
                this.identification = null;
            }
        },
        changeIdentificationValueHomology() {
            if (!this.searchHomology) {
                this.form.homology = null;
            }
        },
        async getIdentification() {
            try {
                if (typeof this.identification !== "object" && this.search) {
                    // New.
                    this.identification = {
                        name: this.search,
                        moType: this.type.value,
                    };
                    const response = await this.addNematodeIdentification(
                        this.identification
                    );
                    if (response.error) throw response;
                    return response.IDENTIFICATION;
                } else {
                    // identification selected
                    return this.identification;
                }
            } catch (e) {
                console.log("Error on getIdentificationId", e);
                return e;
            }
        },
        async getIdentificationHomology() {
            try {
                if (
                    typeof this.identificationHomology !== "object" &&
                    this.searchHomology
                ) {
                    // New.
                    this.identification = {
                        name: this.searchHomology,
                        moType: this.type.value,
                    };
                    const response = await this.addNematodeIdentification(
                        this.identification
                    );
                    if (response.error) throw response;
                    return response.IDENTIFICATION;
                } else {
                    // identification selected
                    return this.identificationHomology;
                }
            } catch (e) {
                console.log("Error on getIdentificationId", e);
                return e;
            }
        },
        generarMiniatura() {
            if (this.linkProject) {
                if (this.linkProject.includes(".xlsx")) {
                    this.miniatura = "doc";
                } else if (this.linkProject.includes("https://docs")) {
                    this.miniatura = "doc";
                } else {
                    this.miniatura = "other";
                }
            } else {
                this.miniatura = null;
            }
        },
        dataURLtoFile(img) {
            let arr = img.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], "evidencePhoto.png", {
                type: mime
            });
        },
        addURL() {
            if (this.url.length != 0) {
                this.form.validationDocuments.push(this.url);
                this.url = null;
            }
        },
        removeLink(index) {
            this.form.validationDocuments.splice(index, 1);
        },
        copyLink(item) {
            const textToCopy = item; // Define the text you want to copy
            // Create a temporary textarea element
            const tempTextarea = document.createElement("textarea");
            tempTextarea.value = textToCopy;
            document.body.appendChild(tempTextarea);

            // Select the text in the temporary element
            tempTextarea.select();
            tempTextarea.setSelectionRange(0, 99999); // For mobile devices

            // Attempt to copy the selected text to the clipboard
            document.execCommand("copy");

            // Remove the temporary element
            document.body.removeChild(tempTextarea);

            // Show a message or perform any other action after copying
            this.SET_ALERT_TYPE({
                visible: true,
                type: "success",
                text: "¡Texto copiado al portapapeles!",
                timeout: 3500,
            });
        },
        updateValue(value) {
            if (value) {
                this.form.isolate = JSON.parse(JSON.stringify(this.strainFound))
                this.strainFound = null
                this.$refs["search_strain"].reset();
            }
        },
        setCropItems() {
            this.crops_items = []
            let cropsArray = Object.entries(this.crops);
            for (let crop of cropsArray) {
                this.crops_items.push({
                    name: crop[1],
                    value: crop[0],
                });
            }
        },
        async verifyCode() {
            if (this.from && this.form.strainId) return
            try {
                let response = await this.findExactByMoSequenceId({
                    search: this.form.strainId
                })
                if (response.isolate) {
                    this.rulesCode.push((v) => false || 'ID de cepa ya existente')
                    this.$nextTick(() => {
                        this.$refs.strain_code.validate();
                    });
                } else {
                    this.rulesCode = [(v) => !!v || "Campo requerido"]
                    this.$nextTick(() => {
                        this.$refs.strain_code.validate();
                    });
                }
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
        },
        cleanForm() {
            this.form = {
                strainId: null,
                receptionDate: null,
                companyName: null,
                inoculationDate: null,
                cultureMedia: null,
                identification: null,
                identificationMedia: null,
                macroscopicallyAxenic: null,
                isolatedColonies: null,
                cells_gram: null,
                sporePresence: null,
                j2: null,
                containerQtyCeparium: null,
                shippingDateMolecular: null,
                homology: null,
                observations: null,
                validationDocuments: [],
                viability: false,
                //new fields
                folio: null,
                isolate: null,
                crop: null,
                stateOrigin: null,
                location: {
                    container: null,
                    container_preservation: null,
                    saline_solution_container: null,
                },
                locationCentralPlant: {
                    container: null,
                    container_preservation: null,
                    saline_solution_container: null,
                },
                preservation: null,
                preservationCentralPlant: null,
                vials: {
                    vial_4: null,
                    vial_80: null,
                    vial_20: null,
                    separation_20: null,
                },
                vialsCentralPlant: {
                    vial_4: null,
                    vial_80: null,
                    vial_20: null,
                    separation_20: null,
                },
            }
            this.isProject = false
            this.linkProject = null
            this.identification = null
            this.identificationHomology = null
            this.url = null
            this.isRelatedToSample = false
            this.isRelatedToIsolate = false
            this.searchIsolate = null
            this.isolates = []
            this.search = null
            this.searchHomology = null
            this.identificationsFoundedHomology = []
            this.strainFound = null
            this.rulesCode = [(v) => !!v || "Campo requerido"]
            this.panels = []
        },
        async mapContent() {
            // get all the content from preserved selected
            let clonedPreserved = JSON.parse(JSON.stringify(this.preservedSelected));
            let mainIdentification = clonedPreserved.genomicSequence.putativeAssignation || clonedPreserved.genomicSequence.genomicAssignation
            if (mainIdentification) {
                this.type = this.types.find(element => element.value == mainIdentification.moType)
            }

            this.isProject = clonedPreserved.research_development.urlProject ? true : false
            this.linkProject = clonedPreserved.research_development.urlProject
            this.identification = clonedPreserved.genomicSequence.putativeAssignation ? clonedPreserved.genomicSequence.putativeAssignation : null
            this.url = clonedPreserved.urlProject
            this.isRelatedToSample = clonedPreserved.folio ? true : false
            this.isRelatedToIsolate = clonedPreserved.isolate ? true : false
            this.observations = clonedPreserved.observations
            this.form = {
                strainId: clonedPreserved.genomicSequence.moSequenceId,
                receptionDate: clonedPreserved.research_development.receptionDate,
                companyName: clonedPreserved.research_development.companyName,
                inoculationDate: clonedPreserved.research_development.inoculationDate,
                cultureMedia: clonedPreserved.research_development.cultureMedia,
                identificationMedia: clonedPreserved.research_development.identificationMedia,
                macroscopicallyAxenic: clonedPreserved.research_development.macroscopicallyAxenic,
                isolatedColonies: clonedPreserved.research_development.isolatedColonies,
                cells_gram: clonedPreserved.research_development.cells_gram,
                cells_gram_type: clonedPreserved.research_development.cells_gram_type,
                sporePresence: clonedPreserved.research_development.sporePresence,
                j2: clonedPreserved.research_development.j2,
                containerQtyCeparium: clonedPreserved.research_development.containerQtyCeparium,
                shippingDateMolecular: clonedPreserved.research_development.shippingDateMolecular,
                validationDocuments: clonedPreserved.research_development.validationDocuments,
                viability: clonedPreserved.research_development.viability,
                preservationDate: clonedPreserved.research_development.preservationDate,
                preservationMethod: clonedPreserved.research_development.preservationMethod,
                receptionCMA: clonedPreserved.research_development.receptionCMA,
                containerCMA: clonedPreserved.research_development.containerCMA,
                preservationDateCMA: clonedPreserved.research_development.preservationDateCMA,
                preservationMethodCMA: clonedPreserved.research_development.preservationMethodCMA,
                //new fields
                folio: clonedPreserved.folio,
                isolate: clonedPreserved.isolate,
                crop: this.crops_items.find(element => element.value == clonedPreserved.genomicSequence.crop) || clonedPreserved.genomicSequence.crop,
                stateOrigin: clonedPreserved.stateOrigin,
                location: clonedPreserved.location || {
                    container: null,
                    container_preservation: null,
                    saline_solution_container: null,
                },
                locationCentralPlant: clonedPreserved.locationCentralPlant || {
                    container: null,
                    container_preservation: null,
                    saline_solution_container: null,
                },
                preservation: clonedPreserved.preservation,
                preservationCentralPlant: clonedPreserved.preservationCentralPlant,
                vials: clonedPreserved.vials || {
                    vial_4: null,
                    vial_80: null,
                    vial_20: null,
                    separation_20: null,
                },
                vialsCentralPlant: clonedPreserved.vialsCentralPlant || {
                    vial_4: null,
                    vial_80: null,
                    vial_20: null,
                    separation_20: null,
                },
            }
            this.formattImages();
        },
        truncateText(text, maxLength) {
            if (text.length <= maxLength) {
                return text;
            } else {
                return text.slice(0, maxLength) + "...";
            }
        },
        closeDialog() {
            this.cleanForm()
            this.$emit("fetchData");
            this.show = false
        },
        async saveConfigurationRequest() {
            if (this.$refs.form_configuration.validate()) {
                try {
                    this.isLoadingSaveConfigurationRequest = true;
                    let identification = await this.getIdentification();
                    this.form.identification = identification._id;
                    let payload = {
                        identification: this.form.identification,
                        viability: this.form.viability,
                        urlProject: this.linkProject,
                        folio: this.form.folio,
                        isRelatedToIsolate: this.preservedSelected.isolate ? true : false
                    }
                    let params = {
                        isolateId: this.preservedSelected._id,
                        genomaId: this.preservedSelected.genomicSequence._id
                    }
                    await this.saveConfiguration({
                        params: params,
                        body: payload
                    });

                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: "Información guardada",
                        timeout: 3500,
                    });
                } catch (error) {
                    console.log(error);
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "error",
                        text: `Algo salió mal. Error: ${error.message}`,
                        timeout: 3500,
                    });
                }
                this.isLoadingSaveConfigurationRequest = false;
            }
        },
        async saveGeneralDataRequest() {
            if (this.$refs.form_general_data.validate()) {
                try {
                    this.isLoadingSaveGeneralDataRequest = true;
                    let finalCrop = typeof this.form.crop == 'object' ? this.form.crop.value : this.form.crop
                    let payload = {
                        crop: finalCrop,
                        stateOrigin: this.form.stateOrigin,
                        companyName: this.form.companyName,
                        inoculationDate: this.form.inoculationDate,
                        cultureMedia: this.form.cultureMedia,
                    }
                    let params = {
                        isolateId: this.preservedSelected._id,
                        genomaId: this.preservedSelected.genomicSequence._id
                    }
                    await this.saveGeneralData({
                        params: params,
                        body: payload
                    });

                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: "Información guardada",
                        timeout: 3500,
                    });
                } catch (error) {
                    console.log(error);
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "error",
                        text: `Algo salió mal. Error: ${error.message}`,
                        timeout: 3500,
                    });
                }
                this.isLoadingSaveGeneralDataRequest = false;
            }
        },
        async savePurityRequest() {
            if (this.$refs.form_purity.validate()) {
                try {
                    this.isLoadingSavePurityRequest = true;
                    let payload = {
                        identificationMedia: this.form.identificationMedia,
                        macroscopicallyAxenic: this.form.macroscopicallyAxenic,
                        isolatedColonies: this.form.isolatedColonies,
                        cells_gram: this.form.cells_gram,
                        cells_gram_type: this.form.cells_gram_type,
                        sporePresence: this.form.sporePresence,
                        j2: this.form.j2,
                    }
                    let params = {
                        isolateId: this.preservedSelected._id,
                    }
                    await this.savePurity({
                        params: params,
                        body: payload
                    });

                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: "Información guardada",
                        timeout: 3500,
                    });
                } catch (error) {
                    console.log(error);
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "error",
                        text: `Algo salió mal. Error: ${error.message}`,
                        timeout: 3500,
                    });
                }
                this.isLoadingSavePurityRequest = false;
            }
        },
        async saveMolecularConfirmationRequest() {
            if (this.$refs.form_molecular_confirmation.validate()) {
                try {
                    this.isLoadingSaveMolecularConfirmationRequest = true;
                    let payload = {
                        shippingDateMolecular: this.form.shippingDateMolecular,
                    }
                    let params = {
                        isolateId: this.preservedSelected._id,
                    }
                    await this.saveMolecularConfirmation({
                        params: params,
                        body: payload
                    });

                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: "Información guardada",
                        timeout: 3500,
                    });
                } catch (error) {
                    console.log(error);
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "error",
                        text: `Algo salió mal. Error: ${error.message}`,
                        timeout: 3500,
                    });
                }
                this.isLoadingSaveMolecularConfirmationRequest = false;
            }
        },
        async saveCepariumLeonRequest() {
            if (this.$refs.form_ceparium_leon.validate()) {
                try {
                    this.isLoadingSaveCepariumLeonRequest = true;
                    let payload = {
                        receptionDate: this.form.receptionDate,
                        containerQtyCeparium: this.form.containerQtyCeparium,
                        preservationDate: this.form.preservationDate,
                        preservationMethod: this.form.preservationMethod,
                        preservationCentralPlant: this.form.preservationCentralPlant,
                        vialsCentralPlant: this.form.vialsCentralPlant,
                        locationCentralPlant: this.form.locationCentralPlant,
                    }
                    let params = {
                        isolateId: this.preservedSelected._id,
                    }
                    await this.saveCepariumLeon({
                        params: params,
                        body: payload
                    });

                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: "Información guardada",
                        timeout: 3500,
                    });
                } catch (error) {
                    console.log(error);
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "error",
                        text: `Algo salió mal. Error: ${error.message}`,
                        timeout: 3500,
                    });
                }
                this.isLoadingSaveCepariumLeonRequest = false;
            }
        },
        async saveCepariumCMARequest() {
            if (this.$refs.form_ceparium_cma.validate()) {
                try {
                    this.isLoadingSaveCepariumCMARequest = true;
                    let payload = {
                        receptionCMA: this.form.receptionCMA,
                        containerCMA: this.form.containerCMA,
                        preservationDateCMA: this.form.preservationDateCMA,
                        preservationMethodCMA: this.form.preservationMethodCMA,
                        preservation: this.form.preservation,
                        vials: this.form.vials,
                        location: this.form.location,
                    }
                    let params = {
                        isolateId: this.preservedSelected._id,
                    }
                    await this.saveCepariumCMA({
                        params: params,
                        body: payload
                    });

                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "success",
                        text: "Información guardada",
                        timeout: 3500,
                    });
                } catch (error) {
                    console.log(error);
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "error",
                        text: `Algo salió mal. Error: ${error.message}`,
                        timeout: 3500,
                    });
                }
                this.isLoadingSaveCepariumCMARequest = false;
            }
        },
        async addValidationsLinkRequest() {
            try {
                let payload = {
                    link: this.url,
                }
                let params = {
                    isolateId: this.preservedSelected._id,
                }
                await this.addValidationsLink({
                    params: params,
                    body: payload
                });
                this.addURL()
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: "Enlace agregado",
                    timeout: 3500,
                });
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 3500,
                });
            }

        },
        async removeValidationsLinkRequest(index) {
            try {
                let payload = {
                    link: this.form.validationDocuments[index],
                }
                let params = {
                    isolateId: this.preservedSelected._id,
                }
                await this.removeValidationsLink({
                    params: params,
                    body: payload
                });
                this.removeLink(index)
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: "Enlace removido",
                    timeout: 3500,
                });
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 3500,
                });
            }

        },
        showAddMicro() {
            this.uploadFileType = "MICRO";
            this.showAddEvidencePhotographCepariumModal = true;
            // this.isAddPhotoCaseMicro = true;
        },
        showAddMacro() {
            this.uploadFileType = "MACRO";
            this.showAddEvidencePhotographCepariumModal = true;
            // this.isAddPhotoCaseMacro = true;
        },
        hidePhotos() {
            this.photos = [];
            this.isVisiblePhoto = false;
        },
        formattImages() {
            this.filesMicroPhotos = [];
            this.filesMacroPhotos = [];
            if (this.preservedSelected.research_development && this.preservedSelected.research_development.imagesMicro) {
                for (let item of this.preservedSelected.research_development.imagesMicro) {
                    this.filesMicroPhotos.push(`${item}?${Date.now()}`);
                }
            }
            if (this.preservedSelected.research_development && this.preservedSelected.research_development.imagesMacro) {
                for (let item of this.preservedSelected.research_development.imagesMacro) {
                    this.filesMacroPhotos.push(`${item}?${Date.now()}`);
                }
            }
        },
        openUpdateImgModal(item) {
            let originalURL = item.split("-small");
            this.imageToUpdate = `${originalURL[0]}${originalURL[1]}`;
            this.showUpdateEvidencePhotographCepariumModal = true;
        },
        showPhoto(item) {
            this.photos = [{
                title: "",
                src: item,
            }, ];
            this.isVisiblePhoto = true;
        }
    },
    watch: {
        async search(value) {
            if (value) {
                this.isLoading = true;
                try {
                    const response = await this.searchIdentifications({
                        name: value,
                        moType: this.type.value,
                    });
                    if (response.error) throw response;
                } catch (e) {
                    console.log(e);
                }
                this.isLoading = false;
                // Validate error text
                if (this.errorText && this.search) {
                    this.errorText = false;
                }
            }
        },

        async searchIsolate(value) {
            try {
                let response = await this.findByMoSequenceId({
                    search: value
                })
                this.isolates = response.isolates
            } catch (error) {
                console.log(error);

            }
        },
        show(value) {
            if (value) {
                this.setCropItems()
                this.mapContent()
            }
        },
        async observations(value) {
            if (this.preservedSelected && value) {
                try {
                    let payload = {
                        observations: value,
                    }
                    let params = {
                        isolateId: this.preservedSelected._id,
                    }
                    await this.saveObservations({
                        params: params,
                        body: payload
                    });
                } catch (error) {
                    console.log(error);
                    this.SET_ALERT_TYPE({
                        visible: true,
                        type: "error",
                        text: `Algo salió mal. Error: ${error.message}`,
                        timeout: 3500,
                    });
                }
            }

        },
    },
};
</script>
