export default {
  quantityAll: 0,
  quantityAvailables: 0,
  filterTable: {
    sort: null,
    sequenciationSendStatus: null,
  },
  searchTable: null,
  itemsPerPageTable: 10,
  rowTableSelected: null,
};
