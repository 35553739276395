<template>
<v-container fluid>
    <v-row class="ma-0 d-flex align-center" style="gap: 8px;">
        <v-col class="pa-0" cols="12" md="4">
            <v-text-field label="Buscar" prepend-inner-icon="mdi-magnify" hide-details v-model="search" outlined background-color="white" dense clearable></v-text-field>
        </v-col>

        <v-col class="pa-0" cols="auto">
            <v-menu offset-y :close-on-click="true" :close-on-content-click="false" v-model="menu">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on" class="ma-0">
                        <v-icon left>mdi-filter</v-icon>
                        Filtro
                    </v-btn>
                </template>
                <v-card width="350px">
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field dense label="ID cepa" v-model="moSequenceId" clearable></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete label="Identificación (MB)" :items="microorganisms" hide-details chips v-model="identification" :search-input.sync="searchMo" :loading="isLoading" @keydown="changeIdentificationValue" item-text="name" clearable hide-no-data return-object dense></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete label="Identificación (SQ)" :items="microorganismsSQ" hide-details chips v-model="identificationSQ" :search-input.sync="searchMoSQ" :loading="isLoadingSQ" @keydown="changeIdentificationValueSQ" item-text="name" clearable hide-no-data return-object dense></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete dense label="Viabilidad" :items="enumViability" v-model="viability" item-text="name" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete dense label="TIpo de cepa" :items="strainTypes" v-model="strainType" item-text="name" clearable></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" @click="clearFilter">Restaurar</v-btn>
                        <v-btn color="primary" @click="applyFilter">Aplicar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </v-col>

        <v-col class="pa-0" cols="auto">
            <v-btn color="primary" @click="showAddNewStrainModal = true" class="ma-0" :disabled="!permissions.find((permission) => permission == 'lab_strains_write')">
                <v-icon>mdi-database-plus</v-icon>
            </v-btn>
        </v-col>

        <v-col class="pa-0" cols="auto">
            <v-btn color="primary" href="https://production-test.solena.ag/cepario-production" target="_blank" class="ma-0">
                Cepario producción
                <v-icon>mdi-arrow-top-right</v-icon>
            </v-btn>
        </v-col>
    </v-row>

    <v-row v-if="strainPreservedSelected">
        <!-- need to fomat strain to see this info preview -->
        <v-col cols="12">
            <detail-preserved-working :reseed="strainPreservedSelected" />
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-data-table :headers="headers" :items="preserved_strains" :page.sync="page" :items-per-page="10" :loading="loadTable" hide-default-footer fixed-header height="56vh" @click:row="rowSelect" :item-class="row_classes">
                            <template #[`item.genomicSequence.moSequenceId`]="{ item }">
                                <template v-if="item.genomicSequence">
                                    <span v-if="!item.isolate" :class="{ 'secondary--text': item.strainRequest }">{{ item.genomicSequence.moSequenceId }}
                                        <span v-if="item.strainRequest">(Producción)</span></span>
                                    <span v-else>{{ item.genomicSequence.moSequenceId }}-{{ item.isolateSerialNumber
                                            }}</span>
                                </template>
                                <template v-else>
                                    {{ item.testMedia }}
                                </template>

                            </template>
                            <template #[`item.genomicSequence.genomicAssignation`]="{ item }">
                                <template v-if="item.genomicSequence">
                                    <span v-if="item.genomicSequence.genomicAssignation" class="primary--text">{{ item.genomicSequence.genomicAssignation.name }}</span>
                                    <span v-else-if="item.genomicSequence.putativeAssignation" class="blue--text">{{ item.genomicSequence.putativeAssignation.name }}</span>
                                    <span v-else>No especificado</span>
                                </template>
                                <template v-else>
                                    {{ item.strain.identification.name }}
                                </template>

                            </template>
                            <template #[`item.viability`]="{ item }">
                                <template v-if="item.genomicSequence">
                                    <span v-if="item.research_development && item.research_development.viability == true" class="primary--text">
                                        Es viable
                                    </span>
                                    <span v-else class="red--text"> No viable </span>
                                </template>
                                <template v-else>
                                    N/A
                                </template>

                            </template>
                            <template #[`item.research_development.companyName`]="{ item }">
                                <template v-if="item.genomicSequence">
                                    <span v-if="item.research_development && item.research_development.companyName && item.research_development.companyName.length != 0">
                                        {{ item.research_development.companyName }}
                                    </span>
                                    <span v-else> No especificado </span>
                                </template>
                                <template v-else>
                                    N/A
                                </template>
                            </template>

                            <template #[`item.research_development.preservationDateCMA`]="{ item }">
                                <template v-if="item.genomicSequence">
                                    <span v-if="item.research_development && item.research_development.preservationDateCMA">{{
                                                dateFormat(item.research_development.preservationDateCMA) }} </span>
                                    <span v-else>----/--/--</span>
                                    <span v-if="item.research_development && item.research_development.containerCMA">
                                        - {{ item.research_development.containerCMA || 0 }} tubos
                                    </span>
                                </template>
                                <template v-else>
                                    N/A
                                </template>
                            </template>
                            <template #[`item.research_development.preservationDate`]="{ item }">
                                <template v-if="item.genomicSequence">
                                    <span v-if="item.research_development && item.research_development.preservationDate">{{
                                                dateFormat(item.research_development.preservationDate) }}</span>
                                    <span v-else>----/--/--</span>
                                    <span v-if="item.research_development && item.research_development.containerQtyCeparium">
                                        - {{ item.research_development.containerQtyCeparium || 0 }} tubos
                                    </span>
                                </template>
                                <template v-else>
                                    N/A
                                </template>
                            </template>
                            <template #[`item._id`]="{ item }">
                                <v-menu bottom right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item @click="openUpdateForm(item)">
                                            <v-list-item-title>Actualizar </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-pagination v-model="page" :length="numPages" total-visible="10" color="primary"></v-pagination>
        </v-col>
    </v-row>

    <add-new-strain-modal v-model="showAddNewStrainModal" @fetchData="fetchData" />
    <update-strain-modal v-model="showUpdateStrainModal" v-show="showUpdateStrainModal" @fetchData="fetchData" />
</v-container>
</template>

<script>
// vuex
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
//Libraries
import dayjs from "dayjs";

// components
import AddNewStrainModal from './modals/AddNewStrainModal.vue';
import UpdateStrainModal from './modals/UpdateStrainModal.vue';
export default {
    components: {
        AddNewStrainModal,
        UpdateStrainModal,
    },
    data: () => ({
        menu: null,
        extraDataMo: null,
        identification: null,
        identificationSQ: null,
        searchMo: null,
        searchMoSQ: null,
        isLoading: false,
        isLoadingSQ: false,
        microorganisms: [],
        microorganismsSQ: [],
        page: 1,
        numPages: 1,
        search: null,
        filter: {
            identificationSQ: null,
            identification: null,
            viability: null,
            moSequenceId: null,
            strainType: null,
        },
        loadTable: false,
        headers: [{
                text: "ID. Cepa",
                sortable: false,
                value: "genomicSequence.moSequenceId",
            },
            {
                text: "Microorganismo",
                value: "genomicSequence.genomicAssignation",
                sortable: false
            },
            {
                text: "Viabilidad",
                value: "viability",
                sortable: false
            },
            {
                text: "Compañia",
                value: "research_development.companyName",
                sortable: false
            },
            {
                text: "Fecha de preservación CMA",
                value: "research_development.preservationDateCMA",
                sortable: false,
            },
            {
                text: "Fecha de preservación León",
                value: "research_development.preservationDate",
                sortable: false,
            },
            {
                text: "Acciones",
                value: "_id",
                sortable: false,
            },
        ],
        viability: false,
        enumViability: [{
                name: "Es viable",
                value: "viable",
            },
            {
                name: "No es viable",
                value: "not_viable",
            },
        ],
        preserved_strains: [],
        moSequenceId: null,
        showAddNewStrainModal: false,
        showUpdateStrainModal: false,
        strainPreservedSelected: null,
        strainTypes: [{
            name: "Cepa producción",
            value: "PROD",
        }, ],
        strainType: null,
    }),
    computed: {
        ...mapState("session", ["permissions"]),
        ...mapState("ceparium", ["preservedSelected"]),
    },
    methods: {
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        ...mapActions("ceparium", ["getPrserved", "getIsolatesPreserveds"]),
        ...mapMutations("ceparium", ["SET_PRESERVED_SELECTED"]),
        ...mapActions("identification", ["searchIdentifications"]),
        changeIdentificationValue() {
            if (!this.searchMo) {
                this.identification = null;
            }
        },
        changeIdentificationValueSQ() {
            if (!this.searchMoSQ) {
                this.identificationSQ = null;
            }
        },
        dateFormat(value) {
            const date = `${dayjs(value).format("MM/DD/YYYY")}`;
            return date || "--/--/----";
        },
        rowSelect(rowValue) {
            this.preserved_strains = this.preserved_strains.map((data) => {
                if (rowValue._id == data._id) {
                    data.clicked = true;
                } else {
                    data.clicked = false;
                }
                return data;
            });
            // this.strainPreservedSelected = this.mapSelection(rowValue);
        },
        mapSelection(preserved) {
            console.log(preserved);

            return {
                _id: preserved._id,
                folioAnalysis: preserved.folio,
                idTest: "NA",
                medium: "NA",
                inoculum: preserved.identification.name,
                sampleType: "",
                businessName: "",
                analysisType: "Resiembra",
                status: preserved.isolate.ceparium_data.status,
                mbTestData: null,
                molTestData: null,
                isolate: preserved.isolate,
                strain: preserved.isolate.strain,
                laboratory: null,
                laboratory_id: null,
            };
        },
        row_classes(value) {
            if (value.clicked) {
                return "custom-highlight-row";
            }
        },
        closeDetail() {
            this.SET_PRESERVED_SELECTED(null);
            this.preserved_strains = this.preserved_strains.map((data) => {
                data.clicked = false;
                return data;
            });
        },
        async applyFilter() {
            this.filter = {};
            if (this.identificationSQ) {
                this.filter.identificationSQ = this.identificationSQ._id;
            } else {
                this.filter.identificationSQ = null;
            }

            if (this.identification) {
                this.filter.identification = this.identification._id;
            } else {
                this.filter.identification = null;
            }

            if (this.viability) {
                this.filter.viability = this.viability;
            } else {
                this.filter.viability = null;
            }

            if (this.moSequenceId) {
                this.filter.moSequenceId = this.moSequenceId;
            } else {
                this.filter.moSequenceId = null;
            }

            if (this.strainType) {
                this.filter.strainType = this.strainType;
            } else {
                this.filter.strainType = null;
            }
            this.menu = false;
            this.page = 1;
            this.numPages = 1;
            this.SET_PRESERVED_SELECTED(null);
            await this.fetchData();
        },
        async clearFilter() {
            this.identification = null;
            this.viability = null;
            this.moSequenceId = null
            this.menu = false;
            this.filter = {
                identificationSQ: null,
                identification: null,
                viability: null,
                moSequenceId: null,
                strainType: null,
            };
            this.SET_PRESERVED_SELECTED(null);
            await this.fetchData();
        },
        async fetchData(value) {
            try {
                if (value) {
                    this.SET_PRESERVED_SELECTED(value);
                }
                this.loadTable = true;
                let payload = {
                    filter: this.filter,
                    page: this.page,
                    search: this.search,
                };
                let response = await this.getIsolatesPreserveds(payload);
                if (!response) throw Error(response);
                this.preserved_strains = response.isolates;
                this.numPages = response.pages;
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 4000,
                });
            }
            this.loadTable = false;
        },
        openUpdateForm(item) {
            this.SET_PRESERVED_SELECTED(item);
            this.showUpdateStrainModal = true
        }
    },
    async mounted() {
        await this.fetchData();
    },
    watch: {
        async page() {
            await this.fetchData();
        },
        async search() {
            this.SET_PRESERVED_SELECTED(null);
            this.page = 1;
            this.numPages = 1;
            await this.fetchData();
        },
        async searchMo(value) {
            if (value) {
                this.isLoading = true;
                try {
                    const response = await this.searchIdentifications({
                        name: value,
                    });
                    if (response.error) throw response;
                    this.microorganisms = response.IDENTIFICATIONS;
                } catch (e) {
                    console.log(e);
                }
                this.isLoading = false;
            }
        },
        async searchMoSQ(value) {
            if (value) {
                this.isLoadingSQ = true;
                try {
                    const response = await this.searchIdentifications({
                        name: value,
                    });
                    if (response.error) throw response;
                    this.microorganismsSQ = response.IDENTIFICATIONS;
                } catch (e) {
                    console.log(e);
                }
                this.isLoadingSQ = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-highlight-row {
    background-color: rgba(4, 193, 5, 0.13);
    font-weight: bold;
}
</style>
