<template>
<v-card elevation="0" outlined>
    <v-card-title>
        <span class="headline">Tabla de Plásmido / Vector</span>
    </v-card-title>
    <v-card-text>
        <v-row>
            <v-col align="end">
                <v-btn color="primary" @click="showFormCreateVectorModal = true" :disabled="!permissions.find((permission) => permission == 'lab_vector_write')">
                    <v-icon> mdi-plus </v-icon>
                    Nuevo vector
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table :headers="headers" :items="vectors" :items-per-page="10" class="elevation-0" no-data-text="No hay registros" hide-default-footer fixed-header :style="{ maxHeight: '400px', overflowY: 'auto' }" :loading="isLoadingTable">

            <template #[`item.createdAt`]="{ item }">
                <span>
                    {{ dateFormat(item.createdAt) }}
                </span>
            </template>
            <template #[`item.microorganism`]="{ item }">
                <span>
                    {{ getMicroorganismLabel(item.microorganism) }}
                </span>
            </template>
            <template #[`item.status`]="{ item }">
                <span>
                    {{ getStatus(item.status) }}
                </span>
            </template>
            <template #[`item._id`]="{ item }">
                <v-container>
                    <v-btn color="primary" small class="mx-1" @click="openUpdateModal(item)" v-if="item.status == 'AVAILABLE'" :disabled="!permissions.find((permission) => permission == 'lab_vector_write')">
                        <v-icon>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn color="warning" small class="mx-1" @click="openDeleteModal(item)" v-if="item.status == 'AVAILABLE'" :disabled="!permissions.find((permission) => permission == 'lab_vector_write')">
                        <v-icon>
                            mdi-delete-forever
                        </v-icon>
                    </v-btn>
                    <v-btn color="blue" small class="mx-1" @click="openRestoreModal(item)" v-if="item.status == 'NOT_AVAILABLE'" dark :disabled="!permissions.find((permission) => permission == 'lab_vector_write')">
                        <v-icon>
                            mdi-delete-restore
                        </v-icon>
                    </v-btn>
                </v-container>
            </template>
        </v-data-table>

        <v-pagination v-model="page" :length="numPages" total-visible="10" color="primary"></v-pagination>
    </v-card-text>
    <FormCreateVectorModal v-model="showFormCreateVectorModal" @close_update="showFormCreateVectorModal = false, fetchData()" @close="showFormCreateVectorModal = false" />
    <form-update-vector-modal v-model="showFormUpdateVectorModal" @close_update="showFormUpdateVectorModal = false, fetchData()" @close="showFormUpdateVectorModal = false" />
    <confirmation-delete-vector-modal v-model="showConfirmationDeleteVectorModal" @close_update="showConfirmationDeleteVectorModal = false, fetchData()" @close="showConfirmationDeleteVectorModal = false" />
    <confirmation-restore-vector-modal v-model="showConfirmationRestoreVectorModal" @close_update="showConfirmationRestoreVectorModal = false, fetchData()" @close="showConfirmationRestoreVectorModal = false" />
</v-card>
</template>

<script>
// VUEX
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
//Libraries
import dayjs from "dayjs";
// COMPONENTS
import FormCreateVectorModal from "./modals/FormCreateVectorModal.vue";
import FormUpdateVectorModal from './modals/FormUpdateVectorModal.vue';
import ConfirmationDeleteVectorModal from './modals/ConfirmationDeleteVectorModal.vue';
import ConfirmationRestoreVectorModal from './modals/ConfirmationRestoreVectorModal.vue';

export default {
    data: () => ({
        headers: [{
                text: "Fecha almacenamiento",
                sortable: false,
                align: "center",
                value: "createdAt",
            },
            {
                text: "ID",
                sortable: false,
                value: "ampliconId",
            },
            {
                text: "Microorganismo",
                sortable: false,
                value: "microorganism",
            },
            {
                text: "Cantidad",
                sortable: false,
                value: "quantity",
            },
            {
                text: "Estatus",
                sortable: false,
                value: "status",
            },
            {
                text: "Acciones",
                sortable: false,
                value: "_id",
            },
        ],
        vectors: [],
        page: 1,
        numPages: 1,
        isLoadingTable: false,
        showFormCreateVectorModal: false,
        showFormUpdateVectorModal: false,
        showConfirmationDeleteVectorModal: false,
        showConfirmationRestoreVectorModal: false,
    }),
    components: {
        FormCreateVectorModal,
        FormUpdateVectorModal,
        ConfirmationDeleteVectorModal,
        ConfirmationRestoreVectorModal,
    },
    computed: {
        ...mapState("vector", ["filterTable", "searchTable", "itemsPerPageTable"]),
        ...mapState("session", ["permissions"]),
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        ...mapActions("vector", ["getVectors"]),
        ...mapMutations("vector", ["SET_DATA_IDICATORS_TABLE", "SET_ROW_TABLE"]),
        ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
        async fetchData() {
            try {
                this.isLoadingTable = true;
                let payload = {
                    filter: this.filterTable,
                    page: this.page,
                    search: this.searchTable,
                    perPageItems: this.itemsPerPageTable,
                };
                let response = await this.getVectors(payload);
                this.numPages = response.pages;
                this.vectors = response.vectors;
                this.SET_DATA_IDICATORS_TABLE({
                    quantityAll: response.quantityAll,
                    quantityAvailables: response.quantityAvailables,
                });
            } catch (error) {
                console.log(error);
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `Algo salió mal. Error: ${error.message}`,
                    timeout: 5000,
                });
            }
            this.isLoadingTable = false;
        },
        async reloadTable() {
            this.page = 1;
            await this.fetchData();
        },
        dateFormat(value) {
            const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
            return date || "--/--/----";
        },
        getStatus(value) {
            let status = {
                AVAILABLE: "Disponible",
                NOT_AVAILABLE: "No disponible"
            }
            return status[value] || "NA"
        },
        getMicroorganismLabel(value) {
            let moTypes = {
                "fungi": "Hongo",
                "nematode": "Nematodo",
                "bacteria": "Bacteria",
            }
            return `${value.name} (${moTypes[value.moType]})`
        },
        openUpdateModal(item) {
            this.SET_ROW_TABLE(item)
            this.showFormUpdateVectorModal = true
        },
        openDeleteModal(item) {
            this.SET_ROW_TABLE(item)
            this.showConfirmationDeleteVectorModal = true
        },
        openRestoreModal(item) {
            this.SET_ROW_TABLE(item)
            this.showConfirmationRestoreVectorModal = true
        },
        addVectors() {

        }
    },
    watch: {
        async searchTable() {
            await this.reloadTable();
        },
        async itemsPerPageTable() {
            await this.reloadTable();
        },
        async page() {
            await this.fetchData();
        },
        async filterTable() {
            await this.reloadTable();
        },
    },
};
</script>

<style scoped>
.headline {
    font-size: 1.5rem;
    font-weight: bold;
}
</style>
