import axios from "axios";

export async function getMolecularMarkerByName({ commit }, payload) {
  try {
    const { data } = await axios.get("/marker/molecular-marker-by-name", {
      params: payload,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function createNewMarkerWithDraft({ commit }, payload) {
  try {
    const { data } = await axios.post("/marker/create-and-draft", payload);
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function getOligoHistoryByMarker({ commit }, payload) {
  try {
    const { data } = await axios.get("/marker/oligo-history-by-marker", {
      params: payload,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function saveDraft({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/marker/save-draft", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function finishOligo({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/marker/finish-oligo", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function discardOligo({ commit }, { params }) {
  try {
    const { data } = await axios.put(
      "/marker/disacard-oligo",
      {},
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function addNewOligo({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/marker/add-new-oligo", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function addTagOligo({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/marker/add-tag-oligo", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function startSynthesis({ commit }, { params }) {
  try {
    const { data } = await axios.put(
      "/marker/start-synthesis",
      {},
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function endSynthesis({ commit }, { params }) {
  try {
    const { data } = await axios.put(
      "/marker/end-synthesis",
      {},
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function startValidations({ commit }, { params }) {
  try {
    const { data } = await axios.put(
      "/marker/start-validations",
      {},
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function createMolecularMarker({ commit }, payload) {
  try {
    const { data } = await axios.post("/marker/create-v2", payload);
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function updateMolecularMarker({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/marker/update", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function addItemToResult({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/marker/add-item-to-result", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function removeItemToResult({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/marker/remove-item-to-result", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function inlcusivitySetupDone({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/marker/inlcusivity-setup-done", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function addInlcusivityObservations({ commit }, { params, body }) {
  try {
    const { data } = await axios.put(
      "/marker/add-inlcusivity-observations",
      body,
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function uploadResultsInclusivity({ commit }, payload) {
  try {
    const { data } = await axios.put(
      "/marker/upload-results-inclusivity-file",
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (data.error) throw data;

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function cleanAllResultsInclusivity({ commit }, { params, body }) {
  try {
    const { data } = await axios.put(
      "/marker/clean-all-results-inclusivity",
      body,
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function selectItemResultForPositiveControl(
  { commit },
  { params, body }
) {
  try {
    const { data } = await axios.put("/marker/select-positive-control", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function exclusivitySameGenusToOtherSpeciesSetupDone(
  { commit },
  { params, body }
) {
  try {
    const { data } = await axios.put(
      "/marker/exclusivity-samegenus-to-otherspecies-setup-done",
      body,
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function addExclusivitySameGenusToOtherSpeciesObservations(
  { commit },
  { params, body }
) {
  try {
    const { data } = await axios.put(
      "/marker/add-exclusivity-samegenus-to-otherspecies-observations",
      body,
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function uploadResultsExclusivitySameGenusToOtherSpecies(
  { commit },
  payload
) {
  try {
    const { data } = await axios.put(
      "/marker/upload-results-exclusivity-samegenus-to-otherspecies-file",
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (data.error) throw data;

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function cleanAllResultsExclusivitySameGenusToOtherSpecies(
  { commit },
  { params, body }
) {
  try {
    const { data } = await axios.put(
      "/marker/clean-all-results-exclusivity-samegenus-to-otherspecies",
      body,
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function exclusivityOtherGenusSetupDone(
  { commit },
  { params, body }
) {
  try {
    const { data } = await axios.put(
      "/marker/exclusivity-othergenus-setup-done",
      body,
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function cleanAllResultsExclusivityOtherGenus(
  { commit },
  { params, body }
) {
  try {
    const { data } = await axios.put(
      "/marker/clean-all-results-exclusivity-othergenus",
      body,
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function addExclusivityOtherGenusObservations(
  { commit },
  { params, body }
) {
  try {
    const { data } = await axios.put(
      "/marker/add-exclusivity-othergenus-observations",
      body,
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function uploadResultsExclusivityOtherGenus({ commit }, payload) {
  try {
    const { data } = await axios.put(
      "/marker/upload-results-exclusivity-othergenus-file",
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (data.error) throw data;

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function cleanAllResultsSoilTest({ commit }, { params, body }) {
  try {
    const { data } = await axios.put(
      "/marker/clean-all-results-soil-test",
      body,
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function soilTestSetupDone({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/marker/soil-test-setup-done", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function addSoilTestObservations({ commit }, { params, body }) {
  try {
    const { data } = await axios.put(
      "/marker/add-soil-test-observations",
      body,
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function uploadResultsSoilTest({ commit }, payload) {
  try {
    const { data } = await axios.put(
      "/marker/upload-results-soil-test-file",
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (data.error) throw data;

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function updateResultReplicasNumber({ commit }, { params, body }) {
  try {
    const { data } = await axios.put(
      "/marker/update-results-replicas-number",
      body,
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function sensibilitySetupDone({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/marker/sensibility-setup-done", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function addSensibilityObservations({ commit }, { params, body }) {
  try {
    const { data } = await axios.put(
      "/marker/add-sensibility-observations",
      body,
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function getLastOligoFromMarkerList({ commit }, payload) {
  try {
    const { data } = await axios.post("/marker/get-last-oligo-list", payload);
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function uploadResultsSensibility({ commit }, payload) {
  try {
    const { data } = await axios.put(
      "/marker/upload-results-sensibility-file",
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (data.error) throw data;

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function cleanAllResultsSensibility({ commit }, { params, body }) {
  try {
    const { data } = await axios.put(
      "/marker/clean-all-results-sensibility",
      body,
      {
        params: params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function finishValidationMarker({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/marker/finish-validation-marker", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function releasePrimerVersion({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/marker/release-primer", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function notifyMarker({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/marker/notify-markers", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function updateFluorophore({ commit }, { params, body }) {
  try {
    const { data } = await axios.put("/marker/update-fluorophore", body, {
      params: params,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}


