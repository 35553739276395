<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" small>
        <v-icon small dark> mdi-pencil </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Edición de reacción ({{ reaction_data.reaction }})</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete v-model="reaction.crops" :items="crops_available" label="Cultivo/s" item-text="es_text"
                return-object dense multiple>
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggle">
                    <v-list-item-content>
                      <v-list-item-title> Seleccionar todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ index, item }">
                  <v-chip v-if="index < 3" color="primary accent-4" dark label small>
                    {{ item.es_text }}
                  </v-chip>

                  <small v-else-if="index === 3" class="grey--text text--darken-3">
                    +{{ reaction.crops.length - 3 }} Cultivo/s
                  </small>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="1">
              <VCheckbox v-model="mos_selected" value="fam" :disabled="reaction.fam == null"></VCheckbox>
            </v-col>
            <v-col cols="7">
              <v-autocomplete v-model="reaction.fam" :auto-select-first="true" :items="mosAvailable"
                label="Microorganismo (FAM)" hide-details return-object clearable item-text="name"
                item-value="markerId">
                <template v-slot:item="data">
                  <div v-if="data.item.markerType == 'MO'">
                    {{ data.item.name }} - {{ data.item.molecularMarkerID }} - ({{ getMoType(data.item.moType) }} |
                    {{ isPathogen(data.item.isPathogen) }})
                  </div>
                  <div v-else>
                    {{ data.item.name }} - {{ data.item.molecularMarkerID }} -  |
                    {{ data.item.metabolicPathway }}
                  </div>
                </template>
              </v-autocomplete>
              <template v-if="reaction.fam && reaction.fam.packages">
                <v-chip v-for="(pack, index) in reaction.fam.packages" class="mt-2" color="secondary" small
                  :close="!permissions.find((per) => per == 'lab_unedit')"
                  @click:close="delete_package(reaction.fam, pack)" :key="index">{{ pack.name }}</v-chip>
              </template>
            </v-col>
            <v-col cols="4">
              <span v-if="reaction.fam">
                <div v-if="reaction.fam.markerType == 'MO'">
                  ({{ getMoType(reaction.fam.moType) }} |
                  {{ isPathogen(reaction.fam.isPathogen) }})
                </div>
                <div v-else>
                  {{ reaction.fam.metabolicPathway }}
                </div>
              </span>
            </v-col>
            <v-col cols="1">
              <VCheckbox v-model="mos_selected" value="hex" :disabled="reaction.hex == null"></VCheckbox>
            </v-col>
            <v-col cols="7">
              <v-autocomplete v-model="reaction.hex" :auto-select-first="true" :items="mosAvailable"
                label="Microorganismo (HEX)" hide-details return-object clearable item-text="name"
                item-value="markerId">
                <template v-slot:item="data">
                  <div v-if="data.item.markerType == 'MO'">
                    {{ data.item.name }} - {{ data.item.molecularMarkerID }} - ({{ getMoType(data.item.moType) }} |
                    {{ isPathogen(data.item.isPathogen) }})
                  </div>
                  <div v-else>
                    {{ data.item.name }} - {{ data.item.molecularMarkerID }} -  |
                    {{ data.item.metabolicPathway }}
                  </div>
                </template>
              </v-autocomplete>
              <template v-if="reaction.hex && reaction.hex.packages">
                <v-chip v-for="(pack, index) in reaction.hex.packages" class="mt-2" color="secondary" small
                  :close="!permissions.find((per) => per == 'lab_unedit')"
                  @click:close="delete_package(reaction.hex, pack)" :key="index">{{ pack.name }}</v-chip>
              </template>
            </v-col>
            <v-col cols="4">
              <span v-if="reaction.hex">
                <div v-if="reaction.hex.markerType == 'MO'">
                  ({{ getMoType(reaction.hex.moType) }} |
                  {{ isPathogen(reaction.hex.isPathogen) }})
                </div>
                <div v-else>
                  {{ reaction.hex.metabolicPathway }}
                </div>
              </span>
            </v-col>
            <v-col cols="1">
              <VCheckbox v-model="mos_selected" value="cy5" :disabled="reaction.cy5 == null"></VCheckbox>
            </v-col>
            <v-col cols="7">
              <v-autocomplete v-model="reaction.cy5" :auto-select-first="true" :items="mosAvailable"
                label="Microorganismo (CY5)" hide-details return-object clearable item-text="name"
                item-value="markerId">
                <template v-slot:item="data">
                  <div v-if="data.item.markerType == 'MO'">
                    {{ data.item.name }} - {{ data.item.molecularMarkerID }} - ({{ getMoType(data.item.moType) }} |
                    {{ isPathogen(data.item.isPathogen) }})
                  </div>
                  <div v-else>
                    {{ data.item.name }} - {{ data.item.molecularMarkerID }} -  |
                    {{ data.item.metabolicPathway }}
                  </div>
                </template>
              </v-autocomplete>
              <template v-if="reaction.cy5 && reaction.cy5.packages">
                <v-chip v-for="(pack, index) in reaction.cy5.packages" class="mt-2" color="secondary" small
                  :close="!permissions.find((per) => per == 'lab_unedit')"
                  @click:close="delete_package(reaction.cy5, pack)" :key="index">{{ pack.name }}</v-chip>
              </template>
            </v-col>
            <v-col cols="4">
              <span v-if="reaction.cy5">
                <div v-if="reaction.cy5.markerType == 'MO'">
                  ({{ getMoType(reaction.cy5.moType) }} |
                  {{ isPathogen(reaction.cy5.isPathogen) }})
                </div>
                <div v-else>
                  {{ reaction.cy5.metabolicPathway }}
                </div>
              </span>
            </v-col>
            <template v-if="mos_selected.length > 0">
              <v-col cols="12">
                Asignación de marcadores moleculares a paquetes
                <v-divider />
              </v-col>
              <v-col cols="9">
                <v-autocomplete v-model="package_selected" :auto-select-first="true" :items="packages_available"
                  label="Paquetes disponibles" return-object item-text="name" hide-details
                  no-data-text="No hay paquetes disponibles para esta reacción">
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-btn color="success" block :disabled="packages_available.length == 0" @click="assign_packages">Asignar
                  paquete</v-btn>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" text @click="dialog = false"> Cerrar </v-btn>
        <v-btn color="primary" text @click="updateReaction" v-if="!permissions.find((per) => per == 'lab_unedit')">
          Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cropsJson from "@/data/crops.json";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  props: {
    laboratory: {
      type: Object,
      default: null,
      required: true,
    },
    reaction_data: {
      type: Object,
      default: null,
      required: true,
    },
    packages: {
      type: Array,
      required: true,
      default: [],
    },
  },
  data() {
    return {
      dialog: false,
      all_crops: cropsJson,
      reaction: {
        reaction: "-",
        crops: [],
        fam: null,
        famPathogen: false,
        hex: null,
        hexPathogen: false,
        cy5: null,
        cy5Pathogen: false,
      },
      mosAvailable: [],
      mos_selected: [],
      packages_available: [],
      package_selected: null,
    };
  },
  computed: {
    ...mapState("session", ["permissions"]),
    crops_available() {
      return this.laboratory.cmda_properties.crops.map((crop_lab) =>
        this.all_crops.find((crop_json) => crop_json.en_text === crop_lab)
      );
    },
    selectedAllCrops() {
      return this.crops_available.length === this.reaction.crops.length;
    },
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        this.reaction = {
          ...this.reaction_data,
          crops: this.reaction_data.crops.map((crop_en) =>
            this.crops_available.find((c) => c.en_text === crop_en)
          ),
        };
        this.findValidPackages();
        await this.getMos();
      } else {
        this.reaction = {
          reaction: "-",
          crops: [],
          fam: null,
          famPathogen: false,
          hex: null,
          hexPathogen: false,
          cy5: null,
          cy5Pathogen: false,
        };
        this.mosAvailable = [];
        this.mos_selected = [];
        this.packages_available = [];
        this.package_selected = null;
      }
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("identification", ["getAllIdentifications"]),
    ...mapActions("laboratory", [
      "updateLaboratory",
      "updatePackage",
      "getMarkersAll",
    ]),
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAllCrops) {
          this.reaction.crops = [];
        } else {
          this.reaction.crops = this.crops_available.slice();
        }
      });
    },
    async getMos() {
      try {
        const req = await this.getMarkersAll();
        // const req = await this.getAllIdentifications();
        if (req.error) {
          throw Error("Error al consultar los nombres de los microorganismos");
        }

        this.mosAvailable = req.molecularMarkers;
        // this.mosAvailable = req.IDENTIFICATIONS;
        console.log(req);
      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
    },
    getMoType(moType) {
      if (moType == "nematode") {
        return "Nemátodo";
      } else if (moType == "bacteria") {
        return "Bacteria";
      } else if (moType == "fungi") {
        return "Hongo";
      }
    },
    async updateReaction() {
      try {
        const cmda_props_aux = { ...this.laboratory.cmda_properties };
        const indexReaction = cmda_props_aux.mos.findIndex(
          (mo) => mo.reaction === this.reaction_data.reaction
        );
        const reactionAux = { ...this.reaction };

        cmda_props_aux.mos[indexReaction] = {
          ...reactionAux,
          crops: this.reaction.crops.map((crop) =>
            crop.en_text ? crop.en_text : crop
          ),
        };

        if (this.reaction.crops.length > 0) {
          const lab_body_req = {
            _id: this.laboratory._id,
            cmda_properties: cmda_props_aux,
          };

          const req = await this.updateLaboratory(lab_body_req);

          if (req.error) {
            throw Error(req);
          }

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Reacción actualizada correctamente",
            timeout: 4000,
          });

          this.$emit("reactionUpdated");

          this.dialog = false;
        } else {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Debes seleccionar uno o más cultivos",
            timeout: 4000,
          });
        }
      } catch (error) { }
    },
    findValidPackages() {
      let packagesAvailable = [];
      let cropsReaction = this.reaction_data.crops;

      for (const pack of this.packages) {
        const cropsPackage = pack.crop;

        for (const cropPack of cropsPackage) {
          const cropp = cropsReaction.find((c) => c == cropPack);
          if (cropp) {
            packagesAvailable.push(pack);
            break;
          }
        }
      }

      this.packages_available = packagesAvailable;
    },
    async assign_packages() {
      try {
        let packages_to_updated = [];

        if (this.package_selected != null) {
          const cmda_props_aux = { ...this.laboratory.cmda_properties };
          const indexReaction = cmda_props_aux.mos.findIndex(
            (mo) => mo.reaction === this.reaction_data.reaction
          );
          let reactionAux = { ...this.reaction };
          let packageAux = { ...this.package_selected };

          // Recorrido de MOs seleccionados
          for (const mo of this.mos_selected) {
            const mo_name = reactionAux[mo].name;
            const markerId = reactionAux[mo].markerId;
            const isPathogen = reactionAux[mo].isPathogen;
            // Validación de paquetes existentes
            if (!reactionAux[mo].packages) {
              reactionAux[mo].packages = [
                {
                  _id: this.package_selected._id,
                  name: this.package_selected.name,
                },
              ];
            } else {
              const existsPackage = reactionAux[mo].packages.find(
                (p) => p.name == this.package_selected.name
              );

              if (!existsPackage) {
                reactionAux[mo].packages.push({
                  _id: this.package_selected._id,
                  name: this.package_selected.name,
                });
              }
            }

            const mo_package = packageAux.mos.find((m) => m.name == mo_name);

            if (!mo_package) {
              packageAux.mos.push({
                identification: reactionAux[mo]._id,
                name: mo_name,
                reaction: reactionAux.reaction,
                markerId: markerId,
                isPathogen: isPathogen,
              });
            }
          }

          packages_to_updated.push(packageAux);

          cmda_props_aux.mos[indexReaction] = {
            ...reactionAux,
            crops: this.reaction.crops.map((crop) =>
              crop.en_text ? crop.en_text : crop
            ),
          };

          if (this.reaction.crops.length > 0) {
            const lab_body_req = {
              _id: this.laboratory._id,
              cmda_properties: cmda_props_aux,
            };

            const req = await this.updateLaboratory(lab_body_req);

            if (req.error) {
              throw Error(req);
            }

            for (const packUpdated of packages_to_updated) {
              const req2 = await this.updatePackage(packUpdated);

              if (req2.error) {
                throw Error(req2);
              }
            }

            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: "Asignacción correcta.",
              timeout: 4000,
            });

            this.$emit("reactionUpdated");

            // this.dialog = false;
          } else {
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: "Debes seleccionar uno o más cultivos",
              timeout: 4000,
            });
          }
        } else {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Debes seleccioanr un paquete",
            timeout: 4000,
          });
        }
      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error al hacer la asignación. Error: ${error.message}`,
          timeout: 4000,
        });
      }
    },
    async delete_package(data, pack) {
      try {
        let reactionAux = { ...this.reaction };
        let packageAux = this.packages.find((p) => p._id == pack._id);
        packageAux = { ...packageAux };

        const cmda_props_aux = { ...this.laboratory.cmda_properties };
        const indexReaction = cmda_props_aux.mos.findIndex(
          (mo) => mo.reaction === this.reaction_data.reaction
        );

        if (reactionAux.fam && reactionAux.fam.name == data.name) {
          reactionAux.fam.packages = reactionAux.fam.packages.filter(
            (p) => p._id != pack._id
          );
          packageAux.mos = packageAux.mos.filter(
            (m) => m.identification != reactionAux.fam._id
          );
        } else if (reactionAux.hex && reactionAux.hex.name == data.name) {
          reactionAux.hex.packages = reactionAux.hex.packages.filter(
            (p) => p._id != pack._id
          );
          packageAux.mos = packageAux.mos.filter(
            (m) => m.identification != reactionAux.hex._id
          );
        } else if (reactionAux.cy5 && reactionAux.cy5.name == data.name) {
          reactionAux.cy5.packages = reactionAux.cy5.packages.filter(
            (p) => p._id != pack._id
          );
          packageAux.mos = packageAux.mos.filter(
            (m) => m.identification != reactionAux.cy5._id
          );
        }

        const packages_to_updated = [packageAux];

        cmda_props_aux.mos[indexReaction] = {
          ...reactionAux,
          crops: this.reaction.crops.map((crop) =>
            crop.en_text ? crop.en_text : crop
          ),
        };

        if (this.reaction.crops.length > 0) {
          const lab_body_req = {
            _id: this.laboratory._id,
            cmda_properties: cmda_props_aux,
          };

          const req = await this.updateLaboratory(lab_body_req);

          if (req.error) {
            throw Error(req);
          }

          for (const packUpdated of packages_to_updated) {
            const req2 = await this.updatePackage(packUpdated);

            if (req2.error) {
              throw Error(req2);
            }
          }

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Se eliminó el paquete del marcador molecular",
            timeout: 4000,
          });

          this.$emit("reactionUpdated");
        } else {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Debes seleccionar uno o más cultivos",
            timeout: 4000,
          });
        }
      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error al remover el paquete del marcador molecular. Error: ${error.message}`,
          timeout: 4000,
        });
      }
    },
    isPathogen(value) {
      if (value) {
        return "Patógeno";
      } else {
        return "Benéfico";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
